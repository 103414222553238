import React, { useState, useEffect } from "react";
import { Button, Text, Flex, Image } from "components";
import { colors } from "consts";
import { useRecoilState } from "recoil";
import { selectedEventsSelector } from "atoms";
import * as S from "./AdminTable.styled";

// To-do:
// Add link to event/account details pages

export default ({ edit, update, type }) => {
  const [selectedEvents, setSelectedEvents] = useRecoilState(
    selectedEventsSelector
  );
  const [currentEvents, setCurrentEvents] = useState(selectedEvents);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCurrentEvents(selectedEvents);
  }, [selectedEvents]);

  const handleToggle = async (attribute) => {
    const updatedEvents = currentEvents.map((event) => ({
      ...event,
      [attribute]: !event[attribute],
    }));

    for (const event of updatedEvents) {
      setIsLoading(true);
      await update(event);
      setIsLoading(false);
    }

    setCurrentEvents(updatedEvents);
  };

  const handleSetAllTrue = async (attribute) => {
    const updatedEvents = currentEvents.map((event) => ({
      ...event,
      [attribute]: true,
    }));

    for (const event of updatedEvents) {
      setIsLoading(true);
      await update(event);
      setIsLoading(false);
    }

    setCurrentEvents(updatedEvents);
  };

  return (
    <S.SelectionBarContainer>
      <Flex>
        <Image
          width="16px"
          margin="0 16px 0 0"
          cursor="pointer"
          src={require("assets/images/icon-deselect.svg").default}
          onClick={() => {
            setSelectedEvents([]);
          }}
        />
        <Text size="14px" color={colors.white}>
          {currentEvents.length} Selected
        </Text>
      </Flex>
      <Flex justify="flex-end">
        {currentEvents.length > 1 ? (
          <>
            <Button
              className="secondary"
              margin="0 12px 0 0"
              onClick={() => handleSetAllTrue("archived")}
            >
              Mark all as archived
            </Button>
            <Button
              className="secondary"
              onClick={() => handleSetAllTrue("active")}
            >
              Mark all as active
            </Button>
          </>
        ) : (
          <>
            <S.ActiveSwitch
              active={currentEvents[0]?.active}
              onClick={() => handleToggle("active")}
            >
              Active
            </S.ActiveSwitch>
            <S.ArchivedSwitch
              archived={currentEvents[0]?.archived}
              onClick={() => handleToggle("archived")}
              margin="0 12px 0 0"
            >
              Archived
            </S.ArchivedSwitch>
            <Button className="secondary" margin="0 12px 0 0">
              Event details
            </Button>
            <Button className="secondary" margin="0 12px 0 0">
              Account details
            </Button>
            <Button primary onClick={() => edit(currentEvents[0]?.id)}>
              Edit event
            </Button>
          </>
        )}
      </Flex>
    </S.SelectionBarContainer>
  );
};
