import axiosRequest from "./axios.config";

export const submit = async (id, comment) =>
  await axiosRequest.post(`/v1/pages/${id}/comments`, comment);

export const getAll = async (id) => {
  const promise = new Promise((resolve, reject) => {
    const _comments = [];

    async function getCommentsPage(url) {
      let _query;
      if (url) {
        _query = await axiosRequest.get(url);
      } else {
        _query = await axiosRequest.get(`/v1/pages/${id}/comments`);
      }
      for (var i in _query.data.data) _comments.push(_query.data.data[i]);
      if (_query.data.next) {
        getCommentsPage(_query.data.next);
      } else {
        resolve(_comments);
      }
    }

    getCommentsPage();
  });

  return promise;
};

export const get = async (id) => {
  const promise = new Promise((resolve, reject) => {
    const _comments = [];

    async function getCommentsPage(url) {
      let _query;
      if (url) {
        _query = await axiosRequest.get(url + "?is_a_dad=true");
      } else {
        _query = await axiosRequest.get(
          `/v1/pages/${id}/comments?is_a_dad=true`
        );
      }
      for (var i in _query.data.data) _comments.push(_query.data.data[i]);
      if (_query.data.next) {
        getCommentsPage(_query.data.next);
      } else {
        resolve(_comments);
      }
    }

    getCommentsPage();
  });

  return promise;
};

export const updateCommentSortOrder = async (id, comments) =>
  await axiosRequest.patch(`/v1/pages/${id}/comments/sort_order`, comments);

export const remove = async (id, commentId) =>
  await axiosRequest.delete(`/v1/pages/${id}/comments/${commentId}`);

export const update = async (id, comment) =>
  await axiosRequest.delete(`/v1/pages/${id}/comments/${comment.id}`, comment);
