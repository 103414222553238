import React, { useEffect } from "react";
import { Flex, Text, Checkbox } from "components";

export default ({
  tosAccepted,
  setTosAccepted,
  toggleTos,
  margin,
  width,
  dark,
  light,
}) => {
  useEffect(() => {
    setTosAccepted && setTosAccepted(false);
    // eslint-disable-next-line
  }, []);

  const handleClick = () => toggleTos();

  return (
    <Flex
      direction="row"
      align="center"
      margin={margin ? margin : ""}
      minwidth={width ? width : ""}
    >
      <Checkbox
        checked={tosAccepted}
        onClick={() => handleClick()}
        selected={tosAccepted}
        dark={dark}
        light={light}
      />
      <Text size="18px" color={dark ? "black" : "white"}>
        I accept the{" "}
        <a
          target="_blank"
          href="https://www.showandtell.film/privacy"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>{" "}
        Agreement
      </Text>
    </Flex>
  );
};
