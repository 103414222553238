import axiosRequest from "./axios.config";
import queryClient from "../rQuery/queryClient";

export const getAllLeads = async (searchTerm = "", page = "") => {
  const queryParams = `?page=${page}&searchTerm=${searchTerm}`;
  return await axiosRequest.get(`/v1/admin/leads${queryParams}`);
};

export const getAllLeadOwners = async (
  searchTerm = "",
  page = "",
  { stage = "", source = "", tag = "" }
) => {
  return await axiosRequest.get(
    `/v1/admin/leadowners?search_term=${searchTerm}&page=${page}&stage=${stage}&source=${source}&tag=${tag}`
  );
};

export const updateStage = async (id, stage) => {
  let queryParams = `?id=${id}&stage=${stage}`;

  try {
    return axiosRequest.patch(`/v1/admin/leadowner/stage${queryParams}`);
  } catch (err) {
    console.error("ERROR: updating leadowner stage", err);
    return err;
  }
};

export const leadOwner = async (leadOwnerId) => {
  let queryParams = `?id=${leadOwnerId}`;
  return await axiosRequest.get(`/v1/admin/leadowner${queryParams}`);
};

export const deleteLeadOwner = async (leadownerId) => {
  let queryParams = `?id=${leadownerId}`;
  await axiosRequest.delete(`/v1/admin/leadowner${queryParams}`);
  queryClient.invalidateQueries(["leadowners"]);
  return;
};

export const patchLeadOwnerById = async (leadOwnerInfo) => {
  let queryParams = `?id=${leadOwnerInfo.id}`;
  return await axiosRequest.patch(
    `/v1/admin/leadowner${queryParams}`,
    leadOwnerInfo
  );
};

export const manualLead = async ({
  // url
  type,
  leadowner_id,
  // body
  first_name,
  last_name,
  email,
  stage,
  film_count,
  documentary_filmmaker,
}) => {
  let queryParams = `?`;
  queryParams = `${queryParams}type=${type}`;
  if (leadowner_id) queryParams = `${queryParams}leadowner_id=${leadowner_id}`;

  const body = {
    first_name,
    last_name,
    email,
    stage,
    film_count,
    documentary_filmmaker,
  };

  return await axiosRequest.post(`v1/admin/manual/lead${queryParams}`, body);
};

export const bulkEditLeadOwners = async (leadOwnerIds, attributes) => {
  return await axiosRequest.patch(`/v1/admin/leadowner/bulk`, {
    leadOwnerIds,
    attributes,
  });
};
