import { Button, Flex, Text } from "components";

export default ({ setStep }) => {
  return (
    <Flex column gap="24px">
      <Text kind="h2" size="24px" weight="bold">
        Would you like to duplicate an existing event?
      </Text>

      <Flex gap="12px" center>
        <Button onClick={() => setStep("duplicate-event-url")}>Yes</Button>
        <a href="/create-diy-event">
          <Button>No</Button>
        </a>
      </Flex>
    </Flex>
  );
};
