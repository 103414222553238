import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Markdown,
  Input,
  Button,
  Textarea,
  Player,
} from "components";
import { AddAboutPhotos } from "./AddAboutPhotos";
import { validateDashboardInputs } from "utils";
import { colors } from "consts";
import * as S from "./DashboardAbout.styled";

export default ({
  screening,
  id,
  update,
  patch,
  setStep,
  isExisting,
  loading,
}) => {
  const [information, setInformation] = useState({});
  const [about, setAbout] = useState();
  const [errors, setErrors] = useState({});
  const [editMessageVideo, setEditMessageVideo] = useState(true);

  useEffect(() => {
    screening && id && setInformation({ ...screening, id: id, ...information });
    // eslint-disable-next-line
  }, [screening, id]);

  const updateAbout = (e) => {
    const updates = { ...information };
    updates.aboutSettings[e.target.name] = e.target.value;
    setInformation(updates);

    if (e.target.name === "messageVideo") {
      setEditMessageVideo(false);
      update(updates);
    }

    e.target.name === "message" && setAbout(e.target.value);
  };

  const saveAndContinue = () => {
    patch(information);
    update(information);
    setStep("eventDetails");
    window.scrollTo(0, 0);
  };

  const updateInfo = () => {
    const _valid = validateDashboardInputs(information);
    _valid.errors && setErrors(_valid.errors);
    if (_valid.valid) {
      patch(information);
      update(information);
      setErrors({});
    }
  };

  const updateUploadedPhotos = (e) => {
    information.photos = e;
    setInformation(information);
    update(information);
  };

  if (!information.aboutSettings) return null;
  return (
    <Flex padding="60px 125px" direction="column" align="flex-start">
      <Text size="38px" weight="500" margin="0 0 17px">
        About Your Event
      </Text>

      <Flex align="flex-start">
        <Flex
          width="540px"
          direction="column"
          justify="flex-start"
          align="flex-start"
        >
          <Flex
            direction="column"
            align="flex-start"
            outline
            padding="23px 32px"
            margin="0 0 28px"
          >
            <Flex direction="column" align="flex-start">
              <Text size="24px" margin="0 0 20px">
                Message from the Film Team
              </Text>
              <Text size="14px" padding="8px 0 20px" color={colors.gray8}>
                Add information about your film and partner. Create different
                sections, add bolding and italics, and bullet-pointed lists
                using Markdown Formatting.
                <a
                  href="https://support.showandtell.film/hc/en-us/articles/360052041572"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Learn more.{" "}
                </a>
              </Text>
              <Textarea
                value={information.aboutSettings.message}
                error={errors.message}
                onChange={updateAbout}
                name="message"
                placeholder=""
                rows="5"
              />
            </Flex>

            <Flex direction="column" align="flex-start" margin="32px 0 0">
              <Text size="16px" margin="0 0 20px">
                Message Preview
              </Text>
              <S.SampleWrapper>
                <Markdown
                  escapeHtml={false}
                  source={about || information?.aboutSettings?.message}
                />
              </S.SampleWrapper>
            </Flex>

            <Flex direction="column" align="flex-start" margin="32px 0 0">
              <AddAboutPhotos
                information={information}
                setUploaded={updateUploadedPhotos}
              />
            </Flex>

            <Flex direction="column" align="flex-start" margin="32px 0 0">
              <Flex justify="space-between">
                {information.aboutSettings.messageVideo && !editMessageVideo && (
                  <Text
                    onClick={() => setEditMessageVideo(true)}
                    size="14px"
                    margin="0 0 2px"
                    pointer
                    color={colors.orange}
                  >
                    Edit
                  </Text>
                )}
              </Flex>

              {information.aboutSettings.messageVideo && !editMessageVideo ? (
                <Flex direction="column" align="flex-start" margin="8px 0 0">
                  <Player
                    controls={true}
                    url={information.aboutSettings.messageVideo}
                  />
                </Flex>
              ) : (
                <>
                  <Text size="24px" margin="0 0 20px">
                    Video Message From The Film Team
                  </Text>
                  <Text size="16px" margin="0 0 20px">
                    Vimeo URL
                  </Text>
                  <Text size="14px" margin="0 0 20px" color={colors.gray8}>
                    Paste a link to a video message from the film team or
                    partner that will appear in the About section of your event
                    page. See an example{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://support.showandtell.film/hc/article_attachments/360075669591/The_Undocumented_Lawyer_-_Copy_1_2020-10-16_20-07-38.jpg"
                    >
                      here
                    </a>
                    .
                  </Text>
                  <Input
                    value={information.aboutSettings.messageVideo}
                    error={errors.messageVideo}
                    onBlur={updateAbout}
                    onChange={updateAbout}
                    name="messageVideo"
                    placeholder="https://www.vimeo.com/170338499"
                  />
                </>
              )}
            </Flex>

            <Flex direction="column" align="flex-start" margin="32px 0 0">
              <Text size="16px" margin="0 0 20px">
                Caption for Video
              </Text>
              <Text size="14px" margin="0 0 20px" color={colors.gray8}>
                This caption will show under your video message.
              </Text>
              <Input
                value={information.aboutSettings.caption}
                error={errors.caption}
                onChange={updateAbout}
                name="caption"
                placeholder="Max 75 Characters"
                maxChar={75}
              />
            </Flex>
          </Flex>

          <Flex margin="0 0 32px" justify="flex-end">
            {isExisting ? (
              <>
                {loading ? (
                  <Button>Saving...</Button>
                ) : (
                  <Button onClick={() => updateInfo()}>Save</Button>
                )}
              </>
            ) : (
              <Button onClick={() => saveAndContinue()}>
                Save and Continue
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
