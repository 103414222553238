import styled from "styled-components";
import { colors } from "consts";
import { Flex } from "components";

export const Sidebar = styled.div`
  background: #232323;
  min-height: 100vh;
  max-width: 235px;
  width: 100%;
  position: fixed;
  top: 88px;
  left: 0;
`;

export const Watch = styled(Flex)`
  position: absolute;
  bottom: 100px;
  left: 0px;
  z-index: 2;
`;

export const Spacer = styled.div`
  max-width: 230px;
  width: 100%;
  min-height: 100vh;
`;

export const Action = styled.div`
  display: flex;
  padding: 0 50px;
  align-items: center;
  transition-duration: 0.3s;
  cursor: pointer;
  height: 45px;
  background: ${({ active }) => (active ? colors.activeWhite : null)};
  color: ${({ active }) => (active ? colors.gray200 : colors.activeWhite2)};
  opacity: ${({ hasID }) => (hasID ? 1 : 0.5)};
  &:hover {
    background: ${({ hasID }) => (hasID ? colors.activeWhite : null)};
    color: ${({ hasID }) => (hasID ? colors.gray200 : null)};
  }
`;

export const Banner = styled.div`
  background: ${colors.orange};
  padding: 8px;
`;

export const BabyButton = styled.div`
  padding: 4px 8px;
  margin-top: 4px;
  border-radius: 4px;
  background: ${colors.gray200};
  &:hover {
    cursor: pointer;
    background: ${colors.activeWhite};
    > div {
      color: ${colors.orange};
    }
  }
`;
