import React from "react";
import * as S from "./AskDonationModal.styled";
import { Text, MobileFlex } from "components";

export default ({
  isDonationSelected,
  selectDonate,
  defaultDonations,
  selectedDonation,
  updateDonate,
  updateCustomDonate,
  paywallSettings,
  donate,
}) => (
  <MobileFlex
    margin="20px 0"
    backgroundColor="transparent"
    borderRadius="2px"
    padding="0 0 12px 12px"
    mobileMargin="0 0 8px"
  >
    <Text
      light
      center
      width="100%"
      size="24px"
      responsive={{ laptopPlus: { padding: "0" } }}
    >
      Donation Amount
    </Text>
    <MobileFlex
      width="100%"
      mobileDirection="row"
      direction="row"
      justify="center"
    >
      <S.Option
        width="64px"
        selected={isDonationSelected(1)}
        onClick={() => selectDonate(1)}
      >
        ${paywallSettings?.midrollDonationSuggestion1 || defaultDonations[1]}
      </S.Option>
      <S.Option
        width="64px"
        selected={isDonationSelected(2)}
        onClick={() => selectDonate(2)}
      >
        ${paywallSettings?.midrollDonationSuggestion2 || defaultDonations[2]}
      </S.Option>
    </MobileFlex>
    <MobileFlex
      width="100%"
      mobileDirection="row"
      direction="row"
      justify="center"
    >
      <S.Option
        width="64px"
        selected={isDonationSelected(3)}
        onClick={() => selectDonate(3)}
      >
        ${paywallSettings?.midrollDonationSuggestion3 || defaultDonations[3]}
      </S.Option>
      <S.Option
        width="64px"
        selected={isDonationSelected(4)}
        onClick={() => selectDonate(4)}
      >
        ${paywallSettings?.midrollDonationSuggestion4 || defaultDonations[4]}
      </S.Option>
    </MobileFlex>
    <MobileFlex
      width="100%"
      mobileDirection="row"
      direction="row"
      justify="center"
    >
      <S.Option
        selected={isDonationSelected("Custom")}
        onClick={() => selectDonate("Custom")}
        width="171px"
        margin="0"
        padding="0 0 0 12px"
      >
        $
        <S.OptionInput
          value={donate.customAmount}
          onChange={updateDonate}
          name="customAmount"
          placeholder={selectedDonation === "custom" ? "" : "Other Amount"}
          type="number"
          min="0"
          color={donate.customAmount && "white"}
          selected={isDonationSelected("Custom")}
          onClick={() => updateCustomDonate()}
        />
      </S.Option>
    </MobileFlex>
  </MobileFlex>
);
