import { Flex, Text } from "components";
import styled from "styled-components";

const Watermark = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background: rgba(100, 100, 100, 0.3);
  .transparent {
    opacity: 0.3;
    position: sticky;
  }
`;

const PreviewWatermark = () => (
  <Watermark>
    <div className="transparent">
      <Flex width="100%" justify="space-around">
        <Text size="54px" weight="bold" center>
          PREVIEW
        </Text>
        <Text size="54px" weight="bold" center>
          PREVIEW
        </Text>
        <Text size="54px" weight="bold" center>
          PREVIEW
        </Text>
      </Flex>
    </div>
  </Watermark>
);

export default PreviewWatermark;
