import { useQuery } from "react-query";
import axiosRequest from "../../api/axios.config";
import queryClient from "../queryClient";

const fetcher = async (searchTerm, page, type, global) => {
  let params = `?`;
  if (searchTerm) params = `${params}&search_term=${searchTerm}`;
  if (page) params = `${params}&page=${page}`;
  if (type) params = `${params}&type=${type}`;
  if (global) params = `${params}&global=${global}`;

  let endpoint = `/v1/tags`;
  if (params !== "?") endpoint = `${endpoint}${params}`;

  const raw = await axiosRequest.get(endpoint);
  return raw.data;
};

const useGetTags = (searchTerm, page, type, global) => {
  const tags = useQuery(["tags"], () =>
    fetcher(searchTerm, page, type, global)
  );
  return tags;
};

const useGetGlobalTags = () => {
  return useQuery(["tags", "global"], async () => {
    const data = await fetcher(null, null, null, true);

    const groupedTags = {};
    data.data.forEach((globalTag) => {
      if (groupedTags[globalTag.type]) {
        groupedTags[globalTag.type] = [
          ...groupedTags[globalTag.type],
          globalTag,
        ];
      } else {
        groupedTags[globalTag.type] = [globalTag];
      }
    });
    for (const type of Object.keys(groupedTags)) {
      queryClient.setQueryData(
        ["tags", "global", type],
        [...groupedTags[type]]
      );
    }

    const response = {
      total: data?.total || null,
      data: data.data,
    };

    return response;
  });
};

const useGetFilteredGlobalTags = (searchTerm, page, { type }) => {
  return useQuery(
    ["tags", "global", "filtered", `searchTerm=${searchTerm}`, `type=${type}`],
    async () => {
      const data = await fetcher(searchTerm, page, type, true);

      const groupedTags = {};
      data.data.forEach((globalTag) => {
        if (groupedTags[globalTag.type]) {
          groupedTags[globalTag.type] = [
            ...groupedTags[globalTag.type],
            globalTag,
          ];
        } else {
          groupedTags[globalTag.type] = [globalTag];
        }
      });
      for (const type of Object.keys(groupedTags)) {
        queryClient.setQueryData(
          ["tags", "global", type],
          [...groupedTags[type]]
        );
      }

      const response = {
        total: data?.total || null,
        data: data.data,
      };

      return response;
    },
    {
      enabled: !!searchTerm || (!!page && page !== 1) || !!type,
      cacheTime: 0,
    }
  );
};

const useGetGlobalAccountTags = (searchTerm, page) => {
  return useQuery(["tags", "global", "account"], async () => {
    const data = await fetcher(searchTerm, page, "account", true);
    return data.data;
  });
};

const useGetGlobalLeadownerTags = (searchTerm, page) => {
  return useQuery(["tags", "global", "leadowner"], async () => {
    const data = await fetcher(searchTerm, page, "leadowner", true);
    return data.data;
  });
};

const useGetGlobalEventTags = (searchTerm, page) => {
  return useQuery(["tags", "global", "event"], async () => {
    const data = await fetcher(searchTerm, page, "page", true);
    return data.data;
  });
};

export {
  useGetTags,
  useGetGlobalTags,
  useGetGlobalAccountTags,
  useGetGlobalLeadownerTags,
  useGetGlobalEventTags,
  useGetFilteredGlobalTags,
};
