import React, { useState, useCallback } from "react";
import { TheaterCta, TheaterText, TheaterSkeleton } from "./";
import { Flex, Text } from "components";
import { useWidth } from "hooks";
import moment from "moment";
import * as momentTZ from "moment-timezone";
import * as S from "../Theater.styled";

export default ({
  eventId,
  backgroundImage,
  mobileBackgroundImage,
  sponsor,
  title,
  description,
  trailerUrl,
  setActiveVideo,
  gateStatus,
  visitLimitExceeded,
  setModal,
  playVideo,
  paywallSettings,
  eventStart,
  eventEnd,
  showHours,
  setTheaterCtaSenario,
}) => {
  const ctaValues = {
    trailerUrl,
    setActiveVideo,
    gateStatus,
    setModal,
    playVideo,
    paywallSettings,
    visitLimitExceeded,
    setTheaterCtaSenario,
  };

  const width = useWidth();
  const [bannerHeight, setBannerHeight] = useState();

  const isMobileWidth = width < 768;
  const hasMobileBackground = mobileBackgroundImage && isMobileWidth;
  const background = hasMobileBackground
    ? mobileBackgroundImage
    : backgroundImage;

  const banner = useCallback(
    (node) => node !== null && setBannerHeight(node.clientHeight),
    // eslint-disable-next-line
    [width]
  );

  const endsSameTime =
    moment(eventEnd).format("YY:DD:HH:mm") ===
      moment(eventStart).format("YY:DD:HH:mm") && showHours;
  const formattedStartTime = moment(eventStart).format(`MMM Do hA`);
  const formattedEndTime = `${moment(eventEnd).format(`MMM Do hA`)} ${
    showHours ? momentTZ.tz(momentTZ.tz.guess(true)).format("z") : ""
  }`;

  return eventId ? (
    <>
      <Flex justify="flex-end">
        <picture ref={banner}>
          <img
            key={Date.now()}
            className="banner"
            bannerheight={bannerHeight}
            src={
              background ||
              require("assets/images/banner-placeholder.png").default
            }
            alt="feature banner"
          />
        </picture>
      </Flex>
      {isMobileWidth && (
        <S.MobileBackground background={mobileBackgroundImage} />
      )}
      <S.Background />
      <S.EventDetails>
        {!endsSameTime && eventStart && (
          <Text className="uppercase">
            available to stream: {eventStart && formattedStartTime}{" "}
            {eventEnd && `- ${formattedEndTime}`}
          </Text>
        )}
        <TheaterText
          title={title}
          sponsor={sponsor}
          description={description}
        />
        <TheaterCta {...ctaValues} />
      </S.EventDetails>
    </>
  ) : (
    <TheaterSkeleton />
  );
};
