import React, { useState, useRef } from "react";
import { Text } from "components";
import { colors } from "consts";
import { useOutsideClick, useLocalStorage } from "hooks";
import * as S from "./AdminHeader.styled";

export default ({ logout }) => {
  const node = useRef();
  const [active, setActive] = useState(false);
  const [user] = useLocalStorage("user");

  useOutsideClick(node, () => {
    setActive(false);
  });

  return (
    <S.UserMenuContainer ref={node} onClick={() => setActive(!active)}>
      <S.UserImagePlaceholder />
      <Text size="14px" weight="400" margin="0 12px 0 8px" color={colors.white}>
        {user.name || "Current User"}
      </Text>
      <S.CaretIcon
        width="12px"
        src={require("assets/images/icon-caret-up.svg").default}
        active={active}
      />
      <S.UserMenuDropdown active={active}>
        <S.DropdownOption active={active}>Help</S.DropdownOption>
        <S.DropdownOption active={active} onClick={() => logout()}>
          Log out
        </S.DropdownOption>
      </S.UserMenuDropdown>
    </S.UserMenuContainer>
  );
};
