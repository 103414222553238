import React, { useState, useEffect } from "react";
import { Button, Flex, Text, SimplePrompt, BulkEditModal } from "components";
// import AdminTable from "./AdminTable";
import AdminTable from "./AdminTable/_NewAdminTable";
import { PAGES } from "api";
import { AdminEventsDrawer } from "./AdminDrawers";
import { toast } from "react-toastify";
import { useGetEvents, useGetFilteredEvents } from "rQuery/api";
import { useGetGlobalEventTags } from "rQuery/api/useGetTags";

export default ({
  edit,
  restore,
  setPrompt,
  superArchive,
  restoreState: [restoreDetails, setRestoreDetails] = [null, () => {}],
  superArchiveState: [superArchiveId, setSuperArchiveId] = [null, () => {}],
  downloadCSV,
  searchTerm,
  filters,
}) => {
  // state
  const [total, setTotal] = useState("");
  const [page, setPage] = useState(1);
  const [isBulkEditing, setIsBulkEditing] = useState(false);
  const [bulkEdits, setBulkEdits] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  // HOOKS
  // hook: global tags
  const { isLoading: isLoadingGlobalEventTags, data: globalEventTags } =
    useGetGlobalEventTags();

  // hooks: events
  const { isLoading: isLoadingEvents, data: events } = useGetEvents();
  const {
    isLoading: isLoadingFilteredEvents,
    isFetching: isFetchingFilteredEvents,
    data: filteredEvents,
  } = useGetFilteredEvents(searchTerm, page, filters);

  // USE EFFECT
  useEffect(() => {
    if (!filteredEvents?.data) return;
    setFilteredData(filteredEvents.data);
  }, [filteredEvents]);

  useEffect(() => {
    let filtering = true;
    if (
      filters.activated === false &&
      filters.archived === false &&
      filters.startEventRange_end === null &&
      filters.startEventRange_start === null &&
      filters.superarchived === false &&
      filters.tag === "" &&
      searchTerm === "" &&
      page === 1
    ) {
      filtering = false;
    }
    setTotal(total);
    setIsFiltering(filtering);
    // eslint-disable-next-line
  }, [searchTerm, page, filters]);

  useEffect(() => {
    if (!events) return;
    let total = filteredEvents?.total || null;
    if (!isFiltering) total = events.total;
    setTotal(total);
  }, [isFiltering, filteredEvents, events]);

  // METHODS
  const updateEvent = async (event) => {
    try {
      await PAGES.update(event);
      toast("event successfully updated", { autoClose: 3000 });
    } catch (err) {
      let _message = "";
      if (err.validation) {
        for (var i in err.validation) {
          _message.concat(err.validation[i].msg);
        }
      }
      toast("Error updating event", { autoClose: 3000 });
    }
  };

  // HELPERS
  const formConfig = {
    tags: {
      title: "Edit Tags",
      type: "form",
      globalTags: globalEventTags,
    },
  };

  return (
    <>
      {/* MODALS */}
      {isBulkEditing && (
        <BulkEditModal
          close={() => setIsBulkEditing(false)}
          bulkEdits={bulkEdits.map((row) => row.values)}
          formConfig={formConfig}
        />
      )}
      {restoreDetails && (
        <SimplePrompt close={() => setRestoreDetails(null)}>
          <Text size="64px" weight="500" margin="0 0 24px" className="canela">
            Restoring this event will make it usable to the filmmaker - Please
            confirm you would like to proceed.
          </Text>
          <Flex>
            <Button
              onClick={() => setRestoreDetails(null)}
              width="50%"
              padding="20px 0"
              margin="0 12px 0 0"
              className="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={restore}
              width="50%"
              padding="20px 0"
              margin="0 0 0 12px"
            >
              Continue
            </Button>
          </Flex>
        </SimplePrompt>
      )}

      {superArchiveId && (
        <SimplePrompt close={() => setSuperArchiveId(null)}>
          <Text size="64px" weight="500" margin="0 0 24px" className="canela">
            Archiving this event will make it not visible to the filmmaker -
            Please confirm you would like to proceed.
          </Text>
          <Flex>
            <Button
              onClick={() => setSuperArchiveId(null)}
              width="50%"
              padding="20px 0"
              margin="0 12px 0 0"
              className="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={superArchive}
              width="50%"
              padding="20px 0"
              margin="0 0 0 12px"
            >
              Continue
            </Button>
          </Flex>
        </SimplePrompt>
      )}

      {/* CONTENT */}
      <Flex width="100%" justify="flex-end" align="center" margin="0 0 16px">
        {bulkEdits.length > 1 && (
          <Button
            className="secondary"
            onClick={() => setIsBulkEditing(true)}
            margin="0 15px 0 0"
          >
            Bulk Update
          </Button>
        )}
        <Button className="secondary" onClick={() => downloadCSV("events")}>
          Download Report
        </Button>
      </Flex>
      {isLoadingEvents ||
      isLoadingGlobalEventTags ||
      isLoadingFilteredEvents ||
      isFetchingFilteredEvents ? (
        <Text color="white">Loading...</Text>
      ) : (!isFiltering && events.length === 0) ||
        (isFiltering && filteredData.length === 0) ? (
        <Text color="white">No data matching your search criteria</Text>
      ) : (
        <AdminTable
          className="clickable"
          isExpandable
          Drawer={AdminEventsDrawer}
          type="events"
          data={isFiltering ? filteredData : events.data}
          edit={edit}
          setPrompt={setPrompt}
          update={updateEvent}
          {...{ page, setPage, total }}
          {...{
            bulkEdits,
            setBulkEdits,
            setIsBulkEditing,
          }}
        />
      )}
    </>
  );
};
