import React, { useState, useEffect } from "react";
import * as S from "./Input.styled";

export default (props) => {
  const {
    change,
    error,
    maxChar,
    name,
    onChange,
    reload,
    showHours,
    type,
    value,
    enterPressed,
  } = props;

  const [inputValue, setInputValue] = useState("");

  const changeInput = (e) => {
    if (maxChar) {
      if (e.target.value.length > maxChar) {
        e.target.value = e.target.value.substring(0, maxChar);
        setInputValue(e.target.value);
        onChange(e);
      } else {
        setInputValue(e.target.value);
        onChange(e);
      }
    } else {
      setInputValue(e.target.value);
      onChange(e);
    }
  };

  const update = (e) => {
    onChange({ target: { name: name, value: e } });
    setInputValue(e);
  };

  useEffect(() => {
    if (value) {
      if (type === "date") {
        if (typeof value.getMonth === "function") {
          setInputValue(value);
        } else {
          setInputValue(new Date(value));
        }
      } else {
        setInputValue(value);
      }
    }
  }, [value, error, type, reload]);

  useEffect(() => {
    change && setInputValue(change);
  }, [change]);

  if (type === "date")
    return (
      <S.Picker
        {...props}
        placeholder="MM/DD/YYYY"
        showTimeSelect={showHours}
        timeIntervals={60}
        dateFormat={showHours ? "MMMM d, yyyy - h aa" : "MMMM d, yyyy"}
        selected={inputValue}
        onChange={update}
      />
    );
  return (
    <S.Input
      value={inputValue}
      onChange={changeInput}
      onKeyDown={({ key, ...props }) =>
        (key === "Enter" && enterPressed?.(props)) || undefined
      }
      {...props}
    />
  );
};
