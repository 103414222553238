import React, { useState, useEffect, useRef } from "react";
import { VIMEO } from "api";
import { Text, AskDonationModal, StripeModal, FallBackModal } from "components";
import { LoadingIcon } from "./";
import randomstring from "randomstring";
import { useInterval, usePreloadImage } from "hooks";
import { browserStats, filmTypes } from "utils";
import * as S from "./Player.styled";

import bannerPlaceholder from "assets/images/banner-placeholder.png";

export default (props) => {
  const {
    className,
    isDashboard,
    reload,
    url,
    eventId,
    paywallSettings,
    activeVideo,
    playNextAndClear,
    closeAndClear,
    midrollInterval,
    setMidrollInterval,
    modal,
    setModal,
    setWillAutoPlay,
    isMobile,
  } = props;
  const [playerReload, setPlayerReload] = useState(false);
  const [playerUrl, setPlayerUrl] = useState(url);
  const [processing, setProcessing] = useState(false);
  const [donationAmount, setDonationAmount] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [mobilePromptShown, setMobilePromptShown] = useState(false);
  const [midrollPaywallSettings, setMidrollPaywallSettings] = useState({});
  const [delay] = useState(250);
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    if (activeVideo?.type === "feature" && isMobile && !mobilePromptShown) {
      setModal("mobilePrompt");
      setMobilePromptShown(true);
    }
    // eslint-disable-next-line
  }, [activeVideo, isMobile]);

  const midrollBanner = paywallSettings?.midrollBackgroundImage
    ? usePreloadImage(paywallSettings.midrollBackgroundImage)
    : usePreloadImage(bannerPlaceholder);

  // iOS midroll variables
  const [completedIOSMidroll, setCompletedIOSMidroll] = useState(false);
  const [storedMidrollTime, setStoredMidrollTime] = useState(0);
  const [singleMidrollUse, setSingleMidrollUse] = useState(true);
  const [logs, setLogs] = useState([]);

  const playerRef = useRef();
  const fullScreenRef = useRef();
  fullScreenRef.current = isFullScreen;
  let _checkStatus;
  const isIOS = browserStats.os === "iOS";

  // set showLog to true to show the log on iOS
  const showLog = false;
  const addLog = (newLog) => setLogs([...logs, newLog]);

  const setPlayerDuringMidroll = async () => {
    if (isIOS) {
      // 1: iOS interval begins at midroll
      if (!completedIOSMidroll) {
        addLog("iOS midroll detected");
        const player = playerRef?.current?.getInternalPlayer();
        const duration = await player.getDuration();
        const currentTime = await player.getCurrentTime();
        setStoredMidrollTime(currentTime);
        player.setCurrentTime(Math.floor(duration));
        setModal("askDonation");
      }
    } else {
      playerRef?.current?.getInternalPlayer().pause();
      setModal("askDonation");
    }
  };

  const closeModal = async () => {
    if (isIOS) {
      // 3: iOS user closes the modal
      const player = playerRef.current.getInternalPlayer();
      setModal("");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      await player.setCurrentTime(storedMidrollTime);
      player.play();
      setCompletedIOSMidroll(true);
      setMidrollInterval(clearInterval(midrollInterval));
      addLog("Modal has been closed");
    } else {
      setModal("");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      playerRef.current.getInternalPlayer().play();
      setMidrollInterval(clearInterval(midrollInterval));
    }
  };

  const videoEnded = () => {
    const hasMidroll = midrollPaywallSettings?.askMidrollDonations;
    if (!completedIOSMidroll && hasMidroll && isIOS) {
      // 2: video on iOS has ended during the midroll
      if (singleMidrollUse) {
        setSingleMidrollUse(false);
        setIsRunning(false);
        const player = playerRef?.current?.getInternalPlayer();
        player.play();
        setWillAutoPlay(false);
      }
    } else {
      // 4: video has ended naturally for all users including iOS
      closeAndClear();
    }
  };

  useInterval(
    () => {
      const currentTime = playerRef?.current
        ? playerRef?.current?.getCurrentTime()
        : 0;
      const duration = playerRef?.current
        ? playerRef?.current?.getDuration()
        : 999999;
      if (
        currentTime &&
        duration &&
        Math.floor(currentTime) === Math.floor(duration)
      ) {
        activeVideo?.type === filmTypes.FEATURE ||
        activeVideo?.type === filmTypes.TRAILER
          ? videoEnded()
          : playNextAndClear();
      }
    },
    isRunning ? delay : null
  );

  useEffect(() => {
    setPlayerUrl(url);
    clearInterval(_checkStatus);
    setMidrollPaywallSettings({
      ...paywallSettings,
      enablePaywall: false,
    });

    if (!isDashboard && setMidrollInterval)
      return () => {
        setMidrollInterval(clearInterval(midrollInterval));
      };
    // eslint-disable-next-line
  }, [url, playerReload, reload]);

  const runChecks = async () => {
    if (!reload) {
      _checkStatus = setInterval(async () => {
        const _status = await VIMEO.checkUploadStatus(
          playerUrl.split("/").pop()
        );

        if (!_status.error) {
          clearInterval(_checkStatus);
          setPlayerReload(randomstring.generate());
          setProcessing(false);
        }
        if (_status.error) {
          setProcessing(true);
        }
      }, 50000);
    }
  };

  const addFullScreenEvent = () => {
    // // Checks when/if the video is put into fullscreen.
    if (playerRef?.current?.getInternalPlayer()?.element) {
      playerRef.current.getInternalPlayer().element.onfullscreenchange = () => {
        setIsFullScreen(!fullScreenRef.current);
      };
    }
  };

  const startMidrollInterval = () => {
    // Starts the check interval for midroll donations if that option is selected
    if (paywallSettings?.askMidrollDonations) {
      let midrollTimestamp = 0;
      if (paywallSettings?.midrollDonationTriggerMinutes)
        midrollTimestamp =
          parseInt(paywallSettings?.midrollDonationTriggerMinutes) * 60;
      if (paywallSettings?.midrollDonationTriggerSeconds)
        midrollTimestamp =
          midrollTimestamp +
          parseInt(paywallSettings?.midrollDonationTriggerSeconds);
      setMidrollInterval(
        setInterval(() => {
          const currentTime = playerRef?.current
            ? playerRef?.current?.getCurrentTime()
            : 0;
          if (Math.floor(currentTime) === midrollTimestamp) {
            if (fullScreenRef.current) {
              if (document.exitFullscreen) {
                document.exitFullscreen();
              }
              if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
              }
              if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
              }
              if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
              }
              if (document.msExitFullscreen) {
                document.msExitFullscreen();
              }
            }

            setPlayerDuringMidroll();
          }
        }, 500)
      );
    }
  };

  const continueToDonation = (step) => {
    setModal(step);
    setMidrollInterval(clearInterval(midrollInterval));
  };

  const closeMobilePrompt = () => {
    setModal("");
    const player = playerRef?.current?.getInternalPlayer();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    player?.play();
  };

  return (
    <S.Wrap className={className}>
      {showLog && (
        <S.Log>
          {logs.length ? (
            logs.map((message, i) => (
              <Text key={i} color="black">
                • {message}
              </Text>
            ))
          ) : (
            <Text color="rgba(0,0,0,.25)">No logs</Text>
          )}
        </S.Log>
      )}
      {processing && (
        <S.Loading
          padding="30px"
          justify="center"
          align="center"
          direction="column"
        >
          <LoadingIcon />
          <Text center size="13px">
            Convertingâ€¦ Your video will be ready soon. Thanks for your
            patience. You can continue while the video is being converted.
          </Text>
        </S.Loading>
      )}
      {!playerUrl && (
        <S.Loading
          padding="30px"
          justify="center"
          align="center"
          direction="column"
        >
          <LoadingIcon />
          <Text center size="13px">
            Video is being uploaded. Thanks for your patience.
          </Text>
        </S.Loading>
      )}
      <>
        <S.Player
          className={className}
          controls={modal === ""}
          playsinline={true}
          {...props}
          url={`https://player.vimeo.com/video/${url.split("/").pop()}`}
          onError={isDashboard && runChecks}
          width="100%"
          height="100%"
          ref={playerRef}
          onStart={() => {
            addFullScreenEvent();
            if (activeVideo.type === filmTypes.FEATURE && !isDashboard) {
              startMidrollInterval();
            }
          }}
          onEnded={async () => {
            if (isIOS) {
              const player = playerRef?.current?.getInternalPlayer();
              await player.setCurrentTime(storedMidrollTime);
              player.pause();
              setModal("askDonation");
              addLog("Forced video to end within the midroll");
              setMidrollInterval(clearInterval(midrollInterval));
              setIsRunning(true);
            } else {
              playNextAndClear();
            }
          }}
        />
        {modal === "askDonation" && (
          <AskDonationModal
            continueToDonation={() => continueToDonation("donate")}
            closeModal={closeModal}
            paywallSettings={midrollPaywallSettings}
            donationAmount={donationAmount}
            setDonationAmount={setDonationAmount}
            midrollBanner={midrollBanner}
          />
        )}
        {modal === "donate" && (
          <StripeModal
            donationAmount={donationAmount}
            close={() => continueToDonation("askDonation")}
            back={() => continueToDonation("askDonation")}
            next={closeModal}
            paywallSettings={midrollPaywallSettings}
            registerar={{}}
            eventId={eventId}
            title={"title"}
            preventClickOut
          />
        )}
        {modal === "mobilePrompt" && (
          <FallBackModal
            close={() => closeMobilePrompt()}
            buttonClick={() => closeMobilePrompt()}
          />
        )}
      </>
    </S.Wrap>
  );
};
