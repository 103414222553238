import React from "react";
import { Flex, Button, Text, Image } from "components";
import { isProduction } from "utils";
import { colors } from "consts";
import { USERS } from "api";
import * as S from "./Header.styled";

export default ({ light, user, isAdmin, showBackButton = true }) => {
  const logout = async () => {
    await USERS.logout();
    localStorage.clear();
    window.location.href = isProduction
      ? "https://showandtell.film/"
      : "/login";
  };

  return (
    <>
      <S.Spacer />
      <S.Wrap
        margin="89px 0 0"
        padding="24px 0"
        background={light ? "white" : colors.gray400}
      >
        <Flex>
          <Flex>
            <a href={user ? "/welcome" : "https://showandtell.film/watch"}>
              <Image
                width="200px"
                src={
                  light
                    ? require("assets/images/logo-dark.svg").default
                    : require("assets/images/icon-logo.svg").default
                }
              />
            </a>
            {showBackButton && (
              <Flex margin="0 0 0 10px">
                <a href={user ? "/welcome" : "https://showandtell.film/watch"}>
                  <Button margin="0 16px" className="secondary">
                    Back to filmmaker dashboard
                  </Button>
                </a>
              </Flex>
            )}
            {isAdmin && (
              <Text
                size="22px"
                weight="700"
                margin="0 0 0 24px"
                color={colors.gray750}
              >
                Admin
              </Text>
            )}
          </Flex>

          <Flex justify="flex-end">
            {user ? (
              <>
                <a
                  href="https://support.showandtell.film/hc/en-us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button margin="0 16px" className="secondary">
                    Help
                  </Button>
                </a>

                <Button
                  margin="0 0 0 16px"
                  className="outline-white"
                  onClick={() => logout()}
                >
                  Logout
                </Button>
              </>
            ) : (
              <>
                <a href="/login">
                  <Button
                    className={light ? "outline" : "outline-white"}
                    margin="0 16px 0 0"
                  >
                    Login
                  </Button>
                </a>
              </>
            )}
          </Flex>
        </Flex>
      </S.Wrap>
    </>
  );
};
