import { PAGES, COMMENTS, PRODUCTS } from "api";
import { toast } from "react-toastify";
import randomString from "randomstring";

export default async (
  screening,
  setId,
  saveAndContinue,
  customerId,
  accountEmail,
  diyEvent
) => {
  try {
    let _event;

    let _exists;
    try {
      _exists = await PAGES.getByUrl(screening.url);
      if (_exists.data) {
        toast.error("This url is already taken, please pick a different one", {
          autoClose: 6000,
        });
      }
    } catch (err) {
      console.log(err);
    }

    if (!_exists) {
      try {
        _event = await PAGES.create(
          { ...screening, account_email: accountEmail, diyEvent },
          customerId
        );
      } catch (err) {
        toast.error("Error creating screening", { autoClose: 3000 });
      }

      //Paywall products
      if (screening.paywallUpdated) {
        const _updatedSettings = await PRODUCTS.updatePaywall(_event.data.data);
        _event.data.data.paywallSettings = _updatedSettings;
        await PAGES.update(_event.data.data);
      }

      //Add comments
      if (screening.comments) {
        try {
          for (var i in screening.comments) {
            const _question = await COMMENTS.submit(_event.data.data.id, {
              text: screening.comments[i].text,
            });
            if (screening.comments[i].children) {
              await COMMENTS.submit(_event.data.data.id, {
                text: screening.comments[i].children[0].text,
                parentId: _question.data.data.id,
              });
            }
          }
        } catch (err) {
          toast.error("Error creating comments for screening", {
            autoClose: 3000,
          });
        }
      }

      //Add products
      if (screening.products) {
        try {
          for (var j in screening.products) {
            const product = screening.products[j];
            product.sku = randomString.generate({
              charset: "numeric",
              length: 10,
            });
            product.active = true;
            delete product.id;
            await PRODUCTS.create(_event.data.data.id, product);
          }
        } catch (err) {
          toast.error("Error creating products for screening", {
            autoClose: 3000,
          });
        }
      }

      setId(_event.data.data.id);
      saveAndContinue(false);
    }
  } catch (err) {
    toast("Error creating/updating event", { autoClose: 3000 });
  }
};
