import styled from "styled-components";

export const Wrap = styled.div`
  background: ${({ background }) => background};
  padding: 24px 32px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const Spacer = styled.div`
  height: 88px;
`;
