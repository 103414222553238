import React, { useState } from "react";
import { Flex, Text, Button, SimplePrompt, EditTagsForm } from "components";
import { colors } from "consts";
import * as S from "./Admin.styled";

export default ({ type, ownerDetails, gTags }) => {
  const { tags } = ownerDetails;

  // STATE
  const [editingTags, setEditingTags] = useState(false);

  return (
    <>
      <S.TagContainer>
        <Flex width="100%" align="center" justify="space-between">
          <Text size="24px" color={colors.white} weight="bold">
            Tags
          </Text>
          <Button className="secondary" onClick={() => setEditingTags(true)}>
            Edit
          </Button>
        </Flex>
        <Flex wrap="wrap">
          {tags?.[0] ? (
            tags.map((tag, idx) => {
              return <S.Tag key={`tag-${idx}`}>{tag.name}</S.Tag>;
            })
          ) : (
            <Text size="14px">No Tags</Text>
          )}
        </Flex>
      </S.TagContainer>

      {editingTags && (
        <SimplePrompt close={() => setEditingTags(false)}>
          <EditTagsForm
            tags={tags}
            gTags={gTags}
            resource={type}
            original={ownerDetails}
          />
        </SimplePrompt>
      )}
    </>
  );
};
