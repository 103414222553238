export default (currentOrder, newOrder, productArray) => {
  let modifiedArray = [...productArray];

  const indexOfChangedItem = modifiedArray.findIndex(
    (item) => item.order === currentOrder
  );
  const indexOfSwappedItem = modifiedArray.findIndex(
    (item) => item.order === newOrder
  );

  modifiedArray[indexOfChangedItem] = {
    ...modifiedArray[indexOfChangedItem],
    order: newOrder,
  };

  if (indexOfSwappedItem !== -1) {
    modifiedArray[indexOfSwappedItem] = {
      ...modifiedArray[indexOfSwappedItem],
      order: currentOrder,
    };
  }

  return modifiedArray;
};
