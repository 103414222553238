import React, { useState, useEffect } from "react";
import { ORDERS, SUBSCRIBERS } from "api";
import { useConsoleLogScenario } from "hooks";
import { TheaterBanner, TheaterVideo, TheaterModals } from "./";
import * as S from "./Theater.styled";
import { filmTypes } from "utils";

export default ({
  description,
  eventId,
  children,
  emailCapture,
  backgroundImage,
  mobileBackgroundImage,
  title,
  subscriptionSettings,
  sponsor,
  trailerUrl,
  gateStatus,
  visits,
  featureUrl,
  paywallSettings,
  eventStart,
  eventEnd,
  setRefresh,
  showHours,
  orderSlug,
  subscriberSlug,
}) => {
  const [activeVideo, setActiveVideo] = useState(null);
  const [modalType, setModal] = useState();
  const [visitLimitExceeded, setVisitLimitExceeded] = useState();
  const [theaterCtaSenario, setTheaterCtaSenario] = useState(null);

  useConsoleLogScenario(gateStatus, paywallSettings);

  useEffect(() => {
    setVisitLimitExceeded(visits >= 20);
  }, [visits]);

  const playVideo = () => {
    if (!featureUrl) {
      // The featureUrl & sponsorPrerollUrl are not being returned with the event
      //    if today does not fall between the event start & end dates.
      // This will prevent setting any active video (and incorrect rendering
      //    of the <FallBackModal />) as well as preventing invalid visit incrementation
      return;
    }
    /*
        If user is using device agnostic link and is subscribed
        via email, increment the visits for that subscriber id
      */
    if (gateStatus.guest === "subscribed" && subscriberSlug) {
      const updateVisits = async () => {
        const {
          data: {
            data: { visits: newVisitTotal },
          },
        } = await SUBSCRIBERS.incrementVisits(eventId, subscriberSlug);

        setVisitLimitExceeded(newVisitTotal >= 20);
      };
      updateVisits();
    }

    /*
        If user is using device agnostic link and is subscribed
        via payment, increment the visits for that order id
      */
    if (gateStatus.guest === "paid" && orderSlug) {
      const updateVisits = async () => {
        const {
          data: {
            data: { visits: newVisitTotal },
          },
        } = await ORDERS.incrementVisits(eventId, orderSlug);

        setVisitLimitExceeded(newVisitTotal >= 20);
      };
      updateVisits();
    }

    sponsor?.hasSponsor && sponsor?.showSponsorPreroll && sponsor?.prerollUrl
      ? setActiveVideo({ url: sponsor.prerollUrl, type: filmTypes.SPONSOR })
      : setActiveVideo({ url: featureUrl, type: filmTypes.FEATURE });
  };

  const bannerValues = {
    description,
    eventId,
    emailCapture,
    backgroundImage,
    mobileBackgroundImage,
    title,
    subscriptionSettings,
    sponsor,
    trailerUrl,
    activeVideo,
    setActiveVideo,
    setModal,
    gateStatus,
    playVideo,
    paywallSettings,
    eventStart,
    eventEnd,
    showHours,
    visitLimitExceeded,
    setTheaterCtaSenario,
  };

  const videoValues = {
    eventId,
    activeVideo,
    setActiveVideo,
    trailerUrl,
    modalType,
    featureUrl,
    sponsor,
    paywallSettings,
  };

  const modalValues = {
    eventId,
    paywallSettings,
    title,
    subscriptionSettings,
    modalType,
    setModal,
    eventStart,
    playVideo,
    setActiveVideo,
    setRefresh,
    gateStatus,
    theaterCtaSenario,
  };

  return (
    <S.Wrapper className={(activeVideo || modalType) && "video-active"}>
      {/* BANNER IMAGE STATE */}
      {!modalType && !activeVideo && <TheaterBanner {...bannerValues} />}

      {/* VIDEO STATE */}
      {activeVideo && <TheaterVideo {...videoValues} />}

      {/* MODAL STATE */}
      {modalType && <TheaterModals {...modalValues} />}

      {children}
    </S.Wrapper>
  );
};
