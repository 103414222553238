export default (url, callback) => {
  const _window = window.URL || window.webkitURL;
  const img = new Image();

  img.onload = function () {
    if (this.width >= 700) {
      callback(true);
    } else {
      alert(
        `Uh-oh! 🥺 This image does not meet the minimum size requirements of 1275px width by 700px height. Please select and upload another image. `
      );
      callback(false);
    }
    _window.revokeObjectURL(url);
  };

  img.onerror = function (errorEvent) {
    console.error("Error loading the image", errorEvent);
  };

  img.src = url;
};
