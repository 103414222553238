import React, { useState } from "react";
import { Text, Flex } from "components";
import * as S from "./FoyerFaq.styled";

export default ({ text, answer }) => {
  const [open, setOpen] = useState(false);

  return (
    <S.Question>
      <Flex direction="column" align="flex-start">
        <Flex pointer justify="space-between" onClick={() => setOpen(!open)}>
          <Text
            margin="0 24px 0 0"
            color={open ? "#898989" : null}
            size="28px"
            responsive={{ tablet: { size: "18px" } }}
          >
            {text}
          </Text>
          <S.Arrow
            open={open}
            src={
              open
                ? require("assets/images/icon-arrow-top.svg").default
                : require("assets/images/icon-arrow-right.svg").default
            }
          />
        </Flex>

        {open && (
          <Flex margin="8px 0 0">
            <S.Markdown
              linkTarget={"_blank"}
              escapeHtml={false}
              source={answer}
            />
          </Flex>
        )}
      </Flex>
    </S.Question>
  );
};
