export default (type, str) => {
  const validate = {
    zip: /^[0-9]{5}(?:-[0-9]{4})?$/,
    number: /^[0-9][A-Za-z0-9 -]*$/,
    email:
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  };

  return validate?.[type]?.test(str);
};
