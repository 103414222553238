import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Text, Image } from "components";
import { UserMenu } from ".";
import { isProduction } from "utils";
import { colors } from "consts";
import { USERS } from "api";

export default ({ text }) => {
  const history = useHistory();

  const logout = async () => {
    await USERS.logout();
    localStorage.clear();
    window.location.href = isProduction
      ? "https://showandtell.film/"
      : "/login";
  };

  return (
    <Flex
      justify="space-between"
      align="center"
      width="100%"
      height="88px"
      padding="60px 0"
    >
      <Flex>
        <Image
          width="20px"
          src={require("assets/images/icon-arrow-left.svg").default}
          onClick={() => {
            history.push("/admin/customers");
          }}
          cursor="pointer"
        />
        <Text
          size="24px"
          margin="0 12px 0 16px"
          color={colors.white}
          weight="bold"
        >
          {text || "Details"}
        </Text>
      </Flex>
      <UserMenu logout={logout} />
    </Flex>
  );
};
