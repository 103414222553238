import styled from "styled-components";
import { colors } from "consts";
import { Flex } from "components";

export const Container = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width : "100%")};
  margin: ${({ margin }) => margin || "0"};
  cursor: default;
  user-select: none;
`;

export const Selector = styled(Flex)`
  border-radius: ${({ radius }) => (radius ? radius : "6px")};
  background: ${({ light }) => (light ? "white" : "#101010")};
  border: ${({ light }) => (light ? "1px solid #bfbfbf" : "1px solid #444444")};
  height: ${({ height }) => (height ? height : "32px")};
  box-shadow: none;
  color: ${({ light }) => (light ? colors.dark : "white")};
  padding: 0 12px;
  font-size: 14px;
  width: ${({ width }) => (width ? width : "100%")};
  height: 36px;
  margin: ${({ margin }) => margin || "0"};
  transition-duration: 0.3s;
  font-family: "Inter", sans-serif;
  ${({ open }) =>
    open &&
    `
    border: 1px solid ${colors.orange};
  `}
`;

export const OptionsContainer = styled.div`
  position: absolute;
  top: 36px;
  left: 0;
  border-radius: 2px;
  background: ${({ light }) => (light ? "white" : "#232323")};
  border: ${({ light, open }) =>
    open ? (light ? "1px solid #bfbfbf" : "1px solid #444444") : "none"};
  box-shadow: none;
  color: ${({ light }) => (light ? colors.dark : "white")};
  font-size: 14px;
  width: ${({ width }) => (width ? width : "100%")};
  margin: ${({ margin }) => margin || "0"};
  font-family: "Inter", sans-serif;
  overflow: hidden;
  max-height: ${({ open }) => (open ? "300px" : "0px")};
  overflow-y: auto;
  z-index: 999;
`;

export const Option = styled.div`
  padding: 0 12px;
  transition: 0.2s;
  :hover {
    background: ${colors.orange};
    color: ${({ light }) => (light ? "white" : colors.dark)};
  }
`;
