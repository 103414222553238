import React, { useState, useEffect, useRef } from "react";
import { useOutsideClick } from "hooks";
import * as S from "./ModalProvider.styled";

export default (props) => {
  const { close, show, light, children, preventClickOut } = props;

  const [showModal, setShowModal] = useState(false);
  const node = useRef();

  if (!preventClickOut) {
    useOutsideClick(node, () => {
      close();
    });
  }

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  if (!showModal) return null;
  return (
    <S.Wrap>
      <S.Overlay light={light} />

      <S.Modal {...props}>
        {/* <S.Close
          onClick={() => close()}
          src={require("assets/images/icon-close.svg").default}
        /> */}
        {children}
      </S.Modal>
    </S.Wrap>
  );
};
