import styled from "styled-components";
import { colors } from "consts";

const Default = styled.div`
  width: 100%;
  border: 1px solid ${colors.gray650};
  background: ${colors.charcoal};
  padding: 8px 12px;
  border-radius: 6px;
  margin-bottom: 24px;
  color: white;
`;

export const Card = styled(Default)`
  border-radius: 6px;
`;

export const Expiration = styled(Default)`
  border-radius: 6px 0 0 6px;
`;

export const Cvc = styled(Default)`
  border-radius: 0 6px 6px 0;
`;
