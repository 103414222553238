import React, { useState, useEffect } from "react";
import { Flex, Text } from "components";
import { BannerPreview, CropUploader, CropModal } from "./Cropper";
import { colors } from "consts";

export default ({ information, updateInformation, updateBanner }) => {
  const [desktopBannerImage, setDesktopBannerImage] = useState();
  const [desktopCropModalOpen, setDesktopCropModalOpen] = useState(false);

  const [mobileBannerImage, setMobileBannerImage] = useState();
  const [mobileCropModalOpen, setMobileCropModalOpen] = useState(false);

  useEffect(() => {
    information?.backgroundImage &&
      setDesktopBannerImage(information?.backgroundImage);

    information?.mobileBackgroundImage &&
      setMobileBannerImage(information?.mobileBackgroundImage);
  }, [information, updateBanner]);

  return (
    <>
      <Text size="24px" margin="0 0 20px">
        Banner Image
      </Text>

      <Text size="14px" margin="0 0 20px" color={colors.gray8}>
        A banner image is the artwork or photo that displays at the top of the
        page, adding a visual element that sets the tone for your event.
        <a
          href="https://support.showandtell.film/hc/en-us/articles/360052340351-My-Banner-Image-Looks-Weird-How-Can-I-Fix-That-"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Learn more.
        </a>
      </Text>
      <Text size="14px" margin="0 0 20px" color={colors.gray8}>
        {`💡 Tip: Don't use images with text in the actual image file. Instead, add text over the image using your Title and Log line.`}
      </Text>

      <Text padding="0 0 8px 0">Desktop banner</Text>
      <Text size="14px" margin="0 0 20px" color={colors.gray8}>
        This banner image will be cropped and optimized for desktops. Images
        must be wider than they are tall. The minimum dimensions are 1275px
        width by 700px height.
        <a
          href="https://support.showandtell.film/hc/en-us/articles/360052340351-My-Banner-Image-Looks-Weird-How-Can-I-Fix-That-"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Learn more.
        </a>
      </Text>

      {desktopBannerImage ? (
        <BannerPreview
          information={information}
          bannerImage={desktopBannerImage}
          setCropModalOpen={setDesktopCropModalOpen}
          target="desktop"
        />
      ) : (
        <CropUploader
          setBannerImage={setDesktopBannerImage}
          setCropModalOpen={setDesktopCropModalOpen}
        />
      )}

      <CropModal
        url={desktopBannerImage}
        open={[desktopCropModalOpen, setDesktopCropModalOpen]}
        information={information}
        updateInformation={updateInformation}
        target="desktop"
      />

      <Flex padding="40px 0 0 0" column align="start">
        <Text padding="0 0 8px 0">Mobile banner</Text>
        <Text size="14px" margin="0 0 20px" color={colors.gray8}>
          This banner image will be cropped and optimized for mobile devices.
          Images must be taller than they are wide. The minimum dimensions are
          700px width by 1275px height.
          <a
            href="https://support.showandtell.film/hc/en-us/articles/360052340351-My-Banner-Image-Looks-Weird-How-Can-I-Fix-That-"
            target="_blank"
            rel="noopener noreferrer"
          >
            &nbsp;Learn more.
          </a>
        </Text>
        {mobileBannerImage ? (
          <BannerPreview
            information={information}
            bannerImage={mobileBannerImage}
            setCropModalOpen={setMobileCropModalOpen}
            target="mobile"
          />
        ) : (
          <CropUploader
            setBannerImage={setMobileBannerImage}
            setCropModalOpen={setMobileCropModalOpen}
          />
        )}
      </Flex>

      <CropModal
        url={mobileBannerImage}
        open={[mobileCropModalOpen, setMobileCropModalOpen]}
        information={information}
        updateInformation={updateInformation}
        target="mobile"
      />
    </>
  );
};
