import React from "react";
import { Text, Input } from "components";
import * as S from "./FormField.styled";

export default ({
  width,
  label,
  onChange,
  error,
  reload,
  value,
  responsive,
  name,
  disabled,
}) => {
  return (
    <S.Wrapper
      direction="column"
      margin="12px 0"
      align="flex-start"
      width={width}
      responsive={responsive}
    >
      <Text dark padding="0 0 4px">
        {label}
      </Text>
      <Input
        disabled={disabled}
        error={error}
        reload={reload}
        name={name}
        light
        onChange={onChange}
        value={value}
      />
    </S.Wrapper>
  );
};
