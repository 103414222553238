import React, { useState, useEffect } from "react";
import * as S from "./ManualLeadModel.styled";
import { useLockBodyScroll } from "hooks";
import ManualLeadForm from "./ManualLeadForm";
import { LEADS } from "api";
import { toast } from "react-toastify";

// rQuery
import { useMutation } from "react-query";
import { queryClient } from "rQuery";

export default ({ close, existing_leadowner_emails }) => {
  // HOOKS
  useLockBodyScroll();

  // STATE
  const [formErrors, setFormErrors] = useState({
    email: null,
  });
  const [canSubmit, setCanSubmit] = useState(false);
  const [newLeadConfig, setNewLeadConfig] = useState({
    type: null, // email || referral
    first_name: null,
    last_name: null,
    email: null,
    stage: null,
    film_count: null,
    documentary_filmmaker: null,
  });
  const [formState, setFormState] = useState({
    type: null,
    stage: null,
    film_count: null,
    documentary_filmmaker: null,
  });

  // METHODS
  const handleAttributeUpdate = (e, attribute) => {
    const udpate = { ...newLeadConfig };
    const errors = { ...formErrors };

    const handleEvent = () => {
      udpate[attribute] = e.target.value === "" ? null : e.target.value;

      if (attribute === "email") {
        if (existing_leadowner_emails.includes(e.target.value)) {
          errors[attribute] = "Email Already Exists";
        } else {
          errors[attribute] = null;
        }
      }
    };
    const handleSelect = () => {
      udpate[attribute] = e.value;
      const updatedFormState = { ...formState };
      updatedFormState[attribute] = e;
      setFormState(updatedFormState);
    };

    if (e.target) {
      handleEvent();
      setNewLeadConfig(udpate);
      setFormErrors(errors);
    } else {
      handleSelect();
      setNewLeadConfig(udpate);
      setFormErrors(errors);
    }
  };

  // MUTATIONS
  const { mutate: submit } = useMutation(
    async () => {
      await LEADS.manualLead(newLeadConfig);
    },
    {
      onSuccess: (data) => {
        toast("Manual lead added", { autoClose: 3000 });
      },
      onError: (err) => {
        toast("Error adding manual lead", { autoClose: 3000 });
        console.error("Error adding manual lead", err);
      },
      onSettled: (data, err) => {
        queryClient.invalidateQueries("leadowners");
        queryClient.invalidateQueries(["leadowners", "filtered"]);
        close();
      },
    }
  );

  // HELPERS
  const newLeadTypeOptions = [
    { value: "email", name: "Email" },
    { value: "referral", name: "Referral" },
    { value: "zoom", name: "Zoom" },
    { value: "typeform", name: "TypeForm" },
    { value: "webflow", name: "Webflow" },
  ];
  const newLeadStageOptions = [
    { value: "new", name: "New" },
    { value: "working", name: "Working" },
    { value: "nurturing", name: "Nurturing" },
    { value: "unqualified", name: "Unqualified" },
    { value: "qualified", name: "Qualified" },
    { value: "high_opp", name: "High Opp" },
    { value: "graduated", name: "Graduated" },
  ];
  const filmCountOptions = [
    { value: "0", name: "0" },
    { value: "1+", name: "1 or more" },
  ];
  const documentaryFilmmakerOptions = [
    { value: true, name: "Yes" },
    { value: false, name: "No" },
  ];
  const optionMap = {
    type: newLeadTypeOptions,
    stage: newLeadStageOptions,
    film_count: filmCountOptions,
    documentary_filmmaker: documentaryFilmmakerOptions,
  };

  // USE EFFECT
  useEffect(() => {
    // TODO: add validation for uniques on leadowners & leads table
    if (
      Object.values(newLeadConfig).includes(null) ||
      !Object.values(formErrors).every((el) => el === null)
    ) {
      if (canSubmit !== false) setCanSubmit(false);
    } else {
      if (canSubmit !== true) setCanSubmit(true);
    }
  }, [newLeadConfig, formErrors, canSubmit]);

  return (
    <S.Prompt>
      <S.Box width="500px">
        <S.ExitIcon onClick={close}>x</S.ExitIcon>
        <ManualLeadForm
          onSubmit={submit}
          {...{
            newLeadConfig,
            handleAttributeUpdate,
            formState,
            optionMap,
            canSubmit,
            formErrors,
          }}
        />
      </S.Box>
    </S.Prompt>
  );
};
