export default (eventTags) => {
  // determine how many tags the user has entered
  const tagsUsed = eventTags?.length || 0;

  // set a limit for how many they're allowed to have total
  const maxTags = 3;

  // determine if the user has already exceeded past the limit
  const hasNegativeTags = tagsUsed < 0;

  // determine how many tags the user has left, if negative, just put zero
  const tagsAvailable = hasNegativeTags ? 0 : maxTags - tagsUsed;

  // determine if the user has exceeeded the limit
  const isAtTagLimit = tagsUsed >= maxTags;

  return {
    tagsAvailable,
    isAtTagLimit,
  };
};
