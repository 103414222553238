import styled, { css } from "styled-components";

export const UploadInput = styled.input`
  display: none;
`;

export const CropLoading = styled.div(
  ({ loading = false }) => css`
    opacity: ${loading ? "1" : "0"};
    pointer-events: ${!loading && "none"};
    position: absolute;
    top: -32px;
    left: -32px;
    width: calc(100% + 32px);
    height: calc(100% + 32px);
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: all linear 350ms;
    &:before {
      position: fixed;
      top: 50%;
      content: "Preparing image...";
      font-size: 36px;
      color: white;
    }
  `
);
