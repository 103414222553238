import styled from "styled-components";
import { colors } from "consts";
import { Flex } from "components";

export const Drawer = styled(Flex)`
  background: ${colors.gray500};
  padding: 20px 24px;
  width: 100%;
`;

export const DrawerItem = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 16px 0px;
  padding: 0 16px 0 0;
`;
