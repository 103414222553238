import React from "react";
import * as S from "./FoyerTabs.styled";
import { Text } from "components";

// NEEDED FROM EVENT interactiveQa, capability,

export default ({ setTab, tab, interactiveQa, enableLive, capability }) => (
  <S.Wrapper responsive={{ tablet: { padding: "0 30px" } }} padding="0 105px">
    <S.Tab onClick={() => setTab("about")} active={tab === "about"}>
      <Text responsive={{ tablet: { size: "18px" } }} size="28px">
        About
      </Text>
    </S.Tab>
    {(interactiveQa || enableLive) && (
      <S.Tab onClick={() => setTab("qa")} active={tab === "qa"}>
        <Text responsive={{ tablet: { size: "18px" } }} size="28px">
          FAQ
        </Text>
      </S.Tab>
    )}
    {capability && capability.status === "active" && (
      <S.Tab onClick={() => setTab("store")} active={tab === "store"}>
        <Text responsive={{ tablet: { size: "18px" } }} size="28px">
          Support Us
        </Text>
      </S.Tab>
    )}
  </S.Wrapper>
);
