import { hot } from "react-hot-loader/root";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import { PrivateRoute } from "providers";
import { useSetTokens, AuthContext } from "hooks";
import {
  AdminDashboard,
  AdminCustomerDetails,
  AdminEventDetails,
  AdminLeadOwnerDetails,
  CreateEvent,
  EventsLibrary,
  ForgotPassword,
  Login,
  Signup,
  Event,
  EditEvent,
  ResetPassword,
  FilmmakerLanding,
  Settings,
  NextSteps,
  DIYCreateEvent,
} from "pages";
import { logPageView } from "utils/analytics";

const App = () => {
  const [user, setTokens] = useSetTokens();

  return (
    <AuthContext.Provider value={{ user, setUser: setTokens }}>
      <Router>
        <RouteTracker />
        {/* Landing Routes */}
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/sign-up" component={Signup} />
        <Route exact path="/next-steps" component={NextSteps} />
        <Route exact path="/settings" component={Settings} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        {/* Event Routes */}
        <Route exact path="/preview/:url" component={Event} />
        <Route exact path="/watch/:url" component={Event} />
        <Route path="/watch/:url/:slug" component={Event} />
        {/* Dashboard Routes */}
        <PrivateRoute exact path="/welcome" component={FilmmakerLanding} />
        <PrivateRoute exact path="/events" component={EventsLibrary} />
        <PrivateRoute path="/create-diy-event" component={DIYCreateEvent} />
        <PrivateRoute
          path="/create-event/:customerId"
          component={CreateEvent}
        />
        <PrivateRoute
          exact
          path="/event/:customerId/:url"
          component={EditEvent}
        />
        <PrivateRoute exact path="/event/:url" component={EditEvent} />
        {/* Admin Routes */}
        <PrivateRoute
          admin
          exact
          path="/admin/:adminRoute"
          component={AdminDashboard}
        />
        <PrivateRoute
          admin
          path="/admin/customer-details/:customerId"
          component={AdminCustomerDetails}
        />
        <PrivateRoute
          admin
          path="/admin/event-details/:eventId"
          component={AdminEventDetails}
        />
        <PrivateRoute
          admin
          path="/admin/leadowner-details/:leadOwnerId"
          component={AdminLeadOwnerDetails}
        />
      </Router>
    </AuthContext.Provider>
  );
};

const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname);
  }, [location]);

  return null;
};

export default hot(App);
