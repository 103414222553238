import { sendInvitation } from "api/invitations";
import { Button, Flex, Input, ModalProvider, Text } from "components";
import * as S from "./ForwardToFriendModal.styled";
import { useState } from "react";

export default (props) => {
  const { show, setShow } = props;
  const [modalState, setModalState] = useState("initial");

  const modalProps = { props, setModalState };

  const modalStates = {
    initial: <InitialModal {...modalProps} />,
    success: <SuccessModal {...modalProps} />,
    error: <ErrorModal {...modalProps} />,
  };

  return (
    <ModalProvider
      width="750px"
      margin="110px"
      padding="32px"
      show={show}
      close={() => setShow(false)}
    >
      {modalStates[modalState]}
    </ModalProvider>
  );
};

const InitialModal = ({ props, setModalState }) => {
  const { setShow, eventId } = props;

  const [yourName, setYourName] = useState("");
  const [friendName, setFriendName] = useState("");
  const [friendEmail, setFriendEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const submit = async () => {
    if (!yourName || !friendName || !friendEmail) {
      setError("Please fill out all fields");
      return;
    }

    if (!isValidEmail(friendEmail)) {
      setError("Please enter a valid email address");
      return;
    }

    setLoading(true);

    try {
      const payload = {
        senderName: yourName,
        recipientName: friendName,
        recipientEmail: friendEmail,
      };

      await sendInvitation(eventId, payload);

      setModalState("success");
    } catch {
      setModalState("error");
    } finally {
      setLoading(false);
    }
  };

  const exit = () => setShow(false);

  return (
    <Flex width="100%" gap="32px" column>
      <Text center size="32px" weight="bold">
        Thanks for sharing this special virtual event with your friend!
      </Text>
      <Text>
        Please enter their name and email and they will recieve an invitation
        immediately. Feel free to invite mulitple friends who you think would
        love to see this film.
      </Text>
      <S.InputWrapper gap="24px" width="100%" justify="center">
        <Input
          placeholder="Your Full Name"
          value={yourName}
          onChange={(e) => setYourName(e.target.value)}
        />
        <Input
          placeholder="Your Friend's Name"
          value={friendName}
          onChange={(e) => setFriendName(e.target.value)}
        />
        <Input
          placeholder="Your Friend's Email"
          value={friendEmail}
          onChange={(e) => setFriendEmail(e.target.value)}
        />
      </S.InputWrapper>
      {error && <Text color="red">{error}</Text>}
      {loading && <Text>Loading...</Text>}
      <Flex gap="24px" width="100%" justify="center">
        <Button onClick={exit}>Cancel</Button>
        <Button onClick={submit}>Share with friend</Button>
      </Flex>
    </Flex>
  );
};

const SuccessModal = ({ props, setModalState }) => {
  const { setShow } = props;

  const exit = () => {
    setShow(false);
    setModalState("initial");
  };

  return (
    <Flex width="100%" gap="32px" column>
      <Text center size="32px" weight="bold">
        Success!
      </Text>
      <Text center>
        Your friend will recieve an email with an invitation to this event.
      </Text>
      <Flex gap="24px" width="100%" justify="center">
        <Button onClick={exit}>Close</Button>
        <Button onClick={() => setModalState("initial")}>
          Send to another friend
        </Button>
      </Flex>
    </Flex>
  );
};

const ErrorModal = ({ props, setModalState }) => {
  const { setShow } = props;

  const exit = () => {
    setShow(false);
    setModalState("initial");
  };

  return (
    <Flex width="100%" gap="32px" column>
      <Text center size="32px" weight="bold">
        Error
      </Text>
      <Text center>Something went wrong. Please try again later.</Text>
      <Button onClick={exit}>Close</Button>
    </Flex>
  );
};
