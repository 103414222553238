import { useState } from "react";

export default (item) => {
  const [localState, setHookState] = useState(
    JSON.parse(localStorage.getItem(item))
  );

  const setLocalStorage = (newItemValue) => {
    localStorage.setItem(item, newItemValue);
    setHookState(newItemValue);
  };

  return [localState, setLocalStorage];
};
