import React, { useState, useEffect } from "react";
import { useAnalytics } from "use-analytics";
import {
  Header,
  BackgroundProvider,
  DashboardSidebar,
  Flex,
  DashboardFilmDetails,
  DashboardEventDetails,
  DashboardStore,
  DashboardActivation,
  DashboardQA,
  DashboardAbout,
  DashboardAttendees,
} from "components";
import { PAGES } from "api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
toast.configure();

export default ({ match }) => {
  const [step, setStep] = useState("attendees");
  const [screening, setScreening] = useState({});
  const [loading, setLoading] = useState(false);
  const { page } = useAnalytics();

  const { customerId } = useParams();

  useEffect(() => {
    page();
  }, [page]);

  useEffect(() => {
    const init = async () => {
      const _page = await PAGES.getByUrl(match.params.url);
      if (!_page.data.data.subscriptionSettings)
        _page.data.data.subscriptionSettings = {};
      if (!_page.data.data.paywallSettings)
        _page.data.data.paywallSettings = {};
      if (!_page.data.data.aboutSettings) _page.data.data.aboutSettings = {};
      setScreening(_page.data.data);
    };

    init();
    // eslint-disable-next-line
  }, []);

  const updateScreening = async (data) => {
    const _updated = { ...screening, ...data };
    setScreening(_updated);
  };

  const checkAndSetStep = (step) => screening.id && setStep(step);

  const patchScreening = async (_screening) => {
    try {
      const start = new Date(_screening.eventStart).valueOf();
      const end = new Date(_screening.eventEnd).valueOf();

      if (start > end) {
        toast.error("The start date must be before the end date");
        return;
      }
      if (start === end) {
        toast.error("The start and end dates must be different");
        return;
      }

      setLoading(true);
      const {
        data: { data },
      } = await PAGES.update(_screening);
      setScreening(data);
      setLoading(false);
      toast("event updated successfully", { autoClose: 3000 });
    } catch (err) {
      toast("Error updating event", { autoClose: 3000 });
    }
  };

  const actions = [
    {
      action: () => checkAndSetStep("attendees"),
      text: "Attendees",
      active: step === "attendees",
      hasID: screening.id,
      forUserType: ["superadmin", "filmmaker", "diy-filmmaker"],
    },
    {
      action: () => checkAndSetStep("filmDetails"),
      text: "Film Details",
      active: step === "filmDetails",
      hasID: screening.id,
      forUserType: ["superadmin", "diy-filmmaker"],
    },
    {
      action: () => checkAndSetStep("about"),
      text: "About",
      active: step === "about",
      hasID: screening.id,
      forUserType: ["superadmin", "diy-filmmaker"],
    },
    {
      action: () => checkAndSetStep("eventDetails"),
      text: "Event Details",
      active: step === "eventDetails",
      hasID: screening.id,
      forUserType: ["superadmin", "diy-filmmaker"],
    },
    {
      action: () => checkAndSetStep("products"),
      text: "Store",
      active: step === "products",
      hasID: screening.id,
      forUserType: ["superadmin", "diy-filmmaker"],
    },
    {
      action: () => checkAndSetStep("qa"),
      text: "FAQ",
      active: step === "qa",
      hasID: screening.id,
      forUserType: ["superadmin", "diy-filmmaker"],
    },
    {
      action: () => checkAndSetStep("activation"),
      text: "Payments & Activation",
      active: step === "activation",
      hasID: screening.id,
      forUserType: ["superadmin", "diy-filmmaker"],
    },
  ];

  const filteredActions = actions.filter(({ forUserType }) => {
    const isSuperAdmin = customerId;
    const isDiy = screening.diyEvent === true;
    const isNonDiy = !isSuperAdmin || !isDiy;

    if (isSuperAdmin) return forUserType.includes("superadmin");
    if (isDiy) return forUserType.includes("diy-filmmaker");
    if (isNonDiy) return forUserType.includes("filmmaker");

    return forUserType.includes("filmmaker");
  });

  return (
    <BackgroundProvider direction="column">
      <Header user />
      <Flex align="flex-start">
        <DashboardSidebar
          title="Edit Event"
          actions={filteredActions}
          watch={screening.url}
          id={screening.id}
        />

        {step === "attendees" && (
          <>
            <DashboardAttendees
              setStep={(e) => setStep(e)}
              screening={screening}
              update={(e) => updateScreening(e)}
              loading={loading}
            />
          </>
        )}
        {step === "filmDetails" && (
          <>
            <DashboardFilmDetails
              setStep={(e) => setStep(e)}
              screening={screening}
              update={(e) => updateScreening(e)}
              patch={patchScreening}
              loading={loading}
              isExisting={true}
              id={match.params.url}
            />
          </>
        )}
        {step === "about" && (
          <DashboardAbout
            setStep={(e) => setStep(e)}
            screening={screening}
            update={(e) => updateScreening(e)}
            patch={patchScreening}
            isExisting={true}
            id={match.params.url}
          />
        )}
        {step === "eventDetails" && (
          <DashboardEventDetails
            setStep={(e) => setStep(e)}
            screening={screening}
            update={(e) => updateScreening(e)}
            patch={patchScreening}
            loading={loading}
            isExisting={true}
            id={match.params.url}
          />
        )}
        {step === "products" && (
          <DashboardStore
            setStep={(e) => setStep(e)}
            screening={screening}
            update={(e) => updateScreening(e)}
            loading={loading}
            isExisting={true}
          />
        )}
        {step === "activation" && (
          <DashboardActivation
            setStep={(e) => setStep(e)}
            screening={screening}
            update={(e) => updateScreening(e)}
            patch={patchScreening}
            editEvent={true}
            eventId={screening.id}
            loading={loading}
            isExisting={true}
          />
        )}
        {step === "qa" && (
          <DashboardQA
            setStep={(e) => setStep(e)}
            screening={screening}
            update={(e) => updateScreening(e)}
            patch={patchScreening}
            loading={loading}
            isExisting={true}
            id={match.params.url}
          />
        )}
      </Flex>
    </BackgroundProvider>
  );
};
