import styled from "styled-components";
import { colors } from "consts";

export const Wrap = styled.div`
  .file-drop-target {
    transition-duration: 0.3s;
    &.file-drop-dragging-over-frame {
      opacity: 0.6;
    }
  }
`;

export const Thumbnail = styled.div`
  width: 160px;
  height: 168px;
  border-radius: 8px;
  background: ${({ url }) => (url ? `url(${url})` : "")};
  background-size: cover;
  background-position: top center;
  position: relative;
  margin: 16px 0;
`;

export const HiddenInput = styled.input`
  overflow: hidden;
  width: 0px;
  height: 0px;
`;

export const Delete = styled.div`
  background: ${colors.red};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: -10px;
  right: -10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
