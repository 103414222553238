import React, { useState } from "react";
import { Button, DuplicatePrompt, Flex, Text } from "components";
import AdminTable from "../AdminTable";
import * as S from "./AdminCustomerDetails.styled";
import { useHistory, useParams } from "react-router-dom";
import { PAGES, CREDITS, ACCOUNT } from "api";
import { toast } from "react-toastify";

export default ({ ownerDetails }) => {
  const { pages } = ownerDetails;

  // hooks
  const history = useHistory();
  const { customerId } = useParams();

  const [showDuplicatePrompt, setShowDuplicatePrompt] = useState(false);
  const [credits, setCredits] = useState(0);
  const [eventId, setEventId] = useState(null);

  const createEvent = () => {
    if (customerId) {
      history.push(`/create-event/${customerId}`);
    } else {
      alert("Customer ID missing. Please contact an administrator.");
    }
  };

  const showDuplicate = async (row) => {
    const {
      data: { credits },
    } = await CREDITS.getCreditsById(row.accountId);
    typeof credits !== "undefined" && setCredits(credits);
    setShowDuplicatePrompt(true);
    setEventId(row.id);
  };

  const duplicate = async () => {
    const { data } = await ACCOUNT.getById(customerId);

    try {
      const { data: pageData } = await PAGES.duplicate(eventId, {
        account_email: data?.data?.email,
      });

      if (pageData?.data?.id) {
        toast.success("Event duplicated successfully");
        setShowDuplicatePrompt(false);
        window.location.reload(false);
      }
    } catch (e) {
      toast.error("Error duplicating event");
      setShowDuplicatePrompt(false);
    }
  };

  return (
    <>
      {showDuplicatePrompt && (
        <DuplicatePrompt
          {...{
            showDuplicatePrompt,
            setShowDuplicatePrompt,
            credits,
            duplicate,
          }}
        />
      )}
      <S.ListContainer margin="0 0 24px 0" maxHeight="450px">
        <Flex justify="space-between" align="center" margin="0 0 24px 0">
          <Text size="24px" weight="bold">
            Events
          </Text>
          <Button className="secondary" onClick={createEvent}>
            Create New Event
          </Button>
        </Flex>

        {pages?.length > 0 ? (
          <AdminTable
            type="customer_events"
            data={pages}
            {...{ history, customerId, showDuplicate }}
          />
        ) : (
          <Flex>
            <Text>Customer has no events</Text>
          </Flex>
        )}
      </S.ListContainer>
    </>
  );
};
