import axiosRequest from "./axios.config";
import randomstring from "randomstring";

export const create = async (id, product) =>
  await axiosRequest.post(`/v1/pages/${id}/products`, product);

export const get = async (id) =>
  await axiosRequest.get(`/v1/pages/${id}/products/all`);

export const update = async (id, product) =>
  await axiosRequest.patch(`/v1/pages/${id}/products/${product.id}`, product);

export const updateProductSortOrder = async (id, products) =>
  await axiosRequest.patch(`/v1/pages/${id}/products/sort_order`, products);

export const getPageProducts = async (id, type) =>
  await axiosRequest.get(`/v1/pages/${id}/products?type=${type}`);

export const getPageStoreProducts = async (id) =>
  await axiosRequest.get(`/v1/pages/${id}/products/store`);

export const purchase = async (id, order) =>
  await axiosRequest.post(`/v1/pages/${id}/orders`, order);

export const remove = async (id, productId) =>
  await axiosRequest.delete(`/v1/pages/${id}/products/${productId}`);

export const updatePaywall = async (screening) => {
  const _screening = { ...screening };
  let paywallOptions = _screening.paywallSettings;
  if (paywallOptions) {
    if (paywallOptions.isOptional && !paywallOptions.donationProductId) {
      const _product = {
        price: Math.round(parseFloat(paywallOptions.price * 100)),
        type: "donation",
        title: "Donation",
        sku: randomstring.generate({ charset: "numeric", length: 10 }),
        active: true,
      };
      const _created = await create(screening.id, _product);
      paywallOptions.donationProductId = _created.data.data.id;
      return paywallOptions;
    } else if (!paywallOptions.isOptional && !paywallOptions.paywallProductId) {
      const _product = {
        price: Math.round(parseFloat(paywallOptions.price * 100)),
        type: "paywall",
        title: "Paywall",
        sku: randomstring.generate({ charset: "numeric", length: 10 }),
        active: true,
      };
      const _created = await create(screening.id, _product);
      paywallOptions.paywallProductId = _created.data.data.id;
      return paywallOptions;
    } else {
      const _paywallProduct = {
        id: paywallOptions.isOptional
          ? paywallOptions.donationProductId
          : paywallOptions.paywallProductId,
        price: Math.round(parseFloat(paywallOptions.price * 100)),
        type: paywallOptions.isOptional ? "donation" : "paywall",
      };
      await update(_screening.id, _paywallProduct);
      return paywallOptions;
    }
  }
};

export const newPaywallId = async (screening) => {
  const _screening = { ...screening };
  let paywallOptions = _screening.paywallSettings;
  if (paywallOptions) {
    const _product = {
      price: Math.round(parseFloat(paywallOptions.price * 100)),
      type: "paywall",
      title: "Paywall",
      sku: randomstring.generate({ charset: "numeric", length: 10 }),
      active: true,
    };
    const _created = await create(screening.id, _product);
    paywallOptions.donationProductId = _created.data.data.id;

    return paywallOptions;
  }
};

export const newPaywallId2 = async (paywallSettings, eventId) => {
  const paywallData = paywallSettings;

  if (paywallData) {
    const _product = {
      price: Math.round(parseFloat(paywallData.price * 100)),
      type: "paywall",
      title: "Paywall",
      sku: randomstring.generate({ charset: "numeric", length: 10 }),
      active: true,
    };

    const {
      data: {
        data: { id: productID },
      },
    } = await create(eventId, _product);

    paywallData.paywallProductId = productID;

    return paywallData;
  }
};

export const newDonationProductId2 = async (
  paywallSettings,
  eventId,
  price
) => {
  let paywallOptions = paywallSettings;
  if (paywallOptions) {
    const _product = {
      price: Math.round(parseFloat(price * 100)),
      type: "donation",
      title: "Donation",
      sku: randomstring.generate({ charset: "numeric", length: 10 }),
      active: true,
    };
    const _created = await create(eventId, _product);
    paywallOptions.donationProductId = _created.data.data.id;

    return paywallOptions;
  }
};

export const newDonationProductId = async (screening, price) => {
  const _screening = { ...screening };
  let paywallOptions = _screening.paywallSettings;
  if (paywallOptions) {
    const _product = {
      price: Math.round(parseFloat(price * 100)),
      type: "donation",
      title: "Donation",
      sku: randomstring.generate({ charset: "numeric", length: 10 }),
      active: true,
    };
    const _created = await create(screening.id, _product);
    paywallOptions.donationProductId = _created.data.data.id;

    return paywallOptions;
  }
};
