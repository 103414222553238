import styled from "styled-components";
import { colors } from "consts";
import { Button } from "components";

export const EditButton = styled.span`
  padding: 16px;
  border-radius: 50%;
  font-size: 200%;
  &:hover {
    cursor: pointer;
    background: ${colors.activeWhite};
  }
`;

export const CreditInput = styled.input`
  width: 50px;
  border: none;
  outline: none;
  padding: 4px;
  margin-right: 12px;
  &.active {
    color: green;
    font-weight: 600;
  }
`;

export const ConfirmButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

export const Drawer = styled.div`
  background: ${colors.charcoal};
  margin-top: -2px;
  padding: 0 12px;
`;

export const DrawerItem = styled.div`
  min-width: 200px;
  float: left;
  margin: 12px 0px;
`;

export const SearchBox = styled.div`
  margin: 12px auto;
  color: ${colors.activeWhite};
  width: 355px;
  border-bottom: 1px solid ${colors.activeWhite};
  padding-bottom: 8px;
  text-align: center;
  input {
    width: 270px;
    background: ${colors.black};
    color: white;
  }
  span {
    cursor: pointer;
    margin-left: 12px;
  }
`;

export const Carrot = styled.div`
  position: absolute;
  top: 20%;
  left: -2px;
  &.down {
    transform: rotate(180deg);
    top: 18%;
  }
  &:after {
    content: "▴";
    font-size: 14px;
  }
`;

export const SubmitButton = styled(Button)`
  position: absolute;
  top: 18px;
  right: 8px;
`;

export const Wrapper = styled.div`
  background-color: ${colors.gray200};
  box-shadow: 0 4px 4px -2px rgba(0, 20, 30, 0.2);
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  position: relative;
  /* These styles are suggested for the table fill all available space in its containing element */
  display: block;
  /* These styles are required for a horizontaly scrollable table overflow */
  overflow: auto;
  &.clickable {
    .td {
      cursor: pointer;
    }
  }

  .table {
    border-spacing: 0;

    .thead {
      /* These styles are required for a scrollable body to align with the header properly */
      overflow-y: auto;
      overflow-x: hidden;
    }

    .tbody {
      /* These styles are required for a scrollable table body */
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      border-bottom: 1px solid ${colors.activeWhite};
    }

    .th {
      color: ${colors.gray8};
      font-size: 16px;
    }

    .td {
      color: ${colors.gray8};
      font-size: 14px;
    }

    .th,
    .td {
      margin: 0;
      padding: 1rem;
      position: relative;
      :last-child {
        border-right: 0;
      }

      .resizer {
        right: 0;
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        &:hover {
          background: rgba(64, 79, 87, 0.6);
        }

        /* prevents from scrolling while dragging on touch devices */
        touch-action: none;

        &.isResizing {
          background: ${colors.orange200};
        }
      }
    }
  }
`;
