import { CREDITS } from "api";
import { CreditsPurchaseModal } from "components/Modals";
import { useEffect } from "react";

export default ({ setCredits, close, setStep }) => {
  const getCredits = async () => {
    const {
      data: { credits },
    } = await CREDITS.getCredits();
    typeof credits !== "undefined" && setCredits(credits);
  };

  useEffect(() => {
    getCredits();
    // eslint-disable-next-line
  }, []);

  const success = ({ credits }) => {
    setCredits(credits);
    setStep("watch-video");
  };

  return (
    <CreditsPurchaseModal
      success={success}
      back={() => setStep("watch-video")}
      close={close}
    />
  );
};
