import styled from "styled-components";
import { Flex } from "components";

export default styled(Flex)`
  background-image: ${({ url }) => (url ? `url(${url})` : "")};
  background-size: cover;
  background-position: center;
  background-color: ${({ color }) => (color ? color : "#101010")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "auto")};
`;
