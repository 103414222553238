import React from "react";
import { Flex } from "components";
import { colors } from "consts";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import ProductItem from "./ProductItem";

export const ProductCard = styled(Flex)`
  background: ${({ background = colors.green }) => background};
  flex-direction: column;
  align-items: center;
  padding: 8px;
  ${({ edit }) =>
    edit &&
    `
    cursor: pointer;
  `}
  img {
    width: 100%;
    object-fit: contain;
  }
`;

const DraggableProduct = ({ url, id, edit }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id, disabled: !edit });

  const draggableCardStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ProductItem ref={setNodeRef} {...attributes} {...listeners}>
      <ProductCard
        id={id}
        edit={edit}
        column
        align="flex-start"
        justify="flex-end"
        style={draggableCardStyle}
        {...attributes}
        {...listeners}
        background={isDragging ? "none" : colors.gray850}
      >
        {!isDragging && <img src={url} alt="draggable_product_image" />}
      </ProductCard>
    </ProductItem>
  );
};
export default DraggableProduct;
