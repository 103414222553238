import { useQuery } from "react-query";
import axiosRequest from "../../api/axios.config";

const fetcher = async (eventId) => {
  const endpoint = `/v1/pages/${eventId}`;

  const raw = await axiosRequest.get(endpoint);
  return raw.data.data;
};

const useGetEvent = (eventId) => {
  return useQuery(
    ["event", eventId],
    async () => {
      const data = await fetcher(eventId);

      return data;
    },
    {
      enabled: !!eventId,
    }
  );
};

export { useGetEvent };
