import React, { useState, useEffect } from "react";
import { useAnalytics } from "use-analytics";
import {
  Button,
  Header,
  BackgroundProvider,
  Flex,
  Box,
  Input,
  Label,
  Text,
} from "components";
import { USERS } from "api";
import { colors } from "consts";
import { toast } from "react-toastify";

export default () => {
  const [message] = useState(null);
  const [email, setEmail] = useState();
  const { page } = useAnalytics();

  useEffect(() => {
    page();
  }, [page]);

  const sendReset = async () => {
    try {
      await USERS.forgotPassword(email);
      toast.success("Password Reset email sent successfully", {
        autoClose: 3000,
      });
    } catch (err) {
      toast.error("Error sending forgot password email", { autoClose: 3000 });
    }
  };

  return (
    <BackgroundProvider
      height="100vh"
      color={colors.bgGray}
      url={require("assets/images/projector.jpg").default}
    >
      <Header showBackButton={false} />

      <Flex justify="center" height="95vh">
        <Box light width="465px" margin="48px 0">
          <Flex direction="column" align="flex-start">
            <Flex justify="center">
              <Text dark size="32px" lineheight="36px" margin="0 0 45px">
                Forgot Password?
              </Text>
            </Flex>

            <Label color={colors.black}>Email Address</Label>
            <Input
              light
              margin="0 0 83px"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email"
            />

            <Flex justify="center">
              {message && (
                <Text dark margin="-32px 0 12px" color={colors.red}>
                  {message}
                </Text>
              )}
            </Flex>

            <Flex justify="flex-end">
              <Button className="" onClick={() => sendReset()}>
                Send Reset Link
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </BackgroundProvider>
  );
};
