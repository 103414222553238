/**
 * Preserve all the API URLs here, and uncomment as needed for development
 * api should be set to the following before committing changes:
 *      process.env.REACT_APP_API_URL || "http://localhost:8080";
 */

// const api = "https://api.showandtell.film"; // live API
// const api = "https://api-staging.showandtell.film"; // staging API
const api = process?.env?.REACT_APP_API_URL || "http://localhost:8080";

console.log("API:", api);

export default api;
