import { useState, useEffect } from "react";

export default (url) => {
  const [preloadImage, setPreloadImage] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = url;
    img.onload = () => setPreloadImage(url);
  }, [url]);

  return preloadImage;
};
