import React, { useState } from "react";
import { Button, Flex, Image } from "components";
import { FILES } from "api";
import * as S from "./AddAboutPhotos.styled";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import DroppableProductArea from "./DroppableProductArea";
import ProductItem from "./ProductItem";

export default ({ setUploaded, images, setImages }) => {
  const [sortableImages, setSortableImages] = useState(images);
  const [edit, setEdit] = useState(false);
  const [sortableItemIds, setSortableItemIds] = useState(
    images.map(({ id }) => id)
  );
  const [activeId, setActiveId] = useState(null);
  const [activeProduct, setActiveProduct] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const deleteFile = async (imageId) => {
    try {
      if (imageId) {
        const _images = images.filter((_image) => _image.id !== imageId);
        setImages(_images);
        setUploaded(_images);
        setSortableImages(_images);
        await FILES.deleteFile(imageId);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDragEnd = (e) => {
    const { active, over } = e;
    let oldIndex;
    let newIndex;

    if (active.id !== over.id) {
      setSortableItemIds((items) => {
        oldIndex = items.indexOf(active.id);
        newIndex = items.indexOf(over.id);
        return arrayMove(sortableItemIds, oldIndex, newIndex);
      });
      setSortableImages(arrayMove(sortableImages, oldIndex, newIndex));
    }
    setActiveId(null);
  };

  const handleDragStart = ({ active }) => {
    setActiveId(active.id);
    const draggedItem = sortableImages.find((img) => img.id === active.id);
    setActiveProduct(draggedItem);
  };

  const handleSave = async () => {
    const payload = [];

    for (const [index, product] of sortableImages.entries()) {
      const productSortUpdate = {
        ...product,
        sort_order: index,
      };
      payload.push(productSortUpdate);
    }

    setEdit(false);
    setImages(payload);
    setUploaded(payload);
  };

  return (
    <>
      <Flex margin="0 -8px" wrap={1}>
        {edit ? (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={sortableItemIds}>
              <Flex justify="center" style={{ border: "2px dotted red" }}>
                <DroppableProductArea
                  {...{
                    images: sortableImages,
                    edit,
                  }}
                />
              </Flex>
            </SortableContext>
            <DragOverlay>
              {activeId ? (
                <ProductItem id={activeId} {...{ activeProduct, edit }} />
              ) : null}
            </DragOverlay>
          </DndContext>
        ) : (
          images.map((_image, i) => (
            <S.Upload
              key={i}
              src={_image.url}
              onClick={() => deleteFile(_image.id)}
            >
              <div className="overlay" />
              <Image
                width="74px"
                src={require("assets/images/icon-delete-image.svg").default}
              />
            </S.Upload>
          ))
        )}

        <Flex margin="12px 0" width="100%" justify="center">
          {!edit ? (
            <Button onClick={() => setEdit(true)}>Edit Order of Photos</Button>
          ) : (
            <Button onClick={handleSave}>Save Photo Order</Button>
          )}
        </Flex>
      </Flex>
    </>
  );
};
