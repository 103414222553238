import React from "react";
import { Flex, Text, Button } from "components";
import * as S from "./MockBanner.styled";

export default ({ information, height, background, target }) => {
  const isMobile = target === "mobile";
  const bannerWidth = isMobile ? "25%" : "100%";

  return (
    <S.Wrapper height={height} maxWidth={bannerWidth} background={background}>
      {isMobile && <S.MobileOverlay />}
      {!isMobile && <S.Background />}
      <S.ContentWrapper
        minwidth="100%"
        direction="column"
        align="flex-start"
        padding={isMobile ? "0 0 0 35px" : "0 0 0 50px"}
        left={isMobile ? "-30px" : "-80px"}
      >
        <Flex width="200px">
          <Text
            size={isMobile ? "16px" : "24px"}
            weight="bold"
            className="canela"
            margin="0 0 8px"
          >
            {information?.title || "title"}
          </Text>
        </Flex>
        <Text size="12px" margin="0 0 4px">
          {information?.description || "description"}
        </Text>

        <Button width="90px" height="25px" margin="0 0 4px">
          <Text size="8px">Watch Film</Text>
        </Button>
        <Button width="90px" height="25px" className="outline-white">
          <Text size="8px">Watch Trailer</Text>
        </Button>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};
