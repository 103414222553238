import { useEffect } from "react";

export default ({ action }) => {
  useEffect(() => {
    action();

    //eslint-disable-next-line
  }, []);

  return <></>;
};

// import { Flex, Text, Checkbox, Button } from "components";
// export default ({ eventReminderOptOut, setEventReminderOptOut, action }) => {
//   return (
//     <Flex align="center" justify="center">
//       <Text dark className="canela" size="64px">
//         Next steps to watch the film
//       </Text>
//       <Flex
//         direction="column"
//         align="flex-start"
//         width="600px"
//         margin="0 0 0 24px"
//       >
//         <ul>
//           <li>
//             <Text dark>
//               You'll recieve an email from Show&Tell with a ticket link to watch
//               the film
//             </Text>
//           </li>
//           {!eventReminderOptOut && (
//             <li>
//               <Text dark>
//                 We'll send you a reminder when the film is available to watch
//               </Text>
//             </li>
//           )}
//         </ul>
//         <Flex direction="column" align="flex-start" margin="0 0 0 38px">
//           <Text dark>
//             Check here if you would not like to recieve an email reminder:
//           </Text>
//           <Checkbox
//             checked={eventReminderOptOut}
//             onClick={() => setEventReminderOptOut(!eventReminderOptOut)}
//           />
//         </Flex>
//         <Text dark margin="12px 0 12px 38px" fontStyle="italic">
//           Your information will never be sold or shared
//         </Text>
//         <Button
//           onClick={() => action()}
//           padding="20px 32px"
//           margin="40px 0 40px 38px"
//           height="auto"
//         >
//           <Text size="18px" padding="0 18px 0 0">
//             Continue
//           </Text>
//           <img
//             src={require("assets/images/icon-arrow-right.svg").default}
//             alt="arrow-right"
//           />
//         </Button>
//       </Flex>
//     </Flex>
//   );
// };
