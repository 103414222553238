import axiosRequest from "./axios.config";

export const get = async (nextPage) => {
  try {
    return await axiosRequest.get(`${nextPage ? nextPage : "/v1/pages"}`);
  } catch (e) {
    // If there are no pages user logged out -- send them to login
    window.location.href = "/login";
  }
};

export const activate = async (id) => {
  return await axiosRequest.post(`/v1/pages/${id}/activate`);
};

export const getArchived = async (nextPage) =>
  await axiosRequest.get(`${nextPage ? nextPage : "/v1/pages/archived"}`);

export const getByUrl = async (url) =>
  await axiosRequest.get("/v1/pages/" + url);

export const getById = async (id) => await axiosRequest.get(`/v1/pages/` + id);

export const create = async (page, customerId) => {
  const _page = { ...page };
  for (var i in _page) if (_page[i] == null) delete _page[i];
  if (_page.subscriptionSettings)
    _page.subscriptionSettings = JSON.stringify(_page.subscriptionSettings);
  if (_page.paywallSettings)
    _page.paywallSettings = JSON.stringify(_page.paywallSettings);
  if (_page.aboutSettings)
    _page.aboutSettings = JSON.stringify(_page.aboutSettings);
  if (_page.photos) _page.photos = JSON.stringify(_page.photos);
  return await axiosRequest.post(`/v1/pages/${customerId}`, _page);
};

export const duplicate = async (id, payload) =>
  await axiosRequest.post(`/v1/pages/duplicate/${id}`, payload);

export const update = async (page) => {
  const _page = { ...page };
  for (var i in _page) if (_page[i] == null) delete _page[i];
  if (_page.subscriptionSettings)
    _page.subscriptionSettings = JSON.stringify(_page.subscriptionSettings);
  if (_page.paywallSettings)
    _page.paywallSettings = JSON.stringify(_page.paywallSettings);
  if (_page.aboutSettings)
    _page.aboutSettings = JSON.stringify(_page.aboutSettings);
  if (_page.photos) _page.photos = JSON.stringify(_page.photos);
  return await axiosRequest.patch("/v1/pages/" + _page.id, _page);
};

export const deleteByUrl = async (url) =>
  await axiosRequest.delete("/v1/pages/" + url);
