import React, { useState, useEffect } from "react";
import { Flex, Text, Button, SimplePrompt } from "components";
import { NOTES } from "api";
import * as S from "./AdminLeadOwnerDetails.styled";
import { toast } from "react-toastify";
import AdminTable from "../AdminTable";

export default ({ ownerDetails: { id, notes } }) => {
  const [notesInfo, setNotesInfo] = useState(null);
  const [writingNote, setWritingNote] = useState(false);
  const [viewingNote, setViewingNote] = useState(false);
  const [currentNote, setCurrentNote] = useState("");
  const [editNoteId, setEditNoteId] = useState("");

  useEffect(() => {
    notes && setNotesInfo(notes);
  }, [notes]);

  const submitNote = async (note) => {
    if (note && id) {
      const body = {
        text: note,
      };

      try {
        const {
          data: { data },
        } = await NOTES.postLeadownerNote(id, body);
        setNotesInfo([data, ...notes]);
        setCurrentNote("");
        setWritingNote(false);
        toast("notes successfully updated", { autoClose: 3000 });
      } catch (err) {
        let _message = "";
        if (err.validation) {
          for (var i in err.validation) {
            _message.concat(err.validation[i].msg);
          }
        }
        toast("Error updating notes", { autoClose: 3000 });
      }
    }
  };

  const submitEditedNote = async (note_id, note) => {
    if (note && note_id) {
      const body = {
        text: note,
      };

      try {
        await NOTES.patchNote(note_id, body);
        setCurrentNote("");
        setWritingNote(false);
        toast("notes successfully updated", { autoClose: 3000 });
      } catch (err) {
        let _message = "";
        if (err.validation) {
          for (var i in err.validation) {
            _message.concat(err.validation[i].msg);
          }
        }
        toast("Error updating notes", { autoClose: 3000 });
      }
    }
  };

  return (
    <S.ListContainer margin="0 0 24px 0" maxHeight="450px">
      <Flex justify="space-between" align="center" margin="0 0 24px 0">
        <Text size="24px" weight="bold">
          Notes
        </Text>
        <Button className="secondary" onClick={() => setWritingNote(true)}>
          Add note
        </Button>
      </Flex>

      {notesInfo?.length > 0 ? (
        <AdminTable
          type="customer_notes"
          data={notesInfo}
          {...{ setViewingNote, setCurrentNote, setEditNoteId }}
        />
      ) : (
        <Flex>
          <Text>No Account Notes</Text>
        </Flex>
      )}
      {writingNote && (
        <SimplePrompt close={() => setWritingNote(false)}>
          <Flex padding="20px" direction="column" align="flex-start">
            <Text htmlFor="note" align="start" margin="0 0 8px">
              Note:
            </Text>
            <S.TextArea
              autoFocus={true}
              cols={75}
              rows={15}
              value={currentNote}
              name="note"
              onChange={(e) => {
                setCurrentNote(e.target.value);
              }}
            />
            <Button
              className={`${!currentNote && "disabled"}`}
              onClick={() => submitNote(currentNote)}
            >
              Submit Note
            </Button>
          </Flex>
        </SimplePrompt>
      )}
      {viewingNote && currentNote && editNoteId && (
        <SimplePrompt
          close={() => {
            setCurrentNote("");
            setViewingNote(false);
          }}
        >
          <Flex padding="20px" direction="column" align="flex-start">
            <Text htmlFor="note" margin="0 0 8px">
              Note:
            </Text>
            <S.TextArea
              autoFocus={true}
              cols={75}
              rows={15}
              value={currentNote}
              name="note"
              onChange={(e) => {
                setCurrentNote(e.target.value);
              }}
            >
              {currentNote || "No Note Attached"}
            </S.TextArea>
            <Button
              className={`${!currentNote && "disabled"}`}
              onClick={() => submitEditedNote(editNoteId, currentNote)}
            >
              Submit Edited Note
            </Button>
          </Flex>
        </SimplePrompt>
      )}
    </S.ListContainer>
  );
};
