import React, { useState, useEffect } from "react";
import { Text, Image } from "components";
import * as S from "./FoyerProduct.styled";

export default ({ product, ...props }) => {
  const [currentProduct, setCurrentProduct] = useState();

  useEffect(() => {
    product && setCurrentProduct(product);
    // eslint-disable-next-line
  }, []);

  if (!currentProduct) return null;
  return (
    <S.Product
      {...props}
      pointer
      padding="24px"
      width="293px"
      direction="column"
      align="flex-start"
      justify="space-between"
      responsive={{ tablet: { width: "50%" }, phonePlus: { width: "100%" } }}
    >
      <Image
        width="100%"
        alt="show-and-tell-product"
        src={
          currentProduct.thumbnail ||
          require("assets/images/icon-shopping-cart.svg").default
        }
      />
      <Text minHeight="60px" weight="bold" size="24px" margin="20px 0 0">
        {currentProduct.title}
      </Text>
      <Text height="220px" size="14px" color="#929292" margin="8px 0 20px">
        {currentProduct.description}
      </Text>
      <Text weight="bold" size="24px">
        ${currentProduct.price / 100}
      </Text>
    </S.Product>
  );
};
