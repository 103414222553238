import styled from "styled-components";

export default styled.div`
  display: grid;
  grid-template-columns: ${({ columnWidth }) =>
    `repeat(auto-fill, ${columnWidth})`};
  grid-gap: 15px;
  justify-content: space-between;
  width: 100%;
`;
