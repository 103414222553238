import React, { useState, useEffect } from "react";
import { Flex, Text, Image } from "components";
import { colors } from "consts";
import * as S from "./AdminTable.styled";

export default ({ total, amount, currentPage, setCurrentPage, limit = 50 }) => {
  const [activePage, setActivePage] = useState(currentPage);
  const [activePageSet, setActivePageSet] = useState([]);
  const [totalPages, setTotalPages] = useState(Math.ceil(total / limit));
  const [pageSets, setPageSets] = useState([]);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const newTotalPages = Math.ceil(total / limit);
    setTotalPages(newTotalPages);
    // eslint-disable-next-line
  }, [total]);

  useEffect(() => {
    if (totalPages) {
      let numberOfPageSets = Math.ceil(totalPages / 3);
      let remainderPageSet = totalPages % 3;
      let firstPage = 1;
      let newPageSets = [];
      for (let i = 1; i <= numberOfPageSets; i++) {
        if (i === numberOfPageSets) {
          switch (remainderPageSet) {
            case 0:
              newPageSets.push([firstPage, firstPage + 1, firstPage + 2]);
              break;
            case 1:
              newPageSets.push([firstPage]);
              break;
            case 2:
              newPageSets.push([firstPage, firstPage + 1]);
              break;
            default:
              break;
          }
        } else {
          newPageSets.push([firstPage, firstPage + 1, firstPage + 2]);
        }
        firstPage = firstPage + 3;
      }
      setPageSets(newPageSets);
    }
  }, [totalPages]);

  useEffect(() => {
    if (pageSets[0]) {
      const activePageSetIdx = pageSets.findIndex((pageSet) =>
        pageSet.includes(activePage)
      );
      activePageSetIdx !== -1 && setActivePageSet(pageSets[activePageSetIdx]);
    }
  }, [activePage, pageSets]);

  const back = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const forward = () => {
    if (currentPage < Math.ceil(total / limit)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <Flex
      width="100%"
      justify="space-between"
      align="center"
      margin="16px 0 0 0"
    >
      <Flex align="center">
        <Text size="14px" color={colors.white}>
          Showing {amount} of {total}
        </Text>
      </Flex>

      <Flex justify="flex-end" align="center">
        <S.PaginationButton
          bgColor={colors.gray850}
          onClick={back}
          margin="0 8px 0 0"
        >
          <Image src={require("assets/images/icon-caret-left.svg").default} />
        </S.PaginationButton>
        {activePageSet[0] &&
          activePageSet.map((page) => (
            <S.PaginationButton
              bgColor={activePage === page ? colors.gray600 : colors.gray850}
              margin="0 8px 0 0"
              onClick={() => setCurrentPage(page)}
              key={`page-${page}`}
            >
              {page}
            </S.PaginationButton>
          ))}
        <S.PaginationButton
          bgColor={colors.gray850}
          onClick={forward}
          margin="0 8px 0 0"
        >
          <Image src={require("assets/images/icon-caret-right.svg").default} />
        </S.PaginationButton>
      </Flex>
    </Flex>
  );
};
