export default [
  { value: "AU", name: "Australia" },
  { value: "AT", name: "Austria" },
  { value: "BE", name: "Belgium" },
  { value: "CA", name: "Canada" },
  { value: "DK", name: "Denmark" },
  { value: "EE", name: "Estonia" },
  { value: "FI", name: "Finland" },
  { value: "FR", name: "France" },
  { value: "DE", name: "Germany" },
  { value: "GR", name: "Greece" },
  { value: "HK", name: "Hong Kong" },
  { value: "IE", name: "Ireland" },
  { value: "IT", name: "Italy" },
  { value: "JP", name: "Japan" },
  { value: "LV", name: "Latvia" },
  { value: "LT", name: "Lithuania" },
  { value: "LU", name: "Luxembourg" },
  { value: "NL", name: "Netherlands" },
  { value: "NZ", name: "New Zealand" },
  { value: "NO", name: "Norway" },
  { value: "PL", name: "Poland" },
  { value: "PT", name: "Portugal" },
  { value: "SG", name: "Singapore" },
  { value: "SK", name: "Slovakia" },
  { value: "SI", name: "Slovenia" },
  { value: "ES", name: "Spain" },
  { value: "SE", name: "Sweden" },
  { value: "CH", name: "Switzerland" },
  { value: "GB", name: "United Kingdom" },
  { value: "US", name: "United States" },
];
