import ReactGA from "react-ga4";

const MEASUREMENT_ID = "G-BZ5FY1EZ07";

export const initGA = () => {
  ReactGA.initialize(MEASUREMENT_ID);
};

export const logPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

export const logEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

export const logException = (description, fatal = false) => {
  ReactGA.exception({ description, fatal });
};
