import { useQuery } from "react-query";
import axiosRequest from "../../api/axios.config";

const fetcher = async (accountId) => {
  let endpoint = `/v1/admin/account/${accountId}`;
  const raw = await axiosRequest.get(endpoint);
  return raw.data.data;
};

const useGetAccount = (accountId) => {
  return useQuery(
    ["account", accountId],
    async () => {
      const data = await fetcher(accountId);

      return data;
    },
    {
      enabled: !!accountId,
    }
  );
};

export { useGetAccount };
