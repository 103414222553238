import React, { useState } from "react";
import { Button, Image, Text, TosCheckbox } from "components";
import * as S from "../Theater.styled";
import { filmTypes } from "utils";

export default ({
  trailerUrl,
  setActiveVideo,
  setModal,
  gateStatus,
  playVideo,
  paywallSettings,
  visitLimitExceeded,
  setTheaterCtaSenario,
}) => {
  const { type, guest, live, complete } = gateStatus;
  const [tosAccepted, setTosAccepted] = useState(false);

  const toggleTos = () => setTosAccepted(!tosAccepted);

  const PrimaryCTA = ({ onClick, summary, text, disabled, margin }) => (
    <Button
      summary={summary}
      className={`theater-btn laptop-margin ${disabled ? "disabled" : ""}`}
      onClick={() => onClick && onClick()}
      margin={margin}
    >
      {text}
      {!disabled && (
        <Image
          margin="0 0 0 16px"
          width="28px"
          src={require("assets/images/icon-arrow-right.svg").default}
        />
      )}
    </Button>
  );

  const scenario = complete
    ? "complete"
    : `${guest}-${type}${paywallSettings.askDonations ? "-donations" : ""}${
        live ? "-live" : "-notLive"
      }`;

  /**
    For a list of scenarios, see /utils/scenarios.js
  */

  if (visitLimitExceeded) {
    return (
      <S.CtaWrapper>
        <PrimaryCTA
          summary="VISIT LIMIT EXCEEDED"
          text="Max plays reached"
          disabled={true}
        />
        <Text
          color="white"
          responsive={{ tablet: { padding: "16px" } }}
          margin="0 0 24px"
        >
          You have exceeded the number of plays for your purchased ticket. If
          you’re having technical issues, please contact the filmmaker using the
          “Contact Film Team” option at the bottom of this page.
        </Text>
      </S.CtaWrapper>
    );
  }

  return (
    <>
      {/* if there is no gate and guest is new */}
      {scenario === "new-no-gate-live" && (
        <TosCheckbox tosAccepted={tosAccepted} toggleTos={toggleTos} light />
      )}
      <S.CtaWrapper>
        {scenario === "complete" && (
          <PrimaryCTA
            summary="COMPLETED EVENT"
            text="This Event Has Ended"
            disabled={true}
          />
        )}

        {scenario === "new-emailCapture-donations-notLive" && (
          <PrimaryCTA
            onClick={() => setModal("new-emailCapture-donations-notLive")}
            summary="EMAIL CAPTURE + DONATION"
            text="Register"
          />
        )}

        {scenario === "new-emailCapture-notLive" && (
          <PrimaryCTA
            onClick={() => setModal("new-emailCapture-notLive")}
            summary="EMAIL CAPTURE"
            text="Register"
          />
        )}

        {(scenario === "subscribed-emailCapture-notLive" ||
          scenario === "subscribed-emailCapture-donations-notLive") && (
          <Text
            color="#D44B19"
            responsive={{ tablet: { padding: "16px" } }}
            margin="0 0 24px"
            center
          >
            Registered for event
          </Text>
        )}

        {scenario === "new-emailCapture-donations-live" && (
          <PrimaryCTA
            onClick={() => setModal("new-emailCapture-donations-live")}
            summary="EMAIL CAPTURE"
            text={paywallSettings.callToAction || "Watch Now"}
          />
        )}

        {scenario === "new-emailCapture-live" && (
          <PrimaryCTA
            onClick={() => {
              setTheaterCtaSenario("new-emailCapture-live");
              setModal("Register");
            }}
            summary="EMAIL CAPTURE"
            text={paywallSettings.callToAction || "Watch Now"}
          />
        )}

        {(scenario === "subscribed-emailCapture-live" ||
          scenario === "subscribed-emailCapture-donations-live") && (
          <PrimaryCTA
            onClick={() => playVideo()}
            summary="SUBSCRIBED"
            text={paywallSettings.callToAction || "Watch Now"}
          />
        )}

        {/* covers all paywall and paid scenarios */}
        {type === "paywall" && guest === "paid" && (
          <PrimaryCTA
            onClick={() => playVideo()}
            summary="SUBSCRIBED"
            text="Watch Film"
          />
        )}

        {/* if there is no gate and guest is new */}
        {scenario === "new-no-gate-live" && (
          <PrimaryCTA
            onClick={() => tosAccepted && playVideo()}
            summary="NO GATE"
            text="Watch Film"
            disabled={!tosAccepted}
            margin="24px 0 0"
          />
        )}

        {/* if gate is paid and user has not paid */}
        {scenario === "new-paywall-live" && (
          <PrimaryCTA
            onClick={() => {
              setTheaterCtaSenario("new-paywall-live");
              setModal("Paywall");
            }}
            summary="IS LIVE PAYWALL"
            text={`Buy Ticket $${(paywallSettings.price * 1).toFixed(2)}`}
          />
        )}

        {scenario === "new-paywall-donations-live" && (
          <PrimaryCTA
            onClick={() => setModal("new-paywall-donations-live")}
            summary="IS LIVE PAYWALL"
            text={`Buy Ticket $${(paywallSettings.price * 1).toFixed(2)}`}
          />
        )}

        {scenario === "new-isPresale-notLive" && (
          <PrimaryCTA
            onClick={() => {
              setTheaterCtaSenario("new-isPresale-notLive");
              setModal("Paywall");
            }}
            summary="IS PRESALE"
            text={`Buy Ticket $${(paywallSettings.price * 1).toFixed(2)}`}
          />
        )}

        {/* if gate is presale and user has not prepaid */}
        {scenario === "new-isPresale-donations-notLive" && (
          <PrimaryCTA
            onClick={() => setModal("new-isPresale-donations-notLive")}
            summary="IS PRESALE DONATION NOT LIVE"
            text={`Buy Ticket $${(paywallSettings.price * 1).toFixed(2)}`}
          />
        )}

        {/* donation only */}
        {scenario === "new-donationOnly-donations-live" && (
          <PrimaryCTA
            onClick={() => {
              setTheaterCtaSenario("new-donationOnly-donations-live");
              setModal("Donation Only");
            }}
            summary="DONATION LIVE"
            text="Watch Film"
          />
        )}

        {scenario === "new-donationOnly-donations-notLive" && (
          <PrimaryCTA
            onClick={() => {
              setTheaterCtaSenario("new-donationOnly-donations-notLive");
              setModal("Donation Only");
            }}
            summary="DONATION NOT LIVE"
            text="Donate"
          />
        )}

        {/* if gate is emailCapture or paid and event hasn't started and user is not subscribed */}
        {type === "getNotified" &&
          guest !== "subscribed" &&
          (paywallSettings.askDonations ? (
            <PrimaryCTA
              onClick={() => setModal("Presale")}
              summary="GET NOTIFIED"
              text="Register"
            />
          ) : (
            <PrimaryCTA
              onClick={() => setModal("Register")}
              summary="GET NOTIFIED"
              text="Register"
            />
          ))}

        {/* if gate is presale and user has prepaid */}
        {type === "isPresale" && (guest === "prepaid" || guest === "paid") && (
          <Text
            color="#D44B19"
            responsive={{ tablet: { padding: "16px" } }}
            margin="0 0 24px"
            center
          >
            Registered for event
          </Text>
        )}

        {trailerUrl && (
          <Button
            className="outline-white theater-btn"
            onClick={() =>
              setActiveVideo({ url: trailerUrl, type: filmTypes.TRAILER })
            }
          >
            Watch Trailer
          </Button>
        )}
      </S.CtaWrapper>
    </>
  );
};
