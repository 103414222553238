import React from "react";
import styled from "styled-components";

const Label = styled.label`
  color: ${({ color }) => color || "white"};
  display: block;
  margin-bottom: 6px;
  font-size: ${({ fontSize }) => fontSize || "14px"};
`;

export default ({ ...props }) => <Label {...props} />;
