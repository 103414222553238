import React from "react";
import { Flex, Text, Image, Button } from "components";
import { useDropzone } from "react-dropzone";
import * as S from "./FileDropzone.styled";

export default ({
  onDrop = () => {},
  allowMultiple,
  fileTypes = [],
  disabled = false,
  loading = false,
  ...props
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <S.FileDropzone
      padding="28px"
      justify="left"
      {...getRootProps({ refKey: "innerRef" })}
      disabled={disabled}
      isDragActive={isDragActive}
      loading={loading ? 1 : 0}
      {...props}
    >
      {loading ? (
        <Text>Loading...</Text>
      ) : (
        <>
          <S.HiddenInput
            {...getInputProps()}
            multiple={allowMultiple}
            accept={fileTypes?.join(", ")}
          />
          <S.Content>
            <Image
              height="85"
              margin="0 32px 0 8px"
              src={require("assets/images/cloud-download.svg").default}
            />
            <Flex direction="column" align="flex-start">
              <S.UploadText
                size="14px"
                margin="0 0 16px 0"
                isDragActive={isDragActive}
              >
                Drag and drop a file here
              </S.UploadText>
              <Button className="outline-white">Choose File...</Button>
            </Flex>
          </S.Content>
        </>
      )}
    </S.FileDropzone>
  );
};
