import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useAnalytics } from "use-analytics";
import {
  BackgroundProvider,
  Button,
  CreditsPurchaseModal,
  Flex,
  Grid,
  Header,
  Image,
  SimplePrompt,
  Text,
} from "components";
import { PAGES, CREDITS } from "api";
import styled from "styled-components";
import { colors } from "consts";
import { useHistory } from "react-router-dom";
import Zendesk from "react-zendesk";

const Relative = styled.div`
  position: relative;
`;

const Tooltip = styled.span`
  visibility: ${({ visibility }) => visibility || "hidden"};
  width: 78px;
  font-size: 12px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  top: ${({ positions }) => positions.top || 0};
  right: ${({ positions }) => positions.right || 0};
  z-index: 2;
`;

const Archive = styled(Image)`
  position: absolute;
  top: 28px;
  right: 28px;
  z-index: 2;
  cursor: pointer;
  background: rgba(196, 196, 196, 0.25);
  border-radius: 52px;
  padding: 3px 3px;
  transition-duration: 0.5s;
  &:hover {
    box-shadow: 1px 1px 8px ${colors.gray300};
  }
`;

const Event = styled.div`
  position: relative;
  cursor: pointer;
  background-image: ${({ background }) =>
    "linear-gradient(180deg, rgba(0, 0, 0, 0) 37.77%, #000000 94.57%), url(" +
      background +
      ")" || null};
  background-size: cover;
  background-color: ${colors.black};
  width: 255px;
  height: 298px;
  border-radius: 3px;
  margin: 15px 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 37px 17px 21px;
  opacity: 0.8;
  transition-duration: 0.5s;
  &:hover {
    opacity: 1;
  }
`;

const Tab = styled.div`
  align-self: flex-start;
  ${({ active }) => active && `border-bottom: ${colors.orange} solid 3px;`}
  color: white;
  padding: 10px 30px;
  cursor: pointer;
  user-select: none;
`;

export default () => {
  const blankEvents = useMemo(
    () => ({
      results: [],
      pages: 0,
      next: "",
    }),
    []
  );

  const [events, setEvents] = useState(blankEvents);
  const [credits, setCredits] = useState(0);
  const [showCreditsPrompt, setShowCreditsPrompt] = useState(false);
  const [showCreditsInfo, setShowCreditsInfo] = useState(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [viewArchived, setViewArchived] = useState(false);
  const [updateEvents, setUpdateEvents] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [loading, setLoading] = useState();
  let history = useHistory();
  const { page } = useAnalytics();

  useEffect(() => {
    page();
  }, [page]);

  // eslint-disable-next-line
  const getPages = useCallback(async (nextPage) => {
    setLoading(true);

    const {
      data: { data, pages, next },
    } = viewArchived
      ? await PAGES.getArchived(nextPage)
      : await PAGES.get(nextPage);

    setEvents({
      results: [...events.results, ...data],
      pages: pages,
      next: next,
    });

    setLoading(false);
  });

  const getCredits = async () => {
    const {
      data: { credits },
    } = await CREDITS.getCredits();
    typeof credits !== "undefined" && setCredits(credits);
  };

  useEffect(() => {
    getPages();
    getCredits();
    // eslint-disable-next-line
  }, [updateEvents]);

  const getNext = () => getPages(events.next);

  const handleButtonHover = (tooltipId) => {
    setTooltipOpen(tooltipId);
  };

  const resetEvents = () => {
    setEvents(blankEvents);
    setUpdateEvents(Date.now());
  };

  const archive = async (event, unarchive) => {
    const _event = { ...event };
    _event.archived = !unarchive;
    await PAGES.update(_event);
    resetEvents();
  };

  const setTab = (archived) => {
    setViewArchived(archived);
    resetEvents();
  };

  const createEvent = () => {
    history.push("/create-event");
  };

  const purchaseCredits = () => {
    setShowCreditsPrompt(false);
    setShowCreditsInfo(false);
    setShowPurchaseModal(true);
  };

  const success = ({ credits }) => {
    setCredits(credits);
    setShowPurchaseModal(false);
  };

  return (
    <BackgroundProvider minHeight="100vh">
      <Zendesk zendeskKey={"2a2352ea-85dc-4661-9d28-bc3ce436ae6c"} />
      <Header user />

      <Flex margin="89px 0 0">
        {showPurchaseModal && (
          <CreditsPurchaseModal
            success={success}
            close={() => setShowPurchaseModal(false)}
          />
        )}

        {showCreditsPrompt && (
          <SimplePrompt close={() => setShowCreditsPrompt(false)}>
            {credits > 0 ? (
              <>
                <Text
                  size="64px"
                  weight="500"
                  margin="0 0 24px"
                  className="canela"
                >
                  Would you like to use 1 credit?
                </Text>
                <Text size="28px" margin="0 0 84px">
                  Clicking continue will create a new event and use one of your
                  credits.
                </Text>
                <Flex>
                  <Button
                    onClick={() => setShowCreditsPrompt(false)}
                    width="50%"
                    padding="20px 0"
                    margin="0 12px 0 0"
                    className="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={createEvent}
                    width="50%"
                    padding="20px 0"
                    margin="0 0 0 12px"
                  >
                    Continue
                  </Button>
                </Flex>
              </>
            ) : (
              <>
                <Text
                  size="64px"
                  weight="500"
                  margin="0 0 24px"
                  className="canela"
                >
                  No Credits Available
                </Text>
                <Text size="28px" margin="0 0 84px">
                  We can’t wait for you to get started creating your virtual
                  event page. Please purchase virtual event credits below to
                  create your next event.
                </Text>
                <Flex>
                  <Button
                    onClick={() => setShowCreditsPrompt(false)}
                    width="50%"
                    padding="20px 0"
                    margin="0 12px 0 0"
                    className="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={purchaseCredits}
                    width="50%"
                    padding="20px 0"
                    margin="0 0 0 12px"
                  >
                    Purchase Credits
                  </Button>
                </Flex>
              </>
            )}
          </SimplePrompt>
        )}

        {showCreditsInfo && (
          <SimplePrompt close={() => setShowCreditsInfo(false)}>
            <Text size="24px" center>
              Credits can be purchased in bundles of three - each credit is good
              for a single virtual event license.
            </Text>
          </SimplePrompt>
        )}

        <Flex
          width="1500px"
          margin="0 auto"
          padding="60px 40px"
          direction="column"
          align="flex-start"
        >
          <Flex margin="24px 0" justify="space-between" align="flex-start">
            <Text size="64px" weight="500" margin="0 0 17px" className="canela">
              Dashboard
            </Text>
          </Flex>

          <Flex margin="24px 0" justify="flex-start">
            <Tab active={!viewArchived} onClick={() => setTab(false)}>
              Active
            </Tab>
            <Tab active={viewArchived} onClick={() => setTab(true)}>
              Archived
            </Tab>
          </Flex>

          <Grid columnWidth="255px">
            {events?.results?.map((_event, idx) => (
              <Relative key={_event.url}>
                <a href={`/event/${_event.id}`}>
                  <Event background={_event.backgroundImage}>
                    <Flex direction="column" align="flex-start">
                      <Text>{_event.title}</Text>
                      <Text size="14px" color="#a4a4a4">
                        {_event.url}
                      </Text>
                    </Flex>
                  </Event>
                </a>
                <Archive
                  src={require("assets/images/icon-archive.svg").default}
                  onMouseOver={() => handleButtonHover(`${idx}-archive`)}
                  onMouseOut={() => handleButtonHover(null)}
                  onClick={() => archive(_event, viewArchived)}
                />
                <Tooltip
                  visibility={
                    tooltipOpen === `${idx}-archive` ? "visible" : "hidden"
                  }
                  positions={{
                    top: "62px",
                    right: "54px",
                  }}
                >
                  {viewArchived ? `Unarchive` : `Archive`}
                </Tooltip>
              </Relative>
            ))}
          </Grid>
          {events.next && (
            <Button onClick={() => getNext()}>
              {loading ? "Loading..." : "Load More"}
            </Button>
          )}
        </Flex>
      </Flex>
    </BackgroundProvider>
  );
};
