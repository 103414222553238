import React from "react";
import ReactDOM from "react-dom";
import { Recoil, Routes } from "providers";
import "./assets/styles/globalStyles.css";
import * as serviceWorker from "./serviceWorker";
import { setToast } from "utils";
import Analytics from "analytics";
import { AnalyticsProvider } from "use-analytics";
import googleAnalytics from "@analytics/google-analytics";

// rQuery
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "./rQuery";
import { initGA } from "utils/analytics";

setToast();
initGA();

// METHODS
const analytics = Analytics({
  app: "show-and-tell-event-portal",
  plugins: [
    googleAnalytics({
      trackingId: "UA-180179487-2",
    }),
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AnalyticsProvider instance={analytics}>
        <Recoil>
          <Routes />
        </Recoil>
      </AnalyticsProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
