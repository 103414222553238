import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Text,
  Flex,
  Input,
  Button,
  ModalProvider,
  Textarea,
  Label,
  Image,
  Outline,
  Checkbox,
  Incrementer,
} from "components";
import { COMMENTS } from "api";
import { toast } from "react-toastify";
import { updateSortOrder } from "utils";
import { colors } from "consts";
import moment from "moment";
import * as S from "./DashboardQA.styled";

export default ({ id, patch, screening, setStep, update }) => {
  const [comments, setComments] = useState([]);
  const [answer, setAnswer] = useState();
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [showEditCommentModal, setShowEditCommentModal] = useState(false);
  const [question, setQuestion] = useState();
  const [editComment, setEditComment] = useState();
  const [showFaq, setShowFaq] = useState(false);
  const [information, setInformation] = useState({});
  const [showLive, setShowLive] = useState(false);
  const [showSaveSort, setShowSaveSort] = useState(false);
  const commentsRef = useRef(comments);
  commentsRef.current = comments;
  const lastCommentRef = useRef();
  lastCommentRef.current = commentsRef?.current?.[comments.length - 1]?.id;

  useEffect(() => {
    const getComments = async () => {
      const _comments = await COMMENTS.getAll(screening.id);
      setComments(_comments);
    };

    if (screening) {
      setInformation({ ...screening, id: id, ...information });
      screening.interactiveQa && setShowFaq(true);
      screening.id && getComments();
      screening.comments && setComments(screening.comments);
      screening.aboutSettings.enableLive && setShowLive(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      comments.length > 0 &&
      comments.every((_comment) => _comment.order || _comment.order === 0)
    ) {
      // All faqs have an order attribute.
      const sortedComments = [...comments].sort((a, b) => a.order - b.order);
      const reSortedComments = sortedComments.map((_comment, idx) => ({
        ..._comment,
        order: idx,
      }));
      setComments(reSortedComments);
    } else if (
      comments.length > 0 &&
      comments.some((_comment) => _comment.order || _comment.order === 0)
    ) {
      // Only some faqs have an order attribute.
      const commentsWithoutOrder = comments.filter(
        (_comment) => _comment.order !== 0 && !_comment.order
      );
      const commentsWithOrder = comments.filter(
        (_comment) => _comment.order || _comment.order === 0
      );
      let sortedCommentsWithOrder = commentsWithOrder.sort(
        (a, b) => a.order - b.order
      );
      for (let _comment in commentsWithoutOrder) {
        const updatedComment = {
          ..._comment,
          order:
            sortedCommentsWithOrder[sortedCommentsWithOrder.length - 1].order +
            1,
        };
        sortedCommentsWithOrder.push(updatedComment);
      }
      setComments(sortedCommentsWithOrder);
    } else {
      // None of the faqs have an order attribute.
      if (comments.length > 0) {
        const commentsWithOrder = comments.map((_comment, idx) => ({
          ..._comment,
          order: idx,
        }));
        commentsWithOrder.sort((a, b) => a.order - b.order);
        setComments(commentsWithOrder);
      }
    }
    // eslint-disable-next-line
  }, [lastCommentRef.current]);

  const toggleFaqs = () => {
    information.interactiveQa = !showFaq;
    update(information);
    setInformation(information);
    setShowFaq(!showFaq);
  };

  const toggleLive = () => {
    information.aboutSettings.enableLive = !showLive;
    update(information);
    setInformation(information);
    setShowLive(!showLive);
  };

  const submitAnswer = async () => {
    if (editComment.id) {
      try {
        await COMMENTS.submit(screening.id, {
          text: answer,
          parentId: editComment.id,
        });
        const _comments = await COMMENTS.getAll(screening.id);
        screening.comments = _comments;
        update(screening);
        setComments(_comments);
        setAnswer(undefined);
        toast("Answer submitted successfully", { autoClose: 4000 });
      } catch (err) {
        toast("Error submitted answer", { autoClose: 4000 });
      }
      setShowEditCommentModal(false);
    } else {
      const _comments = comments.filter((_comment) => {
        if (_comment.text === editComment.text) {
          const _updated = _comment;
          _updated.children[0].text = answer;
          return _updated;
        } else {
          return _comment;
        }
      });
      setComments(_comments);
      screening.comments = _comments;
      update(screening);
      toast.success("FAQ updated successfully", { autoClose: 3000 });
      setShowEditCommentModal(false);
    }
  };

  const createFaq = useCallback(async () => {
    if (!question) {
      toast.error("please enter a question", { autoClose: 3000 });
    } else if (!answer) {
      toast.error("please enter an answer", { autoClose: 3000 });
    } else if (screening.id) {
      const _comment = await COMMENTS.submit(screening.id, {
        text: question,
        order: comments.length,
      });
      if (answer) {
        await COMMENTS.submit(screening.id, {
          text: answer,
          parentId: _comment.data.data.id,
        });
      }
      const _comments = await COMMENTS.getAll(screening.id);
      setComments(_comments);
      toast.success("FAQ added successfully", { autoClose: 3000 });
      setShowQuestionModal(false);
    } else {
      const _comment = {
        text: question,
        order: comments.length,
        children: [
          {
            text: answer,
          },
        ],
      };
      const _comments = [...comments, _comment];
      setComments(_comments);
      screening.comments = _comments;
      update(screening);
      toast.success("Faq added successfully", { autoClose: 3000 });
      setShowQuestionModal(false);
    }
  }, [screening, update, question, answer, comments]);

  const deleteComment = async (comment) => {
    if (comment.id) {
      try {
        await COMMENTS.remove(screening.id, comment.id);
        const _comments = await COMMENTS.getAll(screening.id);
        setComments(_comments);
        toast.success("comment deleted successfully", { autoClose: 3000 });
      } catch (err) {
        console.error(err);
        toast.error("error deleting comment", { autoClose: 3000 });
      }
    } else {
      const _comments = comments.filter(
        (_comment) => _comment.text !== comment.text
      );
      setComments(_comments);
      screening.comments = _comments;
      update(screening);
    }
  };

  const updateAllCommentOrders = async () => {
    if (screening && screening.id) {
      const commentsToUpdate = [];
      commentsRef?.current?.forEach((_comment, idx) => {
        if (_comment.id) {
          commentsToUpdate.push({
            commentId: _comment.id,
            sortOrder: _comment.order,
          });
        }
      });
      const updatedComments = await COMMENTS.updateCommentSortOrder(
        screening.id,
        { comments: commentsToUpdate }
      );
      const filteredReturn = updatedComments.data.data.filter(
        (_comment) => _comment?.id
      );
      const sortedComments = filteredReturn.sort((a, b) => a.order - b.order);
      setComments(sortedComments);
      toast.success("FAQs updated successfully", { autoClose: 3000 });
      setShowSaveSort(false);
    } else {
      toast.error("Unable to update FAQs", { autoClose: 3000 });
    }
  };

  const updateSettings = (e) => {
    const updated = { ...information };
    updated.aboutSettings[e.target.name] = e.target.value;
    setInformation(updated);
  };

  return (
    <Flex
      direction="column"
      align="flex-start"
      padding="60px 125px"
      width="1440px"
    >
      <Flex justify="space-between" align="flex-start">
        <Flex direction="column" align="flex-start">
          <Text size="38px" weight="500" margin="0 0 17px">
            FAQ
          </Text>
          <Text size="16px" margin="0 0 16px">
            Interact with your audience in two ways: 1. frequently asked
            questions + answers, 2. link to a live FAQ, panel discussion, live
            performance, etc.
          </Text>
          <Text size="16px" margin="0 0 16px">
            Check the boxes below to enable FAQs for your screening and add the
            link + details of your Live FAQ event.
          </Text>
          <Text size="16px" margin="0 0 16px">
            To add FAQs click the <b>Add FAQ</b> button.
          </Text>
          <Flex margin="0">
            <Checkbox checked={showFaq} onClick={() => toggleFaqs()} />
            <Text margin="0" size="14px" color={colors.gray8}>
              Enable FAQs on this event
            </Text>
          </Flex>
          <Flex margin="16px 0">
            <Checkbox checked={showLive} onClick={() => toggleLive()} />
            <Text margin="0" size="14px" color={colors.gray8}>
              Enable Live FAQ on this event
            </Text>
          </Flex>
        </Flex>

        <Flex
          direction="column"
          align="flex-end"
          justify="space-between"
          height="180px"
          padding="24px 0"
        >
          <Flex justify="flex-end">
            <Button
              className={"secondary"}
              margin="0 24px 0 0"
              onClick={() => {
                update(information);
                setStep("activation");
                patch(screening);
              }}
            >
              Save
            </Button>
            <Button onClick={() => setShowQuestionModal(true)}>Add FAQ</Button>
          </Flex>
          {showSaveSort && (
            <Flex justify="flex-end">
              <Button onClick={() => updateAllCommentOrders()}>
                Save Sort Order
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>

      {comments.length > 0 && (
        <Outline margin="26px 0 0">
          <Flex direction="column" align="flex-start" padding="16px">
            <Text size="24px" margin="0 0 24px">
              FAQs
            </Text>
            <Text size="14px" margin="0 0 24px" color={colors.gray8}>
              Once all FAQs have been added use the <b>Sort Order Boxes</b> next
              to each item to number them in the order you would like them to
              appear in your FAQ section.
            </Text>

            <Text size="14px" margin="0 0 12px" color={colors.gray8}>
              To save the order of your FAQs please click on the{" "}
              <b>Save Sort Order</b> button.
            </Text>
          </Flex>
          <Flex>
            <Flex padding="16px" width="60%">
              <Text weight="bold" size="14px">
                Questions
              </Text>
            </Flex>

            <Flex padding="16px" width="15%">
              <Text weight="bold" size="14px">
                Date Submitted
              </Text>
            </Flex>

            <Flex padding="16px" width="15%" justify="center">
              <Text weight="bold" size="14px">
                Sort Order
              </Text>
            </Flex>

            <Flex padding="16px" width="15%" justify="center">
              <Text weight="bold" size="14px">
                Actions
              </Text>
            </Flex>
          </Flex>

          {comments.map((comment) => (
            <S.Comment key={comment.id}>
              <Flex
                padding="16px"
                width="60%"
                onClick={() => {
                  setEditComment(comment);
                  setShowEditCommentModal(true);
                }}
              >
                <Text>{comment.text}</Text>
              </Flex>

              <Flex
                padding="16px"
                width="15%"
                onClick={() => {
                  setEditComment(comment);
                  setShowEditCommentModal(true);
                }}
              >
                <Text>
                  {comment.createdAt
                    ? moment(comment.createdAt).format("MM/DD/yyyy")
                    : moment(new Date()).format("MM/DD/yyyy")}
                </Text>
              </Flex>

              <Flex padding="16px" width="15%" justify="center">
                <Incrementer
                  width="50px"
                  margin="0 25px"
                  validateAndSort={(newOrder) => {
                    const newProductArray = updateSortOrder(
                      comment.order,
                      newOrder,
                      comments
                    );
                    setComments(newProductArray);
                    setShowSaveSort(true);
                  }}
                  items={comments}
                  itemOrder={comment.order}
                  max={comments.length}
                />
              </Flex>

              <S.Delete padding="16px" justify="center" width="15%">
                <Image
                  onClick={() => {
                    setShowSaveSort(false);
                    deleteComment(comment);
                    updateAllCommentOrders();
                  }}
                  width="74px"
                  src={require("assets/images/icon-delete.svg").default}
                />
              </S.Delete>
            </S.Comment>
          ))}
        </Outline>
      )}

      {showLive && (
        <Outline margin="26px 0">
          <Flex direction="column" align="flex-start" margin="26px 0 0">
            <Text size="24px" margin="0 0 24px">
              Live Talk Details
            </Text>

            <Text size="14px" margin="0 0 24px" color={colors.gray8}>
              Details for your live FAQ, panel discussion, live performance,
              etc.
            </Text>

            <Text size="14px" margin="0 0 24px" color={colors.gray8}>
              You can host the live FAQ on whatever platform you’d like - Zoom,
              Google Hangouts, Facebook Live, BlueJeans, etc. Simply add the URL
              to the 'Event Url' section.
            </Text>

            <Text size="14px" margin="0 0 24px" color={colors.gray8}>
              Note: This doesn’t even need to be a live event. You can link out
              to a recorded FAQ.
            </Text>

            <Label>Event Title</Label>
            <Input
              value={information.aboutSettings.liveTitle}
              onChange={updateSettings}
              name="liveTitle"
              margin="0 0 26px"
              placeholder="Live FAQ with the Film Team"
            />

            <Label>Live Talk Date</Label>
            <Input
              value={information.aboutSettings.liveDate}
              onChange={updateSettings}
              name="liveDate"
              margin="0 0 26px"
              placeholder="MARCH 23RD, 2020 AT 5PM PST"
            />

            <Label>Live Talk Details</Label>
            <Input
              value={information.aboutSettings.liveDescription}
              onChange={updateSettings}
              name="liveDescription"
              margin="0 0 26px"
              placeholder=""
            />

            <Label>Event URL</Label>
            <Input
              value={information.aboutSettings.liveUrl}
              onChange={updateSettings}
              name="liveUrl"
              margin="0 0 26px"
              placeholder="https://zoom.us/j/12345678"
            />

            <Label>Customize Button Text</Label>
            <Text size="14px" margin="0 0 12px" color={colors.gray8}>
              The text on the button that directs viewers to the URL above.
            </Text>
            <Input
              value={information.aboutSettings.liveCta}
              onChange={updateSettings}
              name="liveCta"
              margin="0 0 26px"
              placeholder="Join the Live Talk"
            />
          </Flex>
        </Outline>
      )}

      <ModalProvider
        width="750px"
        margin="110px"
        show={showQuestionModal}
        close={() => setShowQuestionModal(false)}
      >
        <Flex direction="column" align="flex-start" padding="40px">
          <Text className="canela" size="64px" margin="0 0 40px">
            Add FAQ
          </Text>

          <Label>Type your question here</Label>
          <Textarea
            maxChar={150}
            rows="6"
            margin="0 0 20px"
            onChange={(e) => setQuestion(e.target.value)}
          />

          <Label>Type your answer here</Label>
          <Textarea
            maxChar={500}
            rows="6"
            margin="0 0 80px"
            onChange={(e) => setAnswer(e.target.value)}
          />

          <Flex>
            <Button
              width="100%"
              margin="0 15px 0 0"
              className="outline-white large"
              onClick={() => setShowQuestionModal(false)}
            >
              Back
            </Button>
            <Button
              width="100%"
              margin="0 0 0 15px"
              className="large"
              onClick={() => createFaq()}
            >
              Create
            </Button>
          </Flex>
        </Flex>
      </ModalProvider>

      <ModalProvider
        width="750px"
        margin="110px"
        show={showEditCommentModal}
        close={() => setShowEditCommentModal(false)}
      >
        <Flex direction="column" align="flex-start" padding="40px">
          <Text className="canela" size="64px" margin="0 0 40px">
            Question Details
          </Text>

          {editComment && (
            <>
              <Text size="28px" margin="0 0 35px">
                {editComment.text}
              </Text>

              <Label>Type your answer here</Label>
              <Textarea
                maxChar={500}
                value={
                  editComment.children &&
                  editComment.children.length > 0 &&
                  editComment.children[0].text
                }
                rows="6"
                margin="0 0 80px"
                onChange={(e) => setAnswer(e.target.value)}
              />
            </>
          )}

          <Flex>
            <Button
              width="100%"
              margin="0 15px 0 0"
              className="outline-white large"
              onClick={() => setShowEditCommentModal(false)}
            >
              Back
            </Button>
            <Button
              width="100%"
              margin="0 0 0 15px"
              className="large"
              onClick={() => submitAnswer()}
            >
              Post Answer
            </Button>
          </Flex>
        </Flex>
      </ModalProvider>
    </Flex>
  );
};
