import { useState } from "react";
import {
  ChooseEventType,
  DuplicateEvent,
  DuplicateEventUrl,
  WatchVideo,
  CreditsCheckout,
  OutOfCredits,
  PurchaseCredits,
} from "./";
import { SimplePrompt } from "components";

export default (props) => {
  const { Step, setStep, close } = useCreateEventModal(props);
  const { credits, setCredits } = props;

  return (
    <SimplePrompt close={close}>
      <Step {...{ setStep, credits, setCredits, close }} />
    </SimplePrompt>
  );
};

const useCreateEventModal = ({ setShowCreateEventModal }) => {
  // local state
  const [step, setStep] = useState("watch-video");

  // determines which component to render
  const steps = {
    "watch-video": WatchVideo,
    "choose-event-type": ChooseEventType,
    "duplicate-event": DuplicateEvent,
    "duplicate-event-url": DuplicateEventUrl,
    "out-of-credits": OutOfCredits,
    "purchase-credits": PurchaseCredits,
    "credits-checkout": CreditsCheckout,
  };

  // default to ChooseEventType
  const Step = steps?.[step] || ChooseEventType;

  // close modal
  const close = () => {
    setShowCreateEventModal(false);
    setStep("choose-event-type");
  };

  // return the state
  return { Step, setStep, close };
};
