import React, { useState, useEffect } from "react";
import { Text, FileDropzone } from "components";
import { AboutPhotoGrid } from "./";
import { colors } from "consts";
import { FILES } from "api";

export default ({ information, setUploaded }) => {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    information.photos && setImages(information.photos);
  }, [information]);

  const uploadImages = async (files) => {
    try {
      setLoading(true);
      const _uploaded = await FILES.createMultiple(files);
      const _images = [...images, ..._uploaded.data.data];
      setImages(_images);
      setUploaded(_images);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Text size="16px" margin="0 0 20px">
        Add Photo
      </Text>
      <Text size="14px" margin="0 0 20px" color={colors.gray8}>
        Input images including stills from your film, festival laurels, and
        images from your partner.
      </Text>
      {images.length > 0 && (
        <AboutPhotoGrid
          images={images}
          setImages={setImages}
          setUploaded={setUploaded}
        />
      )}
      <FileDropzone
        onDrop={(files) => uploadImages(files)}
        fileTypes={[".png", ".jpeg", ".jpg", ".gif"]}
        loading={loading}
        allowMultiple
      />
    </>
  );
};
