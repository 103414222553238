import styled from "styled-components";
import { colors } from "consts";

export const Greenlight = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${({ active }) => colors[active ? "green" : "orange"]};
`;
