import React, { useState, useEffect } from "react";
import { Flex, Text, Input, Button, Image } from "components";
import { colors } from "consts";
import * as S from "./EditTagsForm.styled";
import { TAGS } from "api";

/**
 * Sources:
 * Inputs:
 *    resource: STRING
 *        - identifies a logic grouping for db calls
 *
 *    bulkEdits: ARRAY
 *        - array of currently selected rows. these are updated when selectedFlatRows from react-table is updated
 *
 *    tags?: ARRAY
 *        - current tags for individual row
 *        - this will be undefined (leading the tagsData state []) when this form is used for bulk update
 *
 *    original?: OBJECT
 *        - individual row data
 *          - if it is present then the component was called from an individual row instance
 *          - we are also using this value to determine if we are performing a bulk action or not
 */

export default ({ tags, resource, original, bulkEdits, gTags }) => {
  // state
  const [globalTags, setGlobalTags] = useState([]);
  const [bulkQuery, setBulkQuery] = useState(false);
  const [filteredGlobalTags, setFilteredGlobalTags] = useState(null);
  const [globalTagsMessage, setGlobalTagsMessage] = useState(null);
  const [tagData, setTagData] = useState([]);
  const [newTag, setNewTag] = useState("");

  // helpers
  const adminRouteMap = {
    leads: "leadowner",
    customers: "account",
    account: "account",
    events: "page",
    event: "page",
  };

  // methods
  const handleChange = ({ target }) => {
    const inputString = target.value.replace(/\s+/g, "-").toLowerCase();
    setNewTag(inputString);
  };

  const filterGlobalTags = (globalTagsData, tagsData) => {
    const _globaltags = globalTagsData.filter(
      (tag) => tag.type === adminRouteMap[resource]
    );

    const result = _globaltags
      .filter((globalTag) => globalTag.type === adminRouteMap[resource])
      .filter((globalTag) => {
        return !tagsData.some((f) => {
          return f.id === globalTag.id;
        });
      });
    return result;
  };

  const deleteTag = async (tag) => {
    const functionMap = {
      bulk: {
        events: async () => {
          return await TAGS.bulkUnlinkTag(
            Object.values(bulkEdits).map((_) => _.id),
            "events",
            tag
          );
        },
        event: async () => {
          return await TAGS.bulkUnlinkTag(
            Object.values(bulkEdits).map((_) => _.id),
            "events",
            tag
          );
        },
        leads: async () => {
          return await TAGS.bulkUnlinkTag(
            Object.values(bulkEdits).map((_) => _.id),
            "leads",
            tag
          );
        },
        // we do not have a consistant naming convention: accounts vs customers
        // this form is used from many different perspectives so
        // the below functions are duplicated for speed sake
        customers: async () => {
          return await TAGS.bulkUnlinkTag(
            Object.values(bulkEdits).map((_) => _.id),
            "customers",
            tag
          );
        },
        account: async () => {
          return await TAGS.bulkUnlinkTag(
            Object.values(bulkEdits).map((_) => _.id),
            "customers",
            tag
          );
        },
      },
      individual: {
        events: async () => await TAGS.removePageTag(original?.id, tag.id),
        event: async () => await TAGS.removePageTag(original?.id, tag.id),
        leads: async () => await TAGS.removeLeadownerTag(original?.id, tag.id),
        // we do not have a consistant naming convention: accounts vs customers
        // this form is used from many different perspectives so
        // the below functions are duplicated for speed sake
        customers: async () =>
          await TAGS.removeAccountTag(original?.id, tag.id),
        account: async () => await TAGS.removeAccountTag(original?.id, tag.id),
      },
    };

    try {
      if (bulkQuery) {
        await functionMap.bulk[resource]();
      } else {
        await functionMap.individual[resource]();
      }

      const updated_tagData = [...tagData].filter((_tag) => _tag.id !== tag.id);

      const filteredGlobalTags = filterGlobalTags(globalTags, updated_tagData);
      setTagData(updated_tagData);

      if (filteredGlobalTags.length === 0) {
        setGlobalTagsMessage("No Unused Global Tags");
        setFilteredGlobalTags([]);
      } else {
        setFilteredGlobalTags(filteredGlobalTags);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const submitTag = async (tag, global) => {
    const functionMap = {
      bulk: {
        events: async () => {
          return await TAGS.bulkUpsertTags(
            Object.values(bulkEdits).map((_) => _.id),
            "events",
            global,
            tag
          );
        },
        event: async () => {
          return await TAGS.bulkUpsertTags(
            Object.values(bulkEdits).map((_) => _.id),
            "events",
            global,
            tag
          );
        },
        leads: async () => {
          return await TAGS.bulkUpsertTags(
            Object.values(bulkEdits).map((_) => _.id),
            "leads",
            global,
            tag
          );
        },
        // we do not have a consistant naming convention: accounts vs customers
        // this form is used from many different perspectives so
        // the below functions are duplicated for speed sake
        customers: async () => {
          return await TAGS.bulkUpsertTags(
            Object.values(bulkEdits).map((_) => _.id),
            "customers",
            global,
            tag
          );
        },
        account: async () => {
          return await TAGS.bulkUpsertTags(
            Object.values(bulkEdits).map((_) => _.id),
            "customers",
            global,
            tag
          );
        },
      },
      individual: {
        events: async () => await TAGS.postPageTag(original?.id, tag, global),
        event: async () => await TAGS.postPageTag(original?.id, tag, global),
        leads: async () =>
          await TAGS.postLeadownerTag(original?.id, tag, global),
        // we do not have a consistant naming convention: accounts vs customers
        // this form is used from many different perspectives so
        // the below functions are duplicated for speed sake
        customers: async () =>
          await TAGS.postAccountTag(original?.id, tag, global),
        account: async () =>
          await TAGS.postAccountTag(original?.id, tag, global),
      },
    };

    try {
      let new_tag;
      if (bulkQuery) {
        const {
          data: { data },
        } = await functionMap.bulk[resource]();
        new_tag = data;
      } else {
        const {
          data: { data },
        } = await functionMap.individual[resource]();
        new_tag = data;
      }

      const updated_tagData = [...tagData, new_tag];

      const updated_globalTags = global ? [...globalTags, new_tag] : globalTags;
      setGlobalTags(updated_globalTags);

      const filteredGlobalTags = filterGlobalTags(
        updated_globalTags,
        updated_tagData
      );
      setTagData(updated_tagData);

      if (filteredGlobalTags.length === 0) {
        setGlobalTagsMessage("No Unused Global Tags");
        setFilteredGlobalTags([]);
      } else {
        setFilteredGlobalTags(filteredGlobalTags);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const linkTag = async (tag, global) => {
    const functionMap = {
      bulk: {
        events: async () => {
          return await TAGS.bulkLinkTags(
            Object.values(bulkEdits).map((_) => _.id),
            "page",
            tag
          );
        },
        event: async () => {
          return await TAGS.bulkLinkTags(
            Object.values(bulkEdits).map((_) => _.id),
            "page",
            tag
          );
        },
        leads: async () => {
          return await TAGS.bulkLinkTags(
            Object.values(bulkEdits).map((_) => _.id),
            "leads",
            tag
          );
        },
        // we do not have a consistant naming convention: accounts vs customers
        // this form is used from many different perspectives so
        // the below functions are duplicated for speed sake
        customers: async () => {
          return await TAGS.bulkLinkTags(
            Object.values(bulkEdits).map((_) => _.id),
            "customers",
            tag
          );
        },
        account: async () => {
          return await TAGS.bulkLinkTags(
            Object.values(bulkEdits).map((_) => _.id),
            "customers",
            tag
          );
        },
      },
      individual: {
        events: async () => await TAGS.linkTagToPage(original?.id, tag.id),
        event: async () => await TAGS.linkTagToPage(original?.id, tag.id),
        leads: async () => await TAGS.linkTagToLeadowner(original?.id, tag.id),
        // we do not have a consistant naming convention: accounts vs customers
        // this form is used from many different perspectives so
        // the below functions are duplicated for speed sake
        customers: async () =>
          await TAGS.linkTagToAccount(original?.id, tag.id),
        account: async () => await TAGS.linkTagToAccount(original?.id, tag.id),
      },
    };

    try {
      let new_tag;
      if (bulkQuery) {
        const {
          data: { data },
        } = await functionMap.bulk[resource]();
        new_tag = data;
      } else {
        const {
          data: { data },
        } = await functionMap.individual[resource]();
        new_tag = data;
      }

      const updated_tagData = [...tagData, new_tag];

      const filteredGlobalTags = filterGlobalTags(globalTags, updated_tagData);
      setTagData(updated_tagData);

      if (filteredGlobalTags.length === 0) {
        setGlobalTagsMessage("No Unused Global Tags");
        setFilteredGlobalTags([]);
      } else {
        setFilteredGlobalTags(filteredGlobalTags);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // useEffect
  useEffect(() => {
    setGlobalTags(gTags);
  }, [gTags]);

  useEffect(() => {
    if (!globalTags || !tagData) return;
    const filteredGlobalTags = filterGlobalTags(globalTags, tagData);
    filteredGlobalTags.length === 0
      ? setGlobalTagsMessage("No Global Tags")
      : filteredGlobalTags.length === 0
      ? setGlobalTagsMessage("No Unused Global Tags")
      : setFilteredGlobalTags(filteredGlobalTags);
    // eslint-disable-next-line
  }, [globalTags, tagData]);

  useEffect(() => {
    !original ? setBulkQuery(true) : setBulkQuery(false);
  }, [original]);

  useEffect(() => {
    if (tags) setTagData(tags);
  }, [tags]);

  return (
    <Flex direction="column" align="flex-start">
      <Text size="14px" align="start" margin="0 0 8px" color={colors.gray750}>
        Current Tags:
      </Text>
      <S.TagWrapper>
        {tagData?.[0] ? (
          tagData.map((tag, idx) => (
            <S.Tag key={`tag-${idx}`}>
              <Text size="14px" color={colors.white} margin="0 8px">
                {tag.name}
              </Text>
              <Image
                cursor="pointer"
                width="16px"
                src={require("assets/images/icon-close.svg").default}
                onClick={() => deleteTag(tag)}
              />
            </S.Tag>
          ))
        ) : (
          <Text size="14px" color={colors.gray750}>
            No Tags
          </Text>
        )}
      </S.TagWrapper>
      <Text size="14px" align="start" margin="0 0 8px" color={colors.gray750}>
        Available Global Tags:
      </Text>
      <S.TagWrapper>
        {filteredGlobalTags?.[0] ? (
          filteredGlobalTags.map((_tag, idx) => (
            <S.Tag key={`tag-${idx}`}>
              <Text size="14px" color={colors.white} margin="0 8px">
                {_tag.name}
              </Text>
              <Image
                cursor="pointer"
                width="16px"
                src={require("assets/images/icon-check.svg").default}
                onClick={() => linkTag(_tag)}
              />
            </S.Tag>
          ))
        ) : (
          <Text size="14px">{globalTagsMessage}</Text>
        )}
      </S.TagWrapper>
      <Text size="14px" align="start" margin="0 0 8px" color={colors.gray750}>
        Create new tag:
      </Text>
      <Flex direction="column" width="100%">
        <Input type="text" onChange={(e) => handleChange(e)} value={newTag} />
        <Flex justify="space-between" margin="12px 0 0 0">
          <Button
            width="47%"
            className={`${!newTag && "disabled"}`}
            onClick={() => submitTag(newTag, false)}
          >
            {`Submit Tag`}
          </Button>
          <Button
            width="47%"
            className={`${!newTag && "disabled"}`}
            onClick={() => submitTag(newTag, true)}
          >
            {`Submit Global Tag`}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
