import styled from "styled-components";
import { colors } from "consts";

export const CreditPrompt = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 11;
`;

export const Box = styled.div`
  background: ${colors.gray200};
  padding: 54px;
  position: relative;
  z-index: 12;
  max-width: 750px;
`;

export const ExitIcon = styled.div`
  height: 28px;
  width: 28px;
  border: 2px solid white;
  border-radius: 50%;
  line-height: 1;
  color: white;
  font-weight: 600;
  position: absolute;
  right: 24px;
  top: 24px;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
`;
