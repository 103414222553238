import { useState } from "react";

export default () => {
  const existingTokens = JSON.parse(localStorage.getItem("user"));
  const [user, setUser] = useState(existingTokens);

  const setTokens = (data) => {
    // Sets the user data for the context API and localStorage.
    // Must store in localStorage to prevent context being reset on page navigation
    localStorage.setItem("user", JSON.stringify(data));
    setUser(data);
  };

  return [user, setTokens];
};
