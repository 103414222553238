import React, { useState, useEffect, useRef } from "react";
import { Button, Flex } from "components";
import { FileDrop } from "react-file-drop";
import { FILES } from "api";
import * as S from "./FileFullWidth.styled";

export default ({ setUploaded, image, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [imageId, setImageId] = useState();
  const inputRef = useRef();

  const change = async (e) => {
    try {
      setLoading(true);
      const _uploaded = await FILES.create(
        e?.[0]?.name ? e : inputRef.current.files
      );
      setUploaded(_uploaded.data.data[0].url);
      setImageFile(_uploaded.data.data[0].url);
      setImageId(_uploaded.data.data[0].id);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteFile = async () => {
    try {
      if (imageId) {
        await FILES.deleteFile(imageId);
        setImageFile(null);
        setImageId(null);
        setUploaded(undefined);
      } else {
        setImageFile(null);
        setUploaded("");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const clickFile = () => inputRef.current.click();

  useEffect(() => {
    image && setImageFile(image);
    window.addEventListener("dragover", (e) => e.preventDefault(), false);
    window.addEventListener("drop", (e) => e.preventDefault(), false);
  }, [image]);

  return (
    <>
      {loading ? (
        <Flex
          width="100%"
          margin="16px 0"
          direction="column"
          align="flex-start"
        >
          <img
            style={{ opacity: 0.5 }}
            alt="drag-and-drop"
            src={require("assets/images/drag-drop-full.svg").default}
          />
          <Button margin="16px 0 0" className={"outline-white"}>
            Uploading...
          </Button>
        </Flex>
      ) : (
        <>
          {imageFile ? (
            <S.Thumbnail url={imageFile}>
              <S.Delete onClick={() => deleteFile()}>
                <img
                  alt="close"
                  src={require("assets/images/icon-x.svg").default}
                />
              </S.Delete>
            </S.Thumbnail>
          ) : (
            <S.Wrap>
              <FileDrop onDrop={change}>
                <Flex
                  pointer
                  width="100%"
                  margin="16px 0"
                  direction="column"
                  align="flex-start"
                  onClick={() => clickFile()}
                >
                  <img
                    alt="drag-and-drop"
                    src={require("assets/images/drag-drop-full.svg").default}
                  />
                  <S.HiddenInput
                    type="file"
                    accept="image/jpg, image/png, image/jpeg"
                    ref={inputRef}
                    {...props}
                    onChange={change}
                  />
                </Flex>
              </FileDrop>
            </S.Wrap>
          )}
        </>
      )}
    </>
  );
};
