import { Flex, Text, Button } from "components";
import { domain } from "utils";
import * as S from "./AdminDrawers.styled";
import { format } from "date-fns";

export default ({ values: { original } }) => {
  return (
    <S.Drawer>
      <Flex width="100%" wrap>
        <S.DrawerItem width="50%">
          <Text size="14px" className="bold" margin="0 0 8px">
            Description
          </Text>
          <Text size="14px">{original.description || "n/a"}</Text>
        </S.DrawerItem>
        <S.DrawerItem width="50%">
          <Text size="14px" className="bold" margin="0 0 8px">
            Date Created
          </Text>
          <Text size="14px">
            {format(new Date(original.createdAt), "PP, pp")}
          </Text>
        </S.DrawerItem>
        <S.DrawerItem width="50%">
          <Text size="14px" className="bold" margin="0 0 8px">
            Is Email Capture
          </Text>
          <Text size="14px">{original.emailCapture ? "Yes" : "No"}</Text>
        </S.DrawerItem>
        <S.DrawerItem width="50%">
          <Text size="14px" className="bold" margin="0 0 8px">
            Film Team Email
          </Text>
          <Text size="14px">{original.filmTeamEmail || "n/a"}</Text>
        </S.DrawerItem>
        <S.DrawerItem width="50%">
          <Text size="14px" className="bold" margin="0 0 8px">
            Page ID
          </Text>
          <Text size="14px">{original.id}</Text>
        </S.DrawerItem>
        <S.DrawerItem width="50%">
          <Text size="14px" className="bold" margin="0 0 8px">
            Account Email
          </Text>
          <Text size="14px">{original.accountEmail || "n/a"}</Text>
        </S.DrawerItem>
        <S.DrawerItem width="50%">
          <Text size="14px" className="bold" margin="0 0 8px">
            Account Last Name
          </Text>
          <Text size="14px">{original.accountLastName || "n/a"}</Text>
        </S.DrawerItem>
        <S.DrawerItem width="50%">
          <Text size="14px" className="bold" margin="0 0 8px">
            Account ID
          </Text>
          <Text size="14px">{original.accountId || "n/a"}</Text>
        </S.DrawerItem>
        <S.DrawerItem width="50%">
          <Text size="14px" className="bold" margin="0 0 8px">
            Stripe Account
          </Text>
          <Text size="14px">
            {original.connectId ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://dashboard.stripe.com/${original.connectId}/payments`}
              >
                Open in dashboard
              </a>
            ) : (
              "n/a"
            )}
          </Text>
        </S.DrawerItem>
        <S.DrawerItem width="50%">
          <Text size="14px" className="bold" margin="0 0 8px">
            Last Updated
          </Text>
          <Text size="14px">
            {format(new Date(original.updatedAt), "PP, pp")}
          </Text>
        </S.DrawerItem>
        <S.DrawerItem width="50%">
          <a href={`/admin/event-details/${original.id}`}>
            <Button primary>View full details</Button>
          </a>
        </S.DrawerItem>
        <S.DrawerItem width="50%">
          <a
            href={`${domain}/watch/${original.url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button primary>Event Preview</Button>
          </a>
        </S.DrawerItem>
      </Flex>
    </S.Drawer>
  );
};
