import React from "react";
import styled from "styled-components";

const Box = styled.div`
  background: ${({ light }) => (light ? "white" : "rgba(86, 86, 86, 0.2)")};
  padding: 36px 58px;
  width: 100%;
  max-width: ${({ width }) => width || "auto"};
  margin: ${({ margin }) => margin || "0"};
`;

export default ({ ...props }) => <Box {...props} />;
