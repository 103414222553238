import { Flex, Input } from "components";
import styled from "styled-components";
import { colors } from "consts";
import { minMedia } from "utils/media";

export const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: auto;
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 12;
  background: ${({ light }) =>
    light ? `rgba(255, 255, 255, 0.6)` : `rgba(0, 0, 0, 0.8)`};
`;

export const Container = styled(Flex)`
  background: ${colors.bgGray};
  border-radius: 8px;
  max-width: 800px;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  position: absolute;
  top: 200px;
  left: 30%;
  z-index: 9999;
`;

export const Option = styled.div`
  border: 1px solid #b4b4b4;
  border-radius: 0;
  background: transparent;
  color: black;
  min-width: 100%;
  height: 48px;
  width: ${({ width }) => width || "64px"};
  margin: ${({ margin }) => margin || "4px 0 4px 0"};
  padding: ${({ padding }) => padding || "0"};
  justify-content: center;
  user-select: none;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0;
  cursor: pointer;
  ${({ selected }) =>
    selected &&
    `
    background: ${colors.orange};
    color: white;
  `}
  ${minMedia.laptopPlus`
    height: 32px;
    font-size: 18px;
    min-width: 50px;
    margin: 0 12px 0 0;
  `}
`;

export const OptionInput = styled(Input)`
  background: transparent;
  border: none;
  width: 100%;
  height: 100%;
  font-family: "Inter", sans-serif;
  border: none;
  color: black;
  font-size: 18px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus {
    box-shadow: none;
    outline: none;
    border: none;
  }
  ${({ selected }) =>
    selected &&
    `
    color: white;
  `}
`;
