import React from "react";
import { useParams } from "react-router-dom";
import { BackgroundProvider, AdminLayout, Tags, Flex, Text } from "components";
import {
  CustomerCredits,
  CustomerDetailsList,
  CustomerEvents,
  CustomerOrders,
  CustomerNotes,
} from "components/Admin/AdminCustomerDetails";

// rQuery
import { useGetAccount } from "rQuery/api";
import { useGetGlobalAccountTags } from "rQuery/api/useGetTags";

export default () => {
  // HOOKS
  const { customerId } = useParams();
  const {
    isLoading: isLoadingGlobalAccountTags,
    data: globalAccountTags,
    refetch: refetchGlobalAccountTags,
  } = useGetGlobalAccountTags();

  const { isLoading: isLoadingAccount, data: account } =
    useGetAccount(customerId);

  // TODO:  reloading is wiping some nested data -> useQuery is fetching data correctly but last log of `account` here is deleting data
  //          look into the use of state: { superadmin: true } on location
  //          updated redirect (linkToDetails instead of just <a /> link) in <AdminCustomerDetails /> fixed correctly loading data on redirect
  //            it matches the redirect used on the viewDetails() function in the <TableOptionsMenu /> for customers

  return (
    <BackgroundProvider minHeight="100vh">
      <AdminLayout text={account?.name || ""} type="details">
        {isLoadingAccount || isLoadingGlobalAccountTags ? (
          <Flex>
            <Text>Loading...</Text>
          </Flex>
        ) : (
          <Flex direction="column">
            <CustomerDetailsList customerDetails={account} />
            <Tags
              type="account"
              ownerDetails={account}
              gTags={globalAccountTags}
              refetchGlobalTags={refetchGlobalAccountTags}
            />
            <CustomerCredits ownerDetails={account} />
            <CustomerEvents ownerDetails={account} />
            {/* <TeachableInfo customerDetails={account} />  */}
            <CustomerOrders ownerDetails={account} />
            <CustomerNotes ownerDetails={account} />
          </Flex>
        )}
      </AdminLayout>
    </BackgroundProvider>
  );
};
