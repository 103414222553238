import React, { useState, useEffect, useCallback } from "react";
import * as S from "./Incrementer.styled";

export default (props) => {
  const { error, min, max, validateAndSort, reload, value } = props;

  const [inputValue, setInputValue] = useState("");
  const [storedInputValue, setStoredInputValue] = useState("");

  const focusInput = useCallback(
    (e) => {
      setStoredInputValue(inputValue);
      setInputValue("");
    },
    [inputValue]
  );

  const blurInput = useCallback(
    (e) => {
      if (
        !parseInt(e.target.value) ||
        parseInt(e.target.value) > max ||
        parseInt(e.target.value) < min
      ) {
        setInputValue(storedInputValue);
        validateAndSort(storedInputValue - 1);
      } else {
        setStoredInputValue(e.target.value);
        validateAndSort(e.target.value - 1);
      }
    },
    [max, min, validateAndSort, storedInputValue]
  );

  const changeInput = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (value) setInputValue(value);
  }, [value, error, reload]);

  useEffect(() => {
    if (value) setStoredInputValue(value);
    // eslint-disable-next-line
  }, []);

  return (
    <S.Input
      {...props}
      type="number"
      value={inputValue}
      onFocus={focusInput}
      onBlur={blurInput}
      onChange={changeInput}
    />
  );
};
