import { useEffect, useState } from "react";
import debounce from "lodash.debounce";

export default () => {
  const [width, setWidth] = useState();

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    const handleResizeDebounced = debounce(handleResize, 100);

    window.addEventListener("resize", handleResizeDebounced);
    handleResize();
    return () => window.removeEventListener("resize", handleResizeDebounced);
  }, []);

  return width;
};
