import React from "react";
import { Button, Flex, SimplePrompt, Text, Image } from "components";

export default ({ buttonClick, close }) => (
  <SimplePrompt close={close}>
    <Flex direction="column" align="center">
      <Text center size="24px" margin="24px 0 56px">
        Ready to start watching the main feature?
      </Text>
      <Button onClick={() => buttonClick()} className="theater-btn">
        Watch Now
        <Image
          margin="0 0 0 16px"
          width="28px"
          src={require("assets/images/icon-arrow-right.svg").default}
        />
      </Button>
    </Flex>
  </SimplePrompt>
);
