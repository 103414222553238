import React from "react";
import { Text, Flex, Button } from "components";
import { FoyerFaq } from "./";
import { colors } from "consts";

export default ({ faqSectionTitle, aboutSettings, comments }) => {
  return (
    <>
      <Text
        className="canela"
        responsive={{ phonePlus: { size: "35px" } }}
        size="50px"
        margin="0 0 40px"
      >
        {faqSectionTitle || "FAQ"}
      </Text>

      <Flex justify="space-between" align="flex-start" wrap={1}>
        {comments?.[0] && (
          <Flex
            responsive={{ tablet: { width: "100%", padding: "40px 0" } }}
            align="flex-start"
            direction="column"
            width="50%"
            padding="0 80px 0 0"
          >
            {comments?.map((item) => (
              <FoyerFaq
                key={item.text}
                text={item.text}
                answer={item.children[0].text}
              />
            ))}
          </Flex>
        )}

        {aboutSettings?.enableLive && (
          <Flex
            responsive={{ tablet: { width: "100%", padding: "40px 0 0" } }}
            align="flex-start"
            direction="column"
            width="50%"
          >
            <Text size="36px" margin="0 0 12px">
              {aboutSettings?.liveTitle
                ? aboutSettings?.liveTitle
                : "Live Q&A with the Film Team"}
            </Text>
            {aboutSettings?.liveDate && (
              <Text size="18px" margin="0 0 12px" color={colors.orange}>
                {aboutSettings?.liveDate}
              </Text>
            )}
            {aboutSettings?.liveDescription && (
              <Text size="18px" margin="0 0 32px">
                {aboutSettings?.liveDescription}
              </Text>
            )}
            {aboutSettings?.liveUrl && (
              <a
                href={aboutSettings?.liveUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button className="medium">
                  {aboutSettings?.liveCta
                    ? aboutSettings?.liveCta
                    : "Join the Live talk"}
                </Button>
              </a>
            )}
          </Flex>
        )}
      </Flex>
    </>
  );
};
