import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Flex,
  Text,
  Label,
  Input,
  Button,
  Textarea,
  File,
  Image,
  Incrementer,
  SimplePrompt,
} from "components";
import { updateSortOrder } from "utils";
import { colors } from "consts";
import { PRODUCTS } from "api";
import randomString from "randomstring";
import { toast } from "react-toastify";
import * as S from "./DashboardStore.styled";

export default (props) => {
  const { isExisting, products, screening, setStep, update } = props;
  const [currentProducts, setCurrentProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [editProduct, setEditProduct] = useState();
  const [showSaveSort, setShowSaveSort] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    item: null,
  });
  const currentProductsRef = useRef(currentProducts);
  currentProductsRef.current = currentProducts;
  const productRef = useRef(product);
  productRef.current = product;
  const lastProductRef = useRef();
  lastProductRef.current =
    currentProductsRef.current?.[currentProducts.length - 1]?.id;

  useEffect(() => {
    const getProducts = async () => {
      if (screening && screening.id) {
        const _products = await PRODUCTS.getPageStoreProducts(screening.id);
        setCurrentProducts(_products.data.data);
      } else {
        screening.products && setCurrentProducts(screening.products);
      }
    };

    getProducts();
    // eslint-disable-next-line
  }, [props, products]);

  useEffect(() => {
    if (
      currentProducts.length > 0 &&
      currentProducts.every(
        (_product) => _product.order || _product.order === 0
      )
    ) {
      // All products have an order attribute.
      const sortedProducts = [...currentProducts].sort(
        (a, b) => a.order - b.order
      );
      const reSortedProducts = sortedProducts.map((_product, idx) => ({
        ..._product,
        order: idx,
      }));
      setCurrentProducts(reSortedProducts);
    } else if (
      currentProducts.length > 0 &&
      currentProducts.some((_product) => _product.order || _product.order === 0)
    ) {
      // Only some products have an order attribute.
      const productsWithoutOrder = currentProducts.filter(
        (_product) => _product.order !== 0 && !_product.order
      );
      const productsWithOrder = currentProducts.filter(
        (_product) => _product.order || _product.order === 0
      );
      let sortedProductsWithOrder = productsWithOrder.sort(
        (a, b) => a.order - b.order
      );
      for (let _product in productsWithoutOrder) {
        const updatedProduct = {
          ..._product,
          order:
            sortedProductsWithOrder[sortedProductsWithOrder.length - 1].order +
            1,
        };
        sortedProductsWithOrder.push(updatedProduct);
      }
      setCurrentProducts(sortedProductsWithOrder);
    } else {
      // None of the products have an order attribute.
      if (currentProducts.length > 0) {
        const productsWithOrder = currentProducts.map((_product, idx) => ({
          ..._product,
          order: idx,
        }));
        productsWithOrder.sort((a, b) => a.order - b.order);
        setCurrentProducts(productsWithOrder);
      }
    }
    // eslint-disable-next-line
  }, [lastProductRef.current]);

  const updateProduct = (e) => {
    const newProduct = {
      ...productRef.current,
    };
    newProduct[e.target.name] = e.target.value;
    setProduct(newProduct);
  };

  const addProduct = useCallback(async () => {
    const isDigital = product.digital;
    isDigital && (product.shipping = undefined);
    const currentProduct = {
      ...product,
    };
    try {
      if (screening && screening.id) {
        currentProduct.sku = randomString.generate({
          charset: "numeric",
          length: 10,
        });
        currentProduct.active = true;
        currentProduct.price = parseInt(currentProduct.price * 100);
        currentProduct.type = currentProduct.digital ? "digital" : "physical";
        currentProduct.order = currentProducts.length;
        await PRODUCTS.create(screening.id, currentProduct);
        const _products = await PRODUCTS.getPageStoreProducts(screening.id);
        setCurrentProducts(_products.data.data);
        setProduct({});
        setShowAddProduct(false);
      } else {
        currentProduct.price = parseInt(currentProduct.price * 100);
        currentProduct.id = randomString.generate({
          charset: "numeric",
          length: 10,
        });
        currentProduct.type = currentProduct.digital ? "digital" : "physical";
        currentProduct.order = currentProducts.length;
        currentProducts.push(product);
        setCurrentProducts(currentProducts);
        setProduct({});
        setShowAddProduct(false);
        screening.products = currentProducts;
        update(screening);
      }
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line
  }, [currentProducts, product]);

  const uploadProductImage = (url) => {
    product.thumbnail = url;
    setProduct(product);
  };

  const deleteProduct = async (item) => {
    let reorderedProducts;
    if (item.id) {
      try {
        await PRODUCTS.remove(screening.id, item.id);
        const { data: products } = await PRODUCTS.getPageStoreProducts(
          screening.id
        );
        reorderedProducts = [...products.data].map((_product, idx) => ({
          ..._product,
          order: idx,
        }));
        setCurrentProducts(reorderedProducts);
        toast.success("Product deleted successfully", { autoClose: 3000 });
      } catch (err) {
        console.error(err);
        toast.error("Error deleting product", { autoClose: 3000 });
      }
    } else {
      const _products = currentProducts.filter(
        (_product) => _product.name !== item.name
      );
      reorderedProducts = [..._products].map((product, idx) => ({
        ...product,
        order: idx,
      }));
      setCurrentProducts(reorderedProducts);
      screening.products = _products;
      update(screening);
    }
  };

  const editThisProduct = (item) => {
    const _item = { ...item };
    _item.price = (_item.price / 100).toFixed(2);
    _item.shipping = (_item.shipping / 100).toFixed(2);
    setProduct(_item);
    setEditProduct(true);
  };

  const publishEditProduct = useCallback(async () => {
    const currentProduct = {
      ...product,
    };
    if (screening && screening.id) {
      currentProduct.price = parseInt((currentProduct.price * 100).toFixed(2));
      currentProduct.shipping = parseInt(
        (currentProduct.shipping * 100).toFixed(2)
      );
      delete currentProduct.inventory;
      await PRODUCTS.update(screening.id, currentProduct);
      const _products = await PRODUCTS.getPageStoreProducts(screening.id);
      setCurrentProducts(_products.data.data);
      toast.success("Product updated successfully", { autoClose: 3000 });
      setEditProduct(false);
    } else {
      currentProduct.price = (currentProduct.price * 100).toFixed(2);
      currentProduct.shipping = (currentProduct.shipping * 100).toFixed(2);
      const _products = currentProducts.filter(
        (_product) => _product.id !== currentProduct.id
      );
      _products.push(currentProduct);
      setCurrentProducts(_products);
      setProduct({});
      toast.success("Product updated successfully", { autoClose: 3000 });
      setEditProduct(false);
      screening.products = currentProducts;
      update(screening);
    }
    // eslint-disable-next-line
  }, [product]);

  const updateAllProductOrders = async () => {
    if (screening && screening.id) {
      const productsToUpdate = [];
      currentProductsRef?.current?.forEach((_product, idx) => {
        if (_product.id) {
          productsToUpdate.push({
            itemId: _product.id,
            sortOrder: _product.order,
          });
        }
      });
      const updatedProducts = await PRODUCTS.updateProductSortOrder(
        screening.id,
        { products: productsToUpdate }
      );
      const filteredReturn = updatedProducts.data.data.filter(
        (product) => product?.id
      );
      const sortedProducts = filteredReturn.sort((a, b) => a.order - b.order);
      setCurrentProducts(sortedProducts);
      toast.success("Products updated successfully", { autoClose: 3000 });
      setShowSaveSort(false);
    } else {
      toast.error("Unable to update products", { autoClose: 3000 });
    }
  };

  return (
    <Flex padding="60px 125px" direction="column" align="flex-start">
      {!showAddProduct && !editProduct && (
        <>
          {currentProducts.length ? (
            <>
              <Flex justify="space-between" align="flex-start">
                <Flex direction="column" align="flex-start">
                  <Text size="38px" weight="500" margin="0 0 17px">
                    Store
                  </Text>
                  <Text size="16px" margin="0 0 8px">
                    Sell items, including digital downloads of your film, DVDs,
                    screening kits, merch, and more.
                  </Text>
                  <Text size="16px" margin="0 0 8px">
                    To get started click the <b>Add Product</b> button to add
                    the items you wish to sell.
                  </Text>
                  <Text size="16px" margin="0 0 8px">
                    Once all items have been added use the <b>Sort Order</b>{" "}
                    boxes next to each item to number them in the order you
                    would like them to appear in your Store.
                  </Text>
                  <Text size="16px" margin="0 0 8px">
                    To save the order of your items please click on the{" "}
                    <b>Save Sort Order</b> button.
                  </Text>
                  <Text size="14px" padding="8px 0 0" color={colors.gray8}>
                    To sell store products you will need to authenticate your
                    identity with Stripe, our payment processor. Please visit
                    Payments & Activation.
                  </Text>
                </Flex>

                <Flex
                  direction="column"
                  align="flex-end"
                  justify="space-between"
                  height="180px"
                  padding="24px 0"
                >
                  <Flex justify="flex-end">
                    <Button
                      className={"secondary"}
                      margin="0 24px 0 0"
                      onClick={() => {
                        setStep("qa");
                        toast("event updated successfully", {
                          autoClose: 3000,
                        });
                      }}
                    >
                      Save and Continue
                    </Button>
                    <Button onClick={() => setShowAddProduct(true)}>
                      Add Product
                    </Button>
                  </Flex>
                  {showSaveSort && (
                    <Flex justify="flex-end">
                      <Button onClick={() => updateAllProductOrders()}>
                        Save Sort Order
                      </Button>
                    </Flex>
                  )}
                </Flex>
              </Flex>

              <Flex margin="48px 0 0" justify="space-between">
                <Flex justify="center">
                  <Text size="14px" weight="bold">
                    Products
                  </Text>
                </Flex>
                <Flex justify="center">
                  <Text size="14px" weight="bold">
                    Price
                  </Text>
                </Flex>
                <Flex justify="center" width="100px">
                  <Text size="14px" weight="bold">
                    Sort Order
                  </Text>
                </Flex>
                <Flex justify="center" width="250px">
                  <Text size="14px" weight="bold">
                    Actions
                  </Text>
                </Flex>
              </Flex>

              {currentProducts.length > 0 &&
                currentProducts.map((item) => {
                  if (item.type === "paywall" || item.type === "donation")
                    return null;
                  return (
                    <React.Fragment key={item.id}>
                      <Flex margin="14px 0 0" justify="space-between">
                        <S.Highlight onClick={() => editThisProduct(item)}>
                          <Flex>
                            <S.Thumbnail
                              thumbnail={
                                item.thumbnail ||
                                require("assets/images/icon-shopping-cart.svg")
                                  .default
                              }
                            />
                            <Text size="14px">{item.title}</Text>
                          </Flex>
                          <Flex justify="center">
                            <Text size="14px">
                              {(item.price / 100).toFixed(2)}
                            </Text>
                          </Flex>
                        </S.Highlight>
                        <Incrementer
                          width="50px"
                          margin="0 25px"
                          validateAndSort={(newOrder) => {
                            const newProductArray = updateSortOrder(
                              item.order,
                              newOrder,
                              currentProducts
                            );
                            setCurrentProducts(newProductArray);
                            setShowSaveSort(true);
                          }}
                          items={currentProducts}
                          itemOrder={item.order}
                          max={currentProducts.length}
                        />
                        <Flex justify="center" width="250px">
                          <S.Delete justify="center">
                            <Image
                              onClick={() =>
                                setShowDeleteModal({
                                  show: true,
                                  item: item,
                                })
                              }
                              width="74px"
                              src={
                                require("assets/images/icon-delete.svg").default
                              }
                            />
                          </S.Delete>
                        </Flex>
                      </Flex>
                      {showDeleteModal.show && (
                        <SimplePrompt
                          close={() =>
                            setShowDeleteModal({
                              show: false,
                              item: null,
                            })
                          }
                        >
                          <Text size="16px" margin="0 0 20px">
                            Warning: do not delete any products that have orders
                            attached to them, as doing so will result in the
                            orders being removed from your Orders Download.
                          </Text>
                          <Text size="16px" weight="bold" margin="0 0 24px">
                            Are you sure you want to delete that product?
                          </Text>
                          <Button
                            margin="0 16px 0 0"
                            onClick={() => {
                              setShowSaveSort(false);
                              deleteProduct(showDeleteModal?.item || item);
                              updateAllProductOrders();
                              setShowDeleteModal({
                                show: false,
                                item: null,
                              });
                            }}
                          >
                            Delete
                          </Button>
                          <Button
                            className="secondary"
                            onClick={() => {
                              setShowDeleteModal({
                                show: false,
                                item: null,
                              });
                            }}
                          >
                            Cancel
                          </Button>
                        </SimplePrompt>
                      )}
                    </React.Fragment>
                  );
                })}

              {!isExisting && (
                <Flex justify="flex-end" margin="32px 0 0">
                  <Button
                    className={"secondary"}
                    onClick={() => {
                      setStep("qa");
                      toast("event updated successfully", {
                        autoClose: 3000,
                      });
                    }}
                  >
                    Save and Continue
                  </Button>
                </Flex>
              )}
            </>
          ) : (
            <>
              <Flex>
                <Text size="38px" weight="500" margin="0 0 17px">
                  Products
                </Text>

                <Flex justify="flex-end">
                  <Button
                    className={"secondary"}
                    margin="0 26px 0 0"
                    onClick={() => {
                      setStep("qa");
                      toast("event updated successfully", {
                        autoClose: 3000,
                      });
                    }}
                  >
                    Save
                  </Button>
                  <Button onClick={() => setShowAddProduct(true)}>
                    Add Product
                  </Button>
                </Flex>
              </Flex>

              <Flex align="flex-start">
                <Flex
                  width="630px"
                  direction="column"
                  justify="flex-start"
                  align="flex-start"
                >
                  <Text size="14px" margin="0 0 80px">
                    Add products to your event page. You can sell packages or
                    merchandise.
                  </Text>
                  <Text margin="8px 0 0" size="14px" color={colors.gray8}>
                    Please note: do not delete any products that have orders
                    attached to them, as doing so will result in the orders
                    being removed from your Orders Download.
                  </Text>
                </Flex>
              </Flex>
            </>
          )}
        </>
      )}

      {editProduct && (
        <S.Wrap>
          <Flex
            direction="column"
            align="flex-start"
            justify="flex-start"
            width="550px"
          >
            <Text size="38px" weight="500" margin="0 0 17px">
              Add Product
            </Text>

            <Text size="14px" margin="0 0 42px" color={colors.gray8}>
              Add details for your product, including product name, photo, and
              written description. We recommend using square images with 1:1
              ratios, e.g. 1000x1000.
            </Text>

            <Flex width="560px" align="flex-start">
              <Flex width="35%">
                <File
                  image={product.thumbnail}
                  setUploaded={uploadProductImage}
                />
              </Flex>

              <Flex margin="0 0 0 30px" direction="column">
                <Flex>
                  <Flex direction="column" align="flex-start">
                    <Label>Product Name</Label>
                    <Input
                      value={product.title}
                      name="title"
                      onChange={updateProduct}
                      margin="0 0 26px"
                    />
                  </Flex>
                </Flex>

                <Flex>
                  <Flex direction="column" align="flex-start">
                    <Label>Description</Label>
                    <Textarea
                      rows={5}
                      value={product.description}
                      name="description"
                      onChange={updateProduct}
                      margin="0 0 26px"
                      maxChar={300}
                      placeholder="300 max characters"
                    />
                  </Flex>
                </Flex>

                <Flex>
                  <Flex
                    width="50%"
                    margin="0 35px 0 0"
                    direction="column"
                    align="flex-start"
                  >
                    <Label>Price</Label>
                    <Input
                      value={product.price}
                      name="price"
                      type="number"
                      onChange={updateProduct}
                      margin="0 0 26px"
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <S.Spacer />

            <Flex margin="0 0 32px" justify="flex-end">
              <Button
                onClick={() => {
                  setEditProduct(false);
                  setProduct({});
                }}
                className="secondary"
                margin="0 8px 0 0"
              >
                Back
              </Button>
              <Button onClick={() => publishEditProduct()}>
                Update Product
              </Button>
            </Flex>
          </Flex>
        </S.Wrap>
      )}

      {showAddProduct && (
        <S.Wrap>
          <Flex
            direction="column"
            align="flex-start"
            justify="flex-start"
            width="550px"
          >
            <Text size="38px" weight="500" margin="0 0 17px">
              Add Product
            </Text>

            <Text size="14px" margin="0 0 42px" color={colors.gray8}>
              Add details for your product, including product name, photo, and
              written description. We recommend using square images with 1:1
              ratios, e.g. 1000x1000.
            </Text>

            <Flex width="560px" align="flex-start">
              <Flex width="35%">
                <File setUploaded={uploadProductImage} />
              </Flex>

              <Flex margin="0 0 0 30px" direction="column">
                <Flex>
                  <Flex direction="column" align="flex-start">
                    <Label>Product Name</Label>
                    <Input
                      value={product.name}
                      name="title"
                      onChange={updateProduct}
                      margin="0 0 26px"
                    />
                  </Flex>
                </Flex>

                <Flex>
                  <Flex direction="column" align="flex-start">
                    <Label>Description</Label>
                    <Textarea
                      rows={5}
                      value={product.description}
                      name="description"
                      onChange={updateProduct}
                      margin="0 0 26px"
                      maxChar="300"
                      placeholder="300 max characters"
                    />
                  </Flex>
                </Flex>

                <Flex>
                  <Flex
                    width="50%"
                    margin="0 35px 0 0"
                    direction="column"
                    align="flex-start"
                  >
                    <Label>Price</Label>
                    <Input
                      value={product.price}
                      name="price"
                      type="number"
                      onChange={updateProduct}
                      margin="0 0 26px"
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <S.Spacer />

            <Flex margin="0 0 32px" justify="flex-end">
              <Button
                onClick={() => setShowAddProduct(false)}
                className="secondary"
                margin="0 8px 0 0"
              >
                Back
              </Button>
              <Button onClick={() => addProduct()}>Add Product</Button>
            </Flex>
          </Flex>
        </S.Wrap>
      )}
    </Flex>
  );
};
