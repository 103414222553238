// imports
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// styles
import * as S from "./AdminTable.styled";

// api
import { PAGES, NOTES, CREDITS, LEADS } from "api";

// utils
import { useOutsideClick } from "hooks";
import { leadStages, colors } from "consts";

// components
import {
  Checkbox,
  Text,
  Flex,
  Input,
  Button,
  SimplePrompt,
  EditTagsForm,
} from "components";

// rQuery
import { queryClient } from "rQuery";
import { useMutation } from "react-query";
import {
  useGetGlobalLeadownerTags,
  useGetGlobalAccountTags,
  useGetGlobalEventTags,
} from "rQuery/api/useGetTags";

export default ({ original, type, onClick }) => {
  // HOOKS
  const history = useHistory();
  const { adminRoute } = useParams();
  const { data: globalLeadownerTags } = useGetGlobalLeadownerTags();
  const { data: globalAccountTags } = useGetGlobalAccountTags();
  const { data: globalEventsTags } = useGetGlobalEventTags();

  // STATE
  const [expanded, setExpanded] = useState(false);
  const [currentItem, setCurrentItem] = useState(original);
  const [isDeletingLeadOwner, setIsDeletingLeadOwner] = useState(false);
  // state: notes
  const [writingNote, setWritingNote] = useState(false);
  const [currentNote, setCurrentNote] = useState("");
  // state: credits
  const [updatingCredits, setUpdatingCredits] = useState(false);
  const [creditsChange, setCreditsChange] = useState(false);
  const [currentCredits, setCurrentCredits] = useState(null);
  // state: tags
  const [editingTags, setEditingTags] = useState(false);
  const [tagData, setTagData] = useState(null);

  // USE REFS
  const currentItemRef = useRef(currentItem);
  currentItemRef.current = currentItem;
  const menuRef = useRef();
  useOutsideClick(menuRef, () => {
    setExpanded(false);
  });

  // USE EFFECT
  useEffect(() => {
    setCurrentItem(original);
    setTagData(original.tags);
  }, [original]);

  // METHODS
  const cancel = () => {
    setCurrentCredits(null);
    setCreditsChange(false);
    setUpdatingCredits(false);
  };

  const edit = (eventId) => {
    const location = {
      pathname: `/event/${original.accountId}/${eventId}`,
      state: { superadmin: true },
    };
    history.push(location);
  };

  const viewDetails = (type, itemId) => {
    const location = {
      pathname: `/admin/${type}-details/${itemId}`,
      state: { superadmin: true },
    };
    history.push(location);
  };

  const updateEvent = async (event) => {
    try {
      await PAGES.update(event);
      toast("Successfully updated", { autoClose: 3000 });
    } catch (err) {
      let _message = "";
      if (err.validation) {
        for (var i in err.validation) {
          _message.concat(err.validation[i].msg);
        }
      }
      toast("Error updating event", { autoClose: 3000 });
    }
  };

  // const deleteEvent = async (leadownerId) => {
  //   try {
  //     await LEADS.deleteLeadOwner(leadownerId);
  //     toast("Successfully deleted");
  //   } catch (err) {
  //     let _message = "";
  //     if (err.validation) {
  //       for (var i in err.validation) {
  //         _message.concat(err.validation[i].msg);
  //       }
  //     }
  //     toast("Error deleting event", { autoClose: 3000 });
  //   }
  // };

  const handleEventMenuToggles = async (attribute) => {
    const updatedEvent = {
      ...currentItemRef.current,
      [attribute]: !currentItemRef.current[attribute],
    };

    // update mutually exclusive fields
    if (attribute === "archived")
      updatedEvent.activated = !updatedEvent[attribute];
    if (attribute === "activated")
      updatedEvent.archived = !updatedEvent[attribute];

    setCurrentItem(updatedEvent);
    await updateEvent(updatedEvent);
  };

  const handleToggleDIYEvent = async () => {
    const isDiy = currentItem.diyEvent;

    const updatedEvent = {
      ...currentItemRef.current,
      diyEvent: !isDiy,
    };

    setCurrentItem(updatedEvent);
    await updateEvent(updatedEvent);
  };

  // methods: notes
  const submitNote = async (note) => {
    if (note && original.id) {
      const body = {
        text: note,
      };

      const function_map = {
        leads: async () => await NOTES.postLeadownerNote(original.id, body),
        customers: async () => await NOTES.postAccountNote(original.id, body),
      };

      await function_map[adminRoute]();
    }
  };

  const handleCreditsChange = ({ target }) => {
    setCurrentCredits(target.value);
    setCreditsChange(true);
  };

  const deleteLead = async () => {
    try {
      await LEADS.deleteLeadOwner(currentItem.id);
      queryClient.invalidateQueries("leadowners");
      toast("Lead successfully deleted", { autoClose: 3000 });
    } catch (err) {
      let _message = "";
      if (err.validation) {
        for (var i in err.validation) {
          _message.concat(err.validation[i].msg);
        }
      }
      toast("Error deleting lead", { autoClose: 3000 });
    }
  };

  // MUTATIONS
  const { mutate: updateLeadownerStage } = useMutation(
    async (stage) => await LEADS.updateStage(currentItem.id, stage),
    {
      onSuccess: (data) => {
        toast("Lead Stage Updated", { autoClose: 3000 });
      },
      onError: (err) => {
        toast("Error updating lead stage", { autoClose: 3000 });
        console.error("Error updating lead stage", err);
      },
      onSettled: (data, err) => {
        queryClient.invalidateQueries("leadowners");
        queryClient.invalidateQueries(["leadowners", "filtered"]);
      },
    }
  );

  const { mutate: updateAccountCredits } = useMutation(
    async (credits) => {
      await CREDITS.createCredit({
        email: original.email,
        credits: parseInt(credits),
      });
    },
    {
      onSuccess: (data) => {
        toast("Updated Account Credits", { autoClose: 3000 });
      },
      onError: (err) => {
        toast("Error updating account credits", { autoClose: 3000 });
        console.error("Error updating account credits", err);
      },
      onSettled: (data, err) => {
        queryClient.invalidateQueries("accounts");
        queryClient.invalidateQueries(["accounts", "filtered"]);
      },
    }
  );

  const { mutate: mutateSubmitNote } = useMutation(
    async (note) => submitNote(note),
    {
      onSuccess: (data) => {
        toast("notes successfully updated", { autoClose: 3000 });
      },
      onError: (err) => {
        toast("Error adding note", { autoClose: 3000 });
        console.error("Error adding note", err);
      },
      onSettled: (data, err) => {
        queryClient.invalidateQueries([queryMap[type], original.id]);
        setCurrentNote("");
        setWritingNote(false);
      },
    }
  );

  // HELPERS
  const globalTagsMap = {
    leadOwners: globalLeadownerTags,
    customers: globalAccountTags,
    events: globalEventsTags,
  };

  const queryMap = {
    leadOwners: "leadowner",
    customers: "account",
    events: "event",
  };

  return (
    <div ref={menuRef}>
      <S.OptionsCell>
        <S.OptionButton
          width="32px"
          src={require("assets/images/ellipses-icon.svg").default}
          onClick={() => {
            setExpanded(!expanded);
          }}
        />
        {expanded && (
          <S.OptionsMenuContainer
            margin={`${
              type === "customers"
                ? "150px 0 0"
                : type === "events"
                ? "300px 0 0"
                : type === "leadOwners"
                ? "300px 0 0"
                : type === "orders"
                ? "150px 0 0"
                : "0"
            }px`}
          >
            {type === "customers" ? (
              <>
                <S.MenuOption
                  onClick={() =>
                    viewDetails("customer", original.accountId || original.id)
                  }
                >
                  View account details
                </S.MenuOption>
                <S.MenuOption
                  onClick={() => {
                    setWritingNote(true);
                  }}
                >
                  Add Note
                </S.MenuOption>
                <S.MenuOption onClick={() => setEditingTags(true)}>
                  Edit Tags
                </S.MenuOption>
                <S.MenuOption
                  onClick={() => {
                    setUpdatingCredits(true);
                    setCurrentCredits(original?.credits?.credits || 0);
                  }}
                >
                  Update Credits
                </S.MenuOption>
              </>
            ) : type === "customer_notes" ? (
              <>
                <S.MenuOption onClick={() => onClick.edit()}>
                  Edit Note
                </S.MenuOption>
                <S.MenuOption onClick={() => onClick.delete()}>
                  Delete Note
                </S.MenuOption>
              </>
            ) : type === "events" ? (
              <>
                <S.MenuOption onClick={() => viewDetails("event", original.id)}>
                  View event details
                </S.MenuOption>
                <S.MenuOption
                  onClick={() =>
                    viewDetails("customer", original.accountId || original.id)
                  }
                >
                  View account details
                </S.MenuOption>
                <S.MenuOption onClick={() => setEditingTags(true)}>
                  Edit Tags
                </S.MenuOption>
                <S.MenuOption onClick={() => edit(original.id)}>
                  Edit event
                </S.MenuOption>
                <S.MenuSectionDivider />
                <Text
                  margin="6px 0"
                  size="12px"
                  weight="bold"
                  color={colors.white}
                >
                  Test
                </Text>
                <Flex margin="6px 0">
                  <Checkbox
                    light
                    checked={currentItem.test}
                    onClick={() => handleEventMenuToggles("test")}
                  />
                  <Text
                    margin="0 0 0 12px"
                    size="14px"
                    color={colors.activeWhite1}
                  >
                    Test
                  </Text>
                </Flex>
                <S.MenuSectionDivider />
                <Text
                  margin="6px 0"
                  size="12px"
                  weight="bold"
                  color={colors.white}
                >
                  Status
                </Text>
                <Flex margin="6px 0">
                  <Checkbox
                    light
                    checked={currentItem.activated}
                    onClick={() => handleEventMenuToggles("activated")}
                  />
                  <Text
                    margin="0 0 0 12px"
                    size="14px"
                    color={colors.activeWhite1}
                  >
                    Activated
                  </Text>
                </Flex>
                <Flex margin="6px 0">
                  <Checkbox
                    light
                    checked={currentItem.archived}
                    onClick={() => handleEventMenuToggles("archived")}
                  />
                  <Text
                    margin="0 0 0 12px"
                    size="14px"
                    color={colors.activeWhite1}
                  >
                    Archived
                  </Text>
                </Flex>
                <Flex margin="6px 0">
                  <Checkbox
                    light
                    checked={currentItem.superarchived}
                    onClick={() => handleEventMenuToggles("superarchived")}
                  />
                  <Text
                    margin="0 0 0 12px"
                    size="14px"
                    color={colors.activeWhite1}
                  >
                    Super Archived
                  </Text>
                </Flex>
                <Flex margin="6px 0">
                  <Checkbox
                    light
                    checked={currentItem.diyEvent}
                    onClick={handleToggleDIYEvent}
                  />
                  <Text
                    margin="0 0 0 12px"
                    size="14px"
                    color={colors.activeWhite1}
                  >
                    DIY Event
                  </Text>
                </Flex>
                {/*
                    commented out by Reed on 2/16/22 Day Sprint

                    - need to add "social" migration & validation logic to all BE routes
                    - need to confirm FE logic needed
                 */}
                {/* <S.MenuSectionDivider />
                <Flex margin="6px 0">
                  <Checkbox
                    light
                    checked={currentItem}
                    // onClick={() => handleMenuToggles("social")}
                  />
                  <Text
                    margin="0 0 0 12px"
                    size="14px"
                    color={colors.activeWhite1}
                  >
                    Social Approved
                  </Text>
                </Flex> */}
              </>
            ) : type === "leadOwners" ? (
              <>
                <S.MenuOption
                  onClick={() =>
                    viewDetails("leadowner", original.accountId || original.id)
                  }
                >
                  View lead details
                </S.MenuOption>
                <S.MenuOption
                  onClick={() => {
                    setWritingNote(true);
                  }}
                >
                  Add Note
                </S.MenuOption>
                <S.MenuOption onClick={() => setEditingTags(true)}>
                  Edit Tags
                </S.MenuOption>
                <S.MenuOption
                  onClick={() => {
                    setExpanded(false);
                    setIsDeletingLeadOwner(true);
                  }}
                >
                  Delete Lead
                </S.MenuOption>
                <S.MenuSectionDivider />
                <Text
                  margin="6px 0"
                  size="12px"
                  weight="bold"
                  color={colors.white}
                >
                  Stage
                </Text>
                {leadStages.map((stage, id) => {
                  return (
                    <Flex key={id}>
                      <Checkbox
                        light
                        checked={currentItem.stage === stage ? true : false}
                        onClick={async () => {
                          updateLeadownerStage(stage);
                        }}
                      ></Checkbox>
                      <Text>{stage}</Text>
                    </Flex>
                  );
                })}
              </>
            ) : type === "globalTags" ? (
              <>
                <S.MenuOption onClick={() => onClick.edit()}>
                  Edit Tag
                </S.MenuOption>

                <S.MenuOption
                  onClick={() => {
                    onClick.delete();
                    setExpanded(false);
                  }}
                >
                  Delete Tag
                </S.MenuOption>
              </>
            ) : type === "orders" ? (
              <>
                <S.MenuOption
                  onClick={() => viewDetails("event", original.pageId)}
                >
                  View event details
                </S.MenuOption>
                {/* <S.MenuOption onClick={() => console.log("Need to implement")}>
                  Refund payment
                </S.MenuOption> */}
              </>
            ) : (
              <></>
            )}
          </S.OptionsMenuContainer>
        )}
      </S.OptionsCell>

      {/* MODELS */}
      {writingNote && (
        <SimplePrompt close={() => setWritingNote(false)}>
          <Flex padding="20px" direction="column" align="flex-start">
            <Text htmlFor="note" align="start" margin="0 0 8px">
              Note:
            </Text>
            <S.TextArea
              autoFocus={true}
              cols={75}
              rows={15}
              value={currentNote}
              name="note"
              onChange={(e) => {
                setCurrentNote(e.target.value);
              }}
            />
            <Button
              className={`${!currentNote && "disabled"}`}
              onClick={() => mutateSubmitNote(currentNote)}
            >
              Submit Note
            </Button>
          </Flex>
        </SimplePrompt>
      )}

      {updatingCredits && (
        <SimplePrompt close={cancel}>
          <Flex padding="20px" direction="column" align="flex-start">
            <Text htmlFor="note" align="start" margin="0 0 8px">
              Credits:
            </Text>
            <Input
              type="number"
              value={currentCredits}
              onChange={handleCreditsChange}
            />
            <Button
              className={`${!creditsChange && "disabled"}`}
              onClick={() => {
                setUpdatingCredits(false);
                updateAccountCredits(currentCredits);
              }}
              margin="16px 0 0"
            >
              Submit Change
            </Button>
          </Flex>
        </SimplePrompt>
      )}

      {editingTags && (
        <SimplePrompt close={() => setEditingTags(false)}>
          <EditTagsForm
            tags={tagData}
            gTags={globalTagsMap[type]}
            resource={adminRoute}
            original={original}
          />
        </SimplePrompt>
      )}

      {/* MODALS */}
      {isDeletingLeadOwner && (
        <SimplePrompt close={() => setIsDeletingLeadOwner(false)}>
          <Text>{`You are about to delete a lead and all of their lead sources. This action is irreversible. `}</Text>

          <Flex direction="column" padding="7px 0 0 0">
            <Text>{`Are you sure you wish to proceed?`}</Text>
            <Flex justify="center" padding="7px 0 0 0">
              <Button
                onClick={() => {
                  deleteLead();
                  setIsDeletingLeadOwner(false);
                }}
                margin="0 10px 0 0"
              >
                Yes, Delete Lead
              </Button>
              <Button
                onClick={() => {
                  setIsDeletingLeadOwner(false);
                }}
              >
                No, Cancel
              </Button>
            </Flex>
          </Flex>
        </SimplePrompt>
      )}
    </div>
  );
};
