import React, { useState, useEffect } from "react";
import { Flex, Text } from "components";
import * as S from "./AdminEventDetails.styled";
import { SUBSCRIBERS } from "api";
import AdminTable from "../AdminTable";

export default ({ eventDetails: { id } }) => {
  const [subscriberInfo, setSubscriberInfo] = useState(null);

  const getSubscribers = async (pageId) => {
    try {
      const {
        data: { data },
      } = await SUBSCRIBERS.getAll(pageId);
      setSubscriberInfo(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    id && getSubscribers(id);
  }, [id]);

  return (
    <S.ListContainer margin="24px 0 0" maxHeight="450px">
      <Flex justify="space-between" align="center" margin="0 0 24px 0">
        <Text size="24px" weight="bold">
          Emails Collected
        </Text>
      </Flex>

      {subscriberInfo?.length > 0 ? (
        <AdminTable type="event_subscribers" data={subscriberInfo} />
      ) : (
        <Flex>
          <Text>No Event Subscribers</Text>
        </Flex>
      )}
    </S.ListContainer>
  );
};
