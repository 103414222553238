import React, { useEffect } from "react";
import { Flex, Label } from "components";
import * as S from "./Stripe.styled";

export default ({ stripe, setCardNumber, setCardExpiry, setCardCVC }) => {
  useEffect(() => {
    //https://stripe.com/docs/js/elements_object/create
    const _elements = stripe.elements();

    const _style = {
      base: {
        color: "white",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };

    const _card = _elements.create("cardNumber", { style: _style });
    const _expiration = _elements.create("cardExpiry", { style: _style });
    const _cvc = _elements.create("cardCvc", { style: _style });

    _card.mount("#card-element");
    _expiration.mount("#expiration-element");
    _cvc.mount("#cvc-element");

    setCardNumber(_card);
    setCardExpiry(_expiration);
    setCardCVC(_cvc);
    // eslint-disable-next-line
  }, []);

  return (
    <Flex direction="column" align="flex-start">
      <S.Card id="card-element"></S.Card>

      <Flex direction="row" align="center">
        <Flex direction="column" align="flex-start">
          <Label>Expiration Date</Label>
          <S.Expiration id="expiration-element"></S.Expiration>
        </Flex>
        <Flex direction="column" align="flex-start">
          <Label>CVC</Label>
          <S.Cvc id="cvc-element"></S.Cvc>
        </Flex>
      </Flex>
    </Flex>
  );
};
