import { Flex, Input } from "components";
import styled from "styled-components";
import { minMedia } from "utils";
import { colors } from "consts";

export const Wrapper = styled(Flex)`
  /* padding: 120px 110px 200px;
  */
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  ${minMedia.laptopPlus`
    flex-direction: row;
    align-items: flex-start;
    padding: 50px;
  `}
`;

export const WidthProvider = styled(Flex)`
  width: 100%;
  padding: 0 16px 0 16px;
  ${minMedia.laptopPlus`
    width: 48%;
    max-width: 600px;
  `}
`;

export const Close = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 50px;
  z-index: 2;
  cursor: pointer;
  display: ${({ display }) => display || "inline"};
`;

export const Option = styled.div`
  border: 1px solid #b4b4b4;
  border-radius: 0;
  background: transparent;
  color: black;
  min-width: 100%;
  height: 48px;
  width: ${({ width }) => width || "64px"};
  margin: ${({ margin }) => margin || "4px 0 4px 0"};
  padding: ${({ padding }) => padding || "0"};
  justify-content: center;
  user-select: none;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0;
  cursor: pointer;
  ${({ selected }) =>
    selected &&
    `
    background: ${colors.orange};
    color: white;
  `}
  ${minMedia.laptopPlus`
    height: 32px;
    font-size: 18px;
    min-width: 50px;
    margin: 0 12px 0 0;
  `}
`;

export const OptionInput = styled(Input)`
  background: transparent;
  border: none;
  width: 100%;
  height: 100%;
  font-family: "Inter", sans-serif;
  border: none;
  color: black;
  font-size: 18px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus {
    box-shadow: none;
    outline: none;
    border: none;
  }
  ${({ selected }) =>
    selected &&
    `
    color: white;
  `}
`;

export const Donations = styled(Flex)`
  flex-direction: column;
  ${minMedia.laptopPlus`
    flex-direction: row;
  `}
`;
