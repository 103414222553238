import React from "react";
import { Flex } from "components";
import * as S from "./DashboardTagsSection.styled";

const EventTag = ({ id, name, removeTag }) => (
  <S.Tag>
    {name}
    <S.TagDeleteButton onClick={() => removeTag(id)} />
  </S.Tag>
);

export default ({ eventTags, removeTag }) => (
  <Flex align="flex-start" justify="flex-start" wrap="wrap" margin="0 0 24px 0">
    {eventTags?.map((tag) => (
      <EventTag {...tag} key={tag.id} removeTag={removeTag} />
    ))}
  </Flex>
);
