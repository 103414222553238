import styled from "styled-components";
import { colors } from "consts";
import { Text, Flex } from "components";
import { media } from "utils";

export const MainImage = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  height: 0;
  background: ${({ url }) => (url ? "url(" + url + ")" : colors.gray200)};
  background-size: cover;
  background-position: center center;
`;

export const ImageWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
  margin-right: 55px;
  margin-bottom: 55px;
  ${media.tablet`
    margin-right: 0px;
  `}
`;

export const Tab = styled(Text)`
  margin-right: 18px;
  padding-bottom: 14px;
  border-bottom: ${({ active }) =>
    active ? "2px solid #F16648" : "2px solid rgba(0,0,0,0)"};
  cursor: pointer;
  transition-duration: 0.3s;
`;

export const Arrow = styled.img`
  width: 24px;
`;

export const Thumbnail = styled.div`
  background: ${({ src }) => (src ? `url(${src})` : "white")};
  background-size: cover;
  background-position: center center;
  width: 150px;
  min-width: 150px;
  height: 150px;
  border-radius: 5px;
  margin-right: 24px;
`;

export const Delete = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  width: 11px;
`;

export const Item = styled(Flex)`
  padding-bottom: 32px;
  border-bottom: 2px solid #a4a4a4;
`;

export const Items = styled(Flex)`
  height: 400px;
  overflow: scroll;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
`;
