import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { PAGES, PRODUCTS, COMMENTS, ORDERS, SUBSCRIBERS } from "api";
import { toast } from "react-toastify";

const checkGate = ({
  startDate,
  endDate,
  showHours,
  paywallSettings,
  emailCapture,
  eventId,
  prepaidSlug,
  subscriberSlug,
}) => {
  /*
    GATE TYPES: no-gate, isPresale, paywall, emailCapture
    GUEST TYPES: new, subscribed, paid, donationOnly
  */

  const storage = localStorage;
  let isLive = false,
    type = "no-gate",
    guest = "new";
  let isComplete = false;
  const oldSubscriptionExists = storage.getItem(`subscriberId-${eventId}`);
  const isSubscribedTo =
    storage.getItem("isSubscribedTo") === eventId || subscriberSlug;
  const hasPaid = storage.getItem(`paywall-${eventId}`) || prepaidSlug;

  if (emailCapture && (oldSubscriptionExists || isSubscribedTo)) {
    guest = "subscribed";
  }

  if (paywallSettings.enablePaywall && hasPaid) {
    guest = "paid";
  }

  // Check if there are specified dates, and if so, whether the current date falls in that range
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  const endOfDay = moment(end).toDate();
  !showHours && endOfDay.setHours(23);
  !showHours && endOfDay.setMinutes(59);
  !showHours && endOfDay.setSeconds(59);
  endOfDay.toISOString();

  if (start <= now && endOfDay > now) isLive = true;

  if (endOfDay <= now) {
    isComplete = true;
    isLive = false;
  }

  if (!isLive && paywallSettings.enablePaywall && paywallSettings.isPresale)
    type = "isPresale";

  if (emailCapture) type = "emailCapture";

  if (isLive && paywallSettings.enablePaywall) type = "paywall";

  if (
    !paywallSettings.enablePaywall &&
    !emailCapture &&
    paywallSettings.askDonations
  )
    type = "donationOnly";

  return { type: type, guest: guest, live: isLive, complete: isComplete };
};

export default ({ url, subscriberSlug, orderSlug, refresh }) => {
  const [eventDetails, setEventDetails] = useState({
    theaterData: {
      sponsorPrerollUrl: "", //event.sponsorPrerollUrl
      paywallSettings: {}, // event.paywallSettings
      eventId: null, //event.id
      emailCapture: false, // event.emailCapture
      featureUrl: "", // event.featureUrl
    },
    foyerData: {
      title: "", // event.title,
      eventId: "", //event.id
      eventUrl: "", //event.url
      aboutSettings: {}, //event.aboutSettings
      photos: [], // event.photos
      products: [], // event.products
      comments: [], // event.comments
      capability: {}, // event.capability
      interactiveQa: undefined, //event.interactiveQa
      faqSectionTitle: "",
      showForwardButton: false,
    },
    footerData: {
      filmTeamEmail: "", //event.filmTeamEmail
    },
  });

  const getEvent = useCallback(async () => {
    try {
      const {
        data: {
          data: eventData,
          data: { id },
        },
      } = await PAGES.getByUrl(url);
      const {
        data: { data: physicalProductData },
      } = await PRODUCTS.getPageProducts(id, "physical");
      const {
        data: { data: digitalProductData },
      } = await PRODUCTS.getPageProducts(id, "digital");
      const {
        data: { data: paywallData },
      } = await PRODUCTS.getPageProducts(id, "paywall");
      const {
        data: { data: donationData },
      } = await PRODUCTS.getPageProducts(id, "donation");
      const commentData = await COMMENTS.get(id);

      let prepaidSlug;
      let visits;

      if (orderSlug) {
        try {
          const {
            data: {
              data: { products, visits: eventVisits },
            },
          } = await ORDERS.getOne(id, orderSlug);
          prepaidSlug = products?.[0].type === "paywall";
          visits = eventVisits;
        } catch {
          console.log("no order slug");
        }
      }

      let subscriberExists;

      if (subscriberSlug) {
        try {
          const {
            status,
            data: {
              data: { visits: eventVisits },
            },
          } = await SUBSCRIBERS.getById(id, subscriberSlug);
          subscriberExists = status === 200 && true;
          visits = eventVisits;
        } catch {
          // do nothing
        }
      }

      const gate = checkGate({
        startDate: eventData.eventStart,
        endDate: eventData.eventEnd,
        showHours: eventData.showHours,
        emailCapture: eventData.emailCapture,
        paywallSettings: eventData.paywallSettings,
        eventId: id,
        prepaidSlug: prepaidSlug,
        subscriberSlug: subscriberExists,
      });

      setEventDetails({
        theaterData: {
          active: eventData.active,
          activated: eventData.activated,
          eventStart: eventData.eventStart,
          eventEnd: eventData.eventEnd,
          showHours: eventData.showHours,
          gateStatus: gate,
          visits: visits,
          paywallData: paywallData,
          donationData: donationData,
          sponsorPrerollUrl: eventData.sponsorPrerollUrl,
          paywallSettings: eventData.paywallSettings,
          eventId: id,
          featureUrl: eventData.featureUrl,
          backgroundImage: eventData.backgroundImage,
          mobileBackgroundImage: eventData.mobileBackgroundImage,
          title: eventData.title,
          subscriptionSettings: eventData.subscriptionSettings,
          description: eventData.description,
          sponsor: {
            showSponsorPreroll: eventData.showSponsorPreroll,
            hasSponsor: eventData.hasSponsor,
            details: eventData.sponsorDetails,
            logo: eventData.sponsorLogo,
            name: eventData.sponsorName,
            prerollUrl: eventData.sponsorPrerollUrl,
            url: eventData.sponsorUrl,
          },
          trailerUrl: eventData.trailerUrl,
        },
        foyerData: {
          title: eventData.title,
          eventId: id,
          eventUrl: eventData.url, // url or featureURL
          aboutSettings: eventData.aboutSettings,
          photos: eventData.photos,
          comments: commentData.sort((a, b) => a.order - b.order),
          products: [...physicalProductData, ...digitalProductData].sort(
            (a, b) => a.order - b.order
          ),
          capability: eventData.capability,
          interactiveQa: eventData.interactiveQa,
          faqSectionTitle: eventData.faqSectionTitle,
          showForwardButton: eventData.showForwardButton,
        },
        footerData: {
          filmTeamEmail: eventData.filmTeamEmail,
        },
      });
    } catch (err) {
      console.error("Error Finding Event:", err);
      toast.error("There is no event at this URL. Redirecting...");
      setTimeout(() => {
        window.location.replace("https://www.showandtell.film/");
      }, 3000);
    }
  }, [subscriberSlug, orderSlug, url]);

  useEffect(() => {
    getEvent();
  }, [getEvent, refresh]);

  return eventDetails;
};
