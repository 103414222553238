import React, { useEffect, useMemo, forwardRef, useRef } from "react";
import {
  useExpanded,
  useFlexLayout,
  useTable,
  useRowSelect,
} from "react-table";
import * as S from "../AdminTable/_NewAdminTable.styled";
import * as config from "./AdminTable.config";
import PaginationBar from "./PaginationBar";

const headerProps = (props, { column }) => {
  return getStyles(props, column.align);
};

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "left") => [
  props,
  {
    style: {
      justifyContent: align === "right" ? "flex-end" : "flex-start",
      alignItems: "center",
      display: "flex",
    },
  },
];

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

const Table = ({
  columns,
  data,
  hiddenColumns,
  searchLabel,
  showCheckboxes,
  Drawer,
  type,
  update,
  bulkEdits,
  setBulkEdits,
  setIsBulkEditing,
  ...rest
}) => {
  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 200, // width is used for both the flex-basis and flex-grow
      maxWidth: 300, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    isExpandable,
    state: { expanded },
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: hiddenColumns,
        selectedRowIds: config.getSelectedRows(data),
      },
      ...rest,
    },
    useFlexLayout,
    useExpanded,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        {
          id: "selection",
          disableResizing: true,
          minWidth: 35,
          width: 35,
          maxWidth: 35,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div
              // prevents clicking the checkbox from navigating away from page
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    if (!setIsBulkEditing) return;
    if (selectedFlatRows?.length === 0) {
      setIsBulkEditing(false);
      setBulkEdits([]);
    }
    if (selectedFlatRows?.length > 0) {
      setIsBulkEditing(false);
      setBulkEdits(selectedFlatRows);
    }
  }, [selectedFlatRows, setBulkEdits, setIsBulkEditing]);

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, headerGroupIdx) => (
          <tr key={headerGroupIdx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, headerColIdx) => (
              <th key={headerColIdx} {...column.getHeaderProps(headerProps)}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody
      // {...getTableBodyProps()}
      >
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <React.Fragment key={i}>
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      key={i}
                      {...cell.getCellProps(cellProps)}
                      {...(isExpandable && {
                        onClick: () =>
                          cell.column.Header !== "" &&
                          i !== 0 &&
                          i !== row.cells?.length - 1 &&
                          row.toggleRowExpanded(),
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
              {expanded[row.id] && isExpandable && <Drawer values={row} />}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default ({
  type,
  data,
  edit,
  update,
  total,
  page,
  setPage,
  className,
  ...rest
}) => {
  const columns = useMemo(() => config.columns[type], [type]);
  const memoData = useMemo(() => data, [data]);
  const amount = useMemo(() => {
    if (memoData?.length < 50) {
      const amount = (page - 1) * 50 + memoData?.length;
      return amount;
    } else {
      const amount = memoData?.length * page;
      return amount;
    }
  }, [memoData, page]);

  return (
    <>
      <S.Container>
        <Table
          update={update}
          columns={columns}
          data={memoData}
          type={type}
          {...rest}
          hiddenColumns={config.hiddenColumns[type]}
        />
      </S.Container>
      {page && total && amount && (
        <PaginationBar
          amount={amount}
          total={total}
          currentPage={page}
          setCurrentPage={setPage}
        />
      )}
    </>
  );
};
