/******************************************************************
 * 💵 MODAL FOR DETERMINING AND PURCHASING A NUMBER OF CREDITS 💵 *
 * Credits are required for filmmakers to create new events      *
 ******************************************************************/

import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  ModalProvider,
  Button,
  ErrorMessage,
  Image,
  Label,
  Stripe,
  MobileScrollButton,
  TosCheckbox,
} from "components";
import { toast } from "react-toastify";
import { stripeKey } from "utils";
import { useLockBodyScroll } from "hooks";
import { CREDITS } from "api";
import * as S from "./CreditsPurchaseModal.styled";
import { useForm } from "react-hook-form";

export default ({ back, close, success }) => {
  const [cardError, setCardError] = useState();
  const [stripe, setStripe] = useState();
  const [loading, setLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState();
  const [cardExpiry, setCardExpiry] = useState();
  const [cardCVC, setCardCVC] = useState();
  const [tosAccepted, setTosAccepted] = useState(false);
  const { handleSubmit, register, errors } = useForm({
    defaultValues: {},
  });
  useLockBodyScroll();

  useEffect(() => {
    const _stripe = window.Stripe(stripeKey);
    setStripe(_stripe);
  }, []);

  const toggleTos = () => setTosAccepted(!tosAccepted);

  const validateCard = () => {
    let isValid = true;

    if (!cardNumber || cardNumber._empty || cardNumber._invalid) {
      setCardError("Please enter a valid card number");
      isValid = false;
    } else if (!cardExpiry || cardExpiry._empty || cardExpiry._invalid) {
      setCardError("Please enter your card's expiration date");
      isValid = false;
    } else if (!cardCVC || cardCVC._empty || cardCVC._invalid) {
      setCardError("Please enter your card's CVC");
      isValid = false;
    }
    return isValid;
  };

  const purchase = async ({ street, city, state, zip, name, email }) => {
    setLoading(true);
    if (validateCard()) {
      try {
        const stripeToken = await stripe.createToken(cardNumber);

        const address = `${street}, ${city}, ${state}, ${zip}`;

        const order = {
          email,
          name,
          address: address,
          credits: 1,
          total: 30000,
          token: stripeToken.token.id,
        };

        const {
          data: { data },
        } = await CREDITS.purchase(order);
        setLoading(false);
        success({ credits: data.credits });
        toast.success("Your purchase was successful", { autoClose: 6000 });
      } catch (e) {
        setLoading(false);
        toast.error("An unexpected error occurred while creating your order", {
          autoClose: 5000,
        });
      }
    } else {
      toast.error("An unexpected error occurred while creating your order", {
        autoClose: 5000,
      });
    }
  };

  return (
    <ModalProvider width="1100px" margin="64px" light show={true} close={close}>
      <S.StripeBox>
        <S.Summary direction="column" justify="space-between">
          <Flex direction="column" align="center">
            <Flex direction="column" align="flex-start">
              <S.Back pointer onClick={() => back()}>
                <img
                  alt="back-button"
                  src={require("assets/images/icon-chevron-left.svg").default}
                />
                <Text size="15px">Back</Text>
              </S.Back>
              <Text
                color="rgba(255, 255, 255)"
                size="36px"
                weight="600"
                margin="0 0 56px"
              >
                1 Virtual Event Credit
              </Text>

              <Text
                size="64px"
                weight="500"
                margin="0 0 24px"
                className="canela"
              >
                $300
              </Text>
            </Flex>
          </Flex>

          <MobileScrollButton location="card-info" />

          <Flex direction="column" align="center">
            <Flex justify="center">
              <a
                href="https://stripe.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  width="100px"
                  src={require("assets/images/powered-by-stripe.png").default}
                />
              </a>
              <Text
                color="rgba(255, 255, 255, 0.3)"
                size="12px"
                margin="0 0 0 16px"
              >
                |
              </Text>
              <a href="/" target="_blank" rel="noopener noreferrer">
                <Text
                  color="rgba(255, 255, 255, 0.3)"
                  size="12px"
                  margin="0 0 0 16px"
                >
                  Terms
                </Text>
              </a>
              <a href="/" target="_blank" rel="noopener noreferrer">
                <Text
                  color="rgba(255, 255, 255, 0.3)"
                  size="12px"
                  margin="0 0 0 16px"
                >
                  Privacy
                </Text>
              </a>
            </Flex>
          </Flex>
        </S.Summary>
        <Flex
          direction="column"
          align="flex-start"
          padding="60px"
          id="card-info"
        >
          <Text size="19px" margin="0 0 24px">
            Pay with card
          </Text>
          <Label>Card Information</Label>
          <Stripe
            stripe={stripe}
            setCardNumber={(e) => setCardNumber(e)}
            setCardExpiry={(e) => setCardExpiry(e)}
            setCardCVC={(e) => setCardCVC(e)}
          />
          {cardError && <ErrorMessage message={cardError} />}
          <form onSubmit={handleSubmit(purchase)} style={{ width: "100%" }}>
            <Label>Name on card</Label>
            <S.Input
              ref={register({ required: true })}
              name="name"
              margin="0 0 24px"
              placeholder="name"
            />
            {errors.name && <ErrorMessage message="Field Required" />}
            <Label>Street</Label>
            <S.Input
              ref={register({ required: true })}
              name="street"
              margin="0 0 24px"
              placeholder="street"
            />
            {errors.street && <ErrorMessage message="Field Required" />}
            <Label>City</Label>
            <S.Input
              ref={register({ required: true })}
              name="city"
              margin="0 0 24px -1px"
              placeholder="city"
            />
            {errors.city && <ErrorMessage message="Field Required" />}
            <Flex>
              <Flex direction="column" align="flex-start">
                <Label>State</Label>
                <S.Input
                  ref={register({ required: true })}
                  radius="6px 0px 0px 6px"
                  name="state"
                  margin="0 0 24px"
                  placeholder="state"
                />

                {errors.state && <ErrorMessage message="Field Required" />}
              </Flex>
              <Flex direction="column" align="flex-start">
                <Label>Zip</Label>
                <S.Input
                  ref={register({ required: true })}
                  radius="0px 6px 6px 0px"
                  name="zip"
                  margin="0 0 24px -1px"
                  placeholder="zip"
                />

                {errors.zip && <ErrorMessage message="Field Required" />}
              </Flex>
            </Flex>

            <Label>Email receipt to:</Label>
            <S.Input
              ref={register({ required: true })}
              name="email"
              margin="0 0 24px"
              placeholder="email"
            />
            {errors.email && <ErrorMessage message="Field Required" />}

            <TosCheckbox
              tosAccepted={tosAccepted}
              setTosAccepted={setTosAccepted}
              toggleTos={toggleTos}
              light
            />

            <Text size="14px" margin="8px 0 0">
              **Note: Please be aware this charge will appear as SHOW&#38;TELL
              on your credit card statement.**
            </Text>

            {loading ? (
              <Button margin="16px 0 64px" className="medium" width="100%">
                Submitting order...
              </Button>
            ) : (
              <Button
                as="button"
                type="submit"
                margin="16px 0 64px"
                icon={require("assets/images/icon-lock.svg").default}
                className={`medium ${!tosAccepted ? "disabled" : ""}`}
                {...(!tosAccepted && { disabled: true })}
                width="100%"
              >
                Pay $300
              </Button>
            )}
          </form>
        </Flex>
      </S.StripeBox>
    </ModalProvider>
  );
};
