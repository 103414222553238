import styled from "styled-components";
import { colors } from "consts";
import DatePicker from "react-datepicker";

export const Picker = styled(DatePicker)`
  border-radius: 2px;
  background: ${({ light }) => (light ? "white" : "#232323")};
  border: none;
  border: ${({ light }) => (light ? "1px solid #bfbfbf" : "1px solid #444444")};
  height: ${({ height }) => (height ? height : "32px")};
  box-shadow: none;
  color: ${({ light }) => (light ? colors.dark : "white")};
  padding: 0 12px;
  font-size: 14px;
  width: ${({ width }) => (width ? width : "100%")};
  margin: ${({ margin }) => margin || "0"};
  transition-duration: 0.3s;
  font-family: "Inter", sans-serif;
  :focus {
    border: 1px solid ${colors.orange};
    box-shadow: none;
    outline: none;
  }
  .react-datepicker__close-icon::after {
    background-color: ${colors.orange};
  }
`;

export const Input = styled.input`
  border-radius: ${({ radius }) => (radius ? radius : "6px")};
  background: ${({ light }) => (light ? "none" : "#101010")};
  border: none;
  border: ${({ light, error }) =>
    light
      ? error
        ? "1px solid #F1575C"
        : "1px solid #bfbfbf"
      : error
      ? "1px solid #F1575C"
      : "1px solid #525252"};
  height: ${({ height }) => (height ? height : "32px")};
  box-shadow: none;
  color: ${({ light }) => (light ? colors.dark : "white")};
  padding: 0 12px;
  font-size: 14px;
  width: ${({ width }) => (width ? width : "100%")};
  margin: ${({ margin }) => margin || "0"};
  transition-duration: 0.3s;
  height: 36px;
  :focus {
    border: 1px solid ${colors.orange};
    box-shadow: none;
    outline: none;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    color: ${({ light }) =>
      light ? colors.dark + " !important" : "none !important"};
    background-color: ${({ light }) =>
      light ? "none !important" : "none !important"};
    -webkit-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0) inset !important;
    -webkit-text-fill-color: inherit !important;
  }
`;
