import { toast } from "react-toastify";

export default (information) => {
  let _valid = true;
  const _errors = {};
  if (!information.title) {
    toast.error("Please enter a title", { autoClose: 2000 });
    _valid = false;
    _errors.title = true;
  }
  if (!information.url) {
    toast.error("Please enter a url", { autoClose: 2000 });
    _valid = false;
    _errors.url = true;
  }
  if (!information.description) {
    toast.error("Please enter a description", { autoClose: 2000 });
    _valid = false;
    _errors.description = true;
  }
  if (information.aboutSettings.message) {
    const aboutStr = information.aboutSettings.message.toLowerCase();
    const match = aboutStr.match(/venmo|cash.me|donorbox/g);
    if (match?.length) {
      toast.warn(
        `Uh oh! We noticed you’re putting in a link to an outside payment processor. On Show&Tell, we process all payments through our payment processor, Stripe. To get set up to collect and receive payments, please go to Payments & Activation.`,
        {
          position: "top-center",
          autoClose: 8000,
        }
      );
      _valid = false;
      _errors.message = true;
    }
  }
  return { valid: _valid, errors: _errors };
};
