import styled, { css } from "styled-components";
import { colors } from "consts";

const checkboxDisabled = css`
  opacity: 50%;
  cursor: default;
  pointer-events: none;
`;

export const Wrap = styled.div`
  display: inline-block;
  ${({ disabled }) => disabled && checkboxDisabled}
`;

export const Checkbox = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: ${({ dark, light }) =>
    dark
      ? `2px solid ${colors.black}`
      : light
      ? `2px solid ${colors.activeWhite}`
      : `2px solid ${colors.gray650}`};
  background: none;
  position: relative;
  overflow: hidden;
  margin-right: ${({ nomargin }) => (nomargin ? "0" : "8px")};
  cursor: pointer;
`;

export const Checked = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background: ${colors.orange500};
  position: relative;
  overflow: hidden;
  margin: 0;
  margin-right: ${({ nomargin }) => (nomargin ? "0" : "8px")};
  cursor: pointer;
`;

export const Check = styled.div`
  width: 16px;
  height: 16px;
  background: ${colors.orange500};
  display: flex;
  align-items: center;
  justify-content: center;
`;
