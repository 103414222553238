import styled from "styled-components";
import { colors } from "consts";
import { Flex } from "components";

export const Tag = styled(Flex)`
  background: ${colors.orange};
  font-size: 14px;
  color: ${colors.white};
  font-weight: bold;
  border-radius: 50px;
  line-height: 22px;
  padding: 4px 4px 4px 12px;
  margin: 4px;
  width: auto;
`;

export const TagDeleteButton = styled.div`
  color: white;
  cursor: pointer;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 4px;

  &:hover {
    background: rgba(0, 0, 0, 0.25);
  }

  &::after {
    content: "+";
    position: relative;
    bottom: 1px;
    left: 0.5px;
    transform: rotate(45deg);
  }
`;
