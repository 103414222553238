import React, { useState, useEffect } from "react";
import * as S from "./Textarea.styled";

export default (props) => {
  const { change, error, maxChar, onChange, value } = props;
  const [textValue, setTextValue] = useState("");

  const textChange = (e) => {
    if (maxChar) {
      if (e.target.value.length > maxChar) {
        e.target.value = e.target.value.substring(0, maxChar);
        setTextValue(e.target.value);
        onChange(e);
      } else {
        setTextValue(e.target.value);
        onChange(e);
      }
    } else {
      setTextValue(e.target.value);
      onChange(e);
    }
  };

  useEffect(() => {
    value && setTextValue(value);
  }, [value]);

  useEffect(() => {
    change && setTextValue(change);
  }, [change, error]);

  return <S.Input {...props} value={textValue} onChange={textChange} />;
};
