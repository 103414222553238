import styled from "styled-components";
import { colors } from "consts";

export const Input = styled.textarea`
  border-radius: 2px;
  background: ${({ light }) => (light ? "white" : "#232323")};
  border: none;
  font-family: "Inter", sans-serif;
  border: ${({ light, error }) =>
    light
      ? error
        ? "1px solid #F1575C"
        : "1px solid #bfbfbf"
      : error
      ? "1px solid #F1575C"
      : "1px solid #444444"};
  box-shadow: none;
  color: ${({ light }) => (light ? colors.dark : "white")};
  padding: 12px;
  font-size: 14px;
  width: 100%;
  resize: none;
  margin: ${({ margin }) => margin || "0"};
  transition-duration: 0.3s;
  &:focus {
    border: 1px solid ${colors.orange};
    box-shadow: none;
    outline: none;
  }
`;
