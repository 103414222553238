import axiosRequest from "./axios.config";

export const create = async (id, subscriber) =>
  await axiosRequest.post(`/v1/pages/${id}/subscribers`, subscriber);

export const getAll = async (pageId) =>
  await axiosRequest.get(`/v1/pages/${pageId}/subscribers`);

export const getByEmail = async ({ pageId, email }) =>
  await axiosRequest.post(`/v1/pages/${pageId}/subscribers/email`, email);

export const getById = async (pageId, subscriberId) =>
  await axiosRequest.get(
    `/v1/pages/${pageId}/subscribers/${subscriberId}/access`
  );

export const getCSV = async (pageId) =>
  await axiosRequest.get(`/v1/pages/${pageId}/subscribers.csv`);

export const incrementVisits = async (pageId, subscriberId) =>
  await axiosRequest.patch(
    `/v1/pages/${pageId}/subscribers/${subscriberId}/visits`
  );
