import { useQuery } from "react-query";
import axiosRequest from "../../api/axios.config";
import queryClient from "../queryClient";

const fetcher = async (searchTerm, page, stage, source, tag) => {
  let params = `?`;
  if (searchTerm) params = `${params}&search_term=${searchTerm}`;
  if (page) params = `${params}&page=${page}`;
  if (stage) params = `${params}&stage=${stage}`;
  if (source) params = `${params}&source=${source}`;
  if (tag) params = `${params}&tag=${tag}`;

  let endpoint = `/v1/admin/leadowners`;
  if (params !== "?") endpoint = `${endpoint}${params}`;

  const raw = await axiosRequest.get(endpoint);

  return raw.data.data;
};

const useGetFilteredLeadowners = (
  searchTerm,
  page,
  { stage, source, tag } // filters
) => {
  return useQuery(
    [
      "leadowners",
      "filtered",
      `searchTerm=${searchTerm}`,
      `page=${page}`,
      `stage=${stage}`,
      `source=${source}`,
      `tag=${tag}`,
    ],
    async () => {
      // TODO: improve by using axios cancel token instead of setting cacheTime to 0
      // that way if they backtrack you are not recalling the data
      const data = await fetcher(searchTerm, page, stage, source, tag);

      data.data.forEach((leadowner) => {
        queryClient.setQueryData(["leadowner", leadowner.id], leadowner);
      });

      const response = {
        total: data?.total || null, // this should be the total of the NON filtered / searched data
        data: data.data,
      };

      return response;
    },
    {
      enabled:
        !!searchTerm || (!!page && page !== 1) || !!stage || !!source || !!tag,
      cacheTime: 0,
    }
  );
};

const useGetLeadowners = () => {
  return useQuery(["leadowners"], async () => {
    const data = await fetcher();

    data.data.forEach((leadowner) => {
      queryClient.setQueryData(["leadowner", leadowner.id], leadowner);
    });

    const response = {
      total: data?.total || null, // this should be the total of the NON filtered / searched data
      data: data.data,
    };

    return response;
  });
};

export { useGetLeadowners, useGetFilteredLeadowners };
