export default {
  orange: "#D44B19",
  orange200: "#B03A12",
  orange300: "#A7310A",
  orange400: "#6C1B00",
  orange500: "#F16648",
  bgGray: "#F9F9F9",
  gray200: "#232323",
  gray8: "#ACACAC",
  gray300: "#1B1B1B",
  gray400: "#333333",
  gray500: "#2B2B2B",
  gray600: "#5E5E5E",
  gray650: "#525252",
  gray700: "#6A6A6A",
  gray750: "#B4B4B4",
  gray800: "#4B4B4B",
  gray850: "#242424",
  charcoal: "#101010",
  black: "#292929",
  red: "#EB5757",
  blue: "#00B4F7",
  activeWhite: "#C4C4C4",
  activeWhite2: "#929292",
  activeWhite3: "#434343",
  white: "#FFFFFF",
  green: "#3CD419",
};
