// imports and variables alphabetized when possible for readability
import React, { useMemo, forwardRef, useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { selectedEventsAtom } from "atoms";
import * as S from "./AdminTable.styled";
import {
  useExpanded,
  useFlexLayout,
  useTable,
  useRowSelect,
} from "react-table";
import { SelectionBar, PaginationBar } from ".";
import * as config from "./AdminTable.config";

const headerProps = (props, { column }) => {
  return getStyles(props, column.align);
};

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "left") => [
  props,
  {
    style: {
      justifyContent: align === "right" ? "flex-end" : "flex-start",
      alignItems: "center",
      display: "flex",
    },
  },
];

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

const Table = ({
  columns,
  data,
  hiddenColumns,
  searchLabel,
  showCheckboxes,
  Drawer,
  type,
  update,
  bulkEdits,
  setBulkEdits,
  setIsBulkEditing,
  ...rest
}) => {
  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 200, // width is used for both the flex-basis and flex-grow
      maxWidth: 300, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    isExpandable,
    state: { expanded },
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: hiddenColumns,
        selectedRowIds: config.getSelectedRows(data),
      },
      ...rest,
    },
    useFlexLayout,
    useExpanded,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        {
          id: "selection",
          disableResizing: true,
          minWidth: 35,
          width: 35,
          maxWidth: 35,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div
              // prevents clicking the checkbox from navigating away from page
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    if (!setIsBulkEditing) return;
    if (selectedFlatRows.length === 0) {
      setIsBulkEditing(false);
      setBulkEdits([]);
    }
    if (selectedFlatRows.length > 0) {
      setIsBulkEditing(false);
      setBulkEdits(selectedFlatRows);
    }
  }, [selectedFlatRows, setBulkEdits, setIsBulkEditing]);

  return (
    <>
      <div {...getTableProps()} className="table">
        <div className="header-row">
          {headerGroups.map((headerGroup, hgIdx) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              className="tr"
              key={`header-group-${hgIdx}`}
            >
              {headerGroup.headers.map((column, hIdx) => (
                <div
                  {...column.getHeaderProps(headerProps)}
                  className="th"
                  key={`header-${hIdx}`}
                >
                  {column.render("Header")}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="tbody">
          {rows.map((row, rowIdx) => {
            prepareRow(row);
            return (
              <React.Fragment key={`row-${rowIdx}`}>
                <div {...row.getRowProps()} className="tr">
                  {row.cells.map((cell, cellIdx) => {
                    return (
                      <div
                        {...cell.getCellProps(cellProps)}
                        className={`td${cellIdx === 1 ? " title" : ""}`}
                        key={`cell-${cellIdx}`}
                        {...(isExpandable && {
                          onClick: () =>
                            cell.column.Header !== "" &&
                            cellIdx !== 0 &&
                            cellIdx !== row.cells.length - 1 &&
                            row.toggleRowExpanded(),
                        })}
                      >
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
                {expanded[row.id] && isExpandable && <Drawer values={row} />}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ({
  type,
  data,
  edit,
  update,
  total,
  page,
  setPage,
  className,
  ...rest
}) => {
  const selectedEvents = useRecoilValue(selectedEventsAtom);
  const columns = useMemo(() => config.columns[type], [type]);
  const memoData = useMemo(() => data, [data]);
  const amount = useMemo(() => {
    if (memoData.length < 50) {
      const amount = (page - 1) * 50 + memoData.length;
      return amount;
    } else {
      const amount = memoData.length * page;
      return amount;
    }
  }, [memoData, page]);

  const handleScroll = ({ target }) => {
    target.style.setProperty("--scroll-top", target.scrollTop + "px");
  };

  return (
    <>
      {selectedEvents.length > 0 && (
        <SelectionBar edit={edit} update={update} type={type} />
      )}
      <S.TableContainer
        onScroll={handleScroll}
        {...(className && { className: className })}
      >
        <Table
          update={update}
          columns={columns}
          data={memoData}
          type={type}
          {...rest}
          hiddenColumns={config.hiddenColumns[type]}
        />
      </S.TableContainer>
      {page && total && amount && (
        <PaginationBar
          amount={amount}
          total={total}
          currentPage={page}
          setCurrentPage={setPage}
        />
      )}
    </>
  );
};
