import React, { useState, useEffect } from "react";
import { Flex, Text, Button } from "components";
// import AdminTable from "./AdminTable";
import AdminTable from "./AdminTable/_NewAdminTable";
import { AdminOrdersDrawer } from "./AdminDrawers";

// rQuery
import { useGetOrders, useGetFilteredOrders } from "rQuery/api";

export default ({ downloadCSV, searchTerm, filters }) => {
  // state
  const [total, setTotal] = useState("");
  const [page, setPage] = useState(1);
  const [isFiltering, setIsFiltering] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  // HOOKS
  const { isLoading: isLoadingOrders, data: orders } = useGetOrders();
  const {
    isLoading: isLoadingFilteredOrders,
    isFetching: isFetchingFilteredOrders,
    data: filteredOrders,
  } = useGetFilteredOrders(searchTerm, page, filters);

  // USE EFFECT
  useEffect(() => {
    let filtering = true;
    if (filters.after_watching === null && page === 1 && searchTerm === "")
      filtering = false;
    setIsFiltering(filtering);
  }, [searchTerm, page, filters]);

  useEffect(() => {
    if (!filteredOrders?.data) return;
    setFilteredData(filteredOrders.data);
  }, [filteredOrders]);

  useEffect(() => {
    if (!orders) return;
    let total = filteredOrders?.total || null;
    if (!isFiltering) total = orders.total;
    setTotal(total);
  }, [isFiltering, filteredOrders, orders]);

  return (
    <>
      <Flex width="100%" justify="flex-end" align="center" margin="0 0 16px">
        <Button className="secondary" onClick={() => downloadCSV("orders")}>
          Download Report
        </Button>
      </Flex>
      {isLoadingOrders ||
      isLoadingFilteredOrders ||
      isFetchingFilteredOrders ? (
        <Text color="white">Loading...</Text>
      ) : (!isFiltering && orders.length === 0) ||
        (isFiltering && filteredData.length === 0) ? (
        <Text color="white">No data matching your search criteria</Text>
      ) : (
        <AdminTable
          className="clickable"
          type="orders"
          data={isFiltering ? filteredData : orders.data}
          {...{ page, setPage, total }}
          isExpandable
          Drawer={AdminOrdersDrawer}
        />
      )}
    </>
  );
};
