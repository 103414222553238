import styled, { keyframes, css } from "styled-components";
import { Input } from "components";
import { media, minMedia } from "utils";
import { colors } from "consts";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1
  }
`;

export const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  overflow: auto;
  background-color: rgb(255, 255, 255, 0.6);
  animation: ${fadeIn} 1.15s linear;
`;

export const Container = styled.div(
  ({ midroll }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    ${!midroll &&
    minMedia.phonePlus`
      top: 5%;
      left: 10%;
      bottom: 5%;
      width: 80%;
      height: 90%;
    `}
  `
);

export const PictureContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
`;

export const BannerImage = styled.img(
  ({ midroll }) => css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;

    ${midroll &&
    css`
      ${media.laptop`
        position: fixed;
        top: 0;
        left: 0;
      `}
    `}
  `
);

export const Background = styled.div(
  ({ midroll }) => css`
    position: ${midroll ? "fixed" : "absolute"};
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 101;
    background-size: cover;
    background-position: center center;
    background-color: rgba(41, 41, 41, 0.7);
    ${minMedia.laptop`
    background-color: transparent;
    background-image: ${({ src }) =>
      "linear-gradient(90deg, #101010 24.17%, rgba(41, 41, 41, 0) 69.31%)" ||
      null};
    flex-direction: column;
  `}
  `
);

export const OverflowContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 100;
  background-color: ${colors.black};
`;

export const MidrollContainer = styled.div`
  position: absolute;
  top: 3%;
  left: 0;
  z-index: 102;
  padding: 20px;
  width: 100%;
  ${minMedia.tabletPlus`
    padding: 50px;
  `}
`;

export const TextContainer = styled.div`
  width: 100%;
  ${minMedia.tabletPlus`
    width: 75%;
  `}
`;

export const ControlsContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  ${minMedia.tabletPlus`
    width: 50%;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 20px;
`;

export const Option = styled.div`
  border: 1px solid ${colors.gray600};
  border-radius: 0;
  background: ${colors.activeWhite};
  color: black;
  min-width: 50px;
  height: 32px;
  width: ${({ width }) => width || "64px"};
  padding: ${({ padding }) => padding || "0"};
  justify-content: center;
  user-select: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 6px;
  cursor: pointer;
  ${({ selected }) =>
    selected &&
    `
    background: ${colors.orange};
    color: white;
  `}
  ${minMedia.laptopPlus`
    height: 32px;
    font-size: 18px;
    min-width: 64px;
    margin: 0 12px 8px 0;
  `}
`;

export const OptionInput = styled(Input)`
  background: transparent;
  border: none;
  width: 100%;
  height: 100%;
  font-family: "Inter", sans-serif;
  border: none;
  color: black;
  font-size: 14px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus {
    box-shadow: none;
    outline: none;
    border: none;
  }
  ${({ selected }) =>
    selected &&
    `
    color: white;
  `}
`;
