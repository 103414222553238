import React, { useState, useRef } from "react";
import { useOutsideClick } from "hooks";
import { Flex, Text } from "components";
import * as S from "./Select.styled";

export default ({
  light,
  options,
  selected,
  setSelected,
  placeholder,
  toLowerCase = true,
  ...rest
}) => {
  const node = useRef();
  const [open, setOpen] = useState(false);

  useOutsideClick(node, () => {
    setOpen(false);
  });

  const toggleDropdown = () => setOpen(!open);

  const selectOption = (option) => {
    setSelected(option);
    setOpen(false);
  };

  return (
    <S.Container ref={node} open={open} {...rest}>
      <S.Selector
        {...rest}
        open={open}
        onClick={() => toggleDropdown()}
        light={light}
      >
        <Flex justify="space-between">
          {selected?.name ? (
            <Text dark={light}>
              {toLowerCase ? selected?.name.toLowerCase() : selected?.name}
            </Text>
          ) : (
            <Text dark>{placeholder}</Text>
          )}
          <Text dark={light} size="8px">
            {open ? "⯅" : "⯆"}
          </Text>
        </Flex>
      </S.Selector>
      <S.OptionsContainer open={open} {...rest}>
        {options?.map((option, i) => (
          <S.Option key={i} {...rest} onClick={() => selectOption(option)}>
            {option.name}
          </S.Option>
        ))}
      </S.OptionsContainer>
    </S.Container>
  );
};
