import React, { useState } from "react";
import { Button, Flex, Image, Markdown, Player, Text } from "components";
import * as S from "./FoyerAbout.styled";
import ForwardToFriendModal from "components/Foyer/FoyerAbout/ForwardToFriendModal";

export default ({
  aboutSettings,
  contactEmail,
  photos,
  showForwardButton,
  eventId,
}) => {
  const [showForwardModal, setShowForwardModal] = useState(false);

  return (
    <Flex direction="column" align="flex-start" width="100%">
      <ForwardToFriendModal
        show={showForwardModal}
        setShow={setShowForwardModal}
        eventId={eventId}
      />
      <S.FoyerHeader>
        <Text
          responsive={{ phonePlus: { size: "35px" } }}
          size="50px"
          className="canela"
          margin="0 0 24px 0"
        >
          About
        </Text>
        <S.FoyerTabs align="center" justify="flex-end">
          {showForwardButton && (
            <Button onClick={() => setShowForwardModal(true)}>
              Forward to Friend
            </Button>
          )}
          <a href="https://support.showandtell.film/hc/en-us/categories/360004638892-EVENT-ATTENDEE">
            <Text
              responsive={{ phonePlus: { size: "16px" } }}
              size="20px"
              weight="bold"
              margin="0 32px"
            >
              Need Help?
            </Text>
          </a>
          <a href={`mailto:${contactEmail}`}>
            <Text
              responsive={{ phonePlus: { size: "16px" } }}
              size="20px"
              weight="bold"
            >
              Contact Film Team
            </Text>
          </a>
        </S.FoyerTabs>
      </S.FoyerHeader>

      <Flex direction="column" align="flex-start" width="724px">
        <Markdown
          linkTarget="_blank"
          escapeHtml={false}
          source={aboutSettings?.message}
        />

        {aboutSettings?.messageVideo && (
          <S.PlayerWrap>
            <Player
              className="foyer"
              url={`https://player.vimeo.com/video/${aboutSettings.messageVideo
                .split("/")
                .pop()}`}
              controls={true}
            />

            {aboutSettings?.caption && (
              <Text margin="18px 0 0">{aboutSettings?.caption}</Text>
            )}
          </S.PlayerWrap>
        )}
      </Flex>

      <Flex direction="column" align="flex-start" width="724px">
        <Flex
          wrap={1}
          padding="28px 0 0"
          justify="space-between"
          align="flex-start"
        >
          {photos &&
            photos.map(({ url, title }) => (
              <Flex key={url} width="48%" padding="15px 0">
                <Image width="100%" src={url} alt={title} />
              </Flex>
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
