import React from "react";
import styled from "styled-components";
import { colors } from "consts";
import { media, minMedia } from "utils";

const Text = styled.div`
  font-size: ${({ size }) => (size ? size : "16px")};
  display: block;
  color: ${({ color, dark }) =>
    color ? color + " !important" : dark ? colors.black : "white"};
  text-align: ${({ center }) => (center ? "center" : "left")};
  font-weight: ${({ weight }) => (weight ? weight : "normal")};
  padding: ${({ padding }) => (padding ? padding : 0)};
  margin: ${({ margin }) => (margin ? margin : 0)};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  font-family: "Inter", sans-serif;
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : "normal")};
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  line-height: ${({ lineheight, size }) =>
    lineheight ? lineheight : size && parseInt(size) > 30 ? "1em" : "1.4em"};
  min-width: ${({ width }) => (width ? width : "auto")};
  white-space: ${({ nowrap }) => (nowrap ? "nowrap" : "inherit")};
  cursor: ${({ pointer }) => (pointer ? "pointer" : "inherit")};
  height: ${({ height }) => (height ? height : "auto")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "auto")};
  opacity: ${({ opacity }) => opacity || null};
  &.uppercase {
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 20px;
  }
  &.canela {
    font-family: "Canela";
  }
  &.bold-italic {
    font-style: italic;
    font-weight: bold;
  }
  a {
    color: ${colors.orange};
  }

  &.bannerTitle {
    font-size: 40px;
    ${minMedia.desktopPlus`
      font-size: 64px;       
      &.shrink {
        font-size: 48px;
      }
    `}
  }

  &.banner-description {
    font-size: 20px;
    &.shrink {
      font-size: 18px;
    }
    ${minMedia.desktopPlus`
      font-size: 24px; 
      &.shrink {
        font-size: 18px;
      }
    `}
  }

  ${media.laptopPlus`
    font-size: ${({ responsive }) =>
      responsive?.laptopPlus?.size ? responsive.laptopPlus.size : ""};
    text-align: ${({ responsive }) =>
      responsive?.laptopPlus?.textAlign ? responsive.laptopPlus.textAlign : ""};
    padding: ${({ responsive }) =>
      responsive?.laptopPlus?.padding ? responsive.laptopPlus.padding : ""};
    margin: ${({ responsive }) =>
      responsive?.laptopPlus?.margin ? responsive.laptopPlus.margin : ""};
  `};
  ${media.laptop`
    font-size: ${({ responsive }) =>
      responsive?.laptop?.size ? responsive.laptop.size : ""};
    text-align: ${({ responsive }) =>
      responsive?.laptop?.textAlign ? responsive.laptop.textAlign : ""};
    padding: ${({ responsive }) =>
      responsive?.laptop?.padding ? responsive.laptop.padding : ""};
  `};
  ${media.tablet`
    font-size: ${({ responsive }) =>
      responsive?.tablet?.size ? responsive.tablet.size : ""};
    padding: ${({ responsive }) =>
      responsive?.tablet?.padding ? responsive.tablet.padding : ""};
    &.tablet-hide {
      display: none;
    }
  `};
  ${media.phonePlus`
    font-size: ${({ responsive }) =>
      responsive?.phonePlus?.size ? responsive.phonePlus.size : ""};
  `};
`;

export default ({ kind, ...props }) => <Text {...props} as={kind} />;
