import styled from "styled-components";
import { Flex } from "components";

export const Outline = styled.div`
  border: 2px solid #232323;
  padding: 40px;
  width: 100%;
  margin-bottom: 24px;
`;

export const Download = styled.div`
  cursor: pointer;
  background: rgba(196, 196, 196, 0.25);
  border-radius: 54px;
  margin: 0 0 0 8px;
  width: 56px;
  height: 30px;
  background: url(${require("assets/images/icon-download.svg").default})
    no-repeat center center rgba(196, 196, 196, 0.25);
`;

export const Stats = styled(Flex)`
  max-width: 350px;
`;
