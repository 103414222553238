import { Flex } from "components";
import styled from "styled-components";
import { media } from "utils";

export const InputWrapper = styled(Flex)`
  flex-direction: row;
  ${media.tablet`
    flex-direction: column;
  `}
`;
