import React, { useState, useEffect } from "react";
import { useAnalytics } from "use-analytics";
import {
  Header,
  BackgroundProvider,
  Flex,
  DashboardSidebar,
  DashboardFilmDetails,
  DashboardEventDetails,
  DashboardStore,
  DashboardActivation,
  DashboardQA,
  DashboardAbout,
} from "components";
import { toast } from "react-toastify";
import { PAGES, PRODUCTS } from "api";
toast.configure();

export default ({ history }) => {
  const [step, setStep] = useState("filmDetails");
  const [id, setId] = useState();
  const [screening, setScreening] = useState({
    paywallSettings: {
      price: 7.5,
      showTime: 30,
      title: "Start Watching",
      subtitle: "Purchase access to the film",
      callToAction: "Watch Now",
    },
    subscriptionSettings: {},
    aboutSettings: {},
  });
  const { page } = useAnalytics();

  useEffect(() => {
    page();
  }, [page]);

  const updateScreening = async (data) => {
    const _updated = { ...screening, ...data };
    setScreening(_updated);
  };

  const checkAndSetStep = (step) => id && setStep(step);

  const patchScreening = async (_screening) => {
    try {
      const start = new Date(_screening.eventStart).valueOf();
      const end = new Date(_screening.eventEnd).valueOf();

      if (start > end) {
        toast.error("The start date must be before the end date");
        return;
      }
      if (start === end) {
        toast.error("The start and end dates must be different");
        return;
      }

      //update any paywall and donation products
      if (_screening.paywallUpdated) {
        const _updatedSettings = await PRODUCTS.updatePaywall(_screening);
        _screening.paywallSettings = _updatedSettings;
      }
      const {
        data: { data },
      } = await PAGES.update(_screening);

      setScreening(data);
      toast("event updated successfully", { autoClose: 3000 });
    } catch (err) {
      toast("Error updating event", { autoClose: 3000 });
    }
  };

  return (
    <BackgroundProvider>
      <Header user />

      <Flex align="flex-start" margin="90px 0 0 0">
        <DashboardSidebar
          title="Create Event"
          actions={[
            {
              action: () => checkAndSetStep("filmDetails"),
              text: "Film Details",
              active: step === "filmDetails",
              hasID: true,
            },
            {
              action: () => checkAndSetStep("about"),
              text: "About",
              active: step === "about",
              hasID: id,
            },
            {
              action: () => checkAndSetStep("eventDetails"),
              text: "Event Details",
              active: step === "eventDetails",
              hasID: id,
            },
            {
              action: () => checkAndSetStep("products"),
              text: "Store",
              active: step === "products",
              hasID: id,
            },
            {
              action: () => checkAndSetStep("qa"),
              text: "FAQ",
              active: step === "qa",
              hasID: id,
            },
            {
              action: () => checkAndSetStep("activation"),
              text: "Activate & Payments",
              active: step === "activation",
              hasID: id,
            },
          ]}
          id={id}
          watch={screening.url}
        />

        {step === "filmDetails" && (
          <DashboardFilmDetails
            setStep={(e) => setStep(e)}
            screening={screening}
            id={id}
            setId={setId}
            patch={patchScreening}
            update={(e) => updateScreening(e)}
          />
        )}
        {step === "about" && (
          <DashboardAbout
            setStep={(e) => setStep(e)}
            screening={screening}
            update={(e) => updateScreening(e)}
            id={id}
            patch={patchScreening}
          />
        )}
        {step === "eventDetails" && (
          <DashboardEventDetails
            setStep={(e) => setStep(e)}
            screening={screening}
            update={(e) => updateScreening(e)}
            id={id}
            patch={patchScreening}
          />
        )}
        {step === "products" && (
          <DashboardStore
            setStep={(e) => setStep(e)}
            screening={screening}
            update={(e) => updateScreening(e)}
            id={id}
            patch={patchScreening}
          />
        )}
        {step === "qa" && (
          <DashboardQA
            setStep={(e) => setStep(e)}
            screening={screening}
            update={(e) => updateScreening(e)}
            id={id}
            patch={patchScreening}
          />
        )}
        {step === "activation" && (
          <DashboardActivation
            history={history}
            setStep={(e) => setStep(e)}
            screening={screening}
            update={(e) => updateScreening(e)}
            id={id}
            patch={patchScreening}
          />
        )}
      </Flex>
    </BackgroundProvider>
  );
};
