import React, { useEffect, useState } from "react";
import { useAnalytics } from "use-analytics";
import {
  Button,
  Header,
  BackgroundProvider,
  Flex,
  Box,
  Input,
  Label,
  Text,
} from "components";
import { USERS } from "api";
import { colors } from "consts";
import { toast } from "react-toastify";
import queryString from "querystring";

export default ({ location }) => {
  const [message] = useState(null);
  const [password, setPassword] = useState();
  const [verifyPassword, setVerifyPassword] = useState();
  const [success, setSuccess] = useState(false);
  const { page } = useAnalytics();

  useEffect(() => {
    page();
  }, [page]);

  const resetPassword = async () => {
    try {
      if (!password || password.length < 8) {
        toast.error("Password needs to be at least 8 characters", {
          autoClose: 3000,
        });
      } else if (password !== verifyPassword) {
        toast.error("Passwords do not match", { autoClose: 3000 });
      } else {
        const _params = queryString.parse(location.search);
        await USERS.resetPassword(_params["?token"], password);
        setSuccess(true);
        toast.success("Password Reset email sent successfully", {
          autoClose: 3000,
        });
      }
    } catch (err) {
      toast.error("Reset Token has expired", { autoClose: 3000 });
    }
  };

  return (
    <BackgroundProvider
      height="100vh"
      color={colors.bgGray}
      url={require("assets/images/projector.jpg").default}
    >
      <Header showBackButton={false} />

      <Flex justify="center" height="95vh">
        <Box light width="465px" margin="48px 0">
          <Flex direction="column" align="flex-start">
            {success ? (
              <>
                <Flex justify="center">
                  <Text
                    center
                    dark
                    size="32px"
                    lineheight="36px"
                    margin="0 0 45px"
                  >
                    Password Reset Succesfully
                  </Text>
                </Flex>

                <Flex justify="center">
                  <a href="/login">
                    <Button>Login</Button>
                  </a>
                </Flex>
              </>
            ) : (
              <>
                <Flex justify="center">
                  <Text dark size="32px" lineheight="36px" margin="0 0 45px">
                    Reset Password
                  </Text>
                </Flex>

                <Label color={colors.black}>New Password</Label>
                <Input
                  light
                  margin="0 0 16px"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="password"
                />

                <Label color={colors.black}>Verify Password</Label>
                <Input
                  light
                  margin="0 0 83px"
                  type="password"
                  value={verifyPassword}
                  onChange={(e) => setVerifyPassword(e.target.value)}
                  placeholder="Verify Password"
                />

                <Flex justify="center">
                  {message && (
                    <Text dark margin="-32px 0 12px" color={colors.red}>
                      {message}
                    </Text>
                  )}
                </Flex>

                <Flex justify="flex-end">
                  <Button className="" onClick={() => resetPassword()}>
                    Reset Password
                  </Button>
                </Flex>
              </>
            )}
          </Flex>
        </Box>
      </Flex>
    </BackgroundProvider>
  );
};
