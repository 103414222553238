import React, { useEffect, useState } from "react";
import { Flex, Text, Button } from "components";
import { domain } from "utils";
import { useHistory, useParams } from "react-router-dom";
import * as S from "./DashboardSidebar.styled";
import { ACCOUNT } from "api";
import { BsArrowLeftCircle } from "react-icons/bs";

export default ({ id, title, actions, watch }) => {
  let { push } = useHistory();
  const { customerId } = useParams();
  const [account, setAccount] = useState();

  useEffect(() => {
    (async () => {
      if (customerId) {
        const { data } = await ACCOUNT.getById(customerId);
        setAccount(data.data);
      }
    })();
  }, [id, customerId]);

  return (
    <>
      <S.Spacer />
      <S.Sidebar>
        {customerId && (
          <S.Banner>
            <Text size="16px" weight="bold" center>
              superadmin mode
            </Text>
            <Text size="12px" padding="6px 0" fontStyle="italic" center>
              On behalf of:
            </Text>
            <Text size="16px" weight="bold" center>
              {account?.name}
            </Text>
            <Text size="10px" center>
              {account?.email}
            </Text>
            <Text size="10px" center padding="0 0 16px">
              {account?.id}
            </Text>
            <S.BabyButton
              onClick={() => push(`/admin/customer-details/${customerId}`)}
            >
              <Flex width="100%" justify="center">
                <Text color="white" lineheight="1">
                  <BsArrowLeftCircle />{" "}
                </Text>
                <Text
                  margin="0 0 0 12px"
                  size="14px"
                  weight="bold"
                  lineheight="1.8"
                  center
                  color="white"
                >
                  return to this account
                </Text>
              </Flex>
            </S.BabyButton>
          </S.Banner>
        )}

        {title && (
          <Flex padding="21px 31px">
            <Text weight="bold">{title}</Text>
          </Flex>
        )}

        {actions &&
          actions.map(({ active, hasID, action, text }) => {
            return (
              <S.Action
                active={active}
                hasID={hasID}
                key={text}
                onClick={() => action()}
              >
                {text}
              </S.Action>
            );
          })}

        {id && watch && (
          <S.Watch justify="center">
            <a
              href={`${domain}/${customerId ? "watch" : "preview"}/${watch}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button width="200px" className="outline-white">
                Preview
              </Button>
            </a>
          </S.Watch>
        )}
      </S.Sidebar>
    </>
  );
};
