import React, { useEffect, useState } from "react";
import * as S from "./Checkbox.styled";

export default ({ selected, checked, nomargin, dark, light, ...props }) => {
  const [checkSelected, setCheckSelected] = useState(selected);

  useEffect(() => {
    setCheckSelected(checked);
  }, [checked]);

  return (
    <S.Wrap {...props}>
      {checkSelected ? (
        <S.Checked nomargin={nomargin}>
          <S.Check>
            <img
              src={require("assets/images/icon-check.svg").default}
              alt="checkbox"
              height="10"
            />
          </S.Check>
        </S.Checked>
      ) : (
        <S.Checkbox nomargin={nomargin} dark={dark} light={light} />
      )}
    </S.Wrap>
  );
};
