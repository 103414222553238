import styled from "styled-components";
import { colors } from "consts";

// MAIN TABLE STYLES
export const Container = styled.div`
  label: TABLE-WRAPPER;
  position: relative;
  overflow: auto;
  width: 100%;

  background-color: ${colors.WHITE};
  box-shadow: 0 4px 4px -2px rgba(0, 20, 30, 0.2);
  border-radius: 4px;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.gray500};
    border-radius: 0px 8px 8px 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.gray600};
    border-radius: 8px;
  }

  &.clickable: {
    .td {
      cursor: pointer;
    }
  }

  table {
    border-spacing: 0;

    width: 100%;
    margin: auto;

    thead {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
      background: ${colors.gray850};
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      border-bottom: 1px solid ${colors.activeWhite3};
    }

    td {
      color: ${colors.gray8};
      background: ${colors.gray850};
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th,
    .title {
      color: ${colors.white};
      font-size: 14px;
      font-weight: bold;
    }

    th,
    td {
      margin: 0;
      padding: 24px;
      width: calc(calc(100% - 128px) / 4);
      max-width: 400px;
      min-width: 30px;
      height: 64px;
      :first-child {
        width: 64px;
      }
      :last-child {
        width: 64px;
        padding: 8px;
        overflow: visible;
      }
    }
  }
`;
