import styled from "styled-components";
import { colors } from "consts";
import { Flex } from "components";

export const Prompt = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 11;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.gray500};
    border-radius: 0px 8px 8px 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.gray600};
    border-radius: 8px;
  }
`;

export const Box = styled(Flex)`
  background: ${colors.gray200};
  padding: 54px;
  position: relative;
  z-index: 12;
  width: ${({ width }) => (width ? width : "100%")};
  max-width: 750px;
  height: 80%;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.gray500};
    border-radius: 0px 8px 8px 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.gray600};
    border-radius: 8px;
  }
`;

export const ExitIcon = styled.div`
  height: 28px;
  width: 28px;
  border: 2px solid white;
  border-radius: 50%;
  line-height: 1;
  color: white;
  font-weight: 600;
  position: absolute;
  right: 24px;
  top: 24px;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
`;

export const TextArea = styled.textarea`
  background: transparent;
  border: 1px solid ${colors.activeWhite3};
  border-radius: 8px;
  resize: none;
  overflow: auto;
  color: ${colors.white};
  font-family: "Inter", sans-serif;
  padding: 20px;
  margin: 0 0 20px 0;
  min-height: 150px;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.gray500};
    border-radius: 0px 8px 8px 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.gray600};
    border-radius: 8px;
  }
`;
