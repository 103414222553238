import React from "react";
import * as S from "./MobileScrollButton.styled";

export default ({ location }) => (
  <a href={`#${location}`}>
    <S.ScrollButton
      src={require("assets/images/icon-arrow-down.svg").default}
    />
  </a>
);
