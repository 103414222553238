import styled, { keyframes } from "styled-components";
import ReactPlayer from "react-player";
import { Flex } from "components";

export const animatePlayerIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1 ;}
`;

export const Wrap = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: black;
  &.foyer {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 56%;
    z-index: 10;
    background: black;
  }
`;

export const Player = styled(ReactPlayer)`
  width: 100%;
  height: 100%;
  opacity: 1;
  // animation: ${animatePlayerIn} forwards;
  // animation-duration: 2s;
  // animation-delay: 1s;
  &.foyer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 1;
    // animation: ${animatePlayerIn} forwards;
    // animation-duration: 2s;
    // animation-delay: 1s;
  }
`;

export const Loading = styled(Flex)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: black;
`;

export const Log = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(16px);
  padding: 15px;
  border-radius: 8px;
  z-index: 999;
  max-width: 400px;
  max-height: 400px;
  overflow-x: auto;
`;
