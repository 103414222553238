import { useEffect } from "react";
import { TAGS } from "api";

export default ({ pageId, setGlobalTags, existingEventTags, setEventTags }) => {
  const getGlobalTags = async () => {
    try {
      // get the global tags from the database
      const {
        data: { data: _globalTags },
      } = await TAGS.getTags("", pageId, {
        global: true,
        type: "page",
      });

      // set the global tags
      setGlobalTags(_globalTags || []);
    } catch (err) {
      console.error("Failed to load tags");
    }
  };

  useEffect(() => {
    getGlobalTags();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setEventTags(existingEventTags);
    // eslint-disable-next-line
  }, [existingEventTags]);
};
