import styled from "styled-components";
import { colors } from "consts";
import { Flex, Text } from "components";

export const ListContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background: ${colors.gray850};
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.gray500};
    border-radius: 0px 8px 8px 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.gray600};
    border-radius: 8px;
  }
`;

export const CreditsHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.activeWhite3};
  padding: 20px 0;
  display: grid;
  grid-template-columns: 30% 40% 30%;
`;

export const OrdersHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.activeWhite3};
  padding: 20px 0;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
`;

export const NotesHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.activeWhite3};
  padding: 20px 0;
  display: grid;
  grid-template-columns: 25% 75%;
`;

export const LogContainer = styled.div`
  width: 100%;
  display: grid;
  padding: 16px 0;
  grid-template-columns: 30% 40% 30%;
  border-bottom: ${({ divider }) =>
    divider && `1px solid ${colors.activeWhite3}`};
  .log-data {
    align-self: center;
  }
`;

export const OrderContainer = styled.div`
  width: 100%;
  display: grid;
  padding: 16px 0;
  grid-template-columns: 25% 25% 25% 25%;
  border-bottom: ${({ divider }) =>
    divider && `1px solid ${colors.activeWhite3}`};
  .order-data {
    align-self: center;
  }
`;

export const NotesContainer = styled.div`
  width: 100%;
  display: grid;
  padding: 16px 0;
  cursor: pointer;
  grid-template-columns: 25% 75%;
  border-bottom: ${({ divider }) =>
    divider && `1px solid ${colors.activeWhite3}`};
  :hover {
    background: ${colors.gray300};
  }
  .note-data {
    align-self: center;
  }
`;

export const DetailContainer = styled(Flex)`
  width: 50%;
  padding: 0 16px 0 0;
  flex-direction: column;
  margin: "32px 0";
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ActiveSwitch = styled(Flex)`
  background: ${({ active }) => (active ? colors.gray600 : "transparent")};
  border: 1px solid ${colors.gray600};
  border-radius: 2px 0px 0px 2px;
  color: ${colors.white};
  font-size: 14px;
  line-height: 24px;
  padding: 7px 16px;
  cursor: pointer;
  max-width: 100px;
  justify-content: center;
  align-items: center;
`;

export const ArchivedSwitch = styled(Flex)`
  background: ${({ archived }) => (archived ? colors.gray600 : "transparent")};
  border: 1px solid ${colors.gray600};
  border-radius: 0px 2px 2px 0px;
  color: ${colors.white};
  font-size: 14px;
  line-height: 24px;
  padding: 7px 16px;
  cursor: pointer;
  max-width: 100px;
  justify-content: center;
  align-items: center;
`;

export const TextArea = styled.textarea`
  background: transparent;
  border: 1px solid ${colors.activeWhite3};
  border-radius: 8px;
  resize: none;
  overflow: auto;
  color: ${colors.white};
  font-family: "Inter", sans-serif;
  padding: 20px;
  margin: 0 0 20px 0;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.gray500};
    border-radius: 0px 8px 8px 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.gray600};
    border-radius: 8px;
  }
`;

export const NoteText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`;
