import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Text, Button } from "components";
import { domain } from "utils";
import { colors } from "consts";
import { format } from "date-fns";
import * as S from "./AdminEventDetails.styled";

export default ({ eventDetails }) => {
  const history = useHistory();
  const {
    title,
    eventStart,
    eventEnd,
    url,
    filmTeamEmail,
    id,
    updatedAt,
    accountId,
    email,
    emailCapture,
    createdAt,
    lastName,
    description,
    connectId,
  } = eventDetails;

  const edit = (eventId) => {
    const location = {
      pathname: `/event/${accountId}/${eventId}`,
    };
    history.push(location);
  };

  return (
    <S.ListContainer maxHeight="450px">
      <Flex justify="space-between" align="center" margin="0 0 24px 0">
        <Text size="24px" weight="bold">
          Details
        </Text>
        <Button className="secondary" onClick={() => edit(id)}>
          Edit
        </Button>
      </Flex>
      <Flex width="100%" margin="0 0 32px 0">
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Title
          </Text>
          <Text size="14px">{title}</Text>
        </S.DetailContainer>
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Event end
          </Text>
          <Text size="14px">
            {eventEnd ? format(new Date(eventEnd), "Pp") : "Not specified"}
          </Text>
        </S.DetailContainer>
      </Flex>
      <Flex width="100%" margin="0 0 32px 0">
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            URL
          </Text>
          <Text size="14px">{`${domain}/watch/${url}`}</Text>
        </S.DetailContainer>
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Event start
          </Text>
          <Text size="14px">
            {eventStart ? format(new Date(eventStart), "Pp") : "Not specified"}
          </Text>
        </S.DetailContainer>
      </Flex>
      <Flex width="100%" margin="0 0 32px 0">
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Film team email
          </Text>
          <Text size="14px">{filmTeamEmail || "N/A"}</Text>
        </S.DetailContainer>
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Page ID
          </Text>
          <Text size="14px">{id || "N/A"}</Text>
        </S.DetailContainer>
      </Flex>
      <Flex width="100%" margin="0 0 32px 0">
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Last updated
          </Text>
          <Text size="14px">
            {updatedAt ? format(new Date(updatedAt), "Pp") : "N/A"}
          </Text>
        </S.DetailContainer>
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Account email
          </Text>
          <Text size="14px">{email || "N/A"}</Text>
        </S.DetailContainer>
      </Flex>
      <Flex width="100%" margin="0 0 32px 0">
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Description
          </Text>
          <Text size="14px">{description}</Text>
        </S.DetailContainer>
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Account last name
          </Text>
          <Text size="14px">{lastName || "N/A"}</Text>
        </S.DetailContainer>
      </Flex>
      <Flex width="100%" margin="0 0 32px 0">
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Date created
          </Text>
          <Text size="14px">
            {createdAt ? format(new Date(createdAt), "Pp") : "N/A"}
          </Text>
        </S.DetailContainer>
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Customer ID
          </Text>
          <Text size="14px">{accountId || "N/A"}</Text>
        </S.DetailContainer>
      </Flex>
      <Flex width="100%">
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Is email capture
          </Text>
          <Text size="14px">{emailCapture ? "Yes" : "No"}</Text>
        </S.DetailContainer>
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Stripe account
          </Text>
          {connectId ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://dashboard.stripe.com/${connectId}/payments`}
            >
              <Text size="14px" color={colors.white}>
                Open in dashboard
              </Text>
            </a>
          ) : (
            <Text size="14px">N/A</Text>
          )}
        </S.DetailContainer>
      </Flex>
    </S.ListContainer>
  );
};
