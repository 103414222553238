import React from "react";
import { Button, Flex, Text } from "components";
import moment from "moment";

export default ({ eventStart, state, type, live, next }) => (
  <>
    {state === "payment success" && (
      <Flex direction="column" justify="center" align="center">
        <Text
          dark
          className="canela"
          size="64px"
          padding="0 0 74px"
          responsive={{ laptop: { size: "30px" } }}
        >
          Thank You {type === "donationOnly" && "For Your Donation"}!
        </Text>
        {!live && (
          <Text center dark size="28px" padding="0 0 74px">
            Your purchase was successful. <br /> You will be emailed a link to
            the film <br /> which will be available on <br />{" "}
            {moment(eventStart).format("MMMM Do, YYYY")}
          </Text>
        )}
        {live && (
          <>
            <Button
              onClick={() => next()}
              padding="19px 30px"
              margin="40px 21px 0 0"
              height="auto"
              className="outline"
            >
              <Text size="18px" padding="0 18px 0 0" color="black">
                Watch Video
              </Text>
            </Button>
          </>
        )}
      </Flex>
    )}

    {state === "coming soon" && (
      <Flex direction="column" justify="center" align="center">
        <Text
          dark
          className="canela"
          size="64px"
          padding="0 0 74px"
          responsive={{ laptop: { size: "30px" } }}
        >
          Thank you for your purchase!
        </Text>
        <Text center dark size="28px" padding="0 0 74px">
          The film will be available on <br />{" "}
          {moment(eventStart).format("MMMM Do, YYYY")}
        </Text>
      </Flex>
    )}
  </>
);
