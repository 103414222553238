import React, { forwardRef } from "react";
import { Flex } from "components";
import styled from "styled-components";
import { colors } from "consts";

const ProductCard = styled(Flex)`
  background: ${({ background = colors.green }) => background};
  flex-direction: column;
  align-items: center;
  padding: 8px;
  width: 150px;
  ${({ edit }) =>
    edit &&
    `
    cursor: pointer;
  `}
  img {
    width: 100%;
    object-fit: contain;
  }
`;

const ProductItem = forwardRef(
  ({ id, url, children, edit, activeProduct, ...props }, ref) => {
    return (
      <div {...props} ref={ref}>
        {!id && children}
        {id && (
          <ProductCard
            id={id}
            edit={edit}
            column
            align="flex-start"
            justify="flex-end"
            background={
              activeProduct && activeProduct.id === id
                ? colors.orange
                : colors.gray850
            }
          >
            <img src={activeProduct.url} alt="active_prod" />
          </ProductCard>
        )}
      </div>
    );
  }
);

ProductItem.displayName = "ProductItem";

export default ProductItem;
