import styled from "styled-components";
import { Flex } from "components";
import { media } from "utils";

export const PlayerWrap = styled.div`
  padding: 15px;
  background: #3c3c3c;
  width: 100%;
`;

export const FoyerHeader = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
`;

export const FoyerTabs = styled(Flex)`
  flex-direction: row;
  ${media.tabletPlus`
    gap: 20px;
    flex-direction: column;
    align-items: end;
  `}
`;
