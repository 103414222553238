import styled from "styled-components";
import { minMedia } from "utils";
import { Image } from "components";

export const ScrollButton = styled(Image)`
  display: flex;
  ${minMedia.laptop`
    display: none;
    `}
`;
