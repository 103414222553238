import React, { useState } from "react";
import { colors } from "consts";
import { placeboSave } from "utils";
import { Flex, Text, Button } from "components";
import { GlobalTagCheckboxes, EventSpecificTagsList } from "./";
import {
  useGetTags,
  setTags,
  useUpdateCheckboxes,
  determineTagLimit,
} from "./";

export default ({ pageId, existingEventTags }) => {
  const [globalTags, setGlobalTags] = useState();
  const [eventTags, setEventTags] = useState();
  const [commonTags, setCommonTags] = useState({});
  const [newTag, setNewTag] = useState("");

  // populates the global and event tags
  useGetTags({ pageId, setGlobalTags, existingEventTags, setEventTags });

  // determines which checkboxes in the global events should be checked
  useUpdateCheckboxes({ eventTags, globalTags, setCommonTags });

  // methods for creating and removing a tag
  const { createTag, removeTag } = setTags({
    pageId,
    eventTags,
    setNewTag,
    setEventTags,
  });

  // determine how many tags the user is allowed to enter
  const { tagsAvailable, isAtTagLimit } = determineTagLimit(eventTags);

  return eventTags ? (
    <Flex
      direction="column"
      align="flex-start"
      outline
      padding="23px 32px"
      margin="0 0 28px"
    >
      <Text size="24px" margin="0 0 20px">
        Topic of your film
      </Text>
      <Text size="14px" margin="0 0 20px 0" color={colors.gray8}>
        Using the list below, please select up to three topics that best
        describe your film.
      </Text>
      <Text size="20px" margin="0 0 20px">
        Topics remaining: {tagsAvailable}
      </Text>

      <EventSpecificTagsList {...{ eventTags, removeTag }} />
      <GlobalTagCheckboxes
        {...{
          newTag,
          globalTags,
          commonTags,
          createTag,
          removeTag,
          eventTags,
          isAtTagLimit,
        }}
      />
      <Flex justify="flex-end" margin="24px 0 0">
        <Button onClick={placeboSave}>Save</Button>
      </Flex>
    </Flex>
  ) : (
    <></>
  );
};
