import React, { useState } from "react";
import {
  Flex,
  Image,
  Text,
  Input,
  Button,
  TosCheckbox,
  MobileFlex,
} from "components";
import { SUBSCRIBERS } from "api";
import { toast } from "react-toastify";
toast.configure();

export default ({
  subscriptionSettings,
  eventId,
  registerar,
  setRegisterar,
  next,
  eventReminderOptOut,
}) => {
  const [errors, setErrors] = useState({
    email: false,
    name: false,
    organization: false,
    country: false,
    zip: false,
  });
  const [reloadErrors, setReloadErrors] = useState(false);
  const [tosAccepted, setTosAccepted] = useState(false);

  const toggleTos = () => setTosAccepted(!tosAccepted);

  const updateRegisterar = (e) => {
    registerar[e.target.name] = e.target.value;
    setRegisterar(registerar);
  };

  const register = async () => {
    try {
      let _ready = true;
      const _errors = {};
      if (!registerar.email) {
        toast.error("Please enter an email", { autoClose: 3000 });
        _errors.email = true;
        _ready = false;
      }
      if (!registerar.name) {
        toast.error("Please enter a name", { autoClose: 3000 });
        _errors.name = true;
        _ready = false;
      }
      if (
        subscriptionSettings.captureOrganization &&
        !registerar.organization
      ) {
        toast.error("Please enter a organization", { autoClose: 3000 });
        _errors.organization = true;
        _ready = false;
      }
      if (subscriptionSettings.captureCountry && !registerar.country) {
        toast.error("Please enter a country", { autoClose: 3000 });
        _errors.country = true;
        _ready = false;
      }
      if (subscriptionSettings.captureZip && !registerar.zip) {
        toast.error("Please enter a zip", { autoClose: 3000 });
        _errors.zip = true;
        _ready = false;
      }
      setErrors(_errors);
      setReloadErrors(!reloadErrors);

      registerar.eventReminderOptOut = eventReminderOptOut || false;

      if (_ready) {
        const {
          data: { data },
        } = await SUBSCRIBERS.create(eventId, registerar);
        window.localStorage.setItem(`subscriber-${eventId}`, data);
        window.localStorage.setItem(`subscriberId-${eventId}`, data.id);
        window.localStorage.setItem("isSubscribedTo", eventId);

        toast.success("Registration successful", { autoClose: 3000 });
        next();
      }
    } catch (err) {
      // FIXME: REGISTRATION NOT SUCCESSFULL
      window.localStorage.setItem(`subscriber-${eventId}`, "{}");
      toast.error("Please enter a valid email.", { autoClose: 3000 });
    }
  };

  return (
    <>
      <Flex
        align="center"
        justify="center"
        responsive={{
          tablet: { padding: "50px" },
          laptop: { direction: "column" },
        }}
      >
        <Flex direction="column" align="flex-start" width="600px">
          <Text
            dark
            size="28px"
            margin="0 0 24px"
            responsive={{ laptop: { size: "18px" } }}
          >
            Register
          </Text>
          <Text
            dark
            className="canela"
            size="64px"
            margin="0 50px 0 0"
            responsive={{ laptop: { size: "30px", padding: "0 0 24px 0" } }}
          >
            Please share some basic info to continue
          </Text>
        </Flex>

        <Flex direction="column" width="600px">
          <Flex justify="space-between" margin="0 0 12px" wrap={1}>
            <Flex
              padding="0 24px 0 0"
              margin="12px 0"
              direction="column"
              align="flex-start"
              width="50%"
              responsive={{ tablet: { width: "100%", padding: "0" } }}
            >
              <Text dark>Email Address</Text>
              <Input
                error={errors.email}
                reload={reloadErrors}
                name="email"
                light
                onChange={updateRegisterar}
                value={registerar.email}
              />
            </Flex>

            <Flex
              direction="column"
              margin="12px 0"
              align="flex-start"
              width="50%"
              responsive={{ tablet: { width: "100%", padding: "0" } }}
            >
              <Text dark>Full Name</Text>
              <Input
                error={errors.name}
                reload={reloadErrors}
                name="name"
                light
                onChange={updateRegisterar}
                value={registerar.name}
              />
            </Flex>
          </Flex>

          {subscriptionSettings.captureOrganization && (
            <Flex margin="0 0 24px" direction="column" align="flex-start">
              <Text dark>Organization</Text>
              <Input
                error={errors.organization}
                reload={reloadErrors}
                name="organization"
                light
                onChange={updateRegisterar}
                value={registerar.organization}
              />
            </Flex>
          )}

          <Flex justify="space-between" margin="0 0 12px" wrap={1}>
            {subscriptionSettings.captureCountry && (
              <Flex
                padding="0 24px 0 0"
                margin="12px 0"
                direction="column"
                align="flex-start"
                width={subscriptionSettings.captureZip ? "50%" : "100%"}
                responsive={{ tablet: { width: "100%", padding: "0" } }}
              >
                <Text dark>Country</Text>
                <Input
                  error={errors.country}
                  reload={reloadErrors}
                  name="country"
                  light
                  onChange={updateRegisterar}
                  value={registerar.country}
                />
              </Flex>
            )}

            {subscriptionSettings.captureZip && (
              <Flex
                direction="column"
                margin="12px 0"
                align="flex-start"
                width={subscriptionSettings.captureCountry ? "50%" : "100%"}
                responsive={{ tablet: { width: "100%", padding: "0" } }}
              >
                <Text dark>Zip</Text>
                <Input
                  error={errors.zip}
                  reload={reloadErrors}
                  name="zip"
                  light
                  onChange={updateRegisterar}
                  value={registerar.zip}
                />
              </Flex>
            )}
          </Flex>
          <MobileFlex justify="flex-start">
            <TosCheckbox
              tosAccepted={tosAccepted}
              setTosAccepted={setTosAccepted}
              toggleTos={toggleTos}
              dark
              margin="0 0 24px"
            />
            <Button
              className={`large laptop-margin ${
                !tosAccepted ? "disabled" : ""
              }`}
              onClick={() => tosAccepted && register()}
            >
              Register
              <Image
                margin="0 0 0 16px"
                width="28px"
                src={require("assets/images/icon-arrow-right.svg").default}
              />
            </Button>
          </MobileFlex>
        </Flex>
      </Flex>
    </>
  );
};
