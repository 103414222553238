import axiosRequest from "./axios.config";

export const getAllCSV = async () => await axiosRequest.get("/v1/pages.csv");

export const getAll = async (
  searchTerm = "",
  page = "",
  {
    activated = false,
    archived = false,
    superarchived = false,
    startEventRange_start = false,
    startEventRange_end = false,
    tag = "",
  }
) => {
  const queryString = `/v1/admin/pages?search_term=${searchTerm}&page=${page}&tag=${tag}${
    activated ? "&activated=true" : ""
  }${archived ? "&archived=true" : ""}${
    superarchived ? "&superarchived=true" : ""
  }${
    startEventRange_start
      ? `&startEventRange_start=${startEventRange_start.toISOString()}`
      : ""
  }${
    startEventRange_end
      ? `&startEventRange_end=${startEventRange_end.toISOString()}`
      : ""
  }`;
  return await axiosRequest.get(queryString);
};
