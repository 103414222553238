import styled from "styled-components";
import { Flex } from "components";
import { media } from "utils";

export const Wrapper = styled(Flex)`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
`;

export const Tab = styled.div`
  padding: 0 0 25px 0;
  width: 175px;
  margin-right: 70px;
  transition-duration: 0.3s;
  border-bottom: ${({ active }) =>
    active ? "8px solid #D44B19" : "8px solid rgba(0,0,0,0)"};
  cursor: pointer;
  &:last-of-type {
    margin-right: 0;
  }
  ${media.tablet`
    width: 25%;
    margin-right: 0px;
    padding: 0 0 15px 0;
    border-bottom: ${({ active }) =>
      active ? "4px solid #D44B19" : "4px solid rgba(0,0,0,0)"};
    div {
      text-align: center !important;
    }
`}
`;
