import { TAGS } from "api";
import remove from "lodash/remove";

export default ({ pageId, eventTags, setNewTag, setEventTags }) => ({
  createTag: async (name) => {
    // determine if the tag name has already been used or not
    const nameTaken = eventTags.find((tag) => tag.name === name);

    if (!nameTaken) {
      // post a new tag to the database
      const {
        data: { data: createdTag },
      } = await TAGS.postPageTag(pageId, name, false);

      // add the newly created tag to the eventTags array
      setEventTags([...eventTags, createdTag]);

      // clear the new tag input
      setNewTag("");
    } else {
      // give the user a warning if the tag has already been created
      alert("tag already taken");
    }
  },

  removeTag: async (tagId) => {
    // finds the tag to remove, and deletes it from the eventTags array
    const _eventTags = [...eventTags];
    remove(_eventTags, (tags) => tags.id === tagId);

    // deletes it from the database
    await TAGS.removePageTag(pageId, tagId);

    // updates the tags
    setEventTags(_eventTags);
  },
});
