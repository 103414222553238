import React, { useEffect, useState } from "react";
import { TheaterModalContainer } from "./";
import {
  RegisterModal,
  StripeModal,
  DonationSelectModal,
  PrepayModal,
  ThankYouModal,
  PaywallModal,
  EmailReminderOptOutModal,
} from "components";

export default ({
  eventId,
  paywallSettings,
  title,
  subscriptionSettings,
  modalType,
  setModal,
  eventStart,
  playVideo,
  gateStatus,
  setActiveVideo,
  setRefresh,
  theaterCtaSenario,
}) => {
  const defaultDonations = {
    // Same change to default donations
    0: 0,
    1: 10,
    2: 20,
    3: 50,
    4: 100,
    Custom: "",
  };
  const [registerar, setRegisterar] = useState({});
  const [donationAmount, setDonationAmount] = useState(
    parseInt(paywallSettings?.donationSuggestion1) || defaultDonations[1]
  );
  const [activeModals, setActiveModals] = useState([]);
  const [modalStep, setModalStep] = useState(1);
  const [eventReminderOptOut, setEventReminderOptOut] = useState(false);

  useEffect(() => {
    // Change here to ternery check because 0 is falsey, so having it check
    // !donationAmount would return true and it would default to $10 when
    // None was selected (donationAmount === 0)
    if (typeof donationAmount !== "number") {
      if (donationAmount === "") {
        // This is for a custom donation where no number is given
        setDonationAmount(0);
      } else if (typeof donationAmount === "string") {
        // This is for a custom donation where a number is given since
        // it's being captured as a string.
        setDonationAmount(parseInt(donationAmount));
      } else {
        // This is for all other edgecases.
        setDonationAmount();
      }
    }
    setActiveModals([...activeModals, modalType]);
    // eslint-disable-next-line
  }, [paywallSettings, modalType]);

  const registerValues = {
    registerar,
    setRegisterar,
    subscriptionSettings,
    eventId,
    eventReminderOptOut,
  };

  const paywallValues = {
    eventId,
    paywallSettings,
    registerar,
    setRegisterar,
    donationAmount,
    setDonationAmount,
    title,
    modalType,
    setModal,
    eventStart,
    playVideo,
    gateStatus,
    eventReminderOptOut,
    complete: () => setRefresh(new Date()),
  };

  const close = () => {
    setModal();
    setActiveVideo();
  };

  const back = () => {
    activeModals.pop();
    setModal(activeModals[0]);
  };

  return (
    <TheaterModalContainer
      close={close}
      hideClose={modalType === "Paywall"}
      modalType={modalType}
    >
      {modalType === "new-emailCapture-donations-live" && (
        <PrepayModal
          {...paywallValues}
          close={() => {
            setModal();
          }}
          next={(donation) => {
            return donation
              ? setModal("Paywall")
              : (setRefresh(new Date()), setModal(), playVideo());
          }}
        />
      )}

      {modalType === "new-emailCapture-donations-notLive" && (
        <>
          {modalStep === 1 && (
            <EmailReminderOptOutModal
              {...{ eventReminderOptOut, setEventReminderOptOut }}
              action={() => setModalStep(2)}
            />
          )}
          {modalStep === 2 && (
            <PrepayModal
              {...paywallValues}
              close={() => {
                setModal();
              }}
              back={back}
              // If there is a donation go to pay modal, else complete registration
              next={(donation) => {
                return donation
                  ? setModal("Paywall")
                  : (setRefresh(new Date()), setModal());
              }}
            />
          )}
        </>
      )}

      {modalType === "new-emailCapture-notLive" && (
        <>
          {modalStep === 1 && (
            <EmailReminderOptOutModal
              {...{ eventReminderOptOut, setEventReminderOptOut }}
              action={() => setModalStep(2)}
            />
          )}
          {modalStep === 2 && (
            <RegisterModal
              {...registerValues}
              close={() => {
                setModal();
              }}
              next={() => {
                setRefresh(new Date());
                setModal();
              }}
            />
          )}
        </>
      )}

      {modalType === "Register" && (
        <>
          {modalStep === 1 &&
            ![
              "new-emailCapture-live",
              "new-emailCapture-live",
              "new-donationOnly-donations-live",
            ].includes(theaterCtaSenario) && (
              <EmailReminderOptOutModal
                {...{ eventReminderOptOut, setEventReminderOptOut }}
                action={() => setModalStep(2)}
              />
            )}
          {(modalStep === 2 ||
            [
              "new-emailCapture-live",
              "new-emailCapture-live",
              "new-donationOnly-donations-live",
            ].includes(theaterCtaSenario)) && (
            <RegisterModal
              {...registerValues}
              close={() => {
                setModal();
              }}
              next={() => {
                setRefresh(new Date());
                setModal();
                playVideo();
              }}
            />
          )}
        </>
      )}

      {modalType === "Presale" && (
        <>
          {modalStep === 1 && (
            <EmailReminderOptOutModal
              {...{ eventReminderOptOut, setEventReminderOptOut }}
              action={() => setModalStep(2)}
            />
          )}
          {modalStep === 2 && (
            <DonationSelectModal
              {...paywallValues}
              close={() => {
                setModal();
              }}
              next={() => setModal("DonationPaywall")}
            />
          )}
        </>
      )}

      {/* LIVE OR NOT ?? */}
      {modalType === "Donation Only" && (
        <>
          {modalStep === 1 &&
            ![
              "new-emailCapture-live",
              "new-emailCapture-live",
              "new-donationOnly-donations-live",
            ].includes(theaterCtaSenario) && (
              <EmailReminderOptOutModal
                {...{ eventReminderOptOut, setEventReminderOptOut }}
                action={() => setModalStep(2)}
              />
            )}
          {(modalStep === 2 ||
            [
              "new-emailCapture-live",
              "new-emailCapture-live",
              "new-donationOnly-donations-live",
            ].includes(theaterCtaSenario)) && (
            <DonationSelectModal
              {...paywallValues}
              close={() => {
                setModal();
                playVideo();
              }}
              next={() => setModal("DonationPaywall")}
            />
          )}
        </>
      )}

      {modalType === "new-isPresale-donations-notLive" && (
        <>
          {modalStep === 1 && (
            <EmailReminderOptOutModal
              {...{ eventReminderOptOut, setEventReminderOptOut }}
              action={() => setModalStep(2)}
            />
          )}
          {modalStep === 2 && (
            <DonationSelectModal
              {...paywallValues}
              close={() => {
                setModal();
              }}
              next={() => setModal("Paywall")}
            />
          )}
        </>
      )}

      {modalType === "new-paywall-donations-live" && (
        <DonationSelectModal
          {...paywallValues}
          close={() => {
            setModal();
          }}
          next={() => setModal("Paywall")}
        />
      )}

      {/* donation, live, paywall */}
      {modalType === "Pre-paywall" && (
        <>
          {modalStep === 1 && (
            <EmailReminderOptOutModal
              {...{ eventReminderOptOut, setEventReminderOptOut }}
              action={() => setModalStep(2)}
            />
          )}
          {modalStep === 2 && (
            <DonationSelectModal
              {...paywallValues}
              close={() => {
                setModal();
                playVideo();
              }}
              next={() => setModal("Paywall")}
            />
          )}
        </>
      )}

      {modalType === "DonationPaywall" && (
        <StripeModal
          {...paywallValues}
          close={() => {
            setModal();
          }}
          next={() => {
            setRefresh(new Date());
            setModal("Payment Success");
          }}
        />
      )}

      {modalType === "Paywall" && (
        <>
          {modalStep === 1 &&
            ![
              "new-emailCapture-live",
              "new-emailCapture-live",
              "new-donationOnly-donations-live",
            ].includes(theaterCtaSenario) && (
              <EmailReminderOptOutModal
                {...{ eventReminderOptOut, setEventReminderOptOut }}
                action={() => setModalStep(2)}
              />
            )}
          {(modalStep === 2 ||
            [
              "new-emailCapture-live",
              "new-emailCapture-live",
              "new-donationOnly-donations-live",
            ].includes(theaterCtaSenario)) && (
            <PaywallModal
              {...paywallValues}
              close={() => {
                setModal();
              }}
              back={back}
              next={() => {
                setRefresh(new Date());
                setModal("Payment Success");
              }}
            />
          )}
        </>
      )}

      {modalType === "Payment Success" && (
        <ThankYouModal
          close={() => setModal()}
          play={playVideo}
          next={() => {
            setRefresh(new Date());
            setModal();
            playVideo();
          }}
          state={"payment success"}
          eventStart={eventStart}
          {...gateStatus}
        />
      )}

      {modalType === "Coming Soon" && (
        <ThankYouModal
          close={() => setModal()}
          state={"coming soon"}
          eventStart={eventStart}
          isPresale={gateStatus?.type === "isPresale"}
        />
      )}
    </TheaterModalContainer>
  );
};
