import { useEffect } from "react";

export default (gateStatus, paywallSettings) => {
  const logStatus = () => {
    if (gateStatus && paywallSettings) {
      const { type, guest, live, complete } = gateStatus;
      const { askDonations } = paywallSettings;

      console.log(
        complete
          ? "%cCompleted Event"
          : `%cscenario: %c${guest}-%c${type}${
              askDonations ? "%c-donations" : ""
            }${live ? "%c-live" : "%c-notLive"}`,
        "color: #DEB959; font-style: italic; font-weight: bold; margin-right: 6px",
        !complete ? "color: #40D445; font-weight: bold;" : "",
        !complete ? "color: #2EB3AE; font-weight: bold;" : "",
        !complete ? "color: #EB5757; font-weight: bold;" : "",
        !complete ? "color: #00B4F7; font-weight: bold;" : ""
      );
    }
  };

  useEffect(() => {
    logStatus();
    // eslint-disable-next-line
  }, [gateStatus, paywallSettings]);
};
