import styled from "styled-components";

export const Upload = styled.div`
  width: 136px;
  height: 110px;
  background: ${({ src }) => (src ? `url(${src})` : "none")};
  background-size: cover;
  background-position: center;
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: rgba(0, 0, 0, 0.7);
    display: none;
  }
  img {
    display: none;
    z-index: 1;
    position: relative;
  }
  :hover {
    img,
    .overlay {
      display: block;
    }
  }
`;
