import React, { useState } from "react";
import { Flex, Text } from "components";
import AdminTable from "../AdminTable";
import * as S from "./AdminLeadOwnerDetails.styled";

export default ({ ownerDetails }) => {
  const { leads } = ownerDetails;

  // STATE
  const [total] = useState(leads?.length);

  return (
    <S.ListContainer maxHeight="400px">
      <Flex>
        <Flex justify="space-between" align="center" margin="0 0 24px 0">
          <Text size="24px" weight="bold">
            Lead Sources
          </Text>
        </Flex>
        <Flex width="100%" justify="flex-end" align="center" margin="0 0 16px">
          {/* <Button
            className="secondary"
            // onClick={() => downloadCSV("orders")}
            onClick={() => alert("UPDATE DOWNLOAD LOGIC FOR LEAD OWNERS")}
          >
            Download Report
          </Button> */}
        </Flex>
      </Flex>
      {leads?.length !== 0 ? (
        <AdminTable type="leads" data={leads} total={total} />
      ) : (
        <Flex>
          <Text>No Leads</Text>
        </Flex>
      )}
    </S.ListContainer>
  );
};
