import React, { useMemo, useState, useEffect } from "react";
import { Button, Image, ReadMoreText } from "components";
import * as S from "./AskDonationModal.styled";
import { useWidth, useLockBodyScroll } from "hooks";
import MobileOptions from "./MobileOptions";
import DesktopOptions from "./DesktopOptions";
import { browserStats } from "utils";
import { useHistory } from "react-router-dom";

export default ({
  continueToDonation,
  closeModal,
  paywallSettings,
  setDonationAmount,
  donationAmount,
  midrollBanner,
  ...rest
}) => {
  let history = useHistory();
  // eslint-disable-next-line
  const defaultDonations = useMemo(() => ({
    1: 10,
    2: 20,
    3: 50,
    4: 100,
    Custom: "",
  }));

  const [donate, setDonate] = useState({});
  const [readMore, setReadMore] = useState(false);
  const isIOS = browserStats.os === "iOS";
  const width = useWidth();
  isIOS && useLockBodyScroll();

  useEffect(() => {
    setDonationAmount(
      paywallSettings?.midrollDonationSuggestion1 || defaultDonations[1]
    );
    // eslint-disable-next-line
  }, []);
  const [selectedDonation, setSelectedDonation] = useState(
    paywallSettings?.midrollDonationSuggestion1 || defaultDonations[1]
  );

  const updateDonate = (e) => {
    let amount = e.target.value;
    amount < 0 && (amount = Math.abs(amount));
    donate[e.target.name] = amount;
    setDonate(donate);
  };

  const updateCustomDonate = () => {
    const _userAmount = paywallSettings?.donationSuggestionCustom;
    let _defaultAmount = defaultDonations["Custom"];
    _userAmount && (_defaultAmount = _userAmount);

    const _currentAmount = donate.customAmount;

    if (_currentAmount === undefined || _currentAmount === "") {
      const updated = { ...donate };
      updated.customAmount = _defaultAmount;
      setDonate(updated);
    }
  };

  const selectDonate = (field) => {
    const suggestedPrice =
      paywallSettings?.[`midrollDonationSuggestion${field}`];
    setDonationAmount(suggestedPrice || defaultDonations[field]);
    setSelectedDonation(
      field === "Custom" ? "custom" : defaultDonations[field]
    );
    setDonate(donate);
  };

  const isDonationSelected = (field) => {
    const suggestedPrice =
      paywallSettings?.[`midrollDonationSuggestion${field}`];
    const customSelected = selectedDonation === "custom";
    let isSelected = suggestedPrice
      ? donationAmount * 1 === suggestedPrice * 1 && !customSelected
      : donationAmount * 1 === defaultDonations[field] * 1 && !customSelected;

    if (selectedDonation === "custom" && field === "Custom") isSelected = true;

    return isSelected;
  };

  const continueToCheckout = () => {
    if (
      donate.customAmount === "0" ||
      (donate.customAmount === "" && !donationAmount)
    ) {
      return continueToDonation(true);
    } else if (!donate.customAmount && donationAmount === null) {
      continueToDonation(true);
    } else {
      return (
        donate.customAmount && setDonationAmount(parseInt(donate.customAmount)),
        history.push({ afterWatch: true }),
        continueToDonation(true)
      );
    }
  };

  return (
    <S.Wrap>
      <S.Container {...rest} midroll>
        <S.PictureContainer>
          <S.BannerImage
            className="midroll-banner"
            src={midrollBanner}
            alt="midroll banner"
            midroll
          />
        </S.PictureContainer>
        <S.Background midroll />
        {readMore && paywallSettings.midrollDonationSubtitle?.length > 120 && (
          <S.OverflowContainer />
        )}
        <S.MidrollContainer>
          <S.TextContainer>
            <ReadMoreText
              title={
                paywallSettings.midrollDonationTitle ||
                "Please consider making a donation"
              }
              description={
                paywallSettings.midrollDonationSubtitle ||
                "It helps to support the filmmakers or the cause!"
              }
              padding={width > 1230 ? "0" : "0 24px"}
              screenWidth={width}
              titleMargin={width < 768 ? "0 0 12px" : "0 0 18px"}
              sponsorMargin={width < 768 ? "0 0 8px" : "0 0 12px"}
              descriptionMargin={width < 768 ? "0 0 8px" : "0"}
              truncateDescription={
                width < 768 ||
                paywallSettings.midrollDonationSubtitle?.length > 400
              }
              readMore={readMore}
              setReadMore={setReadMore}
            />
          </S.TextContainer>
          <S.ControlsContainer>
            {width > 768 ? (
              <DesktopOptions
                isDonationSelected={isDonationSelected}
                selectDonate={selectDonate}
                defaultDonations={defaultDonations}
                selectedDonation={selectedDonation}
                updateDonate={updateDonate}
                updateCustomDonate={updateCustomDonate}
                paywallSettings={paywallSettings}
                donate={donate}
              />
            ) : (
              <MobileOptions
                isDonationSelected={isDonationSelected}
                selectDonate={selectDonate}
                defaultDonations={defaultDonations}
                selectedDonation={selectedDonation}
                updateDonate={updateDonate}
                updateCustomDonate={updateCustomDonate}
                paywallSettings={paywallSettings}
                donate={donate}
              />
            )}
            <S.ButtonContainer>
              <Button
                className="large"
                padding="12px 24px"
                margin={width < 768 ? "0 20px 12px" : "0 0 20px"}
                onClick={() => continueToCheckout()}
              >
                Send Donation
                <Image
                  margin="0 0 0 16px"
                  width="28px"
                  src={require("assets/images/icon-arrow-right.svg").default}
                />
              </Button>
              <Button
                className="outline-white large"
                padding="12px 24px"
                margin={width < 768 ? "0 20px" : "0"}
                onClick={() => closeModal()}
              >
                Keep Watching
              </Button>
            </S.ButtonContainer>
          </S.ControlsContainer>
        </S.MidrollContainer>
      </S.Container>
    </S.Wrap>
  );
};
