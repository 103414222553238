import styled from "styled-components";
import { Flex } from "components";
import { media } from "utils";
import { colors } from "consts";

export const StripeBox = styled(Flex)`
  flex-direction: row;
  ${media.tablet`
    flex-direction: column;
  `}
`;

export const Back = styled(Flex)`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px;
  img {
    width: 24px;
  }
  z-index: 11;
`;

export const Summary = styled(Flex)`
  background: #1b1b1b;
  width: 100%;
  padding: 82px;
  height: 625px;
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  margin: 0 5px;
  background: #a4a4a4;
`;

export const Input = styled.input`
  border-radius: ${({ radius = "6px" }) => radius};
  padding: 0 12px;
  font-size: 14px;
  height: 36px;
  width: ${({ width = "100%" }) => width};
  background: #101010;
  margin: ${({ margin }) => margin};
  border: 1px solid #525252;
  box-shadow: none;
  color: white;
  :focus {
    border: 1px solid ${colors.orange};
    box-shadow: none;
    outline: none;
  }
`;
