import React, { useEffect, useState } from "react";
import { Flex, Text, Button, BulkEditModal } from "components";
// import AdminTable from "./AdminTable";
import AdminTable from "./AdminTable/_NewAdminTable";
import { ACCOUNT } from "api";
import { AdminCustomerDrawer } from "./AdminDrawers";

// rQuery
import { useGetAccounts, useGetFilteredAccounts } from "rQuery/api";
import { useGetGlobalAccountTags } from "rQuery/api/useGetTags";

export default ({ updateCredit, downloadCSV, searchTerm, filters }) => {
  // state
  const [total, setTotal] = useState("");
  const [page, setPage] = useState(1);
  const [isBulkEditing, setIsBulkEditing] = useState(false);
  const [bulkEdits, setBulkEdits] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  // HOOKS
  // hook: global tags
  const { isLoading: isLoadingGlobalAccountTags, data: globalAccountTags } =
    useGetGlobalAccountTags();

  // hooks: accounts
  const { isLoading: isLoadingAccounts, data: accounts } = useGetAccounts();
  const {
    isLoading: isLoadingFilteredAccounts,
    ifFetching: isFetchingFilteredAccounts,
    data: filteredAccounts,
  } = useGetFilteredAccounts(searchTerm, page, filters);

  // USE EFFECT
  useEffect(() => {
    if (!filteredAccounts?.data) return;
    setFilteredData(filteredAccounts.data);
  }, [filteredAccounts]);

  useEffect(() => {
    let filtering = true;
    if (
      filters.business_type === "" &&
      filters.tag === "" &&
      page === 1 &&
      searchTerm === ""
    )
      filtering = false;
    setIsFiltering(filtering);
  }, [searchTerm, page, filters]);

  useEffect(() => {
    if (!accounts) return;
    let total = filteredAccounts?.total || null;
    if (!isFiltering) total = accounts.total;
    setTotal(total);
  }, [isFiltering, filteredAccounts, accounts]);

  // METHODS
  const bulkUpdate = async (accountIds, attributes) => {
    return await ACCOUNT.bulkEditAccounts(accountIds, attributes);
  };

  // HELPERS
  const formConfig = {
    note: {
      title: "Add Note:",
      placeholder: "New Note",
      type: "text",
    },
    tags: {
      title: "Edit Tags:",
      type: "form",
      globalTags: globalAccountTags,
    },
  };

  return (
    <>
      {/* MODALS */}
      {isBulkEditing && (
        <BulkEditModal
          close={() => setIsBulkEditing(false)}
          bulkEdits={bulkEdits.map((row) => row.values)}
          {...{ formConfig, bulkUpdate }}
        />
      )}

      {/* CONTENT */}
      <Flex width="100%" justify="flex-end" align="center" margin="0 0 16px">
        {bulkEdits?.length > 1 && (
          <Button
            className="secondary"
            onClick={() => setIsBulkEditing(true)}
            margin="0 15px 0 0"
          >
            Bulk Update
          </Button>
        )}
        <Button className="secondary" onClick={() => downloadCSV("customers")}>
          Download Report
        </Button>
      </Flex>
      {isLoadingAccounts ||
      isFetchingFilteredAccounts ||
      isLoadingFilteredAccounts ||
      isLoadingGlobalAccountTags ? (
        <Text color="white">Loading...</Text>
      ) : accounts?.length === 0 ||
        (isFiltering && filteredData?.length === 0) ? (
        <Text color="white">No data matching your search criteria</Text>
      ) : (
        <AdminTable
          className="clickable"
          isExpandable
          Drawer={AdminCustomerDrawer}
          type="customers"
          data={isFiltering ? filteredData : accounts?.data}
          editableCellRequest={updateCredit}
          {...{ page, setPage, total }}
          {...{
            bulkEdits,
            setBulkEdits,
            setIsBulkEditing,
          }}
        />
      )}
    </>
  );
};
