import { Button, Flex, Text } from "components";

export default ({ close }) => {
  return (
    <Flex column gap="24px">
      <Flex column gap="12px">
        <Text kind="h2" size="24px" weight="bold">
          Send the event URL that you wish to duplicate to
          support@showandtell.film
        </Text>
        <Text>
          Our team will send you a new duplicated event URL to edit yourself!
          Please be sure to send us the URL for your past event.
        </Text>
        <Text align="left" width="100%">
          example: https://watch.showandtell.film/watch/naon
        </Text>
      </Flex>
      <Flex gap="12px" center>
        <Button onClick={close}>Filmmaker Dashboard</Button>
        <a href="mailto:support@showandtell.film">
          <Button>Email Support</Button>
        </a>
      </Flex>
    </Flex>
  );
};
