import React, { useState, useEffect, useCallback } from "react";
import { Flex, Text, Label, Input, Button, Textarea, Player } from "components";
import BannerImage from "./BannerImage";
import { DashboardTagsSection } from "./Tags";
import { validateDashboardInputs, createScreening } from "utils";
import { colors } from "consts";
import { ACCOUNT, PAGES } from "api";
import { toast } from "react-toastify";
import * as S from "./DashboardFilmDetails.styled";
import { useParams } from "react-router-dom";

export default ({
  screening,
  id,
  update,
  setStep,
  patch,
  isExisting,
  loading,
  setId,
}) => {
  const [information, setInformation] = useState({});
  const [editTrailer, setEditTrailer] = useState(false);
  const [editFeature, setEditFeature] = useState(false);
  const [accountEmail, setAccountEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [newUrl, setNewUrl] = useState("");
  const [updateBanner, setUpdateBanner] = useState(Date.now());

  const { customerId } = useParams();

  const hasCustomerId = !!customerId;
  const diyEvent = !hasCustomerId;

  const getAccount = useCallback(async () => {
    if (!customerId) return;
    const { data } = await ACCOUNT.getById(customerId);
    setAccountEmail(data?.data?.email);
  }, [customerId]);

  useEffect(() => {
    screening && setInformation({ ...screening, ...information, id: id });
    getAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screening, id]);

  const updateInformation = (e) => {
    const updates = { ...information };
    updates[e.target.name] = e.target.value;
    if (e.target.name === "url") {
      const _url = setPageUrl(e.target.value);
      updates.url = _url;
      setNewUrl(_url);
    } else if (e.target.name === "backgroundImage") {
      setUpdateBanner(Date.now());
    }
    setInformation(updates);
  };

  const checkUrl = async () => {
    if (!screening.id) {
      try {
        const _exists = await PAGES.getByUrl(information.url);
        if (_exists.data) {
          setErrors({ url: true });
          toast.error(
            "This url is already taken, please pick a different one",
            { autoClose: 6000 }
          );
        }
      } catch (err) {
        setErrors({ ...errors, url: false });
        console.error(err);
      }
    }
  };

  const setPageUrl = (title) => {
    const _url = title
      .replace(/ /g, "-")
      .replace(/[^\w\\-]/gi, "")
      .toLowerCase();
    return _url;
  };

  const isValid = () => {
    const _valid = validateDashboardInputs(information);
    _valid.valid && setErrors({});
    _valid.errors && setErrors(_valid.errors);
    if (_valid.valid) return true;
    if (_valid.errors) return false;
  };

  const saveAndContinue = async (saved) => {
    update(information);
    setStep("about");
    window.scrollTo(0, 0);
    !saved &&
      toast("This section has been saved", {
        autoClose: 3000,
      });
    saved && updateComp();
  };

  const updateComp = () => {
    const _valid = validateDashboardInputs(information);
    _valid.errors && setErrors(_valid.errors);
    if (_valid.valid) {
      id && (information.id = id);
      patch(information);
      update(information);
      setErrors({});
    }
  };

  return (
    <Flex padding="60px 125px" direction="column" align="flex-start">
      <Text size="38px" weight="500" margin="0 0 17px">
        Create Event
      </Text>

      <Flex align="flex-start">
        <Flex
          width="540px"
          direction="column"
          justify="flex-start"
          align="flex-start"
        >
          <Text size="14px" margin="0 0 24px" color={colors.gray8}>
            Start building your event by filling in the info on this page and
            clicking "Save and Continue" at the bottom of this page. Note that
            only the items with an asterisk (*) are required.
          </Text>

          <Text size="14px" margin="0 0 24px" color={colors.gray8}>
            Once you click "Save and Continue", your event will be saved in Test
            Mode until you activate but you can still change and update your
            event.
          </Text>

          <Text size="14px" margin="0 0 24px" color={colors.gray8}>
            If you have questions or issues, please visit our Help Desk
            <a
              href="https://support.showandtell.film/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              here
            </a>
          </Text>

          <Text size="14px" margin="0 0 24px" color={colors.gray8}>
            Once you click "Save and Continue", this section will be saved. You
            will still be able to add information or make changes to your event
            page at any time.
          </Text>

          <Flex
            direction="column"
            align="flex-start"
            outline
            padding="23px 32px"
            margin="0 0 28px"
          >
            <Text size="24px" margin="0 0 20px">
              Film Information
            </Text>

            <Label fontSize="16px">Title*</Label>
            <Text size="14px" margin="0 0 20px" color={colors.gray8}>
              The title for your event page, which is usually the title of your
              film. Limit of 45 characters.
            </Text>
            <Input
              value={information.title || ""}
              error={errors.title || ""}
              onChange={updateInformation}
              name="title"
              margin="0 0 26px"
              placeholder="Max 45 Characters"
              maxChar={45}
            />

            <Label fontSize="16px">URL for your event*</Label>
            <Text size="14px" margin="0 0 20px" color={colors.gray8}>
              Create a customized URL for your event. We recommend your film
              title or the name of a partner. This can be changed later.
            </Text>
            <Input
              value={information.url || ""}
              error={errors.url || ""}
              onChange={updateInformation}
              name="url"
              placeholder="my-feature-film"
              margin="0 0 6px"
              onBlur={checkUrl}
            />
            <Text size="14px" margin="0 0 26px">
              https://watch.showandtell.film/watch/{newUrl || information.url}
            </Text>

            <Label fontSize="16px">Log Line*</Label>
            <Text size="14px" margin="0 0 20px" color={colors.gray8}>
              A brief message directly under the Title. Limit of 150 characters.
            </Text>
            <Textarea
              value={information.description}
              error={errors.description}
              onChange={updateInformation}
              name="description"
              margin="0 0 55px"
              placeholder="Max 150 Characters"
              rows="8"
              maxChar={150}
            />
          </Flex>

          <DashboardTagsSection
            pageId={information?.id}
            existingEventTags={information?.tags}
          />

          <Flex
            direction="column"
            align="flex-start"
            outline
            padding="23px 32px"
            margin="0 0 28px"
          >
            <BannerImage
              information={information}
              updateBanner={updateBanner}
              setInformation={setInformation}
              updateInformation={updateInformation}
            />
          </Flex>

          {information.trailerUrl && !editTrailer ? (
            <Flex direction="column" align="flex-start" margin="0 0 40px">
              <Flex margin="0 0 20px" align="flex-end">
                <Text size="24px" margin="0 16px 0 0">
                  Your Trailer
                </Text>
                <Text
                  onClick={() => setEditTrailer(true)}
                  size="14px"
                  margin="0 0 2px"
                  pointer
                  color={colors.orange}
                >
                  Edit
                </Text>
              </Flex>

              <Player
                controls={true}
                url={information.trailerUrl}
                isDashboard={true}
              />
            </Flex>
          ) : (
            <S.VideoFile>
              <Flex margin="0 0 20px" align="flex-end">
                <Text size="24px" margin="0 16px 0 0">
                  Add your Trailer
                </Text>
                {information.trailerUrl && (
                  <Text
                    onClick={() => setEditTrailer(false)}
                    size="14px"
                    margin="0 0 2px"
                    pointer
                    color={colors.orange}
                  >
                    Cancel
                  </Text>
                )}
              </Flex>

              <Text size="14px" margin="0 0 20px" color={colors.gray8}>
                Provide a Vimeo link for your trailer. If your trailer is
                publically available on Vimeo, all you'll need to do is copy the
                link making sure it follows the same formatting as the example
                below. If your trailer is private, please follow the
                instructions
                <a
                  href="https://support.showandtell.film/hc/en-us/articles/360052116851"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  here{" "}
                </a>
                to ensure it plays properly.
              </Text>

              <Label>Vimeo URL</Label>
              <Input
                value={information.trailerUrl || ""}
                name="trailerUrl"
                onChange={updateInformation}
                margin="0 0 26px"
                placeholder="https://www.vimeo.com/170338499"
              />

              <Flex justify="flex-end" margin="24px 0 0">
                <Button
                  onClick={() => {
                    update(information);
                    setEditTrailer(false);
                  }}
                >
                  Save
                </Button>
              </Flex>
            </S.VideoFile>
          )}

          {information.featureUrl && !editFeature ? (
            <Flex direction="column" align="flex-start" margin="0 0 40px">
              <Flex margin="0 0 20px" align="flex-end">
                <Text size="24px" margin="0 16px 0 0">
                  Your Feature
                </Text>
                <Text
                  onClick={() => setEditFeature(true)}
                  size="14px"
                  margin="0 0 2px"
                  pointer
                  color={colors.orange}
                >
                  Edit
                </Text>
              </Flex>

              <Player
                controls={true}
                url={information.featureUrl}
                isDashboard={true}
              />
            </Flex>
          ) : (
            <S.VideoFile>
              <Flex margin="0 0 20px" align="flex-end">
                <Text size="24px" margin="0 16px 0 0">
                  Add your Feature
                </Text>
                {information.featureUrl && (
                  <Text
                    onClick={() => setEditFeature(false)}
                    size="14px"
                    margin="0 0 2px"
                    pointer
                    color={colors.orange}
                  >
                    Cancel
                  </Text>
                )}
              </Flex>

              <Text size="14px" margin="0 0 20px" color={colors.gray8}>
                Provide a Vimeo link for your feature. Please make sure your URL
                follows the pattern in the example below.
              </Text>

              <Text size="14px" margin="0 0 20px" color={colors.gray8}>
                Follow the instructions
                <a
                  href="https://support.showandtell.film/hc/en-us/articles/360052116851"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  &nbsp;here&nbsp;
                </a>
                to ensure your feature plays properly.
              </Text>

              <Label>Vimeo URL</Label>
              <Input
                value={information.featureUrl || ""}
                name="featureUrl"
                onChange={updateInformation}
                margin="0 0 26px"
                placeholder="https://www.vimeo.com/170338499"
              />

              <Flex justify="flex-end" margin="24px 0 0">
                <Button
                  onClick={() => {
                    update(information);
                    setEditFeature(false);
                  }}
                >
                  Save
                </Button>
              </Flex>
            </S.VideoFile>
          )}

          <Text margin="0 0 40px" size="14px" color={colors.gray8}>
            Once you click 'Save and Continue’, this section will be saved. You
            will still be able to add information or make changes to your event
            page at any time.
          </Text>

          <Flex margin="0 0 32px" justify="flex-end">
            {isExisting ? (
              <>
                {loading ? (
                  <Button>Saving...</Button>
                ) : (
                  <Button onClick={() => updateComp()}>Save</Button>
                )}
              </>
            ) : (
              <Button
                onClick={() =>
                  id
                    ? isValid() && saveAndContinue(true)
                    : isValid() &&
                      createScreening(
                        information,
                        setId,
                        saveAndContinue,
                        customerId,
                        accountEmail,
                        diyEvent
                      )
                }
              >
                Save and Continue
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
