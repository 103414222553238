import { useEffect } from "react";
import intersectionWith from "lodash/intersectionWith";

export default ({ eventTags, globalTags, setCommonTags }) => {
  const setCheckBoxes = () => {
    // determine if the eventTags and globalTags exist
    if (eventTags && globalTags) {
      const _commonTags = {};

      // extract the names from both the event and global tags
      const eventTagNames = eventTags.map(({ name }) => name);
      const globalTagNames = globalTags.map(({ name }) => name);

      // compare the event and global tags, and find the common names
      const intersectingTags = intersectionWith(eventTagNames, globalTagNames);

      // if there is a common name between the two, add it as a key to the
      // commonTags object with a value of true
      intersectingTags.forEach((name) => (_commonTags[name] = true));

      // set the common tags
      setCommonTags(_commonTags);
    }
  };

  useEffect(() => {
    setCheckBoxes();
    // eslint-disable-next-line
  }, [globalTags, eventTags]);
};
