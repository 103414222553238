import styled, { keyframes } from "styled-components";
import { minMedia } from "utils";

const animateIn = keyframes`
0% { opacity: 0; }
100% { opacity: 1 ;}
`;

export const Background = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  opacity: 0;
  margin-bottom: 105px;
  background: ${({ dark }) =>
    dark ? "rgba(0,0,0,0.9)" : "rgba(255,255,255,0.9)"};

  animation: ${animateIn} forwards;
  animation-duration: 2s;
  animation-delay: 0s;

  &.stripe-active {
    height: 100vh;
    position: fixed;
    ${minMedia.laptop`
      position: relative;
    `}
  }

  padding: 105px 16px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: auto;
  ${minMedia.laptop`
    padding: 105px 55px 0;
    position: absolute;
  `}
`;

export const Close = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 50px;
  z-index: 2;
  cursor: pointer;
`;
