import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { colors } from "consts";

export default styled(ReactMarkdown)`
  width: 100%;
  * {
    color: white;
  }
  a {
    cursor: pointer;
    color: ${colors.orange};
    &:hover {
      text-decoration: underline;
    }
  }
`;
