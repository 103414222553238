import React from "react";
import styled from "styled-components";
import { media } from "utils";

const Flex = styled.div`
  display: flex;
  position: ${({ relative }) => (relative ? "relative" : "")};
  padding: ${({ padding }) => (padding ? padding : "0")};
  height: ${({ height }) => (height ? height : "auto")};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  flex-direction: ${({ direction, column }) =>
    column ? "column" : direction ? direction : "row"};
  justify-content: ${({ justify, center }) =>
    center ? "center" : justify ? justify : "flex-start"};
  align-items: ${({ align, center }) =>
    center ? "center" : align ? align : "center"};
  gap: ${({ gap }) => gap};
  width: 100%;
  min-width: ${({ minwidth }) => (minwidth ? minwidth : "auto")};
  max-width: ${({ width }) => (width ? width : "auto")};
  cursor: ${({ pointer }) => (pointer ? "pointer" : "inherit")};
  border: ${({ outline }) => (outline ? "2px solid #232323" : "")};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "transparent"};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "")};
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
  overflow: ${({ overflow }) => (overflow ? overflow : "visible")};
  overflow-y: ${({ overflowY }) => (overflowY ? overflowY : "visible")};
  white-space: wrap;
  ${media.tablet`
    padding: ${({ responsive }) =>
      responsive?.tablet?.padding ? responsive.tablet.padding : ""};
    max-width: ${({ responsive }) =>
      responsive?.tablet?.width ? responsive.tablet.width : ""};
    &.tablet-hide {
      display: none;
    }
  `};
  ${media.phonePlus`
    display: ${({ responsive }) =>
      responsive?.phonePlus?.display ? responsive.phonePlus.display : ""};
    padding: ${({ responsive }) =>
      responsive?.phonePlus?.padding ? responsive.phonePlus.padding : ""};
    max-width: ${({ responsive }) =>
      responsive?.phonePlus?.width ? responsive.phonePlus.width : ""};
  `};
  ${media.laptop`
    flex-direction: ${({ responsive }) =>
      responsive?.laptop?.direction ? responsive.laptop.direction : ""};
  `};
`;

export default ({ ...props }) => <Flex {...props} />;
