import axiosRequest from "./axios.config";

export const createCredit = async ({ email, credits }) =>
  await axiosRequest.post(`/v1/credits`, { email, credits });

export const getCredits = async () => await axiosRequest.get("/v1/credits");

export const getCreditsById = async (account_id) =>
  await axiosRequest.get(`/v1/credits/${account_id}`);

export const purchase = async (payload) => {
  return await axiosRequest.post("/v1/orders/credits", payload);
};
