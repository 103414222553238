import React, { useState } from "react";
import { FileDropzone } from "components";
import { measureImage } from "utils";
import { FILES } from "api";

export default ({ setBannerImage, setCropModalOpen }) => {
  const [loading, setLoading] = useState(false);

  const uploadImage = async (image) => {
    try {
      setLoading(true);
      const _uploaded = await FILES.create(image);
      const url = _uploaded.data.data[0].url;

      measureImage(url, (success) => {
        if (success) {
          setBannerImage(url);
          setCropModalOpen(true);
        } else {
          setLoading(false);
        }
      });
    } catch (err) {
      console.error("failed to upload image", err);
    }
  };

  return (
    <FileDropzone
      onDrop={(image) => uploadImage(image)}
      fileTypes={[".png", ".jpeg", ".jpg", ".gif"]}
      loading={loading}
    />
  );
};
