import React, { useEffect, useState } from "react";
// import AdminTable from "./AdminTable";
import AdminTable from "./AdminTable/_NewAdminTable";
import { Flex, Text, Button, SimplePrompt, Select } from "components";
import { TAGS } from "api";
import * as S from "./Admin.styled";
import { toast } from "react-toastify";

// rQuery
import { queryClient } from "rQuery";
import { useMutation } from "react-query";
import { useGetGlobalTags, useGetFilteredGlobalTags } from "rQuery/api";

const initialState = {
  tagId: "",
  type: "",
  name: "",
  value: "",
};

export default ({ searchTerm, filters }) => {
  // STATE
  const [canSubmit, setCanSubmit] = useState(false);
  const [total, setTotal] = useState(null);
  const [editingTag, setEditingTag] = useState(false);
  const [addingTag, setAddingTag] = useState(false);
  const [currentTag, setCurrentTag] = useState(initialState);
  const [page, setPage] = useState(1);
  const [isFiltering, setIsFiltering] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  // HOOKS
  const { isLoading: isLoadingGlobalTags, data: globalTags } =
    useGetGlobalTags();
  const {
    isLoading: isLoadingFilteredGlobalTags,
    ifFetching: isFetchingFilteredGlobalTags,
    data: filteredGlobalTags,
  } = useGetFilteredGlobalTags(searchTerm, page, filters);

  // METHODS
  const handleChange = ({ target }) => {
    return target.value.replace(/\s+/g, "-").toLowerCase();
  };

  const validateShape = () => {
    let result = false;
    if (editingTag) {
      if (currentTag.tagId && currentTag.value) result = true;
    }
    if (addingTag) {
      if (currentTag.type && currentTag.value) result = true;
    }
    setCanSubmit(result);
  };

  const resetState = () => setCurrentTag(initialState);

  // MUTATIONS
  const { mutate: submitEditedTag } = useMutation(
    async ({ tagId, tag }) => await TAGS.patchTag(tagId, tag),
    {
      onSuccess: (data) => {
        toast("Global Tag Added", { autoClose: 3000 });
      },
      onError: (err) => {
        toast("Error editing tag", { autoClose: 3000 });
        console.error("Error editing tag", err);
      },
      onSettled: (data, err) => {
        queryClient.invalidateQueries("tags", "global");
        queryClient.invalidateQueries(["tags", "global", "filtered"]);
        setEditingTag(false);
        resetState();
      },
    }
  );

  const { mutate: addGlobalTag } = useMutation(
    async ({ tag, type }) => await TAGS.createGlobalTag(tag, type),
    {
      onSuccess: (data) => {
        toast("Global Tag Added", { autoClose: 3000 });
      },
      onError: (err) => {
        toast("Error adding tag", { autoClose: 3000 });
        console.error("Error adding tag", err);
      },
      onSettled: (data, err) => {
        queryClient.invalidateQueries("tags", "global");
        queryClient.invalidateQueries(["tags", "global", "filtered"]);
      },
    }
  );
  const { mutate: deleteTag } = useMutation(
    async (id) => await TAGS.deleteTag(id),
    {
      onSuccess: (data) => {
        toast("Global Tag Deleted", { autoClose: 3000 });
      },
      onError: (err) => {
        toast("Error deleting tag", { autoClose: 3000 });
        console.error("Error deleting tagr", err);
      },
      onSettled: (data, err) => {
        queryClient.invalidateQueries("tags", "global");
        queryClient.invalidateQueries(["tags", "global", "filtered"]);
      },
    }
  );

  // USE EFFECT
  useEffect(() => {
    validateShape();
    // eslint-disable-next-line
  }, [currentTag]);

  useEffect(() => {
    if (!filteredGlobalTags?.data) return;
    setFilteredData(filteredGlobalTags.data);
  }, [filteredGlobalTags]);

  useEffect(() => {
    let filtering = true;
    if (filters.type === "" && searchTerm === "" && page === 1) {
      filtering = false;
    }
    setIsFiltering(filtering);
  }, [searchTerm, page, filters.type]);

  useEffect(() => {
    if (!globalTags) return;
    let total = filteredGlobalTags?.total || null;
    if (!isFiltering) total = globalTags.total;
    setTotal(total);
  }, [isFiltering, globalTags, filteredGlobalTags]);

  return (
    <>
      {/* MODALS */}
      {(editingTag || addingTag) && (
        <SimplePrompt
          close={() => {
            resetState();
            setEditingTag(false);
            setAddingTag(false);
          }}
        >
          <Flex padding="20px" direction="column" align="center">
            {addingTag && (
              <>
                <Text margin="0 0 8px">Type:</Text>
                <Select
                  radius="6px 0px 0px 6px"
                  placeholder="Type"
                  toLowerCase={false}
                  selected={{ name: currentTag.name }}
                  options={[
                    { name: "Leadowner", value: "leadowner" },
                    { name: "Account", value: "account" },
                    { name: "Page", value: "page" },
                  ]}
                  setSelected={(e) => {
                    const updated = { ...currentTag };
                    updated.type = e.value;
                    updated.name = e.name;
                    setCurrentTag(updated);
                  }}
                />
              </>
            )}
            {addingTag && (
              <Text htmlFor="note" margin="0 0 8px">
                Tag:
              </Text>
            )}
            <S.TextArea
              autoFocus={true}
              cols={30}
              rows={2}
              value={currentTag.value}
              name="note"
              onChange={(e) => {
                const updated = { ...currentTag };
                updated.value = handleChange(e);
                setCurrentTag(updated);
              }}
            >
              {currentTag.value || "No Tag Attached"}
            </S.TextArea>
            <Button
              className={!canSubmit && "disabled"}
              onClick={() => {
                if (addingTag)
                  addGlobalTag({
                    type: currentTag.type,
                    tag: currentTag.value,
                  });
                if (editingTag) {
                  submitEditedTag({
                    tagId: currentTag.tagId,
                    tag: currentTag.value,
                  });
                }
                setAddingTag(false);
              }}
            >
              {editingTag && `Submit Edited Tag`}
              {addingTag && `Submit Global Tag`}
            </Button>
          </Flex>
        </SimplePrompt>
      )}

      {/* CONTENT */}
      <Flex width="100%" justify="flex-end" align="center" margin="0 0 16px">
        <Button className="secondary" onClick={() => setAddingTag(true)}>
          Add Global Tag
        </Button>
      </Flex>
      {isLoadingGlobalTags ||
      isLoadingFilteredGlobalTags ||
      isFetchingFilteredGlobalTags ? (
        <Text color="white">Loading...</Text>
      ) : (!isFiltering && globalTags.length === 0) ||
        (isFiltering && filteredData.length === 0) ? (
        <Text color="white">No data matching your search criteria</Text>
      ) : (
        <AdminTable
          type="globalTags"
          data={isFiltering ? filteredData : globalTags.data}
          {...{ page, setPage, total }}
          {...{ deleteTag }}
          {...{ setEditingTag, currentTag, setCurrentTag }}
        />
      )}
    </>
  );
};
