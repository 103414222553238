import React, { useState, useEffect } from "react";
import { Image } from "components";
import * as S from "./Searchbar.styled";

export default ({ onChange, value, placeholder, ...rest }) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    value && setInputValue(value);
  }, [value]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    onChange(e);
  };

  return (
    <S.SearchbarContainer {...rest}>
      <Image
        height="12px"
        width="12px"
        src={require("assets/images/search-icon.svg").default}
      />
      <S.Searchbar
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
      />
    </S.SearchbarContainer>
  );
};
