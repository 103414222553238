import React from "react";
import { Text, Flex } from "components";
import * as S from "./Greenlight.styled";

export default ({
  active,
  activeText,
  inactiveText,
  SupportElement,
  ...props
}) => (
  <Flex>
    <S.Greenlight active={active} />
    <Text {...props}>{active ? activeText : inactiveText}</Text>
    {SupportElement && SupportElement}
  </Flex>
);
