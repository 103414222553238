export const createVideo = async ({ file, videoSize }) => {
  try {
    const result = await fetch("https://api.vimeo.com/me/videos", {
      method: "POST",
      headers: {
        Authorization: "bearer f1913d24738cb0d7c5e27b2397bf999f",
        "Content-Type": "application/json",
        Accept: "application/vnd.vimeo.*+json;version=3.4",
      },
      body: JSON.stringify({
        upload: {
          approach: "tus",
          size: videoSize,
        },
      }),
    });

    const uploadResponse = await result.json();

    const patchRes = uploadResponse.upload
      ? patchVideo(uploadResponse.upload.upload_link, file)
      : uploadResponse;

    return {
      videoLink: uploadResponse.link,
      uploadStatus: await patchRes,
    };
  } catch (e) {
    return `HTTP error: ${e}`;
  }
};

export const patchVideo = async (url, video) => {
  try {
    const patchResult = await fetch(url, {
      method: "PATCH",
      headers: {
        "Tus-Resumable": "1.0.0",
        "Upload-Offset": 0,
        "Content-Type": "application/offset+octet-stream",
        Accept: "application/vnd.vimeo.*+json;version=3.4",
      },
      body: video,
    });

    return await patchResult;
  } catch (e) {
    return `Error in uploading file: ${e}`;
  }
};

export const checkUploadStatus = async (id) => {
  const promise = new Promise((resolve, reject) => {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", "https://api.vimeo.com/videos/" + id, true);
      xhr.setRequestHeader(
        "Authorization",
        "Bearer 8dcdc56a00b45ed6ba46d432694dc9db"
      );
      xhr.onload = (e) => {
        if (xhr.readyState === 4) {
          resolve(JSON.parse(xhr.response));
        }
      };
      xhr.onerror = (e) => {
        reject(xhr.statusText);
      };
      xhr.send(null);
    } catch (err) {
      reject(err);
    }
  });

  return promise;
};

export const getVideoDetails = async (id) => {
  const promise = new Promise((resolve, reject) => {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", `https://vimeo.com/api/v2/video/${id}.json`, true);
      xhr.onload = (e) => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            resolve([]);
          }
        }
      };
      xhr.onerror = (e) => {
        reject(xhr.statusText);
      };
      xhr.send(null);
    } catch (err) {
      reject(err);
    }
  });

  return promise;
};
