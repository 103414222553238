import axiosRequest from "./axios.config";

export const get = async () => await axiosRequest.get("/v1/account");

export const update = async (account) =>
  await axiosRequest.patch(`/v1/account`, account);

export const getAllCSV = async () => await axiosRequest.get("/v1/accounts.csv");

export const getAll = async (
  searchTerm = "",
  page = "",
  { business_type = "", tag = "" }
) => {
  return await axiosRequest.get(
    `/v1/accounts?search_term=${searchTerm}&page=${page}&business_type=${business_type}&tag=${tag}`
  );
};

export const getById = async (accountId) => {
  return await axiosRequest.get(`/v1/admin/account/${accountId}`);
};

export const patchAccountById = async (accountInfo) => {
  return await axiosRequest.patch(
    `/v1/admin/account/${accountInfo.id}`,
    accountInfo
  );
};

export const bulkEditAccounts = async (accountIds, attributes) => {
  return await axiosRequest.patch("/v1/admin/account/bulk", {
    accountIds,
    attributes,
  });
};
