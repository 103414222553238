import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Label,
  Input,
  Button,
  Outline,
  Checkbox,
  Player,
  FileFullWidth,
  Image,
} from "components";
import { colors } from "consts";
import randomstring from "randomstring";
import { toast } from "react-toastify";
import { setHours } from "date-fns";

export default ({
  id,
  isExisting,
  loading,
  patch,
  screening,
  setStep,
  update,
}) => {
  const [information, setInformation] = useState({});
  const [startImmediate] = useState(false);
  const [hasSponsor, setHasSponsor] = useState(false);
  const [showHours, setShowHours] = useState(true);
  const [showPreRoll, setShowPreroll] = useState(false);
  const [reloadSponsorVideo, setReloadSponsorVideo] = useState();
  const [showEmailCaptureFields, setShowEmailCaptureFields] = useState(false);
  const [showEnablePaywall, setShowEnablePaywall] = useState(false);
  const [showDonations, setShowDonations] = useState(false);
  const [showMidrollDonations, setShowMidrollDonations] = useState(false);
  const [showForwardButton, setShowForwardButton] = useState(false);

  useEffect(() => {
    if (screening && id) {
      screening.eventStart = new Date(screening?.eventStart || Date.now());
      screening.eventEnd = new Date(screening?.eventEnd || Date.now());
      setInformation({ ...screening, id: id, ...information });
      screening.emailCapture && setShowEmailCaptureFields(true);
      screening.showSponsorPreroll && setShowPreroll(true);
      screening.showHours && setShowHours(true);
      screening.paywallSettings?.enablePaywall && setShowEnablePaywall(true);
      screening.hasSponsor && setHasSponsor(true);
      screening.paywallSettings?.askDonations && setShowDonations(true);
      screening.paywallSettings?.askMidrollDonations &&
        setShowMidrollDonations(true);
      screening.showForwardButton && setShowForwardButton(true);
    }
    // eslint-disable-next-line
  }, [screening, id]);

  const updateInformation = (e) => {
    const updates = { ...information };
    // If no hours are selected, sets hour to 4AM. This helps prevent
    // the event appearing a day early in some timezones
    if (e.target.name === "eventStart" && !showHours) {
      updates.eventStart = setHours(new Date(e.target.value), 4);
    } else {
      updates[e.target.name] = e.target.value;
    }

    e.target.name === "sponsorPrerollUrl" &&
      setReloadSponsorVideo(randomstring.generate());

    if (e.target.name === "emailCapture" && e.target.value === true) {
      updates.paywallSettings.enablePaywall = false;
      updates.paywallSettings.isPresale = false;
      setShowEnablePaywall(false);
    }

    setInformation(updates);
  };

  const updateSubscriberSettings = (e) => {
    const shallowCopy = { ...information };
    shallowCopy.subscriptionSettings[e.target.name] = e.target.value;
    setInformation(shallowCopy);
  };

  const updatePaywallSettings = (e, productUpdate) => {
    const updates = { ...information };
    updates.paywallSettings[e.target.name] = e.target.value;
    productUpdate && (updates.paywallUpdated = true);
    if (e.target.name === "enablePaywall" && e.target.value === true) {
      updates.emailCapture = false;
      setShowEmailCaptureFields(false);
    }
    setInformation(updates);
  };

  const uploadBackground = (url) => {
    information.paywallSettings.midrollBackgroundImage = url;
    setInformation(information);
  };

  const toggleHasSponsor = () => {
    information.hasSponsor = !hasSponsor;
    setInformation(information);
    setHasSponsor(!hasSponsor);
  };

  const togglePreroll = () => {
    information.showSponsorPreroll = !information.showSponsorPreroll;
    setInformation(information);
    setShowPreroll(!showPreRoll);
  };

  const saveAndContinue = () => {
    const start = new Date(information.eventStart).valueOf();
    const end = new Date(information.eventEnd).valueOf();

    if (start > end) {
      toast.error("The start date must be before the end date");
      return;
    }
    if (start === end) {
      toast.error("The start and end dates must be different");
      return;
    }

    if (!validateMidrollDonation(information)) {
      toast.error(
        "Please enter a time stamp for the Post-Film Donation Request - thank you!"
      );
      return;
    }

    patch(information);
    update(information);
    setStep("products");
    window.scrollTo(0, 0);
  };

  const validateMidrollDonation = ({ paywallSettings: ps }) =>
    ps?.askMidrollDonations &&
    (!ps.midrollDonationTriggerMinutes || !ps.midrollDonationTriggerSeconds)
      ? false
      : true;

  const updateScreening = () => {
    if (validateMidrollDonation(information)) {
      patch(information);
      update(information);
    } else {
      toast.error(
        "Please enter a time stamp for the Post-Film Donation Request - thank you!"
      );
    }
  };

  if (!information) return null;
  return (
    <Flex padding="60px 125px" direction="column" align="flex-start">
      <Flex
        width="540px"
        padding="0 56px 0 0"
        direction="column"
        align="flex-start"
      >
        <Text size="38px" weight="500" margin="0 0 17px">
          Event Details
        </Text>

        <Text size="14px" margin="0 0 30px" color={colors.gray8}>
          Set the basic details of your event, including, dates your film is
          available, what type of event you'd like to host, and how you'd like
          to recognize your partner. These settings can all be updated and
          changed at any time.
        </Text>
      </Flex>

      <Flex align="flex-start">
        <Flex
          width="540px"
          direction="column"
          justify="flex-start"
          align="flex-start"
          margin="0 -32px"
        >
          <Outline margin="0 0 20px">
            <Text size="24px" margin="0 0 20px">
              Set Start and End Date
            </Text>

            <Text size="14px" margin="0 0 20px" color={colors.gray8}>
              This is the window of time during which your film will be
              available to watch. These dates and times will display at the top
              of your virtual event page.
              <a
                href="https://support.showandtell.film/hc/en-us/articles/360052793911"
                target="_blank"
                rel="noopener noreferrer"
              >
                &nbsp;Learn More.
              </a>
            </Text>

            <Text size="14px" margin="20px 0" color={colors.gray8}>
              <b>
                The time you set is based on your timezone and will
                automatically adjust to the timezone of your viewers. If no time
                is selected, the screening window will begin at 4 am in your
                time zone on the day you selected.
              </b>
            </Text>

            <Text size="14px" margin="20px 0" color={colors.gray8}>
              Show&Tell gives you the power to host audiences globally. Please
              keep in mind if some members of your audience are in other parts
              of the globe, the start and end time and date may vary. You can
              check what time your screening will start in another time zone
              <a
                href="https://dateful.com/time-zone-converter"
                target="_blank"
                rel="noopener noreferrer"
              >
                &nbsp;here.
              </a>
            </Text>

            {!startImmediate && (
              <Flex justify="space-between" align="flex-end" margin="26px 0 0">
                <Flex
                  width="50%"
                  direction="column"
                  align="flex-start"
                  margin="0 37px 0 0"
                >
                  <Label>Starts</Label>
                  <Input
                    type="date"
                    id="eventStart"
                    value={information.eventStart}
                    onChange={updateInformation}
                    name="eventStart"
                    placeholder="MM/DD/YYYY"
                    showHours={showHours}
                  />
                </Flex>

                <Flex width="50%" direction="column" align="flex-start">
                  <Label>Ends</Label>
                  <Input
                    type="date"
                    id="eventEnd"
                    value={information.eventEnd}
                    onChange={updateInformation}
                    name="eventEnd"
                    placeholder="MM/DD/YYYY"
                    showHours={showHours}
                  />
                </Flex>
              </Flex>
            )}
          </Outline>

          <Flex
            width="540px"
            padding="22px 36px"
            direction="column"
            align="flex-start"
          >
            <Text size="24px" margin="0 0 20px">
              Choose Your Event Type
            </Text>

            <Text margin="0 0 20px" size="14px" color={colors.gray8}>
              Host a free event, charge for tickets, ask for donations, or do a
              combination by choosing from the options below.
            </Text>

            <Text margin="0 0 20px" size="14px" color={colors.gray8}>
              Note: If you would like to host a free event without collecting
              e-mail RSVPs, simply do not select either the Email Capture or
              Paywall sections.
            </Text>

            <Text margin="0 0 20px" size="14px" color={colors.gray8}>
              <a
                href="https://support.showandtell.film/hc/en-us/articles/360052220011"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more{" "}
              </a>
              about the different event types.
            </Text>
          </Flex>

          <Outline margin="0 0 26px">
            <Text size="24px" margin="0 0 26px">
              Email Capture
            </Text>

            <Text size="14px" margin="0 0 20px" color={colors.gray8}>
              This requires viewers to provide information to watch your film.
              This information will be available for you to download. Select the
              information you’d like to collect.
              <a
                href="https://support.showandtell.film/hc/en-us/articles/360052796071"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Learn More.
              </a>
            </Text>

            <Flex margin="0 0 26px">
              <Checkbox
                checked={information.emailCapture}
                disabled={information.paywallSettings?.enablePaywall}
                onClick={() => {
                  setShowEmailCaptureFields(!information.emailCapture);
                  updateInformation({
                    target: {
                      name: "emailCapture",
                      value: !information.emailCapture,
                    },
                  });
                }}
              />
              <Text margin="0 0 0 8px" size="14px" color={colors.gray8}>
                Require viewers submit an email to access your film
              </Text>
            </Flex>

            {information.paywallSettings?.enablePaywall && (
              <Flex
                padding="10px"
                backgroundColor={colors.orange}
                borderRadius="5px"
              >
                <Text margin="-4px 0 0 0" size="14px" color="white" center>
                  Audience information (emails) will be captured automatically
                  when the paywall (tickets) is enabled.
                </Text>
              </Flex>
            )}

            {showEmailCaptureFields && (
              <>
                <Flex margin="0 0 26px">
                  <Checkbox
                    checked={information.subscriptionSettings.captureName}
                    onClick={() =>
                      updateSubscriberSettings({
                        target: {
                          name: "captureName",
                          value: !information.subscriptionSettings.captureName,
                        },
                      })
                    }
                  />
                  <Text margin="0 0 0 8px" size="14px" color={colors.gray8}>
                    Capture Name
                  </Text>
                </Flex>

                <Flex margin="0 0 26px">
                  <Checkbox
                    checked={
                      information.subscriptionSettings.captureOrganization
                    }
                    onClick={() =>
                      updateSubscriberSettings({
                        target: {
                          name: "captureOrganization",
                          value:
                            !information.subscriptionSettings
                              .captureOrganization,
                        },
                      })
                    }
                  />
                  <Text margin="0 0 0 8px" size="14px" color={colors.gray8}>
                    Capture Organization
                  </Text>
                </Flex>

                <Flex margin="0 0 26px">
                  <Checkbox
                    checked={information.subscriptionSettings.captureCountry}
                    onClick={() =>
                      updateSubscriberSettings({
                        target: {
                          name: "captureCountry",
                          value:
                            !information.subscriptionSettings.captureCountry,
                        },
                      })
                    }
                  />
                  <Text margin="0 0 0 8px" size="14px" color={colors.gray8}>
                    Capture Country
                  </Text>
                </Flex>

                <Flex margin="0 0 26px">
                  <Checkbox
                    checked={information.subscriptionSettings.captureZip}
                    onClick={() =>
                      updateSubscriberSettings({
                        target: {
                          name: "captureZip",
                          value: !information.subscriptionSettings.captureZip,
                        },
                      })
                    }
                  />
                  <Text margin="0 0 0 8px" size="14px" color={colors.gray8}>
                    Capture Zip
                  </Text>
                </Flex>
              </>
            )}
          </Outline>

          <Outline margin="0 0 26px">
            <Text size="24px" margin="0 0 26px">
              Show Forward to a friend button
            </Text>

            <Text size="14px" margin="0 0 20px" color={colors.gray8}>
              Allows your attendees to easily forward your event URL to their
              friends via email.
              <a
                href="https://support.showandtell.film/hc/en-us/articles/360052799311"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Learn More.
              </a>
            </Text>

            <Flex margin="0 0 26px">
              <Checkbox
                checked={showForwardButton}
                onClick={() => {
                  setShowForwardButton(!showForwardButton);
                  setInformation({
                    ...information,
                    showForwardButton: !showForwardButton,
                  });
                }}
              />
              <Text margin="0 0 0 8px" size="14px" color={colors.gray8}>
                Show Forward to Friend Button
              </Text>
            </Flex>
          </Outline>

          <Outline margin="0 0 26px">
            <Text size="24px" margin="0 0 26px">
              Paywall
            </Text>

            <Text size="14px" margin="0 0 20px" color={colors.gray8}>
              This requires that a viewer purchase a ticket to watch your film.
              Info about purchasers will be available for you to download. When
              enabled, your event will display a “Buy Ticket” button with the
              cost of the ticket.
              <a
                href="https://support.showandtell.film/hc/en-us/articles/360052799311"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Learn More.
              </a>
            </Text>

            <Text size="14px" margin="0 0 20px" color={colors.gray8}>
              <b>IMPORTANT:</b> To start selling tickets and collecting funds on
              your behalf you will need to authenticate your identity with
              Stripe, our payment processor. Your information will be stored
              safely and securely with Stripe. You will find where to add your
              information at the end of the event creation process in the
              “Activate &#38; Payments” section.
            </Text>

            <Flex margin="0 0 26px">
              <Checkbox
                checked={
                  information.paywallSettings &&
                  information.paywallSettings.enablePaywall
                }
                onClick={() => {
                  setShowEnablePaywall(
                    !information.paywallSettings.enablePaywall
                  );
                  updatePaywallSettings({
                    target: {
                      name: "enablePaywall",
                      value: !information.paywallSettings.enablePaywall,
                    },
                  });
                  updatePaywallSettings(
                    {
                      target: {
                        name: "isPresale",
                        value: information.paywallSettings.enablePaywall,
                      },
                    },
                    true
                  );
                  if (information.emailCapture) {
                    setInformation({ ...information, emailCapture: false });
                    setShowEmailCaptureFields(false);
                  }
                }}
              />
              <Text margin="0 0 0 8px" size="14px" color={colors.gray8}>
                Enable Paywall
              </Text>
            </Flex>

            {showEnablePaywall && (
              <>
                <Flex margin="0 0 26px" direction="column" align="flex-start">
                  <Text margin="0 0 8px" size="14px" color={colors.gray8}>
                    Price ($)
                  </Text>
                  <Input
                    type="number"
                    placeholder="19.99"
                    value={
                      information.paywallSettings &&
                      information.paywallSettings.price
                    }
                    name="price"
                    onChange={(e) => updatePaywallSettings(e, true)}
                  />
                </Flex>
              </>
            )}
          </Outline>

          <Outline margin="0 0 26px">
            <Text size="24px" margin="0 0 26px">
              Request Donations
            </Text>

            <Text size="14px" margin="0 0 32px" color={colors.gray8}>
              Encourage your attendees to make a donation using one or both of
              the options available below. Information about donors will be
              available for you to download as a CSV file on the Attendees tab.
            </Text>

            <Text size="14px" margin="0 0 32px" color={colors.gray8}>
              <b>💡 Tip:</b> To increase support from your attendees we
              recommend using both options for requesting donations. Before your
              screening begins using Pre-film donations and at the end of the
              film before the credits using Post-Film Donation Request.
            </Text>

            <Text size="14px" margin="0 0 32px" color={colors.gray8}>
              <b>IMPORTANT:</b> To start collecting donations on your behalf you
              will need to authenticate your identity with Stripe, our payment
              processor. You will find where to add your information at the end
              of the event creation process in the “Activate &#38; Payments”
              section.
            </Text>

            <Text size="20px" margin="0 0 20px">
              Pre-Film Donation Request
            </Text>

            <Text size="14px" margin="0 0 16px" color={colors.gray8}>
              Allow attendees to make an optional donation prior to watching
              your film when registering or purchasing their ticket.
            </Text>

            <Flex margin="0 0 20px">
              <Checkbox
                checked={
                  information.paywallSettings &&
                  information.paywallSettings.askDonations
                }
                onClick={() => {
                  setShowDonations(!information.paywallSettings.askDonations);
                  updatePaywallSettings({
                    target: {
                      name: "askDonations",
                      value: !information.paywallSettings.askDonations,
                    },
                  });
                }}
              />
              <Text margin="0 0 0 8px" size="14px" color={colors.gray8}>
                Ask for donations before the screening begins
              </Text>
            </Flex>

            {showDonations && (
              <>
                <Text size="14px" margin="0 0 32px" color={colors.gray8}>
                  Add your suggested donation amounts:
                </Text>

                <Text size="14px" margin="0 0 16px" color={colors.gray8}>
                  <b>💡 Tip:</b> You can always change these amounts later. If
                  you’re unsure where to start, use the suggested amounts below.
                </Text>

                <Text
                  size="12px"
                  margin="0 0 32px"
                  weight="bold"
                  color={colors.gray8}
                >
                  *Keep in mind that transaction fees are deducted from each
                  donation.
                </Text>

                <Flex margin="0 0 26px" direction="column" align="flex-start">
                  <Text
                    margin="0 0 8px"
                    size="14px"
                    weight="bold"
                    color={colors.gray8}
                  >
                    Donation Suggeston 1
                  </Text>
                  <Input
                    type="number"
                    min="0"
                    value={
                      information.paywallSettings &&
                      information.paywallSettings.donationSuggestion1
                    }
                    placeholder="10"
                    name="donationSuggestion1"
                    onChange={updatePaywallSettings}
                  />
                </Flex>

                <Flex margin="0 0 26px" direction="column" align="flex-start">
                  <Text
                    margin="0 0 8px"
                    size="14px"
                    weight="bold"
                    color={colors.gray8}
                  >
                    Donation Suggeston 2
                  </Text>
                  <Input
                    type="number"
                    min="0"
                    value={
                      information.paywallSettings &&
                      information.paywallSettings.donationSuggestion2
                    }
                    placeholder="20"
                    name="donationSuggestion2"
                    onChange={updatePaywallSettings}
                  />
                </Flex>

                <Flex margin="0 0 26px" direction="column" align="flex-start">
                  <Text
                    margin="0 0 8px"
                    size="14px"
                    weight="bold"
                    color={colors.gray8}
                  >
                    Donation Suggeston 3
                  </Text>
                  <Input
                    type="number"
                    min="0"
                    value={
                      information.paywallSettings &&
                      information.paywallSettings.donationSuggestion3
                    }
                    placeholder="50"
                    name="donationSuggestion3"
                    onChange={updatePaywallSettings}
                  />
                </Flex>

                <Flex margin="0 0 26px" direction="column" align="flex-start">
                  <Text
                    margin="0 0 8px"
                    size="14px"
                    weight="bold"
                    color={colors.gray8}
                  >
                    Donation Suggeston 4
                  </Text>
                  <Input
                    type="number"
                    min="0"
                    value={
                      information.paywallSettings &&
                      information.paywallSettings.donationSuggestion4
                    }
                    placeholder="100"
                    name="donationSuggestion4"
                    onChange={updatePaywallSettings}
                  />
                </Flex>

                <Flex margin="0 0 26px" direction="column" align="flex-start">
                  <Text
                    margin="0 0 8px"
                    size="14px"
                    weight="bold"
                    color={colors.gray8}
                  >
                    Title
                  </Text>
                  <Text
                    margin="0 0 8px"
                    size="14px"
                    weight="bold"
                    color={colors.gray8}
                  >
                    Include your partner's name and purpose.
                  </Text>
                  <Input
                    value={
                      information.paywallSettings &&
                      information.paywallSettings.donationTitle
                    }
                    placeholder="Please consider making a donation"
                    name="donationTitle"
                    onChange={updatePaywallSettings}
                  />
                </Flex>

                <Flex margin="0 0 26px" direction="column" align="flex-start">
                  <Text
                    margin="0 0 8px"
                    size="14px"
                    weight="bold"
                    color={colors.gray8}
                  >
                    Subtitle
                  </Text>
                  <Text margin="0 0 8px" size="12px" color={colors.gray8}>
                    Explain your mission or cause you are supporting.
                  </Text>
                  <Input
                    value={
                      information.paywallSettings &&
                      information.paywallSettings.donationSubtitle
                    }
                    placeholder="Please consider adding a donation to your ticket purchase."
                    name="donationSubtitle"
                    onChange={updatePaywallSettings}
                  />
                </Flex>

                <Flex margin="0 0 26px" direction="column" align="flex-start">
                  <Text
                    margin="0 0 8px"
                    size="14px"
                    weight="bold"
                    color={colors.gray8}
                  >
                    Button Text
                  </Text>
                  <Text margin="0 0 8px" size="12px" color={colors.gray8}>
                    Customize your check out button or use the prefilled example
                    below.
                  </Text>
                  <Input
                    value={
                      information.paywallSettings &&
                      information.paywallSettings.donationCallToAction
                    }
                    placeholder="Continue To Checkout"
                    name="donationCallToAction"
                    onChange={updatePaywallSettings}
                  />
                </Flex>
              </>
            )}

            <Flex width="100%" height="348px" margin="0 0 8px">
              <Image
                src={require("assets/images/dono-example.png").default}
                width="100%"
              />
            </Flex>

            <Text size="12px" margin="0 0 32px" color={colors.gray8}>
              <i>Pre-Film Donation Request Example</i>
            </Text>

            <Text size="20px" margin="0 0 20px">
              Post-Film Donation Request
            </Text>

            <Text size="14px" margin="0 0 16px" color={colors.gray8}>
              Give your attendees the option to leave a donation before the
              credits at the end of your film. At the time stamp specified below
              attendees will be prompted to make a donation or click a button to
              continue watching.
            </Text>

            <Flex margin="0 0 20px">
              <Checkbox
                checked={
                  information.paywallSettings &&
                  information.paywallSettings.askMidrollDonations
                }
                onClick={() => {
                  setShowMidrollDonations(
                    !information.paywallSettings.askMidrollDonations
                  );
                  updatePaywallSettings({
                    target: {
                      name: "askMidrollDonations",
                      value: !information.paywallSettings.askMidrollDonations,
                    },
                  });
                }}
              />
              <Text margin="0 0 0 8px" size="14px" color={colors.gray8}>
                Request donation during the screening
              </Text>
            </Flex>

            {showMidrollDonations && (
              <>
                <Flex margin="0 0 26px" direction="column" align="flex-start">
                  <Text margin="0 0 8px" size="14px" color={colors.gray8}>
                    In the boxes below please input the time stamp right before
                    your credits begin.
                  </Text>
                  <Flex>
                    <Flex direction="column" width="50%">
                      <Text size="14px" margin="0 0 8px" color={colors.gray8}>
                        Minutes
                      </Text>
                      <Input
                        type="string"
                        value={
                          information.paywallSettings &&
                          information.paywallSettings
                            .midrollDonationTriggerMinutes
                        }
                        placeholder="00"
                        name="midrollDonationTriggerMinutes"
                        onChange={updatePaywallSettings}
                      />
                    </Flex>

                    <Text
                      size="14px"
                      margin="24px 8px 0 8px"
                      color={colors.gray8}
                    >
                      :
                    </Text>

                    <Flex direction="column" width="50%">
                      <Text size="14px" margin="0 0 8px" color={colors.gray8}>
                        Seconds
                      </Text>
                      <Input
                        type="string"
                        value={
                          information.paywallSettings &&
                          information.paywallSettings
                            .midrollDonationTriggerSeconds
                        }
                        placeholder="00"
                        name="midrollDonationTriggerSeconds"
                        onChange={updatePaywallSettings}
                      />
                    </Flex>
                  </Flex>
                </Flex>

                <Text size="14px" margin="0 0 16px" color={colors.gray8}>
                  Add your suggested donation amounts:
                </Text>

                <Text size="14px" margin="0 0 16px" color={colors.gray8}>
                  <b>💡 Tip:</b> You can always change these amounts later. If
                  you’re unsure where to start, use the suggested amounts below.
                </Text>

                <Text
                  size="12px"
                  margin="0 0 16px"
                  weight="bold"
                  color={colors.gray8}
                >
                  *Keep in mind that transaction fees are deducted from each
                  donation.
                </Text>

                <Flex margin="0 0 26px" direction="column" align="flex-start">
                  <Text
                    margin="0 0 8px"
                    size="14px"
                    weight="bold"
                    color={colors.gray8}
                  >
                    Donation Suggeston 1
                  </Text>
                  <Input
                    type="number"
                    min="0"
                    value={
                      information.paywallSettings &&
                      information.paywallSettings.midrollDonationSuggestion1
                    }
                    placeholder="10"
                    name="midrollDonationSuggestion1"
                    onChange={updatePaywallSettings}
                  />
                </Flex>

                <Flex margin="0 0 26px" direction="column" align="flex-start">
                  <Text
                    margin="0 0 8px"
                    size="14px"
                    weight="bold"
                    color={colors.gray8}
                  >
                    Donation Suggeston 2
                  </Text>
                  <Input
                    type="number"
                    min="0"
                    value={
                      information.paywallSettings &&
                      information.paywallSettings.midrollDonationSuggestion2
                    }
                    placeholder="20"
                    name="midrollDonationSuggestion2"
                    onChange={updatePaywallSettings}
                  />
                </Flex>

                <Flex margin="0 0 26px" direction="column" align="flex-start">
                  <Text
                    margin="0 0 8px"
                    size="14px"
                    weight="bold"
                    color={colors.gray8}
                  >
                    Donation Suggeston 3
                  </Text>
                  <Input
                    type="number"
                    min="0"
                    value={
                      information.paywallSettings &&
                      information.paywallSettings.midrollDonationSuggestion3
                    }
                    placeholder="50"
                    name="midrollDonationSuggestion3"
                    onChange={updatePaywallSettings}
                  />
                </Flex>

                <Flex margin="0 0 26px" direction="column" align="flex-start">
                  <Text
                    margin="0 0 8px"
                    size="14px"
                    weight="bold"
                    color={colors.gray8}
                  >
                    Donation Suggeston 4
                  </Text>
                  <Input
                    type="number"
                    min="0"
                    value={
                      information.paywallSettings &&
                      information.paywallSettings.midrollDonationSuggestion4
                    }
                    placeholder="100"
                    name="midrollDonationSuggestion4"
                    onChange={updatePaywallSettings}
                  />
                </Flex>

                <Flex margin="0 0 26px" direction="column" align="flex-start">
                  <Text
                    margin="0 0 8px"
                    size="14px"
                    weight="bold"
                    color={colors.gray8}
                  >
                    Title
                  </Text>
                  <Text margin="0 0 8px" size="14px" color={colors.gray8}>
                    <b>💡 Tip:</b> Include your partner's name and purpose.
                  </Text>
                  <Input
                    value={
                      information.paywallSettings &&
                      information.paywallSettings.midrollDonationTitle
                    }
                    placeholder="Please consider making a donation"
                    name="midrollDonationTitle"
                    onChange={updatePaywallSettings}
                  />
                </Flex>

                <Flex margin="0 0 26px" direction="column" align="flex-start">
                  <Text
                    margin="0 0 8px"
                    size="14px"
                    weight="bold"
                    color={colors.gray8}
                  >
                    Subtitle
                  </Text>
                  <Text margin="0 0 8px" size="12px" color={colors.gray8}>
                    Explain your mission or cause you are supporting.
                  </Text>
                  <Input
                    value={
                      information.paywallSettings &&
                      information.paywallSettings.midrollDonationSubtitle
                    }
                    placeholder="It helps to support the filmmakers or the cause!"
                    name="midrollDonationSubtitle"
                    onChange={updatePaywallSettings}
                  />
                </Flex>

                <Label fontSize="16px">Donation Cover Photo</Label>
                <Text margin="8px 0" size="14px" color={colors.gray8}>
                  Add a photo that best represents the cause you are supporting.
                </Text>
                <Text margin="0 0 8px" size="14px" color={colors.gray8}>
                  <b>💡 Tip:</b> We recommend using a high-quality photo that
                  will help tell your story and build trust with donors.
                </Text>
                <Text margin="0 0 8px" size="14px" color={colors.gray8}>
                  You must use an image with a 2 to 1.1 aspect ratio with a
                  minimum height of 700 pixels, e.g. 2000px width by 1100px
                  height.
                  <a
                    href="https://support.showandtell.film/hc/en-us/articles/360052340351-My-Banner-Image-Looks-Weird-How-Can-I-Fix-That-"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    &nbsp;Learn more.
                  </a>
                </Text>
                <FileFullWidth
                  image={information.paywallSettings.midrollBackgroundImage}
                  setUploaded={uploadBackground}
                />
              </>
            )}

            <Flex width="100%" height="348px" margin="0 0 8px">
              <Image
                src={require("assets/images/midroll-example.png").default}
                width="100%"
              />
            </Flex>

            <Text size="12px" margin="0 0 32px" color={colors.gray8}>
              <i>Post-Film Donation Request Example</i>
            </Text>
          </Outline>

          <Outline margin="0 0 26px">
            <Text size="24px" margin="0 0 26px">
              Recognize a Partner
            </Text>

            <Text size="14px" margin="0 0 32px" color={colors.gray8}>
              This allows you to recognize your partner in two ways: below the
              Title of your event and with a video message that plays before
              your film.
            </Text>

            <Flex margin="0 0 26px">
              <Checkbox
                checked={hasSponsor}
                onClick={() => toggleHasSponsor()}
              />
              <Text margin="0 0 0 8px" size="14px" color={colors.gray8}>
                I’d like to recognize a partner
              </Text>
            </Flex>

            {hasSponsor && (
              <>
                <Label>Partner Name</Label>
                <Input
                  value={information.sponsorName}
                  onChange={updateInformation}
                  name="sponsorName"
                  margin="0 0 26px"
                  placeholder="Name"
                />

                <Label>Partner URL</Label>
                <Input
                  value={information.sponsorUrl}
                  onChange={updateInformation}
                  name="sponsorUrl"
                  margin="0 0 26px"
                  placeholder="https://google.com"
                />
                <Flex margin="0 0 26px">
                  <Checkbox
                    checked={showPreRoll}
                    onClick={() => togglePreroll()}
                  />
                  <Text margin="0 0 0 8px" size="14px" color={colors.gray8}>
                    Add a video message before your film.
                  </Text>
                </Flex>

                {showPreRoll && (
                  <>
                    <Label>Add Partner Video</Label>
                    <Text size="14px" margin="0 0 32px" color={colors.gray8}>
                      This video will automatically play before your film. It is
                      a separate Vimeo link.
                    </Text>
                    <Input
                      value={information.sponsorPrerollUrl}
                      onChange={updateInformation}
                      name="sponsorPrerollUrl"
                      margin="0 0 26px"
                      placeholder="Paste Vimeo URL Here"
                    />

                    {information.sponsorPrerollUrl && (
                      <Flex margin="0 0 24px">
                        <Player
                          controls={true}
                          reload={reloadSponsorVideo}
                          url={information.sponsorPrerollUrl}
                          isdashboard="true"
                        />
                      </Flex>
                    )}
                  </>
                )}
              </>
            )}
          </Outline>

          <Outline margin="0 0 26px">
            <Text size="24px" margin="0 0 26px">
              Add a Contact Email
            </Text>

            <Text size="14px" margin="0 0 32px" color={colors.gray8}>
              This is the e-mail address you'd like viewers to reach you. It
              will be at the bottom of your event page and included on
              confirmation emails sent to your viewers.
            </Text>

            <Flex margin="0 0 26px">
              <Input
                value={information.filmTeamEmail}
                onChange={updateInformation}
                name="filmTeamEmail"
                margin="0 0 26px"
                placeholder="Contact Email"
              />
            </Flex>
          </Outline>

          <Flex margin="0 0 32px" justify="flex-end">
            {isExisting ? (
              <>
                {loading ? (
                  <Button>Saving...</Button>
                ) : (
                  <Button onClick={updateScreening}>Save</Button>
                )}
              </>
            ) : (
              <>
                <Button
                  onClick={() => setStep("trailer")}
                  className="secondary"
                  margin="0 8px 0 0"
                >
                  Back
                </Button>
                <Button onClick={() => saveAndContinue()}>
                  Save and Continue
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
