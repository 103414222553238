import React, { useState, useMemo } from "react";
import {
  Flex,
  Text,
  Button,
  MobileFlex,
  TosCheckbox,
  ReadMoreText,
} from "components";
import { FormField } from "./";
import { SUBSCRIBERS } from "api";
import { toast } from "react-toastify";
import { validate } from "utils";
import { useWidth } from "hooks";
import * as S from "./PrepayModal.styled";
toast.configure();

const CTA = ({ text, action }) => (
  <Button
    onClick={() => action()}
    padding="20px 32px"
    margin="40px 0 0"
    height="auto"
  >
    <Text size="18px" padding="0 18px 0 0 ">
      {text}
    </Text>
    <img
      src={require("assets/images/icon-arrow-right.svg").default}
      alt="arrow-right"
    />
  </Button>
);

export default ({
  close,
  eventId,
  paywallSettings,
  next,
  donationAmount,
  setDonationAmount,
  subscriptionSettings,
  registerar,
  setRegisterar,
  gateStatus,
  eventReminderOptOut,
}) => {
  const defaultDonations = useMemo(
    () => ({
      0: null,
      1: 10,
      2: 20,
      3: 50,
      4: 100,
      Custom: "",
    }),
    []
  );
  const [errors, setErrors] = useState({
    email: false,
    name: false,
    organization: false,
    country: false,
    zip: false,
    customDonation: false,
  });
  const [reloadErrors, setReloadErrors] = useState(false);
  const [donate, setDonate] = useState({});
  const [selectedDonation, setSelectedDonation] = useState(
    paywallSettings?.donationSuggestion1 || defaultDonations[1]
  );
  const [isSubscribed] = useState(
    localStorage.getItem("isSubscribedTo") &&
      localStorage.getItem("isSubscribedTo") === eventId
  );
  const [isPaywall] = useState(paywallSettings?.enablePaywall);
  const [tosAccepted, setTosAccepted] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const width = useWidth();

  const toggleTos = () => setTosAccepted(!tosAccepted);

  const continueToCheckout = () => {
    if (
      donate.customAmount === "0" ||
      (donate.customAmount === "" && !donationAmount)
    ) {
      return isPaywall && next(true);
    } else {
      return isSubscribed
        ? (donate.customAmount &&
            setDonationAmount(parseInt(donate.customAmount)),
          next(true))
        : register({ capturePayment: true });
    }
  };

  const nonRegisteredSubmit = () => {
    if (tosAccepted) {
      isSubscribed ? next(false) : register({ capturePayment: false });
    } else {
      return null;
    }
  };

  const updateRegisterar = (e) => {
    registerar[e.target.name] = e.target.value;
    setRegisterar(registerar);
  };

  const updateDonate = (e) => {
    let amount = e.target.value;
    amount < 0 && (amount = Math.abs(amount));
    donate[e.target.name] = amount;
    setDonate(donate);
  };

  const updateCustomDonate = () => {
    const _userAmount = paywallSettings?.donationSuggestionCustom;
    let _defaultAmount = defaultDonations["Custom"];
    _userAmount && (_defaultAmount = _userAmount);

    const _currentAmount = donate.customAmount;

    if (_currentAmount === undefined || _currentAmount === "") {
      const updated = { ...donate };
      updated.customAmount = _defaultAmount;
      setDonate(updated);
    }
  };

  const selectDonate = (field) => {
    const suggestedPrice = paywallSettings?.[`donationSuggestion${field}`];
    setDonationAmount(suggestedPrice || defaultDonations[field]);
    setSelectedDonation(
      field === "Custom" ? "custom" : defaultDonations[field]
    );
    setDonate(donate);
  };

  const isDonationSelected = (field) => {
    const suggestedPrice = paywallSettings?.[`donationSuggestion${field}`];
    const customSelected = selectedDonation === "custom";
    let isSelected = suggestedPrice
      ? donationAmount * 1 === suggestedPrice * 1 && !customSelected
      : donationAmount * 1 === defaultDonations[field] * 1 && !customSelected;

    selectedDonation === "custom" && field === "Custom" && (isSelected = true);

    return isSelected;
  };

  const register = async ({ capturePayment }) => {
    try {
      let _ready = true;
      const _errors = {};
      if (!registerar.name) {
        toast.error("Please enter a name", { autoClose: 3000 });
        _errors.name = true;
        _ready = false;
      }
      if (!registerar.email) {
        toast.error("Please enter an email", { autoClose: 3000 });
        _errors.email = true;
        _ready = false;
      }
      if (!validate("email", registerar.email)) {
        toast.error("Please enter a valid email", { autoClose: 3000 });
        _errors.email = true;
        _ready = false;
      }
      if (
        subscriptionSettings?.captureOrganization &&
        !registerar.organization
      ) {
        toast.error("Please enter a organization", { autoClose: 3000 });
        _errors.organization = true;
        _ready = false;
      }
      if (subscriptionSettings?.captureCountry && !registerar.country) {
        toast.error("Please enter a country", { autoClose: 3000 });
        _errors.country = true;
        _ready = false;
      }
      if (subscriptionSettings?.captureZip && !registerar.zip) {
        toast.error("Please enter a zip code", { autoClose: 3000 });
        _errors.zip = true;
        _ready = false;
      }
      setErrors(_errors);
      setReloadErrors(!reloadErrors);

      registerar.eventReminderOptOut = eventReminderOptOut || false;

      if (_ready) {
        const _subscriber = await SUBSCRIBERS.create(eventId, registerar);
        window.localStorage.setItem(
          `subscriber-${eventId}`,
          _subscriber.data.data
        );
        window.localStorage.setItem(`isSubscribedTo`, eventId);
        toast.success("Registration successful", { autoClose: 3000 });
        capturePayment &&
          donate.customAmount &&
          setDonationAmount(parseInt(donate.customAmount));
        next(capturePayment);
      }
    } catch (err) {
      toast.error("Please enter a valid email.", { autoClose: 3000 });
    }
  };

  const determineText = () => {
    if (paywallSettings?.askDonations) {
      return {
        title:
          paywallSettings?.donationTitle || "Please consider adding a donation",
        subtitle: paywallSettings?.donationSubtitle || "",
      };
    } else {
      return {
        title:
          paywallSettings?.title || "Please share some basic info to continue",
        subtitle: paywallSettings?.subtitle || "",
      };
    }
  };

  const FormSide = () => (
    <MobileFlex>
      {isSubscribed && (
        <Text color="#292929" className="bold-italic">
          already subscribed to event
        </Text>
      )}
      <Flex justify="space-between" wrap={1}>
        <FormField
          disabled={isSubscribed}
          width="half"
          label="Name"
          name={`name`}
          onChange={updateRegisterar}
          error={errors.name}
          reload={reloadErrors}
          value={registerar.name}
          responsive={{ tablet: { width: "100%", padding: "0" } }}
        />
        <FormField
          disabled={isSubscribed}
          width="half"
          label="Email Address"
          name="email"
          onChange={updateRegisterar}
          error={errors.email}
          reload={reloadErrors}
          value={registerar.email}
          responsive={{ tablet: { width: "100%", padding: "0" } }}
        />
      </Flex>

      <FormField
        disabled={isSubscribed}
        width="100%"
        label="Organization"
        name="organization"
        onChange={updateRegisterar}
        error={errors.organization}
        reload={reloadErrors}
        value={registerar.organization}
        responsive={{ tablet: { width: "100%", padding: "0" } }}
      />

      <Flex justify="space-between" wrap={1}>
        <FormField
          disabled={isSubscribed}
          width="half"
          label="Country"
          name="country"
          onChange={updateRegisterar}
          error={errors.country}
          reload={reloadErrors}
          value={registerar.country}
          responsive={{ tablet: { width: "100%", padding: "0" } }}
        />

        <FormField
          disabled={isSubscribed}
          width="half"
          label="Zip"
          name="zip"
          onChange={updateRegisterar}
          error={errors.zip}
          reload={reloadErrors}
          value={registerar.zip}
          responsive={{ tablet: { width: "100%", padding: "0" } }}
        />
      </Flex>

      {paywallSettings?.askDonations && (
        <MobileFlex margin="12px 0">
          <Text dark padding="4px 0px">
            Donation Amount
          </Text>
          <S.Donations width="100%" align="flex-start">
            <S.Option
              width="64px"
              selected={isDonationSelected(0)}
              onClick={() => selectDonate(0)}
            >
              $0
            </S.Option>
            <S.Option
              width="64px"
              selected={isDonationSelected(1)}
              onClick={() => selectDonate(1)}
            >
              ${paywallSettings?.donationSuggestion1 || 10}
            </S.Option>
            <S.Option
              width="64px"
              selected={isDonationSelected(2)}
              onClick={() => selectDonate(2)}
            >
              ${paywallSettings?.donationSuggestion2 || 20}
            </S.Option>
            <S.Option
              width="64px"
              selected={isDonationSelected(3)}
              onClick={() => selectDonate(3)}
            >
              ${paywallSettings?.donationSuggestion3 || 50}
            </S.Option>
            <S.Option
              width="64px"
              selected={isDonationSelected(4)}
              onClick={() => selectDonate(4)}
            >
              ${paywallSettings?.donationSuggestion4 || 100}
            </S.Option>
            <S.Option
              selected={isDonationSelected("Custom")}
              onClick={() => selectDonate("Custom")}
              width="171px"
              margin="0"
              padding="0 0 0 12px"
            >
              $
              <S.OptionInput
                value={donate.customAmount}
                onChange={updateDonate}
                name="customAmount"
                placeholder={
                  selectedDonation === "custom" ? "" : "Other Amount"
                }
                type="number"
                min="0"
                color={donate.customAmount && "white"}
                selected={isDonationSelected("Custom")}
                onClick={() => updateCustomDonate()}
              />
            </S.Option>
          </S.Donations>
        </MobileFlex>
      )}

      {isPaywall && (
        <TosCheckbox tosAccepted={tosAccepted} toggleTos={toggleTos} dark />
      )}

      {/*
        if user is subscribed to event, bypass register() and go to next()
        if there is a donation, register({ capturePayment: true }) and go to payment portal
      */}
      <MobileFlex>
        {!isPaywall &&
          selectedDonation !== "custom" &&
          donationAmount === null && (
            <>
              <TosCheckbox
                tosAccepted={tosAccepted}
                toggleTos={toggleTos}
                dark
              />
              <Button
                onClick={() => tosAccepted && nonRegisteredSubmit()}
                padding="19px 30px"
                margin="24px 21px 0 0"
                height="auto"
                className={`${!tosAccepted ? "disabled" : ""}`}
              >
                <Text size="18px" padding="0 18px 0 0">
                  {gateStatus.live ? "Keep Watching" : "Get Notified"}
                </Text>
              </Button>
            </>
          )}
        {paywallSettings?.askDonations ? (
          (isPaywall ||
            donationAmount > 0 ||
            selectedDonation === "custom") && (
            <CTA
              action={continueToCheckout}
              text={
                paywallSettings?.donationCallToAction || `Continue To Checkout`
              }
              margin="0 25px 0 0"
            />
          )
        ) : (
          <>
            <CTA
              action={continueToCheckout}
              text={
                paywallSettings?.donationCallToAction || `Continue To Checkout`
              }
              margin="0 25px 0 0"
            />
          </>
        )}
      </MobileFlex>
    </MobileFlex>
  );

  return (
    <S.Wrapper direction="row" align="flex-start" padding="0 50px 0 50px">
      {/* <S.Close
        onClick={() => close()}
        src={require("assets/images/icon-exit-dark.svg").default}
      /> */}
      <S.WidthProvider>
        <ReadMoreText
          title={determineText().title}
          description={determineText().subtitle}
          padding={width > 1230 ? "0" : "0 24px"}
          screenWidth={width}
          titleMargin={width < 768 ? "0 0 12px" : "0 0 18px"}
          sponsorMargin={width < 768 ? "0 0 8px" : "0 0 12px"}
          descriptionMargin={width < 768 ? "0 0 8px" : "0"}
          truncateDescription={
            width < 768 || determineText().subtitle.length > 400
          }
          readMore={readMore}
          setReadMore={setReadMore}
          dark
        />
      </S.WidthProvider>
      <S.WidthProvider>
        <FormSide />
      </S.WidthProvider>
    </S.Wrapper>
  );
};
