import { atom, selector } from "recoil";

export const globalTags = atom({
  key: "globalTags",
  default: [],
});

export const customersAtom = atom({
  key: "customers",
  default: [],
});

export const selectedCustomersAtom = atom({
  key: "selectedCustomers",
  default: [],
});

export const eventsAtom = atom({
  key: "events",
  default: [],
});

export const selectedEventsAtom = atom({
  key: "selectedEvents",
  default: [],
});

export const selectedEventsSelector = selector({
  key: "selectedEventsSelector",
  get: ({ get }) => {
    const selectedEventIds = get(selectedEventsAtom);
    if (selectedEventIds[0]) {
      const events = get(eventsAtom);
      if (events[0]) {
        const selectedEvents = [];
        for (const eventId of selectedEventIds) {
          const selectedEvent = events.find((event) => event.id === eventId);
          selectedEvent && selectedEvents.push(selectedEvent);
        }
        return selectedEvents;
      }
    }
  },
  set: ({ set, get }, newValue) => {
    set(selectedEventsAtom, newValue);
  },
});

export const selectedLeadsAtom = atom({
  key: "selectedLeads",
  default: [],
});

export const selectedOrdersAtom = atom({
  key: "selectedOrders",
  default: [],
});

export const updateCustomersTableAtom = atom({
  key: "updateCustomersTable",
  default: Date.now(),
});

export const updateLeadownersTableAtom = atom({
  key: "updateLeadownersTable",
  default: Date.now(),
});
