import React, { useState, useEffect } from "react";
import { Flex, Text, Button, Input, SimplePrompt } from "components";
import { colors } from "consts";
import AdminTable from "../AdminTable";
import * as S from "./AdminCustomerDetails.styled";
import { CREDITS } from "api";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

export default ({ ownerDetails: { email, credits, credits_log } }) => {
  // HOOKS
  const history = useHistory();
  const { customerId } = useParams();

  // STATE
  const [creditLog, setCreditLog] = useState(null);
  const [usedCredits, setUsedCredits] = useState(null);
  const [currentCredits, setCurrentCredits] = useState(null);
  const [updatingCredits, setUpdatingCredits] = useState(false);
  const [creditsChange, setCreditsChange] = useState(false);

  // METHODS
  const submitCredits = async (credits) => {
    if (credits && email) {
      try {
        await CREDITS.createCredit({
          email: email,
          credits: parseInt(credits),
        });
        setUpdatingCredits(false);
        setCurrentCredits(credits);
        toast("credits successfully updated", { autoClose: 3000 });
      } catch (err) {
        let _message = "";
        if (err.validation) {
          for (var i in err.validation) {
            _message.concat(err.validation[i].msg);
          }
        }
        toast("Error updating credits", { autoClose: 3000 });
      }
    }
  };

  // USE EFFECT
  useEffect(() => {
    credits && setCurrentCredits(credits.credits);
    credits_log && setCreditLog(credits_log);
  }, [credits, credits_log]);

  useEffect(() => {
    if (!credits_log) return;
    setUsedCredits(
      credits_log.reduce((prev, curr) => {
        if (curr.type === "credit_usage") {
          const curr_val = Math.abs(parseInt(curr.creditsChange));
          return prev + curr_val;
        }
        return prev;
      }, 0)
    );
  }, [credits_log]);

  return (
    <S.ListContainer margin="0 0 24px 0" maxHeight="450px">
      <Flex
        justify="space-between"
        align="flex-start"
        margin="0 0 24px 0"
        width="100%"
      >
        <Flex direction="column" align="flex-start">
          <Text size="24px" weight="bold">
            Credits Used Log
          </Text>
          <Text size="16px" weight="bold" color={colors.orange}>
            Credits Used - {usedCredits ? `${usedCredits}` : `0`}
          </Text>
          <Text size="16px" weight="bold" color={colors.orange}>
            Available Credits - {currentCredits ? `${currentCredits}` : `0`}
          </Text>
        </Flex>
        <Flex justify="flex-end">
          <Button
            className="secondary"
            onClick={() => {
              setUpdatingCredits(true);
            }}
          >
            Edit Credits
          </Button>
        </Flex>
      </Flex>

      {creditLog?.length > 0 ? (
        <AdminTable
          type="customer_credits"
          data={creditLog}
          {...{ history, customerId }}
        />
      ) : (
        <Flex>
          <Text>No Credit Logs</Text>
        </Flex>
      )}

      {updatingCredits && (
        <SimplePrompt close={() => setUpdatingCredits(false)}>
          <Flex padding="20px" direction="column" align="flex-start">
            <Text htmlFor="note" align="start" margin="0 0 8px">
              Credits:
            </Text>
            <Input
              type="number"
              value={creditsChange === false ? currentCredits : creditsChange}
              onChange={(e) => setCreditsChange(e.target.value)}
            />
            <Button
              className={`${!creditsChange && "disabled"}`}
              onClick={(e) => submitCredits(creditsChange)}
              margin="16px 0 0"
            >
              Submit Change
            </Button>
          </Flex>
        </SimplePrompt>
      )}
    </S.ListContainer>
  );
};
