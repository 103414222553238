import { useQuery } from "react-query";
import axiosRequest from "../../api/axios.config";
import queryClient from "../queryClient";

const fetcher = async (
  searchTerm,
  page,
  activated,
  archived,
  superarchived,
  startEventRange_start,
  startEventRange_end,
  tag
) => {
  let params = `?`;
  if (searchTerm) params = `${params}&search_term=${searchTerm}`;
  if (page) params = `${params}&page=${page}`;
  if (activated) params = `${params}&activated=${page}`;
  if (archived) params = `${params}&archived=${archived}`;
  if (superarchived) params = `${params}&superarchived=${superarchived}`;
  if (startEventRange_start)
    params = `${params}&startEventRange_start=${startEventRange_start}`;
  if (startEventRange_end)
    params = `${params}&startEventRange_end=${startEventRange_end}`;
  if (tag) params = `${params}&tag=${tag}`;

  let endpoint = `/v1/admin/pages`;
  if (params !== "?") endpoint = `${endpoint}${params}`;
  const raw = await axiosRequest.get(endpoint);
  return raw.data.data;
};

const useGetFilteredEvents = (
  searchTerm,
  page,
  {
    activated,
    archived,
    superarchived,
    startEventRange_start,
    startEventRange_end,
    tag,
  }
) => {
  return useQuery(
    [
      "accounts",
      "filtered",
      `searchTerm=${searchTerm}`,
      `page=${page}`,
      `activated=${activated}`,
      `archived=${archived}`,
      `superarchived=${superarchived}`,
      `startEventRange_start=${startEventRange_start}`,
      `startEventRange_end=${startEventRange_end}`,
      `tag=${tag}`,
    ],
    async () => {
      // TODO: improve by using axios cancel token instead of setting cacheTime to 0
      // that way if they backtrack you are not recalling the data

      const data = await fetcher(
        searchTerm,
        page,
        activated,
        archived,
        superarchived,
        startEventRange_start,
        startEventRange_end,
        tag
      );

      data.data.forEach((event) => {
        queryClient.setQueryData(["event", event.id], event);
      });

      const response = {
        total: data?.total || null, // this should be the total of the NON filtered / searched data
        data: data.data,
      };

      return response;
    },
    {
      enabled:
        !!searchTerm ||
        (!!page && page !== 1) ||
        !!activated ||
        !!archived ||
        !!superarchived ||
        !!startEventRange_start ||
        !!startEventRange_end ||
        !!tag,
    }
  );
};

const useGetEvents = () => {
  return useQuery(["events"], async () => {
    const data = await fetcher();

    data.data.forEach((event) => {
      queryClient.setQueryData(["event", event.id], event);
    });

    const response = {
      total: data?.total || null, // this should be the total of the NON filtered / searched data
      data: data.data,
    };

    return response;
  });
};

export { useGetEvents, useGetFilteredEvents };
