import { Button, Flex, Text } from "components";
import { useRef, useState } from "react";
import ReactPlayer from "react-player";

export default ({ setStep, credits }) => {
  const [continueDisabled, setContinueDisabled] = useState(true);
  const [showTranscript, setShowTranscript] = useState(false);
  const playerRef = useRef();

  const videoWatched = localStorage.getItem("instructionVideoWatched");
  const videoFinished = () => {
    localStorage.setItem("instructionVideoWatched", true);
    setContinueDisabled(false);
  };

  const restartVideo = () => {
    const player = playerRef?.current?.getInternalPlayer();
    player.setCurrentTime(0);
    player.play();
  };

  const buttonDisabled = continueDisabled && !videoWatched;

  const OutOfCredits = credits < 1;

  return (
    <Flex column gap="24px" overflowY="auto" maxHeight="500px">
      <Text kind="h2" size="24px" weight="bold">
        Please watch this 2 minute video message from the Show&Tell team
      </Text>
      <ReactPlayer
        controls={false}
        playsinline={true}
        playing={true}
        url={`https://vimeo.com/833482943`}
        width="100%"
        height="300px"
        onEnded={videoFinished}
        ref={playerRef}
      />
      <Flex gap="12px" width="100%" center>
        <Button onClick={restartVideo}>Restart video</Button>
        <Button onClick={() => setShowTranscript((show) => !show)}>
          {showTranscript ? "Hide" : "Show"} transcript
        </Button>
        <Button
          onClick={() =>
            setStep(OutOfCredits ? "purchase-credits" : "choose-event-type")
          }
          disabled={buttonDisabled}
        >
          Continue
        </Button>
        {/* <Button
          onClick={() => setStep("purchase-credits")}
          disabled={buttonDisabled}
        >
          Purchase credits
        </Button> */}
      </Flex>
      {showTranscript && <Transcript />}
    </Flex>
  );
};

const Transcript = () => {
  return (
    <Flex width="80%" column gap="20px">
      <Text>
        Hi, I'm Keith and I'm the founder of Show&Tell. This is my teammate,
        Siena. I'm a documentary filmmaker and educator supporting our community
        of filmmakers that are seeking help with fundraising and distribution.
      </Text>

      <Text>
        Hi, I'm Siena. I'm a filmmaker and I'm the director of Client
        Experience. Let's talk about virtual events.
      </Text>

      <Text>
        Our goal is to help filmmakers build beautiful and dependable virtual
        events that help their films get seen and help them make money. I want
        to set some expectations. Show&Tell is a small team. We can't offer 24
        hour customer service. Our team is working between Monday and Friday, 9
        a.m. to 5 p.m. Pacific. That said, we'll get back to you on your
        questions as quickly as we can. And we always make sure that all events
        are tested and reviewed before we activate them. We want to make sure
        your event is perfect before you go live.
      </Text>

      <Text>
        You have two options when it comes to building your virtual event. You
        can build it yourself or you can have the Show&Tell team build it for
        you. If this is your first time, we recommend having our team build it
        for you to make it easier on you. That being said, if you're pretty tech
        savvy, feel free to build your own. Either option costs the same. This
        is more about how much support you want during the building process of
        your virtual event. Add your video, add your language, and feel free to
        work with all the customization options that we have on our platform. We
        did our best to make these features easy for you to use, but if you have
        questions, feel free to check out our Help Desk. Remember, while we
        don't offer 24 hours customer service, we are available to you Monday
        through Friday, 9 a.m. to 5 p.m. Pacific Time. And we will do our best
        to get back to you as quickly as possible. A few things to note if you
        would like Show&Tell to build your event for you. We need a little bit
        of lead time in order to make your event as beautiful and dependable as
        possible when it comes to giving us the assets like your video, photos
        and language, please follow our instructions. Following the process
        leads to a faster turnaround for your event and a better overall
        experience for all involved.
      </Text>

      <Text>
        So it's time to make your choice on which approach you'd like to take
        with building a virtual event. Either way, we'll support you. We've
        helped hundreds of filmmakers build thousands of virtual events and
        reach millions of viewers. Let's get started.
      </Text>
    </Flex>
  );
};
