import { Flex, Text, Button } from "components";
import * as S from "./AdminDrawers.styled";
import { colors, countries } from "consts";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

export default ({ values: { original } }) => {
  // HOOKS
  const history = useHistory();

  // METHODS
  const linkToDetails = (customerId) => {
    const location = {
      pathname: `/admin/customer-details/${customerId}`,
      state: { superadmin: true },
    };
    history.push(location);
  };

  return (
    <S.Drawer>
      <Flex width="100%" direction="column">
        <S.DrawerItem width="100%">
          <Text size="14px" weight="bold">
            Country
          </Text>
          <Text size="14px">
            {countries.filter((item) => {
              if (item["alpha-2-code"] === original.country) return true;
              return false;
            })[0].country || "N/A"}
          </Text>
        </S.DrawerItem>
        <S.DrawerItem width="100%">
          <Text size="14px" weight="bold">
            Date account created
          </Text>
          <Text size="14px">
            {format(new Date(original.createdAt), "PP, pp")}
          </Text>
        </S.DrawerItem>
        <S.DrawerItem width="100%">
          <Text size="14px" weight="bold">
            Teachable ID
          </Text>
          <Text size="14px">{original.teachableId || "N/A"}</Text>
        </S.DrawerItem>
        <S.DrawerItem width="100%">
          <Text size="14px" weight="bold">
            Stripe purchases
          </Text>
          <Text
            size="14px"
            color={colors.orange}
            pointer
            onClick={() => linkToDetails(original.id)}
          >
            See details
          </Text>
        </S.DrawerItem>
        <S.DrawerItem width="100%">
          <Text size="14px" weight="bold">
            Notes
          </Text>
          <Text
            size="14px"
            color={colors.orange}
            pointer
            onClick={() => linkToDetails(original.id)}
          >
            See details
          </Text>
        </S.DrawerItem>
        <S.DrawerItem width="100%">
          <Button onClick={() => linkToDetails(original.id)}>
            View full details
          </Button>
        </S.DrawerItem>
      </Flex>
    </S.Drawer>
  );
};
