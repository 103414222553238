import React, { useRef } from "react";
import { Text, Flex, Button } from "components";
import { measureImage } from "utils";
import "react-image-crop/dist/ReactCrop.css";
import { FILES } from "api";
import * as S from "./Cropper.styled";

export default function ImageCrop({
  image,
  loading,
  setLoading,
  croppedImage,
  updateInformation,
  target,
  closeModal,
}) {
  const inputRef = useRef(null);

  const uploadImage = async (files) => {
    try {
      setLoading(true);
      const _uploaded = await FILES.create(files);
      const url = _uploaded.data.data[0].url;

      let imageTarget = "backgroundImage";
      if (target === "desktop") imageTarget = "backgroundImage";
      if (target === "mobile") imageTarget = "mobileBackgroundImage";

      measureImage(url, (success) => {
        if (success) {
          updateInformation({
            target: {
              name: imageTarget,
              value: url,
            },
          });
        } else {
          setLoading(false);
        }
      });
    } catch (err) {
      console.error("failed to upload image", err);
    }
  };

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = e.target.files;
      await uploadImage(files);
    }
  };

  const convertBlobToFile = async (blob) => {
    const fetchedBlob = await fetch(blob);
    const bufferedArray = await fetchedBlob.arrayBuffer();
    const convertedFile = new File([bufferedArray], image.name, {
      type: image.type,
    });
    return convertedFile;
  };

  const applyImage = async () => {
    const file = await convertBlobToFile(croppedImage);
    await uploadImage([file]);
    closeModal();
  };

  return (
    <Flex justify="space-between" align="center">
      <S.UploadInput
        ref={inputRef}
        style={{ display: "none" }}
        type="file"
        accept="image/*"
        onChange={onSelectFile}
      />
      <Text
        size="16px"
        margin="0 0 40px"
        padding="25px 0 0 0"
        onClick={() => closeModal?.()}
        style={{ cursor: "pointer" }}
      >
        Cancel
      </Text>

      <Flex justify="flex-end">
        <Button
          width="156px"
          margin="0 15px 0 0"
          className="outline-white large"
          onClick={() => inputRef?.current?.click()}
          disabled={loading}
        >
          Change photo
        </Button>
        <Button
          width="140px"
          margin="0 0 0 15px"
          className="large"
          onClick={async () => applyImage()}
          disabled={loading}
        >
          Apply
        </Button>
      </Flex>
    </Flex>
  );
}
