import axiosRequest from "./axios.config";

export const postAccountNote = async (account_id, body) =>
  await axiosRequest.post(`/v1/admin/account/note/${account_id}`, body);

export const postLeadownerNote = async (leadowner_id, body) =>
  await axiosRequest.post(`/v1/admin/leadowner/note/${leadowner_id}`, body);

export const patchNote = async (note_id, body) =>
  await axiosRequest.patch(`/v1/admin/notes?note_id=${note_id}`, body);

export const deleteNote = async (note_id) => {
  await axiosRequest.delete(`/v1/admin/note/${note_id}`);
};
