import React, { useState, useEffect } from "react";
import { useAnalytics } from "use-analytics";
import {
  BackgroundProvider,
  Button,
  Flex,
  Greenlight,
  Header,
  SimplePrompt,
  Text,
  DashboardCreateEventModal,
} from "components";
import styled from "styled-components";
import Zendesk from "react-zendesk";
import EventsGrid from "templates/EventsGrid";
import { DashboardBankInfo } from "components/Dashboard/DashboardActivation";
import { CREDITS, USERS } from "api";

const QuestionIcon = styled.div`
  font-size: 10px;
  border: 2px solid white;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  line-height: 1;
  margin-left: 8px;
  color: white;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
`;

export default () => {
  const [credits, setCredits] = useState(0);
  const [showCreateEventModal, setShowCreateEventModal] = useState(undefined);
  const [showCreditsInfo, setShowCreditsInfo] = useState(false);
  const [stripeStatus, setStripeStatus] = useState(undefined);
  const [showSupportPrompt, setShowSupportPrompt] = useState(false);
  const [bankInfoPrompt, setBankInfoPrompt] = useState(false);
  const [showBankInfoForm, setShowBankInfoForm] = useState(false);
  const [stripeConnectedBankStatus, setStripeConnectedBankStatus] = useState();
  const { page } = useAnalytics();

  useEffect(() => {
    page();
  }, [page]);

  const getCredits = async () => {
    const {
      data: { credits },
    } = await CREDITS.getCredits();
    typeof credits !== "undefined" && setCredits(credits);
  };

  useEffect(() => {
    getCredits();
    checkStripeStatus();
    // eslint-disable-next-line
  }, []);

  const checkStripeStatus = async () => {
    const { data } = await USERS.checkCapabilities();
    if (data?.data?.capability?.status === "active") {
      setStripeStatus("COMPLETE");
    } else {
      setStripeStatus("INCOMPLETE");
    }

    if (data?.data?.hasConnectedBank) {
      setStripeConnectedBankStatus("COMPLETE");
    } else {
      setStripeConnectedBankStatus("INCOMPLETE");
    }
  };

  return (
    <>
      {showBankInfoForm && (
        <SimplePrompt close={() => setShowBankInfoForm(false)}>
          <DashboardBankInfo
            setConnectedBank={() => setStripeConnectedBankStatus("COMPLETE")}
            setShowDisbursement={() => setShowBankInfoForm(false)}
          />
        </SimplePrompt>
      )}
      {bankInfoPrompt && (
        <SimplePrompt close={() => setBankInfoPrompt(false)}>
          <Text size="44px" weight="500" margin="0 0 24px" className="canela">
            Connected Bank
          </Text>
          <Text size="18px" margin="0 0 24px">
            In order for us to disburse funds from your Stripe connected
            account, you’ll need to provide your bank information (routing
            number, account number).
          </Text>

          <Text size="18px" margin="0 0 12px" weight="bold">
            Note: this option will only be available after you've Connected
            Stripe.
          </Text>
          <Button
            onClick={() => {
              setBankInfoPrompt(false);
              setShowBankInfoForm(true);
            }}
            width="50%"
            padding="20px 0"
            margin="24px auto"
          >
            <Text size="18px" weight="700">
              Enter Bank Info
            </Text>
          </Button>
        </SimplePrompt>
      )}
      {showSupportPrompt && (
        <SimplePrompt close={() => setShowSupportPrompt(false)}>
          <Text size="44px" weight="500" margin="0 0 24px" className="canela">
            Need help with your Stripe account?
          </Text>
          <Text size="18px" margin="0 0 12px">
            Contact our customer service and we will be happy to help!
          </Text>
          <Flex margin="84px 0 0">
            <a href="mailto:support@showandtell.film">
              <Button width="200px" padding="20px 0" margin="0 12px 0 0">
                <Text size="18px" weight="700">
                  Contact Support
                </Text>
              </Button>
            </a>
            <Button
              onClick={() => setShowCreateEventModal(undefined)}
              width="200px"
              padding="20px 0"
              margin="0 12px 0 0"
              className="secondary"
            >
              <Text size="18px" weight="700">
                Cancel
              </Text>
            </Button>
          </Flex>
        </SimplePrompt>
      )}
      {showCreateEventModal && (
        <DashboardCreateEventModal
          {...{ setShowCreateEventModal, credits, setCredits }}
        />
      )}
      <BackgroundProvider align="flex-start" minHeight="100vh">
        <Zendesk zendeskKey={"2a2352ea-85dc-4661-9d28-bc3ce436ae6c"} />
        <Header user showBackButton={false} />

        <Flex margin="89px 0 0">
          {showCreditsInfo && (
            <SimplePrompt close={() => setShowCreditsInfo(false)}>
              <Text size="24px" center>
                These credits are from a preexisting purchase. To use these
                credits please click on the orange Create an Event button
              </Text>
            </SimplePrompt>
          )}
          <Flex
            width="1500px"
            margin="0 auto"
            padding="60px 40px"
            direction="column"
            align="flex-start"
            justify="flex-start"
          >
            <Flex margin="24px 0" justify="space-between" align="flex-start">
              <Text
                size="64px"
                weight="500"
                margin="0 0 17px"
                className="canela"
              >
                Filmmaker Dashboard
              </Text>
              <Flex direction="column" width="320px">
                <Button
                  width="320px"
                  className="large"
                  onClick={() => setShowCreateEventModal(new Date())}
                >
                  <Text size="18px" weight="700">
                    Create an Event
                  </Text>
                </Button>
                {credits > 0 && (
                  <Flex justify="center" margin="12px 0 0">
                    <Text size="18px" weight="700">
                      {credits} credits available
                    </Text>
                    <QuestionIcon onClick={() => setShowCreditsInfo(true)}>
                      ?
                    </QuestionIcon>
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Greenlight
              size="20px"
              active={stripeStatus === "COMPLETE"}
              activeText="Your Stripe authentication is complete!"
              inactiveText="Your Stripe authentication is incomplete"
              {...(stripeStatus === "INCOMPLETE" && {
                SupportElement: (
                  <QuestionIcon onClick={() => setShowSupportPrompt(true)}>
                    ?
                  </QuestionIcon>
                ),
              })}
            />
            <Greenlight
              size="20px"
              active={stripeConnectedBankStatus === "COMPLETE"}
              activeText="Your bank account is verified and set to receive funds"
              inactiveText="Complete bank account verification to receive payments"
              {...(stripeConnectedBankStatus === "INCOMPLETE" && {
                SupportElement: (
                  <QuestionIcon onClick={() => setBankInfoPrompt(true)}>
                    ?
                  </QuestionIcon>
                ),
              })}
            />
            <EventsGrid />
          </Flex>
        </Flex>
      </BackgroundProvider>
    </>
  );
};
