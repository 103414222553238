import axiosRequest from "./axios.config";

export const create = async (image) => {
  const formData = new FormData();
  formData.append("file", image[0]);

  return await axiosRequest({
    method: "post",
    url: "/v1/files",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const createMultiple = async (files) => {
  const formData = new FormData();
  for (var i in files) {
    formData.append("file", files[i]);
  }

  return await axiosRequest({
    method: "post",
    url: "/v1/files",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const deleteFile = async (id) =>
  await axiosRequest.delete(`/v1/files/${id}`);

export const getS3Image = async (filename) =>
  await axiosRequest.get(`/v1/s3/${filename}`, { responseType: "blob" });
