import React, { useState, useRef, useEffect } from "react";
import { Flex, Button } from "components";
import { domain } from "utils";
import * as S from "./AdminEventDetails.styled";
import { PAGES } from "api";
import { toast } from "react-toastify";

export default ({ eventDetails }) => {
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(eventDetails);
  const currentEventRef = useRef(currentEvent);
  currentEventRef.current = currentEvent;

  useEffect(() => {
    setCurrentEvent(eventDetails);
  }, [eventDetails]);

  const updateEvent = async (event) => {
    try {
      await PAGES.update(event);
      toast("event successfully updated", { autoClose: 3000 });
    } catch (err) {
      let _message = "";
      if (err.validation) {
        for (var i in err.validation) {
          _message.concat(err.validation[i].msg);
        }
      }
      toast("Error updating event", { autoClose: 3000 });
    }
  };

  const handleToggle = async (attribute) => {
    const mutually_exclusive_map = {
      // a page can only be active || archived
      // if needed update the values to be arrays and loop through those arrays if the exclusivity is > 1:1
      active: "archived",
      archived: "active",
    };

    const updatedEvent = {
      ...currentEventRef.current,
      [attribute]: !currentEventRef.current[attribute],
    };

    if (attribute in mutually_exclusive_map) {
      // find the mutually exclusive pair and update to be opposite of currently passed toggle
      //    if TRUE is passed 👉 we want the mutually exclusive pair to be FALSE on the update object
      //    (which is what the current || unedited currentItemReg will be)
      updatedEvent[mutually_exclusive_map[attribute]] =
        currentEventRef.current[attribute];
    }

    setCurrentEvent(updatedEvent);
    setIsLoading(true);
    await updateEvent(updatedEvent);
    setIsLoading(false);
  };

  return (
    <Flex width="100%" justify="flex-end" align="center" margin="0 0 16px">
      <S.ActiveSwitch
        active={currentEvent?.active}
        onClick={() => handleToggle("active")}
      >
        Active
      </S.ActiveSwitch>
      <S.ArchivedSwitch
        archived={currentEvent?.archived}
        onClick={() => handleToggle("archived")}
        margin="0 12px 0 0"
      >
        Archived
      </S.ArchivedSwitch>
      <a
        href={`${domain}/watch/${currentEvent?.url}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>Preview</Button>
      </a>
    </Flex>
  );
};
