import React from "react";
import { Flex, Text, Input, Button, Select } from "components";
import { colors } from "consts";

const ManualLeadForm = ({
  onSubmit,
  newLeadConfig,
  handleAttributeUpdate,
  formState,
  optionMap,
  canSubmit,
  formErrors,
}) => {
  // CONTENT
  return (
    <Flex justify="center" direction="column" width="100%">
      <Flex direction="column">
        {[
          // TODO: add ability to link a new email / referral (not a webhook source) lead to an existing leadowner?
          { type: "select", placeholder: "Type", attribute: "type" },
          { type: "text", placeholder: "First Name", attribute: "first_name" },
          { type: "text", placeholder: "Last Name", attribute: "last_name" },
          { type: "text", placeholder: "Email", attribute: "email" },
          { type: "select", placeholder: "Stage", attribute: "stage" },
          {
            type: "select",
            placeholder: "Documentary Filmmaker?",
            attribute: "documentary_filmmaker",
          },
          {
            type: "select",
            placeholder: "Film Count",
            attribute: "film_count",
          },
        ].map((attr, i) => {
          const error = formErrors[attr.attribute];

          if (attr.type === "text") {
            return (
              <Flex direction="column" key={i} margin="0 0 10px 0">
                <Input
                  type={attr.type}
                  placeholder={attr.placeholder}
                  value={newLeadConfig.attribute}
                  onChange={(e) => handleAttributeUpdate(e, attr.attribute)}
                />
                {error && <Text color={colors.red}>{error}</Text>}
              </Flex>
            );
          }
          if (attr.type === "select") {
            return (
              <Flex key={i} margin="0 0 10px 0">
                <Select
                  radius="6px 0px 0px 6px"
                  placeholder={attr.placeholder}
                  toLowerCase={false}
                  selected={formState[attr.attribute]}
                  options={optionMap[attr.attribute]}
                  setSelected={(e) => handleAttributeUpdate(e, attr.attribute)}
                />
              </Flex>
            );
          }
          return null;
        })}
      </Flex>
      <Flex justify="center" width="100%">
        <Button
          className={`${!canSubmit && "disabled"}`}
          disabled={canSubmit ? false : true}
          onClick={() => {
            canSubmit && onSubmit();
          }}
        >
          Add Lead
        </Button>
      </Flex>
    </Flex>
  );
};

export default ManualLeadForm;
