import React from "react";
import { Text, Flex } from "components";
import { colors } from "consts";
import * as S from "./FoyerFooter.styled";

export default () => (
  <S.Wrap padding="24px 0" background={colors.black333}>
    <Flex justify="space-between">
      <Flex>
        <a href="https://showandtell.film/" style={{ cursor: "pointer" }}>
          <Text color={colors.gray700}>
            This virtual event is presented by Show&amp;Tell Films.{" "}
          </Text>
        </a>
      </Flex>
    </Flex>
  </S.Wrap>
);
