import { detect } from "detect-browser";
const {
  name: browser,
  version,
  os,
} = detect() || {
  browser: null,
  version: null,
  os: null,
};

export default { browser, version, os };
