import React, { useState, useEffect } from "react";
import { Flex, Text, Image } from "components";
import { colors } from "consts";
import { useHistory, useParams } from "react-router-dom";
import * as S from "./Admin.styled";

export default ({ light }) => {
  let { push } = useHistory();
  const { adminRoute, customerId, eventId } = useParams();
  const actions = {
    leads: {
      hasID: true,
      text: "Leads",
      icon: require("assets/images/admin-leads.svg").default,
      route: "/admin/leads",
    },
    customers: {
      hasID: true,
      text: "Customers",
      icon: require("assets/images/admin-customers.svg").default,
      route: "/admin/customers",
    },
    events: {
      hasID: true,
      text: "Events",
      icon: require("assets/images/admin-customers.svg").default,
      route: "/admin/events",
    },
    orders: {
      hasID: true,
      text: "Orders",
      icon: require("assets/images/admin-orders.svg").default,
      route: "/admin/orders",
    },
    globaltags: {
      hasID: true,
      text: "Global Tags",
      icon: require("assets/images/admin-tags.svg").default,
      route: "/admin/globaltags",
    },
  };
  const [activeTab, setActiveTab] = useState(null);
  useEffect(() => {
    if (adminRoute) {
      switch (adminRoute) {
        case "customers":
          setActiveTab("customers");
          break;
        case "events":
          setActiveTab("events");
          break;
        case "leads":
          setActiveTab("leads");
          break;
        case "orders":
          setActiveTab("orders");
          break;
        case "globaltags":
          setActiveTab("globaltags");
          break;
        default:
          setActiveTab(null);
      }
    } else if (customerId) {
      setActiveTab("customers");
    } else if (eventId) {
      setActiveTab("events");
    }
  }, [adminRoute, customerId, eventId]);

  return (
    <S.Sidebar>
      <Flex
        onClick={() => push("/admin/customers")}
        padding="45px 24px 30px 24px"
      >
        <Image
          width="126px"
          src={
            light
              ? require("assets/images/logo-dark.svg").default
              : require("assets/images/icon-logo.svg").default
          }
        />
        <Text
          size="14px"
          weight="700"
          margin="0 0 0 24px"
          color={colors.gray750}
        >
          Admin
        </Text>
      </Flex>

      <Flex padding="20px" direction="column">
        {Object.keys(actions).length > 0 &&
          Object.keys(actions).map((tab) => {
            const { hasID, text, icon, route } = actions[tab];
            return (
              <S.Action
                active={activeTab && activeTab === tab}
                hasID={hasID}
                key={text}
                onClick={() => push(route)}
              >
                <Image
                  width="16px"
                  height="16px"
                  margin="0 12px 0 0"
                  src={icon}
                />
                {text}
              </S.Action>
            );
          })}
      </Flex>
    </S.Sidebar>
  );
};
