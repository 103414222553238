import { Flex, Text } from "components";
import * as S from "./AdminDrawers.styled";
import { format } from "date-fns";

export default ({ values: { original } }) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  return (
    <S.Drawer>
      <Flex width="100%" direction="column">
        <S.DrawerItem width="100%">
          <Text size="14px" weight="bold">
            Charge ID
          </Text>
          <Text size="14px">{original.chargeId || "N/A"}</Text>
        </S.DrawerItem>
        <S.DrawerItem width="100%">
          <Text size="14px" weight="bold">
            Date order created
          </Text>
          <Text size="14px">
            {format(new Date(original.createdAt), "PP, pp")}
          </Text>
        </S.DrawerItem>
        <S.DrawerItem width="100%">
          <Text size="14px" weight="bold">
            Dispute reason
          </Text>
          <Text size="14px">{original?.disputeReason || "N/A"}</Text>
        </S.DrawerItem>
        <S.DrawerItem width="100%">
          <Text size="14px" weight="bold">
            Dispute status
          </Text>
          <Text size="14px">{original?.disputeStatus || "N/A"}</Text>
        </S.DrawerItem>
        <S.DrawerItem width="100%">
          <Text size="14px" weight="bold">
            Customer's country
          </Text>
          <Text size="14px">{original?.customersCountry || "N/A"}</Text>
        </S.DrawerItem>
        <S.DrawerItem width="100%">
          <Text size="14px" weight="bold">
            Charge total
          </Text>
          <Text size="14px">
            {formatter.format(original.chargeTotal / 100) || "N/A"}
          </Text>
        </S.DrawerItem>
        <S.DrawerItem width="100%">
          <Text size="14px" weight="bold">
            Purchased after watching
          </Text>
          <Text size="14px">{original?.afterWatching ? "Yes" : "No"}</Text>
        </S.DrawerItem>
      </Flex>
    </S.Drawer>
  );
};
