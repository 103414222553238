/*******************************************
 * 💐 CONFIG FILE FOR REACT-TABLE TABLE 💐 *
 * Sets columns, hidden columns,
 * customs Cells, et cetera
 *******************************************/

import React from "react";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import { format } from "date-fns";
import { Flex, Text, Image, Button } from "components";
import { TableOptionsMenu } from ".";
import PhoneNumber from "awesome-phonenumber";
import styled from "styled-components";
import { centsToDollars } from "utils";
import { MdEmail, MdCopyAll } from "react-icons/md";
import { FaHandshake } from "react-icons/fa";

const Ellipses = styled.p`
  width: ${({ width = "100%" }) => width};
  padding: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: ${({ align = "left" }) => align};
  font-weight: inherit;
`;

export const getSelectedRows = (data) =>
  data.map((datum, i) => datum.selected && { [i]: true });

export const hiddenColumns = {
  events: [
    "description",
    "createdAt",
    "emailCapture",
    "filmTeamEmail",
    "id",
    "email",
    "lastName",
    "featureUrl",
    "customerId",
    "connectId",
    "updatedAt",
    "previewButton",
    "previewUrl",
  ],
  event_orders: ["selection"],
  event_subscribers: ["selection"],
  customers: ["id", "country", "createdAt"],
  customer_credits: ["selection"],
  customer_events: ["selection"],
  customer_orders: ["selection"],
  customer_notes: ["selection"],
  leadowner_notes: ["selection"],
  orders: [
    "chargeId",
    "disputeStatus",
    "disputeReason",
    "amountRefunded",
    "selection",
  ],
  leads: ["selection"],
  leadOwners: ["id"],
  globalTags: ["selection"],
};

export const columns = {
  events: [
    {
      accessor: "description",
    },
    {
      accessor: "createdAt",
    },
    {
      accessor: "emailCapture",
    },
    {
      accessor: "filmTeamEmail",
    },
    {
      accessor: "id",
    },
    {
      accessor: "email",
    },
    {
      accessor: "lastName",
    },
    {
      accessor: "customerId",
    },
    {
      accessor: "connectId",
    },
    {
      accessor: "updatedAt",
    },
    {
      accessor: "url",
      id: "previewUrl",
    },
    {
      Header: "Title",
      accessor: "title",
      id: "title",
      Cell: ({ value }) => <div className="title">{value}</div>,
    },
    {
      Header: "URL",
      accessor: "url",
      id: "url",
    },
    {
      Header: "Event start",
      accessor: "eventStart",
      Cell: ({ value }) => format(new Date(value), "Pp"),
    },
    {
      Header: "Event end",
      accessor: "eventEnd",
      Cell: ({ value }) => format(new Date(value), "Pp"),
    },
    {
      Header: (props) => <></>,
      id: "options",
      Cell: ({ customerId, row: { original } }) => (
        <TableOptionsMenu {...{ original, customerId }} type="events" />
      ),
      width: "64px",
    },
  ],
  event_orders: [
    {
      Header: () => <div style={{ textAlign: "center" }}>Date</div>,
      accessor: "createdAt",
      Cell: ({ value }) => format(new Date(value), "PP, pp"),
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Charge ID</div>,
      accessor: "chargeId",
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Account Username</div>,
      accessor: "name",
    },
    {
      Header: () => (
        <div style={{ textAlign: "center" }}>Transaction Amount</div>
      ),
      accessor: "chargeTotal",
      Cell: ({ value }) => {
        return centsToDollars(value);
      },
    },
  ],
  event_subscribers: [
    // date subscribed
    // name
    // email
    // country
    {
      Header: () => <div style={{ textAlign: "center" }}>Date</div>,
      accessor: "createdAt",
      Cell: ({ value }) => format(new Date(value), "PP, pp"),
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Country",
      accessor: "country",
    },
  ],
  customers: [
    {
      accessor: "id",
    },
    {
      accessor: "country",
    },
    {
      accessor: "createdAt",
    },
    {
      Header: "Name",
      id: "name",
      Cell: ({ row: { original } }) => {
        return (
          <Text className="title">{`${original.firstName} ${original.lastName}`}</Text>
        );
      },
    },
    {
      Header: "Phone number",
      accessor: "phone_number",
      Cell: ({ row: { original } }) => {
        const phoneNumber = original.phoneNumber || null;
        const country = original.country || null;
        let cleanedPN;
        if (phoneNumber && !country) {
          cleanedPN = new PhoneNumber(phoneNumber);
        }
        if (phoneNumber && country) {
          cleanedPN = new PhoneNumber(phoneNumber, country);
        }
        return (
          <Text size="14px">
            {cleanedPN ? cleanedPN.getNumber("international") : "N/A"}
          </Text>
        );
      },
    },
    {
      Header: "Email address",
      accessor: "email",
    },
    {
      Header: "Credits available",
      Cell: ({ row: { original } }) => (
        <Text size="14px">{original?.credits?.credits || 0}</Text>
      ),
    },
    {
      Header: (props) => <></>,
      id: "options",
      Cell: ({ row: { original } }) => (
        <TableOptionsMenu original={original} type="customers" />
      ),
      width: "64px",
    },
  ],
  customer_credits: [
    {
      Header: () => <div style={{ textAlign: "center" }}>Usage Type</div>,
      accessor: "type",
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Credits Change</div>,
      accessor: "creditsChange",
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Date</div>,
      accessor: "createdAt",
      Cell: ({ value }) => format(new Date(value), "PP, pp"),
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Event Preview</div>,
      id: "eventPreview",
      Cell: ({ history, customerId, row: { original } }) =>
        original.pageId ? (
          <Button
            primary
            onClick={() => {
              const location = {
                pathname: `/event/${customerId}/${original.pageId}`,
                state: { superadmin: true },
              };
              history.push(location);
            }}
          >
            Event Preview
          </Button>
        ) : (
          "n/a"
        ),
    },
  ],
  customer_events: [
    {
      Header: () => <div style={{ textAlign: "center" }}>Date</div>,
      accessor: "updatedAt",
      Cell: ({ value }) => format(new Date(value), "PP, pp"),
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "URL",
      accessor: "url",
    },
    {
      Header: "Activated",
      accessor: "activated",
      Cell: ({ value }) => {
        return value ? "Yes" : "No";
      },
    },
    {
      Header: () => <div sytpe={{ textAlign: "center" }}>Event Preview</div>,
      id: "eventPreview",
      Cell: ({ history, customerId, row: { original } }) =>
        original.id ? (
          <Button
            primary
            onClick={() => {
              const location = {
                pathname: `/event/${customerId}/${original.id}`,
                state: { superadmin: true },
              };
              history.push(location);
            }}
          >
            Event Preview
          </Button>
        ) : (
          "n/a"
        ),
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Duplicate Event</div>,
      id: "duplicateEvent",
      Cell: ({ showDuplicate, row: { original } }) => {
        return original.id ? (
          <Button primary onClick={() => showDuplicate(original)}>
            Duplicate Event
          </Button>
        ) : (
          "n/a"
        );
      },
    },
  ],
  customer_orders: [
    {
      Header: () => <div style={{ textAlign: "center" }}>Date</div>,
      accessor: "createdAt",
      Cell: ({ value }) => format(new Date(value), "PP, pp"),
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Charge ID</div>,
      accessor: "chargeId",
      Cell: ({ value }) => {
        return value ? value : "N/A";
      },
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Account Username</div>,
      accessor: "name",
    },
    {
      Header: () => (
        <div style={{ textAlign: "center" }}>Transaction Amount</div>
      ),
      accessor: "net",
      Cell: ({ value }) => {
        return centsToDollars(value);
      },
    },
  ],
  customer_notes: [
    {
      Header: () => <div style={{ textAlign: "center" }}>Date</div>,
      accessor: "createdAt",
      width: 50,
      minWidth: 50,
      Cell: ({ value }) => format(new Date(value), "PP, pp"),
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Created By</div>,
      id: "createdBy",
      width: 50,
      minWidth: 50,
      Cell: ({
        row: {
          original: { created_account },
        },
      }) => {
        return `${created_account?.firstName} ${created_account?.lastName}`;
      },
    },
    {
      Header: "Note",
      accessor: "text",
      // TODO: how to make flex grow
      width: 200,
      minWidth: 200,
    },
    // TODO: menu click is broken
    // {
    //   // TODO: UPDATE MENU OPTIONS
    //   Header: (props) => <></>,
    //   id: "options",
    //   Cell: ({
    //     setViewingNote,
    //     setCurrentNote,
    //     setEditNoteId,
    //     row: { original },
    //   }) => {
    //     return (
    //       <TableOptionsMenu
    //         original={original}
    //         type="customer_notes"
    //         onClick={{
    //           edit: () => {
    //             setViewingNote(true);
    //             setCurrentNote(original.text);
    //             setEditNoteId(original.id);
    //           },
    //           delete: () => {
    //             NOTES.deleteNote(original.id);
    //           },
    //         }}
    //       />
    //     );
    //   },
    //   minWidth: "64px",
    //   width: "64px",
    // },
  ],
  leadowner_notes: [
    {
      Header: () => <div style={{ textAlign: "center" }}>Date</div>,
      accessor: "createdAt",
      width: 100,
      minWidth: 100,
      Cell: ({ value }) => format(new Date(value), "PP, pp"),
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Created By</div>,
      id: "createdBy",
      width: 25,
      minWidth: 25,
      Cell: ({
        row: {
          original: { created_account },
        },
      }) => {
        return `${created_account?.firstName} ${created_account?.lastName}`;
      },
    },
    {
      Header: "Note",
      accessor: "text",
      // TODO: how to make flex grow
      width: 200,
      minWidth: 200,
    },
    // TODO: menu click is broken
    // {
    //   // TODO: UPDATE MENU OPTIONS
    //   Header: (props) => <></>,
    //   id: "options",
    //   Cell: ({
    //     setViewingNote,
    //     setCurrentNote,
    //     setEditNoteId,
    //     row: { original },
    //   }) => {
    //     return (
    //       <TableOptionsMenu
    //         original={original}
    //         type="leadowner_notes"
    //         onClick={{
    //           edit: () => {
    //             setViewingNote(true);
    //             setCurrentNote(original.text);
    //             setEditNoteId(original.id);
    //           },
    //           delete: () => {
    //             NOTES.deleteNote(original.id);
    //           },
    //         }}
    //       />
    //     );
    //   },
    //   minWidth: "64px",
    //   width: "64px",
    // },
  ],
  orders: [
    {
      accessor: "chargeId",
    },
    {
      accessor: "disputeStatus",
    },
    {
      accessor: "disputeReason",
    },
    {
      accessor: "amountRefunded",
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => <div className="title">{value}</div>,
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Event Title",
      accessor: "pageTitle",
    },
    {
      Header: (props) => <></>,
      id: "options",
      Cell: ({ row: { original } }) => (
        <TableOptionsMenu original={original} type="orders" />
      ),
      minWidth: "64px",
      width: "64px",
    },
  ],
  leads: [
    {
      Header: "source",
      accessor: "sourceType",
      width: "100",
      Cell: ({ value }) => {
        switch (value) {
          case "typeform":
            return (
              <Flex width="100%" justify="center" align="center">
                <Image
                  width="30px"
                  src={require("assets/images/typeform.svg").default}
                />
              </Flex>
            );
          case "webflow":
            return (
              <Flex width="100%" justify="center" align="center">
                <Image
                  width="30px"
                  src={require("assets/images/webflow.svg").default}
                />
              </Flex>
            );
          case "zoom":
            return (
              <Flex width="100%" justify="center" align="center">
                <Image
                  width="30px"
                  src={require("assets/images/zoom.svg").default}
                />
              </Flex>
            );
          case "referral":
            return (
              <Text>
                <FaHandshake style={{ fontSize: "30px" }} />
              </Text>
            );
          case "email":
            return (
              <Text>
                <MdEmail style={{ fontSize: "30px" }} />
              </Text>
            );
          default:
            return (
              <Flex>
                <Text>N/A</Text>
              </Flex>
            );
        }
      },
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Film Count</div>,
      accessor: "filmCount",
    },
    {
      Header: () => (
        <div style={{ textAlign: "center" }}>Documentary Filmmaker</div>
      ),
      accessor: "documentaryFilmmaker",
      Cell: ({ value }) => <Text>{!value ? "N/A" : value}</Text>,
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Form Title</div>,
      accessor: "formTitle",
      Cell: ({ value }) => (
        <Tooltip
          placement="bottom"
          overlay={<span>Copy Form Title: {value}</span>}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <Ellipses
            onClick={() => {
              navigator.clipboard.writeText(value);
            }}
          >
            {value}
          </Ellipses>
        </Tooltip>
      ),
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Form ID</div>,
      accessor: "formId",
      Cell: ({ value }) => (
        <Tooltip
          placement="bottom"
          overlay={<span>Copy Form ID: {value}</span>}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <Ellipses
            onClick={() => {
              navigator.clipboard.writeText(value);
            }}
          >
            {value}
          </Ellipses>
        </Tooltip>
      ),
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Date Created</div>,
      accessor: "createdAt",
      Cell: ({ value }) => format(new Date(value), "Pp"),
    },
  ],
  leadOwners: [
    {
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => <div className="title">{value}</div>,
    },
    {
      Header: "Email",
      accessor: "email",
      width: 250,
    },
    {
      Header: "Stage",
      accessor: "stage",
      width: 100,
    },
    {
      Header: "Sources",
      id: "sources",
      width: 150,
      Cell: ({ row: { original } }) => {
        const sources = new Set();
        for (const lead of original.leads) {
          if (!sources.has(lead.sourceType)) {
            sources.add(lead.sourceType);
          }
        }
        return (
          <Flex gap="5px">
            {Array.from(sources).map((source, i) => {
              switch (source) {
                case "typeform":
                  return (
                    <Image
                      key={i}
                      width="30px"
                      src={require("assets/images/typeform.svg").default}
                    />
                  );
                case "webflow":
                  return (
                    <Image
                      key={i}
                      width="30px"
                      src={require("assets/images/webflow.svg").default}
                    />
                  );
                case "zoom":
                  return (
                    <Image
                      key={i}
                      width="30px"
                      src={require("assets/images/zoom.svg").default}
                    />
                  );
                case "referral":
                  return (
                    <Text key={i}>
                      <FaHandshake style={{ fontSize: "30px" }} />
                    </Text>
                  );
                case "email":
                  return (
                    <Text key={i}>
                      <MdEmail style={{ fontSize: "30px" }} />
                    </Text>
                  );
                default:
                  return (
                    <Flex key={i}>
                      <Text>N/A</Text>
                    </Flex>
                  );
              }
            })}
          </Flex>
        );
      },
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Account ID</div>,
      accessor: "account_id",
      width: 125,
      Cell: ({ value }) => {
        return value ? (
          <MdCopyAll
            style={{ color: "white", fontSize: "30px" }}
            onClick={navigator.clipboard.writeText(value)}
          />
        ) : (
          "Null"
        );
      },
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Date Created</div>,
      accessor: "createdAt",
      Cell: ({ value }) => format(new Date(value), "PP, pp"),
    },
    {
      Header: (props) => <></>,
      id: "options",
      Cell: ({ row: { original } }) => (
        <TableOptionsMenu original={original} type="leadOwners" />
      ),
      width: "64px",
    },
  ],
  globalTags: [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => <div className="title">{value}</div>,
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ value }) => (value === "leadowner" ? "lead" : value),
    },
    {
      Header: (props) => <></>,
      id: "options",
      Cell: ({
        setEditingTag,
        currentTag,
        setCurrentTag,
        deleteTag,
        row: { original },
      }) => (
        <TableOptionsMenu
          original={original}
          type="globalTags"
          onClick={{
            edit: () => {
              const updated = { ...currentTag };
              updated.tagId = original.id;
              updated.value = original.name;
              setCurrentTag(updated);
              setEditingTag(true);
            },
            delete: () => {
              deleteTag(original.id);
            },
          }}
        />
      ),
      width: "64px",
    },
  ],
};
