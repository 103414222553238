import React from "react";
import DraggableProduct from "./DraggableProduct";
import styled from "styled-components";
import { Flex } from "components";
import { minMedia } from "utils";

const ProductContainer = styled(Flex)`
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
  background: ${({ background }) => background};
`;
const Grid = styled.div`
  display: grid;
  grid-gap: 15px 6px;
  padding-bottom: 48px;
  justify-items: center;
  grid-template-columns: repeat(1, 1fr);

  ${minMedia.phonePlus`
        grid-template-columns: repeat(2, 1fr);
    `}
  ${minMedia.tablet`
        grid-template-columns: repeat(2, 1fr);
    `}
    ${minMedia.laptop`
        grid-template-columns: repeat(3, 1fr);
    `}
`;

const DroppableProductArea = ({ images, edit }) => {
  return (
    <ProductContainer>
      <Grid>
        {images.map(({ url, id }) => {
          return (
            <DraggableProduct
              key={id}
              {...{
                url,
                id,
                edit,
              }}
            />
          );
        })}
      </Grid>
    </ProductContainer>
  );
};
export default DroppableProductArea;
