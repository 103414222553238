import axiosRequest from "./axios.config";

export const login = async (user) =>
  await axiosRequest.post("/v1/session", user);

export const logout = async () => await axiosRequest.delete("/v1/session");

export const create = async (user) =>
  await axiosRequest.post("/v1/account", user);

export const verify = async () => await axiosRequest.post("/v1/tokens/verify");

export const getOnboardLink = async () =>
  await axiosRequest.get("/v1/account/onboard");

export const checkCapabilities = async () =>
  await axiosRequest.get("/v1/account/capabilities");

export const checkCapabilitiesById = async (id) =>
  await axiosRequest.get(`/v1/account/${id}/capabilities`);

export const forgotPassword = async (email) =>
  await axiosRequest.post("/v1/tokens/reset", {
    email: email,
    redirectUrl: "/reset-password",
  });

export const resetPassword = async (id, password) =>
  await axiosRequest.post(`/v1/tokens/reset/${id}`, { password: password });
