import React, { useState, useEffect } from "react";
import { Flex, Text, Image } from "components";
import { ReadMore } from "./ReadMoreText.styled";

export default ({
  title,
  description,
  sponsor,
  padding,
  screenWidth,
  titleMargin,
  descriptionMargin,
  sponsorMargin,
  truncateDescription,
  readMore,
  setReadMore,
  dark,
}) => {
  const [descriptionObject, setDescriptionObject] = useState(null);
  const [descriptionSize, setDescriptionSize] = useState("24px");
  const [readMoreSize, setReadMoreSize] = useState("16px");

  useEffect(() => {
    if (screenWidth > 768 && description.length > 375) {
      const shortDescription = description.slice(0, 375);
      setDescriptionObject({
        shortDescription: shortDescription.trim(),
        fullDescription: description.trim(),
      });
    } else if (screenWidth <= 768 && description.length > 120) {
      const shortDescription = description.slice(0, 120);
      setDescriptionObject({
        shortDescription: shortDescription.trim(),
        fullDescription: description.trim(),
      });
    } else {
      setDescriptionObject({
        shortDescription: null,
        fullDescription: description.trim(),
      });
    }
    if (screenWidth) {
      screenWidth < 768
        ? setDescriptionSize("20px") && setReadMoreSize("16px")
        : setDescriptionSize("24px") && setReadMoreSize("20px");
    }
  }, [description, screenWidth]);

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };

  return (
    <Flex
      minwidth="100%"
      direction="column"
      align="flex-start"
      padding={padding}
    >
      <Text
        dark={dark}
        size="64px"
        weight="bold"
        className={`canela bannerTitle ${title.length > 30 ? "shrink" : ""}`}
        margin={titleMargin ? titleMargin : "0 0 18px"}
      >
        {title}
      </Text>

      {/* OPTION based on sponsor */}
      {sponsor?.name && (
        <Text dark={dark} margin={sponsorMargin ? sponsorMargin : "0"}>
          In partnership with{" "}
          <a href={sponsor.url} target="_blank" rel="noopener noreferrer">
            {sponsor.name}
          </a>
        </Text>
      )}
      {!truncateDescription ? (
        <Text
          dark={dark}
          className={`banner-description ${title.length > 30 ? "shrink" : ""}`}
          margin={
            descriptionMargin
              ? descriptionMargin
              : sponsor?.name
              ? "24px 0"
              : "0 0 24px"
          }
        >
          {descriptionObject?.fullDescription}
        </Text>
      ) : descriptionObject?.shortDescription && !readMore ? (
        <Text
          dark={dark}
          size={descriptionSize}
          margin={
            descriptionMargin
              ? descriptionMargin
              : sponsor?.name
              ? "24px 0"
              : "0 0 24px"
          }
        >
          {descriptionObject.shortDescription}{" "}
          <ReadMore size={readMoreSize} onClick={toggleReadMore}>
            read more{" "}
            <Image
              margin="0 0 0 4px"
              width="12px"
              src={
                require(`assets/images/icon-arrow-right${
                  dark ? "-dark" : ""
                }.svg`).default
              }
            />
          </ReadMore>
        </Text>
      ) : descriptionObject?.shortDescription && readMore ? (
        <Text
          dark={dark}
          size={descriptionSize}
          margin={
            descriptionMargin
              ? descriptionMargin
              : sponsor?.name
              ? "24px 0"
              : "0 0 24px"
          }
        >
          {descriptionObject?.fullDescription}{" "}
          <ReadMore size={readMoreSize} onClick={toggleReadMore}>
            <Image
              margin="0 4px 0 0"
              width="12px"
              src={
                require(`assets/images/icon-arrow-left${
                  dark ? "-dark" : ""
                }.svg`).default
              }
            />{" "}
            read less
          </ReadMore>
        </Text>
      ) : (
        <Text
          dark={dark}
          size={descriptionSize}
          margin={
            descriptionMargin
              ? descriptionMargin
              : sponsor?.name
              ? "24px 0"
              : "0 0 24px"
          }
        >
          {descriptionObject?.fullDescription}
        </Text>
      )}
    </Flex>
  );
};
