import React, { useState, useRef, useEffect, useCallback } from "react";
import { Flex, Text, MockBanner } from "components";
import CropModalControls from "./CropModalControls";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { colors } from "consts";

export default function ImageCrop({
  image,
  loading,
  setLoading,
  information,
  updateInformation,
  target,
  closeModal,
}) {
  let aspectRatio = 16 / 7;

  const desktopAspectRatio = 16 / 7;
  if (target === "desktop") aspectRatio = desktopAspectRatio;

  const mobileAspectRatio = 9 / 16;
  if (target === "mobile") aspectRatio = mobileAspectRatio;

  const [croppedImage, setCroppedImage] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const [crop, setCrop] = useState({
    unit: "px",
    width: 700,
    aspect: aspectRatio,
  });

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const onLoad = useCallback(
    (url) => {
      imgRef.current = url;
      setTimeout(() => {
        setLoading(false);
      }, 500);
    },
    [setLoading]
  );

  const createbannerURL = () => {
    const canvas = previewCanvasRef?.current;
    const crop = completedCrop;
    if (!crop || !canvas) return;

    const croppedImage = canvas.toDataURL(image.type);

    setCroppedImage(croppedImage);
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) return;

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    let originWidth = crop.width * scaleX;
    let originHeight = crop.height * scaleY;
    // maximum width/height
    let maxWidth = 5000;
    let maxHeight = 5000 / aspectRatio;
    let targetWidth = originWidth;
    let targetHeight = originHeight;
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth;
        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      } else {
        targetHeight = maxHeight;
        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      }
    }

    canvas.width = targetWidth * pixelRatio;
    canvas.height = targetHeight * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    );

    createbannerURL();
    // eslint-disable-next-line
  }, [completedCrop]);

  return (
    <>
      <Flex margin="0 0 24px 0">
        <ReactCrop
          src={image.url}
          crop={crop}
          onImageLoaded={onLoad}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
          minWidth={50}
          keepSelection={true}
        />
      </Flex>

      <Text size="24px" margin="0 0 12px">
        Preview
      </Text>

      <Text size="14px" margin="0 0 34px" color={colors.gray8}>
        Below is a preview of how your banner image will appear on your event
        page. Once you are satisfied with the placement please select Apply to
        save your changes.
      </Text>

      <Flex margin="0 0 24px 0" style={{ position: "relative", width: "100%" }}>
        <MockBanner
          information={information}
          background={croppedImage}
          target={target}
        />
        <canvas
          ref={previewCanvasRef}
          style={{
            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
            display: "none",
          }}
        />
      </Flex>
      <CropModalControls
        image={image}
        loading={loading}
        setLoading={setLoading}
        closeModal={closeModal}
        information={information}
        updateInformation={updateInformation}
        target={target}
        croppedImage={croppedImage}
      />
    </>
  );
}
