import { Button, Flex, Text } from "components";

export default ({ setStep }) => {
  return (
    <Flex column gap="24px">
      <Flex column gap="12px">
        <Text kind="h2" size="24px" weight="bold">
          It appears you're out of credits.
        </Text>
      </Flex>
      <Flex gap="12px" center>
        <Button onClick={() => setStep("credits-checkout")}>
          Purchase more credits
        </Button>
      </Flex>
    </Flex>
  );
};
