import styled, { css } from "styled-components";
import { Flex } from "components";

export const Wrapper = styled.div(
  ({ maxWidth, height, background }) => css`
    position: relative;
    width: 100%;
    max-width: ${maxWidth || "100%"};
    height: ${height || "300px"};
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${background});
    background-size: cover;
    background-position: top right;
  `
);

export const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center center;
  background-color: transparent;
  background-image: ${({ src }) =>
    "linear-gradient(90deg, #101010 24.17%, rgba(41, 41, 41, 0) 69.31%)" ||
    null};
`;

export const ContentWrapper = styled(Flex)`
  position: relative;
  transform: scale(0.75);
  left: -80px;
  left: ${({ left }) => left || "-80px"};
`;

export const MobileOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 41, 41, 0.7);
`;
