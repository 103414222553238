import styled from "styled-components";
import { colors } from "consts";
import { Text, Flex, Button, Image } from "components";

export const OptionsCell = styled(Flex)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const OptionButton = styled(Image)`
  cursor: pointer;
  padding: 8px;
`;

export const OptionsMenuContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  background: ${colors.gray800};
  width: 180px;
  padding: 12px 16px;
  position: absolute;
  opacity: 1;
  border-radius: 8px;
  z-index: 2;
  transform: translateX(calc((100% - 238px)))
    translateY(calc(var(--scroll-top, 0px) * -1));
`;

export const MenuSectionDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.gray750};
  margin: 6px 0;
`;

export const MenuOption = styled(Text)`
  font-size: 14px;
  background: transparent;
  margin: 6px 0;
  width: 100%;
  cursor: pointer;
  color: ${colors.activeWhite1};
  &:hover {
    color: ${colors.white};
  }
`;

export const TableContainer = styled.div`
  background-color: ${colors.gray850};
  border-radius: 8px;
  width: 100%;
  max-height: 740px;
  overflow-y: auto;
  clip-path: inset(0px -999px -999px -999px);
  margin-bottom: 16px;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.gray500};
    border-radius: 0px 8px 8px 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.gray600};
    border-radius: 8px;
  }

  &.clickable: {
    .td {
      cursor: pointer;
    }
  }

  .table {
    border-spacing: 0;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      border-bottom: 1px solid ${colors.activeWhite3};
    }

    .td {
      color: ${colors.gray8};
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .th,
    .title {
      color: ${colors.white};
      font-size: 14px;
      font-weight: bold;
    }
    .th,
    .td {
      margin: 0;
      padding: 24px;
      width: calc(calc(100% - 128px) / 4);
      max-width: 400px;
      min-width: 30px;
      height: 64px;
      :first-child {
        width: 64px;
      }
      :last-child {
        width: 64px;
        padding: 8px;
        overflow: visible;
      }
    }

    .header-row {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
      background: ${colors.gray850};
    }
  }
`;

export const SelectionBarContainer = styled(Flex)`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 20px 40px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.45);
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: ${colors.gray850};
  z-index: 3;
`;

export const ActiveSwitch = styled(Flex)`
  background: ${({ active }) => (active ? colors.gray600 : "transparent")};
  border: 1px solid ${colors.gray600};
  border-radius: 2px 0px 0px 2px;
  color: ${colors.white};
  font-size: 14px;
  line-height: 24px;
  padding: 7px 16px;
  cursor: pointer;
  max-width: 100px;
  justify-content: center;
  align-items: center;
`;

export const ArchivedSwitch = styled(Flex)`
  background: ${({ archived }) => (archived ? colors.gray600 : "transparent")};
  border: 1px solid ${colors.gray600};
  border-radius: 0px 2px 2px 0px;
  color: ${colors.white};
  font-size: 14px;
  line-height: 24px;
  padding: 7px 16px;
  cursor: pointer;
  max-width: 100px;
  justify-content: center;
  align-items: center;
`;

export const PaginationButton = styled(Flex)`
  height: 32px;
  width: 32px;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  background: ${({ bgColor }) => (bgColor ? bgColor : "transparent")};
  cursor: pointer;
`;

// ******************************************** //

export const EditButton = styled.span`
  padding: 16px;
  border-radius: 50%;
  font-size: 200%;
  &:hover {
    cursor: pointer;
    background: ${colors.activeWhite};
  }
`;

export const CreditInput = styled.input`
  width: 50px;
  border: none;
  outline: none;
  padding: 4px;
  margin-right: 12px;
  &.active {
    color: green;
    font-weight: 600;
  }
`;

export const ConfirmButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

export const Download = styled.div`
  cursor: pointer;
  background: rgba(196, 196, 196, 0.25);
  border-radius: 54px;
  margin: 0 0 0 8px;
  width: 56px;
  height: 30px;
  background: url(${require("assets/images/icon-download.svg").default})
    no-repeat center center rgba(196, 196, 196, 0.25);
`;

export const SearchBox = styled.div`
  margin: 12px auto;
  color: ${colors.activeWhite};
  width: 355px;
  border-bottom: 1px solid ${colors.activeWhite};
  padding-bottom: 8px;
  text-align: center;
  input {
    width: 270px;
    background: ${colors.black};
    color: white;
  }
  span {
    cursor: pointer;
    margin-left: 12px;
  }
`;

export const SubmitButton = styled(Button)`
  position: absolute;
  top: 18px;
  right: 8px;
`;

export const Wrapper = styled.div`
  background-color: ${colors.gray200};
  box-shadow: 0 4px 4px -2px rgba(0, 20, 30, 0.2);
  border-radius: 4px;
  padding: 24px;
  width: 100%;
  position: relative;
  /* These styles are suggested for the table fill all available space in its containing element */
  display: block;
  /* These styles are required for a horizontaly scrollable table overflow */
  overflow: auto;
  &.clickable {
    .td {
      cursor: pointer;
    }
  }

  .table {
    border-spacing: 0;

    .thead {
      /* These styles are required for a scrollable body to align with the header properly */
      overflow-y: auto;
      overflow-x: hidden;
    }

    .tbody {
      /* These styles are required for a scrollable table body */
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      border-bottom: 1px solid ${colors.activeWhite};
    }

    .th {
      color: ${colors.gray8};
      font-size: 16px;
    }

    .td {
      color: ${colors.gray8};
      font-size: 14px;
    }

    .th,
    .td {
      margin: 0;
      padding: 1rem;
      position: relative;
      :last-child {
        border-right: 0;
      }

      .resizer {
        right: 0;
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        &:hover {
          background: rgba(64, 79, 87, 0.6);
        }

        /* prevents from scrolling while dragging on touch devices */
        touch-action: none;

        &.isResizing {
          background: ${colors.orange200};
        }
      }
    }
  }
`;

export const TextArea = styled.textarea`
  background: transparent;
  border: 1px solid ${colors.activeWhite3};
  border-radius: 8px;
  resize: none;
  overflow: auto;
  color: ${colors.white};
  font-family: "Inter", sans-serif;
  padding: 20px;
  margin: 0 0 20px 0;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.gray500};
    border-radius: 0px 8px 8px 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.gray600};
    border-radius: 8px;
  }
`;

export const Tag = styled(Flex)`
  background: ${colors.orange};
  font-size: 14px;
  color: ${colors.white};
  font-weight: bold;
  border-radius: 50px;
  line-height: 22px;
  padding: 4px 12px;
  margin: 4px;
  width: auto;
`;

export const TagWrapper = styled(Flex)`
  border: 1px solid ${colors.activeWhite3};
  background: ${colors.charcoal};
  border-radius: 8px;
  padding: 16px;
  margin: 0 0 16px;
  flex-wrap: wrap;
`;
