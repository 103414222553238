import { useQuery } from "react-query";
import axiosRequest from "../../api/axios.config";
import queryClient from "../queryClient";

const fetcher = async (searchTerm, page, after_watching) => {
  let params = `?`;
  if (searchTerm) params = `${params}&search_term=${searchTerm}`;
  if (page) params = `${params}&page=${page}`;
  if (after_watching) params = `${params}&after_watching=${after_watching}`;

  let endpoint = `/v1/admin/orders`;
  if (params !== "?") endpoint = `${endpoint}${params}`;
  const raw = await axiosRequest.get(endpoint);
  return raw.data.data;
};

const useGetFilteredOrders = (searchTerm, page, { after_watching }) => {
  return useQuery(
    [
      "orders",
      "filtered",
      `searchTerm=${searchTerm}`,
      `page=${page}`,
      `after_watching=${after_watching}`,
    ],
    async () => {
      // TODO: improve by using axios cancel token instead of setting cacheTime to 0
      // that way if they backtrack you are not recalling the data

      const data = await fetcher(searchTerm, page, after_watching);

      data.results.forEach((order) => {
        queryClient.setQueryData(["order", order.id], order);
      });

      const response = {
        total: data?.total || null,
        data: data.results,
      };

      return response;
    },
    {
      enabled: !!searchTerm || (!!page && page !== 1) || !!after_watching,
      cacheTime: 0,
    }
  );
};

const useGetOrders = () => {
  return useQuery(["orders"], async () => {
    const data = await fetcher();

    data.results.forEach((order) => {
      queryClient.setQueriesData(["order", order.id], order);
    });

    const response = {
      total: data?.total || null, // this should be the total of the NON filtered / searched data
      data: data.results,
    };

    return response;
  });
};

export { useGetOrders, useGetFilteredOrders };
