import React from "react";
import { useParams } from "react-router-dom";
import { BackgroundProvider, AdminLayout, Flex, Text, Tags } from "components";
import {
  LeadOwnerLeads,
  LeadOwnerDetails,
  LeadOwnerNotes,
} from "components/Admin/AdminLeadOwnerDetails";
import { useGetGlobalLeadownerTags } from "rQuery/api/useGetTags";

// rQuery
import { useGetLeadowner } from "rQuery/api";

export default () => {
  // HOOKS
  const { leadOwnerId } = useParams();
  const {
    isLoading: isLoadingGlobalLeadownerTags,
    data: globalLeadownerTags,
    refetch: refetchGlobalLeadownerTags,
  } = useGetGlobalLeadownerTags();

  // HOOKS
  const { isLoading: isLoadingLeadowner, data: leadowner } =
    useGetLeadowner(leadOwnerId);

  // TODO:  reloading is wiping some nested data -> useQuery is fetching data correctly but last log of `leadowner` here is deleting data
  //          look into the use of state: { superadmin: true } on location
  //          review redirect note in <AdminCustomerDetails />

  return (
    <BackgroundProvider minHeight="100vh">
      <AdminLayout text="Lead Owner Details" type="details">
        {isLoadingLeadowner || isLoadingGlobalLeadownerTags ? (
          <Flex>
            <Text>Loading...</Text>
          </Flex>
        ) : (
          <Flex direction="column">
            <LeadOwnerDetails owner={leadowner} />
            <Tags
              type="leads"
              ownerDetails={leadowner}
              gTags={globalLeadownerTags}
              refetchGlobalTags={refetchGlobalLeadownerTags}
            />
            <LeadOwnerLeads ownerDetails={leadowner} />
            <LeadOwnerNotes ownerDetails={leadowner} />
          </Flex>
        )}
      </AdminLayout>
    </BackgroundProvider>
  );
};
