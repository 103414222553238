import styled from "styled-components";
import { minMedia } from "utils";

export const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: auto;
`;

export const Overlay = styled.div`
  position: fixed;
  right: 16px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: ${({ light }) =>
    light ? `rgba(255, 255, 255, 0.6)` : `rgba(0, 0, 0, 0.8)`};
`;

export const Modal = styled.div`
  position: relative;
  z-index: 2;
  background: ${({ light }) => (light ? `#232323` : `#1b1b1b`)};
  border-radius: 2px;
  overflow: hidden;
  margin: ${({ mobileMargin }) => mobileMargin || 0} auto;
  width: 100%;
  max-width: ${({ width }) => width || "80%"};
  padding: ${({ padding }) => padding || "0"};
  ${minMedia.laptop`
    margin: ${({ margin }) => margin || 0} auto;
  `}
`;

export const Close = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 50px;
  z-index: 2;
  cursor: pointer;
`;
