import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Button,
  Outline,
  ModalProvider,
  Image,
  Greenlight,
} from "components";
import { DashboardBankInfo } from "./";
import { PAGES, USERS } from "api";
import { toast } from "react-toastify";
import { domain } from "utils";
import { colors } from "consts";

export default ({ editEvent, patch, screening, id }) => {
  const [currentScreening, setCurrentScreening] = useState({});
  const [isActivated, setIsActivated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDisbursement, setShowDisbursement] = useState(false);
  const [connectedBank, setConnectedBank] = useState(
    screening?.capability?.hasConnectedBank
  );

  const isDiy = screening?.diyEvent;
  const isDiyAndActivated = isDiy && !isActivated;

  useEffect(() => {
    if (screening) {
      setCurrentScreening(screening);
      setIsActivated(screening.activated);
    }
  }, [screening]);

  const updateScreening = async () => {
    try {
      setLoading(true);
      await PAGES.update(currentScreening);
      setLoading(false);
      toast("event updated successfully", { autoClose: 3000 });
    } catch (err) {
      let _message = "";
      if (err.validation) {
        for (var i in err.validation) {
          _message.concat(err.validation[i].msg);
        }
      }
      toast("Error updating event", { autoClose: 3000 });
    }
  };

  const activateScreening = async () => {
    try {
      await PAGES.activate(currentScreening.id);
      setIsActivated(true);
      screening.activated = true;
      toast("event successfully activated", { autoClose: 3000 });
    } catch (err) {
      let _message = "";
      if (err.validation) {
        for (var i in err.validation) {
          _message.concat(err.validation[i].msg);
        }
      }
      toast("Error updating event", { autoClose: 3000 });
    }
  };

  const startOnboarding = async () => {
    const _link = await USERS.getOnboardLink();
    const _win = window.open(_link.data.data.url, "_blank");
    _win.focus();
  };

  const saveAndContinue = () => {
    !currentScreening.id && (currentScreening.id = id);
    patch(currentScreening);
    toast("event created successfully", { autoClose: 3000 });
  };

  var currentWebsite = window.location.protocol + "//" + window.location.host;

  return (
    <Flex padding="60px 125px" direction="column" align="flex-start">
      <Flex
        width="900px"
        direction="column"
        justify="space-between"
        align="flex-start"
      >
        {!isDiy && (
          <ActivateAndCollect
            {...{
              screening,
              saveAndContinue,
              editEvent,
              loading,
              updateScreening,
              isActivated,
              activateScreening,
            }}
          />
        )}
        {isDiyAndActivated && <ContactShowAndTell />}
        <Outline margin="0 0 26px">
          <Text size="38px" weight="500" margin="24px 0 17px">
            Connect to Stripe
          </Text>

          <Text size="14px" margin="0 0 4px" color={colors.gray8}>
            If you are collecting donations, selling tickets, or selling items
            in your store, you must authenticate your identity with Stripe, our
            payment processor. Our users’ security is extremely important to us
            here at Show&#38;Tell. For more info and guidance,
            <a
              href="https://support.showandtell.film/hc/en-us/articles/360054379951-Verify-Your-Stripe-Account"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              click here.
            </a>
          </Text>

          <Flex margin="12px 0 0 0">
            <Greenlight
              size="14px"
              color={colors.gray8}
              active={screening?.capability?.status === "active"}
              activeText="Stripe connected"
              inactiveText="Stripe not connected"
            />
          </Flex>
          {screening?.capability?.status === "active" ? (
            <Flex justify="flex-start" align="flex-start" margin="12px 0 24px">
              <Button
                margin="12px 8px 0 0"
                className="outline-white"
                width="200px"
                onClick={() => startOnboarding()}
              >
                Edit Business Info
              </Button>
            </Flex>
          ) : (
            <Flex justify="flex-start" align="flex-start" margin="12px 0 24px">
              <Button
                margin="12px 8px 0 0"
                className="outline-white"
                width="200px"
                onClick={() => startOnboarding()}
              >
                Connect to Stripe
              </Button>
            </Flex>
          )}
        </Outline>
        <Outline margin="0 0 26px">
          <Text size="38px" weight="500" margin="24px 0 17px">
            Receive Funds
          </Text>

          <Text size="14px" margin="0 0 24px" color={colors.gray8}>
            In order for us to disburse funds from your Stripe connected
            account, you’ll need to provide your bank information (routing
            number, account number).
          </Text>

          <Text size="14px" margin="0 0 4px" weight="bold" color={colors.gray8}>
            Note: this option will only be available after you've Connected
            Stripe.
          </Text>

          <Flex justify="flex-start" align="flex-start" margin="24px 0 0 0">
            <Greenlight
              size="14px"
              color={colors.gray8}
              active={connectedBank}
              activeText="Bank account connected"
              inactiveText="No bank account connected"
            />
          </Flex>
          {!connectedBank && (
            <Flex justify="flex-start" align="flex-start" margin="0 0 24px">
              <Button
                margin="24px 8px 0 0"
                className={`outline-white${
                  screening?.capability?.status === "active" ? "" : "-disabled"
                }`}
                onClick={() =>
                  screening?.capability?.status === "active" &&
                  setShowDisbursement(true)
                }
              >
                Connect Bank Account
              </Button>
            </Flex>
          )}
        </Outline>

        {isActivated && (
          <Flex justify="flex-start" align="flex-start" margin="0 0 32px 36px">
            <a
              href={screening.url && `${currentWebsite}/watch/${screening.url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                margin="24px 8px 0 0"
                width="200px"
                className="outline-white"
              >
                Go To Event
              </Button>
            </a>
          </Flex>
        )}
      </Flex>

      <ModalProvider
        width="515px"
        margin="64px"
        show={showDisbursement}
        close={() => setShowDisbursement(false)}
      >
        <Flex direction="column" align="flex-start" padding="60px">
          <Text size="19px" margin="0 0 24px">
            Bank Information
          </Text>
          <DashboardBankInfo
            setConnectedBank={setConnectedBank}
            setShowDisbursement={setShowDisbursement}
          />

          <Flex justify="center">
            <a
              href="https://stripe.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                width="100px"
                src={require("assets/images/powered-by-stripe.png").default}
              />
            </a>
            <Text
              color="rgba(255, 255, 255, 0.3)"
              size="12px"
              margin="0 0 0 16px"
            >
              |
            </Text>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <Text
                color="rgba(255, 255, 255, 0.3)"
                size="12px"
                margin="0 0 0 16px"
              >
                Terms
              </Text>
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <Text
                color="rgba(255, 255, 255, 0.3)"
                size="12px"
                margin="0 0 0 16px"
              >
                Privacy
              </Text>
            </a>
          </Flex>
        </Flex>
      </ModalProvider>
    </Flex>
  );
};

const ActivateAndCollect = ({
  screening,
  saveAndContinue,
  editEvent,
  loading,
  updateScreening,
  isActivated,
  activateScreening,
}) => {
  return (
    <Outline margin="0 0 26px">
      <Text size="38px" weight="500" margin="24px 0 17px">
        Activate &amp; Collect Payments
      </Text>

      <Text size="14px" margin="0 0 24px" color={colors.gray8} weight="bold">
        You've built your event and you're almost ready to share. Once you click
        the activate button below your event page will be shareable - you can
        then share your event with partners and audience members by sending them
        the event’s unique URL via email.
      </Text>

      <Text size="14px" margin="0 0 24px" color={colors.gray8}>
        After activating your event, your film will not be available to watch
        until the date and time you have selected in your Event Details tab.
      </Text>

      <Text size="14px" margin="0 0 24px" color={colors.gray8}>
        We highly recommend doing a Quality Assurance (QA) check before clicking
        activate. Click{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.showandtell.film/hc/en-us/articles/360058453931-How-do-I-do-a-quality-assurance-check-before-going-live"
        >
          here
        </a>{" "}
        for a quick video of the step by step process for activating your event.
      </Text>

      <Text size="14px" margin="0 0 24px" color={colors.gray8}>
        Still making changes? You will have the ability to add information or
        make changes to your event page at any time.
      </Text>

      <Text size="14px" margin="0 0 24px" color={colors.gray8}>
        Clicking the save button below will save any unsaved edits from previous
        sections.
      </Text>

      <Flex margin="12px 0 24px" justify="flex-start">
        <a
          href={`${domain}/watch/${screening.url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button className="secondary">Preview</Button>
        </a>
        {editEvent ? (
          <>
            {loading ? (
              <Button className="outline-white" margin="0 16px 0">
                Saving Event...
              </Button>
            ) : (
              <Button onClick={() => updateScreening()} margin="0 16px 0">
                Save
              </Button>
            )}
          </>
        ) : (
          <>
            {loading ? (
              <Button margin="0 16px" className="outline-white">
                Saving Event...
              </Button>
            ) : (
              <Button margin="0 16px" onClick={() => saveAndContinue()}>
                Save
              </Button>
            )}
          </>
        )}
        <Button
          {...(isActivated
            ? { className: "disabled" }
            : { onClick: activateScreening })}
        >
          {isActivated ? "Event is activated" : "Activate"}
        </Button>
      </Flex>
    </Outline>
  );
};

const ContactShowAndTell = () => {
  return (
    <Outline margin="0 0 26px">
      <Text size="38px" weight="500" margin="24px 0 17px">
        Activate your event
      </Text>
      <Text margin="0 0 17px">
        Contact support to activate your event at{" "}
        <a
          href="mailto:support@showandtell.film"
          rel="noreferrer noopener"
          target="blank"
        >
          support@showandtell.film
        </a>{" "}
        or click the button below.
      </Text>
      <a href="mailto:support@showandtell.film">
        <Button>Activate event</Button>
      </a>
    </Outline>
  );
};
