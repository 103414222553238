import React from "react";
import * as S from "./SimplePrompt.styled";
import { useLockBodyScroll } from "hooks";

export default ({ children, close }) => {
  useLockBodyScroll();
  return (
    <S.CreditPrompt>
      <S.Box>
        <S.ExitIcon onClick={close}>x</S.ExitIcon>
        {children}
      </S.Box>
    </S.CreditPrompt>
  );
};
