import React, { useState, useEffect } from "react";
import { Flex, Text, Checkbox, Input } from "components";
import { colors } from "consts";

const GlobalTag = ({
  checked,
  name,
  createTag,
  removeTag,
  eventTags,
  isAtTagLimit,
}) => {
  const addCommonTag = () => !isAtTagLimit && createTag(name);
  const removeCommonTag = () => {
    // find the tag that matches the name, and get the id
    const tagToBeRemoved = eventTags.find((eventTag) => eventTag.name === name);
    removeTag(tagToBeRemoved.id);
  };

  const toggleCommonTag = () => (checked ? removeCommonTag() : addCommonTag());
  const disableButton = isAtTagLimit && !checked;

  return (
    <Flex margin="6px 0" width="50%" pointer onClick={() => toggleCommonTag()}>
      <Checkbox checked={checked} disabled={disableButton} />
      <Text margin="0 0 0 12px" size="14px" color={colors.activeWhite1}>
        {name}
      </Text>
    </Flex>
  );
};

export default ({
  commonTags,
  createTag,
  removeTag,
  eventTags,
  globalTags,
  isAtTagLimit,
}) => {
  const [filter, setFilter] = useState("");
  const [filteredTags, setFilteredTags] = useState([]);

  useEffect(() => {
    setFilteredTags(globalTags);
    // eslint-disable-next-line
  }, [globalTags]);

  useEffect(() => {
    let _filteredTags = [...(globalTags || filteredTags)];
    _filteredTags = _filteredTags.filter((tag) =>
      tag.name.includes(filter.toLowerCase())
    );
    setFilteredTags(filter === "" ? globalTags : _filteredTags);
    // eslint-disable-next-line
  }, [filter]);

  return globalTags ? (
    <Flex
      backgroundColor={colors.gray850}
      padding="26px"
      wrap="wrap"
      borderRadius="3px"
      maxHeight="300px"
      overflowY="auto"
    >
      <Input
        value={filter}
        onChange={({ target }) => setFilter(target.value)}
        placeholder="Filter"
        margin="0 0 12px 0"
      />
      {filteredTags?.map((tag) => (
        <GlobalTag
          key={tag.id}
          checked={commonTags?.[tag.name]}
          createTag={createTag}
          removeTag={removeTag}
          eventTags={eventTags}
          isAtTagLimit={isAtTagLimit}
          {...tag}
        />
      ))}
    </Flex>
  ) : (
    <></>
  );
};
