import React, { useState, useEffect } from "react";
import { Flex, Text } from "components";
import { SUBSCRIBERS, ORDERS } from "api";
import * as S from "./DashboardAttendees.styled";

export default ({ screening }) => {
  const [subscribers, setSubscribers] = useState();
  const [orders, setOrders] = useState();

  useEffect(() => {
    const init = async () => {
      const _subscribers = await SUBSCRIBERS.getAll(screening.id);
      const _orders = await ORDERS.getAllPageOrders(screening.id);

      _subscribers?.data?.data && setSubscribers(_subscribers?.data);
      _orders?.data?.data && setOrders(_orders?.data);
    };

    screening?.id && init();
  }, [screening]);

  // Retrieves data then triggers an automatic download in the browser
  const downloadCSV = async (type) => {
    const { data } =
      type === "subscribers"
        ? await SUBSCRIBERS.getCSV(screening.id)
        : await ORDERS.getCSV(screening.id);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `All-${type}__${new Date()}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Flex padding="60px 125px" direction="column" align="flex-start">
      <Text size="38px" weight="500" margin="0 0 17px">
        Attendees
      </Text>

      <Flex direction="column" align="flex-start">
        <S.Outline>
          <Flex>
            <S.Stats direction="column" align="flex=start">
              <Text size="64px" className="canela">
                {orders && orders.data.length
                  ? orders.pages === 1
                    ? orders.data.length
                    : "~" + (orders?.pages || 0) * 10
                  : 0}
              </Text>
              <Text size="24px" margin="16px 0 0">
                <Flex>
                  Orders
                  {orders?.data?.length ? (
                    <S.Download onClick={() => downloadCSV("orders")} />
                  ) : (
                    <></>
                  )}
                </Flex>
              </Text>
            </S.Stats>

            <S.Stats direction="column" align="flex=start">
              <Text size="64px" className="canela">
                {subscribers
                  ? subscribers.pages === 1
                    ? subscribers.data.length
                    : "~" + (subscribers?.pages || 0) * 10
                  : 0}
              </Text>
              <Text size="24px" margin="16px 0 0">
                <Flex>
                  Emails Collected
                  <S.Download onClick={() => downloadCSV("subscribers")} />
                </Flex>
              </Text>
            </S.Stats>
          </Flex>
        </S.Outline>
      </Flex>
    </Flex>
  );
};
