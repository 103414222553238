import React from "react";
import { Route, Redirect } from "react-router";
import { useAuth } from "hooks";

export default ({ component: Component, admin, ...rest }) => {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.getItem("x-session-id")) {
          return admin ? (
            user?.type === "admin" ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          ) : (
            <Component {...props} />
          );
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};
