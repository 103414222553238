import { Flex } from "components";
import styled from "styled-components";
import { minMedia } from "utils";

export default styled(Flex)`
  flex-direction: ${({ direction }) => (direction ? direction : "column")};
  align-items: ${({ align }) => (align ? align : "center")};
  justify-content: ${({ justify }) => (justify ? justify : "center")};
  gap: ${({ mobileGap }) => (mobileGap ? mobileGap : "0")};
  flex-direction: ${({ mobileDirection }) =>
    mobileDirection ? mobileDirection : "column"};
  height: ${({ mobileHeight }) => (mobileHeight ? mobileHeight : "auto")};
  padding: ${({ mobilePadding }) => (mobilePadding ? mobilePadding : "0")};
  margin: ${({ mobileMargin }) => (mobileMargin ? mobileMargin : "0")};
  ${minMedia.laptopPlus`
      height: auto;
      padding: ${({ padding }) => (padding ? padding : "0")};
      gap: ${({ gap }) => (gap ? gap : "0")};
      margin: ${({ margin }) => (margin ? margin : "0")};
      align-items: flex-start;
      flex-direction: ${({ direction }) => (direction ? direction : "column")};
    `}
`;
