import React from "react";
import * as S from "./LoadingIcon.styled";

export default () => (
  <S.Loader>
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </S.Loader>
);
