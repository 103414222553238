import styled from "styled-components";
import { colors } from "consts";
import { Flex } from "components";

export const SearchbarContainer = styled(Flex)`
  max-width: 300px;
  width: 100%;
  height: 32px;
  padding: 10px;
  align-items: center;
  background: ${colors.gray300};
  border-radius: 2px;
`;

export const Searchbar = styled.input`
  color: ${colors.white};
  font-size: 14px;
  font-weight: 400;
  background: none;
  box-shadow: none;
  width: 100%;
  margin-left: 8px;
  border-width: 0;
  border-style: none;
  border-color: transparent;
  border-image: none;
  :focus {
    box-shadow: none;
    outline: none;
  }
`;
