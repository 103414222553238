import React, { useEffect, useState } from "react";
import { LEADS } from "api";
// import AdminTable from "./AdminTable";
import AdminTable from "./AdminTable/_NewAdminTable";
import { Flex, Text, Button, ManualLeadModal, BulkEditModal } from "components";

// rQuery
import { useGetLeadowners, useGetFilteredLeadowners } from "rQuery/api";
import { useGetGlobalLeadownerTags } from "rQuery/api/useGetTags";

export default ({
  // downloadCSV,
  searchTerm,
  filters,
}) => {
  // state
  const [isAddingManualLead, setIsAddingManualLead] = useState(false);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(1);
  const [isBulkEditing, setIsBulkEditing] = useState(false);
  const [bulkEdits, setBulkEdits] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  // HOOKS
  // hook: global tags
  const { isLoading: isLoadingGlobalLeadownerTags, data: globalLeadownerTags } =
    useGetGlobalLeadownerTags();

  // hooks: leadowners
  const { isLoading: isLoadingLeadowners, data: leadowners } =
    useGetLeadowners();
  const {
    isLoading: isLoadingFilteredLeadowners,
    isFetching: isFetchingFilteredLeadowners,
    data: filteredLeadowners,
  } = useGetFilteredLeadowners(searchTerm, page, filters);

  // USE EFFECT
  useEffect(() => {
    let filtering = true;
    if (
      filters.stage === "" &&
      filters.source === "" &&
      filters.tag === "" &&
      page === 1 &&
      searchTerm === ""
    ) {
      filtering = false;
    }
    setIsFiltering(filtering);
  }, [searchTerm, page, filters]);

  useEffect(() => {
    if (!leadowners) return;
    let total = filteredLeadowners?.total || null;
    if (!isFiltering) total = leadowners.total;
    setTotal(total);
  }, [isFiltering, filteredLeadowners, leadowners]);

  useEffect(() => {
    if (!filteredLeadowners?.data) return;
    setFilteredData(filteredLeadowners.data);
  }, [filteredLeadowners]);

  // METHODS
  const bulkUpdate = async (rowIds, attributes) => {
    return await LEADS.bulkEditLeadOwners(rowIds, attributes);
  };

  // helpers
  const formConfig = {
    stage: {
      placeholder: "Stage",
      title: "Edit Stage:",
      type: "select",
      options: [
        { value: "new", name: "New" },
        { value: "working", name: "Working" },
        { value: "nurturing", name: "Nurturing" },
        { value: "unqualified", name: "Unqualified" },
        { value: "qualified", name: "Qualified" },
        { value: "high_opp", name: "High Opp" },
        { value: "graduated", name: "Graduated" },
      ],
    },
    tags: {
      title: "Edit Tags:",
      type: "form",
      globalTags: globalLeadownerTags,
    },
  };

  return (
    <>
      {/* MODALS */}
      {isAddingManualLead && (
        <ManualLeadModal
          close={() => {
            setIsAddingManualLead(false);
          }}
          existing_leadowner_emails={leadowners.data.map((el) => el.email)}
        />
      )}

      {isBulkEditing && (
        <BulkEditModal
          close={() => setIsBulkEditing(false)}
          bulkEdits={bulkEdits.map((row) => row.values)}
          formConfig={formConfig}
          bulkUpdate={bulkUpdate}
        />
      )}

      {/* CONTENT */}
      <Flex width="100%" justify="flex-end" align="center" margin="0 0 16px">
        {/* <Button
          className="secondary"
          // onClick={() => downloadCSV("orders")}
          onClick={() => alert("UPDATE DOWNLOAD LOGIC FOR LEAD OWNERS")}
        >
          Download Report
        </Button> */}
        {bulkEdits.length > 1 && (
          <Button
            className="secondary"
            onClick={() => setIsBulkEditing(true)}
            margin="0 15px 0 0"
          >
            Bulk Update
          </Button>
        )}
        <Button
          className="secondary"
          onClick={() => setIsAddingManualLead(true)}
        >
          Add Lead Manually
        </Button>
      </Flex>
      {isLoadingLeadowners ||
      isLoadingGlobalLeadownerTags ||
      isLoadingFilteredLeadowners ||
      isFetchingFilteredLeadowners ? (
        <Text color="white">Loading...</Text>
      ) : (!isFiltering && leadowners.length === 0) ||
        (isFiltering && filteredData.length === 0) ? (
        <Text color="white">No data matching your search criteria</Text>
      ) : (
        <AdminTable
          className="clickable"
          type="leadOwners"
          data={isFiltering ? filteredData : leadowners.data}
          {...{ page, setPage, total }}
          {...{
            bulkEdits,
            setBulkEdits,
            setIsBulkEditing,
          }}
        />
      )}
    </>
  );
};
