import React, { useEffect, useState } from "react";
import * as S from "./BulkEditModal.styled";
import { Flex, Select, Button, EditTagsForm, Text } from "components";
import { useParams } from "react-router-dom";
import { colors } from "consts";

/**
 * This is a dynaimc component that will process a formConfig and render appropriate components
 *
 * The formConfig is an OBJECT whos keys represent the various attributes that can be updates
 *
 * It accepts the following component types:
 *    1. text
 *    2. select
 *    3. form
 *
 *    - text -
 *        input box
 *
 *    - select -
 *        dropdown
 *
 *    - form -
 *        if type === form then the attr type will be used to render the correct imported form
 *
 */

export default ({ formConfig, bulkEdits, bulkUpdate, close, gTags }) => {
  // hooks
  const { adminRoute } = useParams();

  // state
  const [formState, setFormState] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);

  // useEffect
  useEffect(() => {
    const updatedFormState = Object.keys(formConfig).reduce((prev, curr) => {
      prev[curr] = null;
      return prev;
    }, {});
    setFormState({ ...updatedFormState });
  }, [formConfig]);

  // methods
  const handleAttributeUpdate = (e, attribute) => {
    const prep = { ...formState };

    const handleTextUpdate = () => {
      prep[attribute] = e.target.value === "" ? null : e.target.value;
    };

    const handleSelectUpdate = () => {
      prep[attribute] = e.value;
    };

    if (e.target) {
      handleTextUpdate();
    } else {
      handleSelectUpdate();
    }

    setFormState(prep);
  };

  useEffect(() => {
    if (!formState) return;
    let result = true;
    for (const item of Object.keys(formState)) {
      if (formState[item] === null) result = false;
      break;
    }
    setCanSubmit(result);
  }, [formState]);

  return (
    <S.Prompt>
      <S.Box width="100%">
        <S.ExitIcon onClick={close}>x</S.ExitIcon>
        <Flex direction="column" height="100%" justify="space-between">
          {formState &&
            Object.keys(formConfig).map((attr, i) => (
              <>
                {/* title */}
                <Text
                  color={colors.white}
                  weight="700"
                  size="20px"
                  margin="10px 0"
                >
                  {formConfig[attr].title}
                </Text>

                {/* dynamic content */}
                {formConfig[attr].type === "text" && (
                  <S.TextArea
                    key={i}
                    autoFocus={true}
                    placeholder={formConfig[attr].placeholder}
                    cols={75}
                    rows={10}
                    value={formState[attr]}
                    name="note"
                    onChange={(e) => handleAttributeUpdate(e, attr)}
                  />
                )}
                {formConfig[attr].type === "form" && attr === "tags" && (
                  <EditTagsForm
                    key={i}
                    gTags={formConfig[attr].globalTags}
                    bulkEdits={bulkEdits}
                    resource={adminRoute}
                  />
                )}
                {formConfig[attr].type === "select" && (
                  <Flex key={i} margin="0 0 10px 0">
                    <Select
                      key={i}
                      radius="6px 0px 0px 6px"
                      placeholder={formConfig[attr].placeholder}
                      toLowerCase={false}
                      selected={{ name: formState[attr] }}
                      options={formConfig[attr].options}
                      setSelected={(e) => handleAttributeUpdate(e, attr)}
                    />
                  </Flex>
                )}
              </>
            ))}

          <Button
            className={!canSubmit && `disabled`}
            margin="30px 0 0 0"
            onClick={async () => {
              const reducedAttributes = Object.entries(formState).reduce(
                (prev, curr) => {
                  prev[curr[0]] = curr[1];
                  return prev;
                },
                {}
              );

              try {
                bulkUpdate &&
                  bulkUpdate(
                    bulkEdits.map((row) => row.id),
                    reducedAttributes
                  );
                close();
              } catch (err) {
                console.error("ERROR: adding manual lead", err);
              }
            }}
          >
            {`Bulk Update ${bulkEdits.length} ${
              bulkEdits.length < 1 ? "Row" : "Rows"
            }`}
          </Button>
        </Flex>
      </S.Box>
    </S.Prompt>
  );
};
