import styled from "styled-components";
import { Flex } from "components";

export const Delete = styled(Flex)`
  cursor: pointer;
  height: 22px;
`;

export const Comment = styled(Flex)`
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    background: #302f2f;
  }
`;
