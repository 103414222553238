import React, { useState, useMemo } from "react";
import * as S from "../Theater.styled";
import { Player, FallBackModal } from "components";
import { useWidth } from "hooks";
import { browserStats, filmTypes } from "utils";

export default ({
  modalType,
  activeVideo,
  setActiveVideo,
  featureUrl,
  paywallSettings,
  eventId,
}) => {
  const [midrollInterval, setMidrollInterval] = useState(null);
  const [modal, setModal] = useState("");
  const [willAutoPlay, setWillAutoPlay] = useState(true);
  const [, setForceUpdate] = useState(Date.now());
  const isIOS = browserStats.os === "iOS";
  const width = useWidth();
  const isMobile = useMemo(() => {
    const value = isIOS || width < 768 ? 1 : 0;
    return value;
    // eslint-disable-next-line
  }, [width]);

  const closeAndClear = () => {
    setActiveVideo(null);
    if (midrollInterval) {
      setMidrollInterval(clearInterval(midrollInterval));
    }
    setForceUpdate();
  };

  const playNextAndClear = () => {
    setActiveVideo(
      activeVideo.type === filmTypes.SPONSOR
        ? { url: featureUrl, type: filmTypes.FEATURE }
        : null
    );
    setForceUpdate();
    if (activeVideo.type === filmTypes.FEATURE) {
      setMidrollInterval(clearInterval(midrollInterval));
    }
  };

  return (
    <S.VideoContainer className="video-container">
      {!modalType && !modal && (
        <S.Close
          onClick={() => closeAndClear()}
          src={require("assets/images/icon-close.svg").default}
        />
      )}
      {!modalType && !modal && (
        <S.Back onClick={() => closeAndClear()}>{`< BACK`}</S.Back>
      )}

      {activeVideo !== null && activeVideo.url ? (
        <Player
          key={activeVideo.url}
          controls={true}
          playNextAndClear={playNextAndClear}
          closeAndClear={closeAndClear}
          playing={true}
          paywallSettings={paywallSettings}
          url={activeVideo.url}
          autoPlay={willAutoPlay}
          isMobile={isMobile}
          {...{
            activeVideo,
            eventId,
            modal,
            setModal,
            midrollInterval,
            setMidrollInterval,
            setWillAutoPlay,
          }}
        />
      ) : (
        <FallBackModal
          close={closeAndClear}
          buttonClick={() =>
            setActiveVideo({ url: featureUrl, type: "feature" })
          }
        />
      )}
    </S.VideoContainer>
  );
};
