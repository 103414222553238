import React, { useState, useEffect, useCallback } from "react";
import { Flex, Text, Button, Input, Select } from "components";
import { format } from "date-fns";
import * as S from "./AdminCustomerDetails.styled";
import { ACCOUNT, USERS } from "api";
import { toast } from "react-toastify";
import { countries, colors } from "consts";

export default ({ customerDetails }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [capabilities, setCapabilities] = useState(null);

  const getCapabilities = useCallback(async () => {
    const { data } = await USERS.checkCapabilitiesById(
      customerDetails.connectId
    );
    if (data?.data) {
      setCapabilities(data.data);
    }
  }, [customerDetails.connectId]);

  useEffect(() => {
    if (customerDetails) {
      getCapabilities();
      const updated = { ...customerDetails };

      // eslint-disable-next-line
      countries.filter((item) => {
        if (item["alpha-2-code"] === customerDetails.country) {
          updated.country = item.country;
        }
      });

      setCustomerData(updated);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails]);

  const handleInput = (name, value) => {
    setCustomerData({
      ...customerData,
      [name]: value,
    });
  };

  const submitChanges = async (customerData) => {
    setIsEditing(false);
    setIsSubmitting(true);

    // request entity to large?
    // remove unneeded keys
    const updateData = { ...customerData };
    delete updateData.credits;
    delete updateData.credits_log;
    delete updateData.events;
    delete updateData.notes;
    delete updateData.orders;
    delete updateData.pages;
    delete updateData.tags;

    const countryData = countries.filter(
      (item) => item.country === customerData.country
    );
    updateData.country = countryData[0]["alpha-2-code"];

    try {
      await ACCOUNT.patchAccountById(updateData);
      toast("customer data successfully updated", { autoClose: 3000 });
    } catch (err) {
      let _message = "";
      if (err.validation) {
        for (var i in err.validation) {
          _message.concat(err.validation[i].msg);
        }
      }
      toast("Error updating customer data", { autoClose: 3000 });
    }
    setIsSubmitting(false);
  };

  return (
    <S.ListContainer margin="0 0 24px" maxHeight="450px">
      <Flex justify="space-between" align="center" margin="0 0 24px 0">
        <Text size="24px" weight="bold">
          Details
        </Text>
        <Button
          className={`secondary${isSubmitting ? " disabled" : ""}`}
          onClick={() => setIsEditing(!isEditing)}
        >
          {isEditing ? "Cancel" : isSubmitting ? "Saving" : "Edit"}
        </Button>
      </Flex>
      <Flex width="100%" margin="0 0 32px 0">
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            First name
          </Text>
          {isEditing ? (
            <Input
              name="firstName"
              value={customerData?.firstName}
              onChange={(e) => handleInput(e.target.name, e.target.value)}
              type="text"
            />
          ) : (
            <Text size="14px">{customerData?.firstName || "N/A"}</Text>
          )}
        </S.DetailContainer>
      </Flex>
      <Flex width="100%" margin="0 0 32px 0">
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Last name
          </Text>
          {isEditing ? (
            <Input
              name="lastName"
              value={customerData?.lastName}
              onChange={(e) => handleInput(e.target.name, e.target.value)}
              type="text"
            />
          ) : (
            <Text size="14px">{customerData?.lastName || "N/A"}</Text>
          )}
        </S.DetailContainer>
      </Flex>
      <Flex width="100%" margin="0 0 32px 0">
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Phone number
          </Text>
          {isEditing ? (
            <Input
              name="phoneNumber"
              value={customerData?.phoneNumber}
              onChange={(e) => handleInput(e.target.name, e.target.value)}
              type="text"
            />
          ) : (
            <Text size="14px">{customerData?.phoneNumber || "N/A"}</Text>
          )}
        </S.DetailContainer>
      </Flex>
      <Flex width="100%" margin="0 0 32px 0">
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Email address
          </Text>
          {isEditing ? (
            <Input
              name="email"
              value={customerData?.email}
              onChange={(e) => handleInput(e.target.name, e.target.value)}
              type="text"
            />
          ) : (
            <Text size="14px">{customerData?.email || "N/A"}</Text>
          )}
        </S.DetailContainer>
      </Flex>
      <Flex width="100%" margin="0 0 32px 0">
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Country
          </Text>
          {isEditing ? (
            <Select
              radius="6px 0px 0px 6px"
              placeholder={customerData?.country}
              toLowerCase={false}
              selected={customerData?.country}
              options={countries.map(({ country }) => ({ name: country }))}
              setSelected={(e) => handleInput("country", e.name)}
            />
          ) : (
            <Text size="14px">{customerData?.country || "N/A"}</Text>
          )}
        </S.DetailContainer>
      </Flex>
      <Flex width="100%" margin="0 0 32px 0">
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Date became customer
          </Text>
          <Text size="14px">
            {customerData?.createdAt
              ? format(new Date(customerData?.createdAt), "Pp")
              : "N/A"}
          </Text>
        </S.DetailContainer>
      </Flex>
      <Flex width="100%" margin="0 0 32px 0">
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Teachable ID
          </Text>
          {isEditing ? (
            <Input
              name="teachableId"
              value={customerData?.teachableId}
              onChange={(e) => handleInput(e.target.name, e.target.value)}
              type="number"
            />
          ) : (
            <Text size="14px">{customerData?.teachableId || "N/A"}</Text>
          )}
        </S.DetailContainer>
      </Flex>
      <Flex width="100%" margin="0 0 32px 0">
        <S.DetailContainer>
          <Text size="14px" weight="bold" margin="0 0 8px 0">
            Stripe Status
          </Text>
          {capabilities?.currently_due ? (
            <Flex direction="column" align="flex-start">
              <Text size="14px" color={colors.orange} margin="0 0 8px 0">
                Incomplete
              </Text>
              <Flex margin="0 0 8px 0">
                <Text size="14px">Items needed:</Text>
                <Text size="14px" margin="0 0 0 8px">
                  {capabilities.currently_due.join(", ")}
                </Text>
              </Flex>
              <a
                href={capabilities.onboarding_url}
                target="_blank"
                rel="noreferrer"
              >
                <Text size="14px" color={colors.orange}>
                  Stripe onboarding link
                </Text>
              </a>
            </Flex>
          ) : (
            <Text size="14px" color={colors.green}>
              Complete
            </Text>
          )}
        </S.DetailContainer>
      </Flex>
      {isEditing && (
        <Button onClick={() => submitChanges(customerData)}>Submit</Button>
      )}
    </S.ListContainer>
  );
};
