import React, { useState, useMemo, useEffect } from "react";
import {
  Text,
  Button,
  MobileFlex,
  TosCheckbox,
  ReadMoreText,
} from "components";
import { useWidth } from "hooks";
import { toast } from "react-toastify";
import { colors } from "consts";
import * as S from "./DonationSelectModal.styled";
toast.configure();

const CTA = ({ text, action }) => (
  <Button
    onClick={() => action()}
    padding="20px 32px"
    margin="40px 0 0"
    height="auto"
  >
    <Text size="18px" padding="0 18px 0 0">
      {text}
    </Text>
    <img
      src={require("assets/images/icon-arrow-right.svg").default}
      alt="arrow-right"
    />
  </Button>
);

export default ({
  close,
  paywallSettings,
  next,
  donationAmount,
  setDonationAmount,
  gateStatus,
}) => {
  const defaultDonations = useMemo(
    () => ({
      0: null,
      1: 10,
      2: 20,
      3: 50,
      4: 100,
      Custom: "",
    }),
    []
  );

  const [donate, setDonate] = useState({});
  const [selectedDonation, setSelectedDonation] = useState(
    paywallSettings?.donationSuggestion1 || defaultDonations[1]
  );
  const [isPaywall] = useState(paywallSettings?.enablePaywall);
  const [tosAccepted, setTosAccepted] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const width = useWidth();

  useEffect(() => {}, [donate]);

  const toggleTos = () => setTosAccepted(!tosAccepted);

  const continueToCheckout = () => {
    if (
      donate.customAmount === "0" ||
      (donate.customAmount === "" && !donationAmount)
    ) {
      return isPaywall && next(true);
    } else if (!donate.customAmount && donationAmount === null) {
      next(true);
    } else {
      return (
        donate.customAmount && setDonationAmount(parseInt(donate.customAmount)),
        next(true)
      );
    }
  };

  const updateDonate = (e) => {
    let amount = e.target.value;
    amount < 0 && (amount = Math.abs(amount));
    donate[e.target.name] = amount;
    setDonate(donate);
  };

  const updateCustomDonate = () => {
    const _userAmount = paywallSettings?.donationSuggestionCustom;
    let _defaultAmount = defaultDonations["Custom"];
    _userAmount && (_defaultAmount = _userAmount);

    const _currentAmount = donate.customAmount;

    if (_currentAmount === undefined || _currentAmount === "") {
      const updated = { ...donate };
      updated.customAmount = _defaultAmount;
      setDonate(updated);
    }
  };

  const selectDonate = (field) => {
    const suggestedPrice = paywallSettings?.[`donationSuggestion${field}`];
    setDonationAmount(suggestedPrice || defaultDonations[field]);
    setSelectedDonation(
      field === "Custom" ? "custom" : defaultDonations[field]
    );
    setDonate(donate);
  };

  const isDonationSelected = (field) => {
    const suggestedPrice = paywallSettings?.[`donationSuggestion${field}`];
    const customSelected = selectedDonation === "custom";
    let isSelected = suggestedPrice
      ? donationAmount * 1 === suggestedPrice * 1 && !customSelected
      : donationAmount * 1 === defaultDonations[field] * 1 && !customSelected;

    if (selectedDonation === "custom" && field === "Custom") isSelected = true;

    return isSelected;
  };

  const FormSide = () => (
    <MobileFlex mobileMargin="0 0 50px">
      {paywallSettings?.askDonations && (
        <MobileFlex margin="12px 0">
          <Text
            dark
            padding="4px 0px"
            responsive={{ laptopPlus: { padding: "0" } }}
          >
            Donation Amount
          </Text>
          <MobileFlex width="100%" direction="row">
            <S.Option
              width="64px"
              selected={isDonationSelected(0)}
              onClick={() => selectDonate(0)}
            >
              $0
            </S.Option>
            <S.Option
              width="64px"
              selected={isDonationSelected(1)}
              onClick={() => selectDonate(1)}
            >
              ${paywallSettings?.donationSuggestion1 || 10}
            </S.Option>
            <S.Option
              width="64px"
              selected={isDonationSelected(2)}
              onClick={() => selectDonate(2)}
            >
              ${paywallSettings?.donationSuggestion2 || 20}
            </S.Option>
            <S.Option
              width="64px"
              selected={isDonationSelected(3)}
              onClick={() => selectDonate(3)}
            >
              ${paywallSettings?.donationSuggestion3 || 50}
            </S.Option>
            <S.Option
              width="64px"
              selected={isDonationSelected(4)}
              onClick={() => selectDonate(4)}
            >
              ${paywallSettings?.donationSuggestion4 || 100}
            </S.Option>
            <S.Option
              selected={isDonationSelected("Custom")}
              onClick={() => selectDonate("Custom")}
              width="171px"
              margin="0"
              padding="0 0 0 12px"
            >
              $
              <S.OptionInput
                value={donate.customAmount}
                onChange={updateDonate}
                name="customAmount"
                placeholder={
                  selectedDonation === "custom" ? "" : "Other Amount"
                }
                type="number"
                min="0"
                color={donate.customAmount && "white"}
                selected={isDonationSelected("Custom")}
                onClick={() => updateCustomDonate()}
              />
            </S.Option>
          </MobileFlex>
        </MobileFlex>
      )}

      <MobileFlex direction="row" justify="flex-start">
        {!isPaywall && donationAmount === null && gateStatus.live && (
          // No paywall, $0 donation, live
          <MobileFlex padding="12px 0 0">
            <TosCheckbox
              tosAccepted={tosAccepted}
              toggleTos={toggleTos}
              color={colors.gray8}
            />
            <Button
              onClick={() => tosAccepted && close()}
              padding="20px 32px"
              margin="18px 0 0"
              height="auto"
              className={`${!tosAccepted ? "disabled" : ""}`}
            >
              <Text size="18px" padding="0 18px 0 0">
                Keep Watching
              </Text>
            </Button>
          </MobileFlex>
        )}

        {!isPaywall && donationAmount === null && !gateStatus.live && (
          // No paywall, $0 donation, not live
          <MobileFlex padding="12px 0 0">
            <Button
              padding="20px 32px"
              margin="40px 0 0"
              height="auto"
              className="disabled"
            >
              <Text size="18px" padding="0 18px 0 0">
                This Event Is Not Live Yet
              </Text>
            </Button>
          </MobileFlex>
        )}

        {(donationAmount > 0 || selectedDonation === "custom") && (
          // Paywall or not, any donation amount over $0, live or not
          <MobileFlex padding="12px 0 0">
            <CTA
              action={continueToCheckout}
              text={
                paywallSettings?.donationCallToAction || `Continue To Checkout`
              }
              margin="0 25px 0 0"
            />
          </MobileFlex>
        )}

        {isPaywall && donationAmount === null && (
          // Paywall, $0 donation, live or not (purchase or prepay)
          <MobileFlex padding="12px 0 0">
            <CTA
              action={continueToCheckout}
              text={
                paywallSettings?.donationCallToAction || `Continue To Checkout`
              }
              margin="0 25px 0 0"
            />
          </MobileFlex>
        )}
      </MobileFlex>
    </MobileFlex>
  );

  return (
    <S.Wrapper>
      <S.Close
        onClick={() => close()}
        src={require("assets/images/icon-exit-dark.svg").default}
      />
      <S.WidthProvider>
        <ReadMoreText
          title={
            paywallSettings?.donationTitle ||
            "Please consider adding a donation"
          }
          description={paywallSettings?.donationSubtitle || ``}
          padding={width > 1230 ? "0" : "0 24px"}
          screenWidth={width}
          titleMargin={width < 768 ? "0 0 12px" : "0 0 18px"}
          sponsorMargin={width < 768 ? "0 0 8px" : "0 0 12px"}
          descriptionMargin={width < 768 ? "0 0 8px" : "0"}
          truncateDescription={
            width < 768 || paywallSettings?.donationSubtitle?.length > 400
          }
          readMore={readMore}
          setReadMore={setReadMore}
          dark
        />
      </S.WidthProvider>
      <S.WidthProvider>
        <FormSide />
      </S.WidthProvider>
    </S.Wrapper>
  );
};
