import styled from "styled-components";
import { colors } from "consts";
import { Flex } from "components";

export const Download = styled.div`
  cursor: pointer;
  background: rgba(196, 196, 196, 0.25);
  border-radius: 54px;
  margin: 0 0 0 8px;
  width: 56px;
  height: 30px;
  background: url(${require("assets/images/icon-download.svg").default})
    no-repeat center center rgba(196, 196, 196, 0.25);
`;

export const Spacer = styled.div`
  max-width: 264px;
  width: 100%;
  min-height: 100vh;
`;

export const Sidebar = styled(Flex)`
  flex-direction: column;
  background: #232323;
  height: 100%;
  min-width: 264px;
  max-width: 264px;
`;

export const Action = styled.div`
  display: flex;
  padding: 16px;
  margin: 0 24px;
  border-radius: 8px;
  align-items: center;
  transition-duration: 0.3s;
  cursor: pointer;
  height: 56px;
  min-width: 100%;
  background: ${({ active }) => (active ? colors.charcoal : null)};
  color: ${({ active }) => (active ? colors.white : colors.gray750)};
  font-weight: 700;
  opacity: ${({ hasID }) => (hasID ? 1 : 0.5)};
  &:hover {
    background: ${({ hasID }) => (hasID ? colors.charcoal : null)};
    color: ${({ hasID }) => (hasID ? colors.white : null)};
  }
`;

export const Watch = styled(Flex)`
  position: absolute;
  bottom: 100px;
  left: 0px;
  z-index: 2;
`;

export const BabyButton = styled.div`
  padding: 4px 8px;
  margin-top: 4px;
  border-radius: 4px;
  background: ${colors.gray200};
  &:hover {
    cursor: pointer;
    background: ${colors.activeWhite};
    > div {
      color: ${colors.orange};
    }
  }
`;

export const Banner = styled.div`
  background: ${colors.orange};
  padding: 8px;
`;

export const TagContainer = styled(Flex)`
  width: 100%;
  background: ${colors.gray850};
  padding: 24px;
  border-radius: 8px;
  flex-direction: column;
  margin: 0 0 24px 0;
`;

export const Tag = styled(Flex)`
  background: ${colors.orange};
  font-size: 14px;
  color: ${colors.white};
  font-weight: bold;
  border-radius: 50px;
  line-height: 22px;
  padding: 4px 12px;
  margin: 4px;
  width: auto;
`;

export const TagWrapper = styled(Flex)`
  border: 1px solid ${colors.activeWhite3};
  background: ${colors.charcoal};
  border-radius: 8px;
  padding: 16px;
  margin: 0 0 16px;
  flex-wrap: wrap;
`;

export const TextArea = styled.textarea`
  background: transparent;
  border: 1px solid ${colors.activeWhite3};
  border-radius: 8px;
  resize: none;
  overflow: auto;
  color: ${colors.white};
  font-family: "Inter", sans-serif;
  padding: 20px;
  margin: 0 0 20px 0;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.gray500};
    border-radius: 0px 8px 8px 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.gray600};
    border-radius: 8px;
  }
`;
