import React from "react";
import { Flex, Button, MockBanner } from "components";

export default ({ information, bannerImage, setCropModalOpen, target }) => {
  return (
    <Flex direction="column" align="start">
      <MockBanner
        height="180px"
        information={information}
        background={bannerImage}
        target={target}
      />
      <Button margin="20px 0 0 0" onClick={() => setCropModalOpen(true)}>
        Edit image
      </Button>
    </Flex>
  );
};
