import React, { useState, useEffect } from "react";
import { Flex, Label, Input, Button, Select } from "components";
import { ACCOUNT } from "api";
import { toast } from "react-toastify";
import { stripeKey } from "utils";

export default ({ setConnectedBank, setShowDisbursement }) => {
  const [stripe, setStripe] = useState();
  const [loading, setLoading] = useState(false);
  const [bankInfo, setBankInfo] = useState({});
  const [bankCountry, setBankCountry] = useState({
    value: "US",
    name: "United States",
  });
  const [bankCurrency, setBankCurrency] = useState({
    value: "USD",
    name: "US Dollar",
  });

  useEffect(() => {
    const _stripe = window.Stripe(stripeKey);
    setStripe(_stripe);
  }, []);

  const sendDisbursement = async () => {
    setLoading(true);

    !bankInfo.name &&
      toast.error("Please enter an account holder name", { autoClose: 5000 });
    !bankInfo.accountNumber &&
      toast.error("Please enter an account number", { autoClose: 5000 });

    if (bankInfo.accountNumber && bankInfo.name) {
      const bankToken = {
        country: bankCountry.value,
        currency: bankCurrency.value,
        account_number: bankInfo.accountNumber,
        routing_number: bankInfo.routingNumber,
        account_holder_name: bankInfo.name,
        account_holder_type: "individual",
      };

      try {
        const _token = await stripe.createToken("bank_account", bankToken);
        setConnectedBank(true);
        setShowDisbursement(false);

        _token.error &&
          toast.error(_token.error.message, {
            autoClose: 5000,
          });

        const _payout = await ACCOUNT.update({ payoutToken: _token.token.id });

        if (_payout.statusText === "OK" && _payout.status === 200) {
          toast.success("Bank successfully linked", { autoClose: 6000 });
        } else {
          toast.error("An unexpeced error occured while creating your order", {
            autoClose: 5000,
          });
        }
        setLoading(false);
      } catch (err) {
        toast.error("An unexpeced error occured while creating your order", {
          autoClose: 5000,
        });
      }
    }
    setLoading(false);
  };

  const updateBankInfo = (e) => {
    bankInfo[e.target.name] = e.target.value;
    setBankInfo(bankInfo);
  };

  return (
    <>
      <Label>Account Holder Name</Label>
      <Input
        radius="6px"
        value={bankInfo.name}
        name="name"
        onChange={updateBankInfo}
        margin="0 0 24px -1px"
        placeholder="holder name"
      ></Input>

      <Flex>
        <Flex direction="column" align="flex-start">
          <Label>Country</Label>
          <Select
            margin="0 0 12px 0px"
            radius="6px 0px 0px 6px"
            selected={bankCountry}
            options={[
              { value: "US", name: "United States" },
              { value: "CA", name: "Canada" },
              { value: "GB", name: "United Kingdom" },
              { value: "DE", name: "Germany" },
              { value: "FR", name: "France" },
              { value: "ES", name: "Spain" },
            ]}
            setSelected={setBankCountry}
          />
        </Flex>
        <Flex direction="column" align="flex-start">
          <Label>Currency</Label>
          <Select
            margin="0 0 12px -1px"
            radius="0px 6px 6px 0px"
            selected={bankCurrency}
            options={[
              { value: "USD", name: "US Dollar" },
              { value: "CAD", name: "Canadian Dollar" },
              { value: "GBP", name: "Pound" },
              { value: "EUR", name: "Euro" },
            ]}
            setSelected={setBankCurrency}
          />
        </Flex>
      </Flex>

      <Flex>
        <Flex direction="column" align="flex-start">
          <Label>Account Number</Label>
          <Input
            radius="6px 0px 0px 6px"
            value={bankInfo.accountNumber}
            name="accountNumber"
            onChange={updateBankInfo}
            margin="0 0 24px"
            placeholder="account number"
            maxlength="100"
          />
        </Flex>
        <Flex direction="column" align="flex-start">
          <Label>Routing Number</Label>
          <Input
            radius="0px 6px 6px 0px"
            value={bankInfo.routingNumber}
            name="routingNumber"
            onChange={updateBankInfo}
            margin="0 0 24px -1px"
            placeholder="routing number"
            maxlength="100"
          />
        </Flex>
      </Flex>

      {loading ? (
        <Button margin="16px 0 64px" className="medium" width="100%">
          Submitting order...
        </Button>
      ) : (
        <Button
          margin="16px 0 64px"
          icon={require("assets/images/icon-lock.svg").default}
          className="medium"
          width="100%"
          onClick={() => sendDisbursement()}
        >
          Connect
        </Button>
      )}
    </>
  );
};
