import React, { useEffect, useState } from "react";
import { Flex, Text, Button, Input, Select, SimplePrompt } from "components";
import * as S from "./AdminLeadOwnerDetails.styled";
import { leadStages } from "consts";
import { LEADS } from "api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export default ({ owner }) => {
  // hooks
  const history = useHistory();

  // state
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ownerData, setOwnerData] = useState(null);
  const [isDeletingLeadOwner, setIsDeletingLeadOwner] = useState(false);

  // methods
  const handleInput = (name, value) => {
    setOwnerData({
      ...ownerData,
      [name]: value,
    });
  };

  const submitChanges = async (ownerData) => {
    setIsEditing(false);
    setIsSubmitting(true);
    try {
      await LEADS.patchLeadOwnerById(ownerData);
      toast("lead data successfully updated", { autoClose: 3000 });
    } catch (err) {
      let _message = "";
      if (err.validation) {
        for (var i in err.validation) {
          _message.concat(err.validation[i].msg);
        }
      }
      toast("Error updating lead data", { autoClose: 3000 });
    }
    setIsSubmitting(false);
  };

  const deleteLead = async () => {
    try {
      await LEADS.deleteLeadOwner(ownerData.id);
      toast("lead successfully deleted", { autoClose: 3000 });
      const location = {
        pathname: `/admin/leads`,
        state: { superadmin: true },
      };
      history.push(location);
    } catch (err) {
      let _message = "";
      if (err.validation) {
        for (var i in err.validation) {
          _message.concat(err.validation[i].msg);
        }
      }
      toast("Error deleting lead", { autoClose: 3000 });
    }
  };

  // useEffect
  useEffect(() => {
    owner && setOwnerData(owner);
  }, [owner]);

  return (
    <>
      {/* MODALS */}
      {isDeletingLeadOwner && (
        <SimplePrompt close={() => setIsDeletingLeadOwner(false)}>
          <Text>{`You are about to delete a lead and all of their lead sources. This action is irreversible. `}</Text>

          <Flex direction="column" padding="7px 0 0 0">
            <Text>{`Are you sure you wish to proceed?`}</Text>
            <Flex justify="center" padding="7px 0 0 0">
              <Button
                onClick={() => {
                  deleteLead();
                  setIsDeletingLeadOwner(false);
                }}
                margin="0 10px 0 0"
              >
                Yes, Delete Lead
              </Button>
              <Button
                onClick={() => {
                  setIsDeletingLeadOwner(false);
                }}
              >
                No, Cancel
              </Button>
            </Flex>
          </Flex>
        </SimplePrompt>
      )}

      {/* CONTENT */}
      <S.ListContainer margin="0 0 24px" maxHeight="450px">
        <Flex
          justify="space-between"
          align="center"
          margin="0 0 24px 0"
          width="100%"
        >
          <Text size="24px" weight="bold">
            Details
          </Text>
          <Flex justify="flex-end">
            <Button
              margin="0 10px 0 0"
              className={`secondary${isSubmitting ? " disabled" : ""}`}
              onClick={() => setIsEditing(!isEditing)}
            >
              {isEditing ? "Cancel" : isSubmitting ? "Saving" : "Edit"}
            </Button>
            <Button
              className="secondary"
              onClick={() => setIsDeletingLeadOwner(true)}
            >
              Delete Lead
            </Button>
          </Flex>
        </Flex>
        <Flex width="100%" margin="0 0 32px 0">
          <S.DetailContainer>
            <Text size="14px" weight="bold" margin="0 0 8px 0">
              Stage
            </Text>
            {isEditing ? (
              <Select
                radius="6px 0px 0px 6px"
                placeholder={ownerData?.stage}
                toLowerCase={false}
                selected={ownerData?.stage}
                options={leadStages.map((stage) => ({ name: stage }))}
                setSelected={(e) => handleInput("stage", e.name)}
              />
            ) : (
              <Text size="14px">{ownerData?.stage || "N/A"}</Text>
            )}
          </S.DetailContainer>
        </Flex>
        <Flex width="100%" margin="0 0 32px 0">
          <S.DetailContainer>
            <Text size="14px" weight="bold" margin="0 0 8px 0">
              Name
            </Text>
            {isEditing ? (
              <Input
                name="name"
                value={ownerData?.name}
                onChange={(e) => handleInput(e.target.name, e.target.value)}
                type="text"
              />
            ) : (
              <Text size="14px">{ownerData?.name || "N/A"}</Text>
            )}
          </S.DetailContainer>
        </Flex>
        <Flex width="100%" margin="0 0 32px 0">
          <S.DetailContainer>
            <Text size="14px" weight="bold" margin="0 0 8px 0">
              Email
            </Text>
            {isEditing ? (
              <Input
                name="email"
                value={ownerData?.email}
                onChange={(e) => handleInput(e.target.name, e.target.value)}
                type="text"
              />
            ) : (
              <Text size="14px">{ownerData?.email || "N/A"}</Text>
            )}
          </S.DetailContainer>
        </Flex>
        <Flex width="100%" margin="0 0 32px 0">
          <S.DetailContainer>
            <Text size="14px" weight="bold" margin="0 0 8px 0">
              ID
            </Text>
            <Text size="14px">{ownerData?.id || "N/A"}</Text>
          </S.DetailContainer>
        </Flex>
        {isEditing && (
          <Button onClick={() => submitChanges(ownerData)}>Submit</Button>
        )}
      </S.ListContainer>
    </>
  );
};
