import React from "react";
import { useParams } from "react-router-dom";
import { BackgroundProvider, AdminLayout, Tags, Flex, Text } from "components";
import {
  EventDetailsList,
  EventDetailsToolbar,
  EventOrders,
  EventSubscribers,
} from "components/Admin/AdminEventDetails";

// rQuery
import { useGetGlobalEventTags } from "rQuery/api/useGetTags";
import { useGetEvent } from "rQuery/api";

export default () => {
  // HOOKS
  const { eventId } = useParams();
  const {
    isLoading: isLoadingGlobalLeadownerTags,
    data: globalLeadownerTags,
    refetch: refetchGlobalEventTags,
  } = useGetGlobalEventTags();

  const { isLoading: isLoadingEvent, data: event } = useGetEvent(eventId);

  return (
    <BackgroundProvider minHeight="100vh">
      <AdminLayout text={event?.title || ""} type="details">
        {isLoadingEvent || isLoadingGlobalLeadownerTags ? (
          <Flex>
            <Text>Loading...</Text>
          </Flex>
        ) : (
          <Flex direction="column">
            <EventDetailsToolbar eventDetails={event} />
            <EventDetailsList eventDetails={event} />
            <Tags
              type="event"
              ownerDetails={event}
              gTags={globalLeadownerTags}
              refetchGlobalTags={refetchGlobalEventTags}
            />
            <EventOrders eventDetails={event} />
            <EventSubscribers eventDetails={event} />
          </Flex>
        )}
      </AdminLayout>
    </BackgroundProvider>
  );
};
