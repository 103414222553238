import React from "react";
import styled from "styled-components";
import { colors } from "consts";

const Box = styled.div`
  border: 2px solid ${colors.gray200};
  padding: 22px 36px;
  width: 100%;
  margin: ${({ margin }) => margin || "0"};
`;

export default ({ ...props }) => <Box {...props} />;
