import { useQuery } from "react-query";
import axiosRequest from "../../api/axios.config";
import queryClient from "../queryClient";

const fetcher = async (searchTerm, business_type, tag, page) => {
  let params = `?`;
  if (searchTerm) params = `${params}&search_term=${searchTerm}`;
  if (business_type) params = `${params}&business_type=${business_type}`;
  if (tag) params = `${params}&tag=${tag}`;
  if (page) params = `${params}&page=${page}`;

  let endpoint = `/v1/accounts`;
  if (params !== "?") endpoint = `${endpoint}${params}`;
  const raw = await axiosRequest.get(endpoint);
  return raw.data.data;
};

const useGetFilteredAccounts = (searchTerm, page, { business_type, tag }) => {
  return useQuery(
    [
      "accounts",
      "filtered",
      `searchTerm=${searchTerm}`,
      `page=${page}`,
      `business_type=${business_type}`,
      `tag=${tag}`,
    ],
    async () => {
      // TODO: improve by using axios cancel token instead of setting cacheTime to 0
      // that way if they backtrack you are not recalling the data

      const data = await fetcher(searchTerm, business_type, tag, page);

      const response = {
        total: data?.total || null, // this should be the total of the NON filtered / searched data
        data: data.data,
      };

      data.data.forEach((account) => {
        queryClient.setQueryData(["account", account.id], account);
      });

      return response;
    },
    {
      enabled:
        !!searchTerm || (!!page && page !== 1) || !!business_type || !!tag,
      cacheTime: 0,
    }
  );
};

const useGetAccounts = () => {
  return useQuery(["accounts"], async () => {
    const data = await fetcher();

    data.data.forEach((account) => {
      queryClient.setQueryData(["account", account.id], account);
    });

    const response = {
      total: data?.total || null, // this should be the total of the NON filtered / searched data
      data: data.data,
    };

    return response;
  });
};

export { useGetAccounts, useGetFilteredAccounts };
