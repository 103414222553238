import React from "react";
import * as S from "./TheaterModalContainer.styled";

export default ({ children, close, hideClose, modalType }) => {
  return (
    <S.Background
      className={
        (modalType === "Paywall" || modalType === "DonationPaywall") &&
        `stripe-active`
      }
    >
      {/* {!hideClose && (
        <S.Close
          onClick={() => close()}
          src={require("assets/images/icon-exit-dark.svg").default}
        />
      )} */}
      {children}
    </S.Background>
  );
};
