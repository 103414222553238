import React, { useState, useEffect } from "react";
import { SortOrderInput } from ".";
import * as S from "./Incrementer.styled";

export default ({ validateAndSort, items, itemOrder, max, ...rest }) => {
  const [shownValue, setShownValue] = useState(itemOrder + 1);

  useEffect(() => {
    setShownValue(itemOrder + 1);
  }, [items, itemOrder]);

  return (
    <S.Container {...rest}>
      <SortOrderInput
        validateAndSort={validateAndSort}
        value={shownValue}
        height="12px"
        min={1}
        max={max}
      />
    </S.Container>
  );
};
