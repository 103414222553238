import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { colors } from "consts";

export const Question = styled.div`
  padding: 24px 0;
  border-top: 1px solid #535353;
  width: 100%;
`;

export const Arrow = styled.img`
  width: ${({ open }) => (open ? "19px" : "28px")};
`;

export const Markdown = styled(ReactMarkdown)`
  width: 100%;
  * {
    color: white;
    font-size: 28px;
  }
  a {
    cursor: pointer;
    color: ${colors.orange};
    &:hover {
      text-decoration: underline;
    }
  }
`;
