import React from "react";
import * as S from "../Theater.styled";

export default () => (
  <>
    <picture>
      <img
        className="banner"
        src="https://www.efilecabinet.com/wp-content/uploads/2016/03/Blurry-Background-Gray.jpg"
        alt="feature banner"
      />
    </picture>
    <S.Background />
    <S.EventDetails className="skeleton">
      <S.SkeletonTitle />
      <S.SkeletonTextLine />
      <S.SkeletonTextLine />
      <S.SkeletonTextLine />
      <S.SkeletonTextLine />
      <S.SkeletonTextLine />
      <S.SkeletonTextLine />
      <S.SkeletonTextLine />
      <S.SkeletonTextLine />
    </S.EventDetails>
  </>
);
