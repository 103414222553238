import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  ModalProvider,
  Button,
  Image,
  Label,
  Input,
  Stripe,
  MobileScrollButton,
  TosCheckbox,
  MobileFlex,
} from "components";
import { toast } from "react-toastify";
import { stripeKey } from "utils";
import { useLockBodyScroll } from "hooks";
import { PRODUCTS } from "api";
import * as S from "./PaywallModal.styled";
import { colors } from "consts";

export default ({
  paywallSettings,
  eventId,
  close,
  registerar,
  donationAmount,
  title,
  back,
  next,
  eventReminderOptOut,
}) => {
  const [order, setOrder] = useState({ email: registerar?.email });
  const [stripe, setStripe] = useState();
  const [loading, setLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState();
  const [cardExpiry, setCardExpiry] = useState();
  const [cardCVC, setCardCVC] = useState();
  const [tosAccepted, setTosAccepted] = useState(false);
  useLockBodyScroll();

  useEffect(() => {
    const _stripe = window.Stripe(stripeKey);
    setStripe(_stripe);
  }, []);

  const updateOrder = (e) => {
    order[e.target.name] = e.target.value;
    setOrder(order);
  };

  const toggleTos = () => setTosAccepted(!tosAccepted);

  const purchase = async () => {
    setLoading(true);
    if (!order.email) {
      toast.error("Please enter an email for this order", { autoClose: 5000 });
    } else if (!order.name) {
      toast.error("Please enter a name for this order", { autoClose: 5000 });
    } else if (!order.street) {
      toast.error("Please enter a street for this order", { autoClose: 5000 });
    } else if (!order.city) {
      toast.error("Please enter a city for this order", { autoClose: 5000 });
    } else if (!order.state) {
      toast.error("Please enter a state for this order", { autoClose: 5000 });
    } else if (!order.zip) {
      toast.error("Please enter a zip for this order", { autoClose: 5000 });
    } else if (!cardNumber || cardNumber._empty || cardNumber._invalid) {
      toast.error("Please enter a valid card number", { autoClose: 5000 });
    } else if (!cardExpiry || cardExpiry._empty || cardExpiry._invalid) {
      toast.error("Please enter your card's expiration date", {
        autoClose: 5000,
      });
    } else if (!cardCVC || cardCVC._empty || cardCVC._invalid) {
      toast.error("Please enter your card's CVC", { autoClose: 5000 });
    } else {
      try {
        const _token = await stripe.createToken(cardNumber);

        if (_token.error) {
          toast.error(_token.error.message, { autoClose: 5000 });
          setLoading(false);
          return;
        }

        order.token = _token.token.id;

        const _newPaywall = await PRODUCTS.newPaywallId2(
          paywallSettings,
          eventId
        );
        const _productId = _newPaywall.paywallProductId;

        order.products = [
          {
            id: _productId,
            quantity: 1,
          },
        ];
        order.address =
          order.street +
          ", " +
          order.city +
          ", " +
          order.state +
          ", " +
          order.zip;
        order.prepay = true; // Need to handle this
        delete order.street;
        delete order.city;
        delete order.state;
        delete order.zip;

        const { products, token, ...rest } = order;
        await donateToFilmMaker(rest);

        order.eventReminderOptOut = eventReminderOptOut || false;

        if (paywallSettings?.enablePaywall) {
          const _purchase = await PRODUCTS.purchase(eventId, order);
          if (_purchase.statusText === "Created" && _purchase.status === 201) {
            toast.success(
              "Purchase successful, you will receive an email with your receipt",
              { autoClose: 6000 }
            );
            localStorage.setItem(
              `paywall-${eventId}`,
              JSON.stringify(_purchase.data.data)
            );
            close();
            next();
          } else {
            toast.error(
              "An unexpeced error occured while creating your order",
              {
                autoClose: 5000,
              }
            );
          }
        } else {
          close();
          next();
        }

        setLoading(false);
      } catch (err) {
        toast.error("An unexpeced error occured while creating your order", {
          autoClose: 5000,
        });
      }
    }
    setLoading(false);
  };

  const donateToFilmMaker = async (order) => {
    if (donationAmount && paywallSettings?.askDonations) {
      let _donationProductId = paywallSettings.donationProductId
        ? paywallSettings.donationProductId
        : null;

      const _updatedSettings = await PRODUCTS.newDonationProductId2(
        paywallSettings,
        eventId,
        donationAmount
      );
      _donationProductId = _updatedSettings.donationProductId;

      const _donation = { ...order };
      const _donationToken = await stripe.createToken(cardNumber);

      _donation.products = [
        {
          id: _donationProductId,
          quantity: 1,
        },
      ];
      _donation.token = _donationToken.token.id;
      _donation.eventReminderOptOut = eventReminderOptOut || false;
      await PRODUCTS.purchase(eventId, _donation);
    }
  };

  return (
    <Flex summary="TWO COLUMN">
      <ModalProvider
        width="1100px"
        margin="64px"
        light
        show={true}
        close={back}
      >
        <S.StripeBox>
          <S.Summary direction="column" justify="space-between">
            <Flex direction="column" align="center">
              <Flex direction="column" align="flex-start">
                <S.Back pointer onClick={() => back()}>
                  <img
                    alt="back-button"
                    src={require("assets/images/icon-chevron-left.svg").default}
                  />
                  <Text size="15px">Back</Text>
                </S.Back>
                <Text color="rgba(255, 255, 255)" size="19px" margin="0 0 56px">
                  Order Summary
                </Text>
                {paywallSettings?.enablePaywall && (
                  <>
                    <Text
                      color="rgba(255, 255, 255, 0.6)"
                      size="16px"
                      margin="0 0 6px"
                    >
                      Ticket to {title}
                    </Text>
                    <Text
                      color="rgba(255, 255, 255, 0.9)"
                      size="36px"
                      margin="0 0 18px"
                    >
                      ${(paywallSettings?.price * 1).toFixed(2)}
                    </Text>
                  </>
                )}
              </Flex>
              {paywallSettings?.enablePaywall && (
                <Flex justify="center">
                  <S.Line />
                </Flex>
              )}
              {paywallSettings?.askDonations && donationAmount > 0 && (
                <>
                  <Flex
                    direction="column"
                    align="flex-start"
                    padding="30px 0 0 0"
                  >
                    <Text
                      color="rgba(255, 255, 255, 0.6)"
                      size="16px"
                      margin="0 0 6px"
                    >
                      One time donation
                    </Text>
                    <Text
                      color="rgba(255, 255, 255, 0.9)"
                      size="36px"
                      margin="0 0 18px"
                    >
                      ${(donationAmount * 1).toFixed(2)}
                    </Text>
                  </Flex>
                  <Flex justify="center">
                    <S.Line />
                  </Flex>
                </>
              )}
            </Flex>

            <MobileScrollButton location="card-info" />

            <Flex direction="column" align="center">
              <Flex justify="center">
                <a
                  href="https://stripe.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    width="100px"
                    src={require("assets/images/powered-by-stripe.png").default}
                  />
                </a>
                <Text
                  color="rgba(255, 255, 255, 0.3)"
                  size="12px"
                  margin="0 0 0 16px"
                >
                  |
                </Text>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <Text
                    color="rgba(255, 255, 255, 0.3)"
                    size="12px"
                    margin="0 0 0 16px"
                  >
                    Terms
                  </Text>
                </a>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <Text
                    color="rgba(255, 255, 255, 0.3)"
                    size="12px"
                    margin="0 0 0 16px"
                  >
                    Privacy
                  </Text>
                </a>
              </Flex>
            </Flex>
          </S.Summary>
          <Flex
            direction="column"
            align="flex-start"
            padding="60px"
            id="card-info"
          >
            <Text size="19px" margin="0 0 24px">
              Pay with card
            </Text>
            <Label>Card Information</Label>
            <Stripe
              stripe={stripe}
              setCardNumber={(e) => setCardNumber(e)}
              setCardExpiry={(e) => setCardExpiry(e)}
              setCardCVC={(e) => setCardCVC(e)}
            />
            <Label>Name on card</Label>
            <Input
              value={order.name}
              name="name"
              onChange={updateOrder}
              margin="0 0 24px"
              placeholder="name"
            />
            <Label>Street</Label>
            <Input
              radius="6px"
              value={order.street}
              name="street"
              onChange={updateOrder}
              margin="0 0 24px"
              placeholder="street"
            />
            <Label>City</Label>
            <Input
              radius="6px"
              value={order.city}
              name="city"
              onChange={updateOrder}
              margin="0 0 24px -1px"
              placeholder="city"
            />
            <Flex align="start">
              <Flex direction="column" align="flex-start" relative>
                <Label>State</Label>
                <Flex column gap="4px" margin="0 0 24px" align="start">
                  <Input
                    radius="6px 0px 0px 6px"
                    value={order.state}
                    name="state"
                    onChange={updateOrder}
                    placeholder="state"
                  />
                  <Text color={colors.gray700} size="12px">
                    Not in the US? enter "NA"
                  </Text>
                </Flex>
              </Flex>
              <Flex direction="column" align="flex-start" relative>
                <Label>Zip</Label>
                <Flex column gap="4px" margin="0 0 24px" align="start">
                  <Input
                    radius="0px 6px 6px 0px"
                    value={order.zip}
                    name="zip"
                    onChange={updateOrder}
                    margin="0 0 0px -1px"
                    placeholder="zip"
                  />
                  <Text color={colors.gray700} size="12px">
                    Not in the US? enter "00000"
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            {!registerar.email && (
              <>
                <Label>Email receipt to:</Label>
                <Input
                  value={order.email}
                  name="email"
                  onChange={updateOrder}
                  margin="0 0 24px"
                  placeholder="email"
                />
              </>
            )}
            <TosCheckbox
              tosAccepted={tosAccepted}
              setTosAccepted={setTosAccepted}
              toggleTos={toggleTos}
              light
            />

            <Text size="14px" margin="8px 0 0">
              **Note: Please be aware this charge will appear as SHOW&#38;TELL
              on your credit card statement.**
            </Text>

            {loading ? (
              <Button margin="16px 0 64px" className="medium" width="100%">
                Submitting order...
              </Button>
            ) : (
              <Button
                margin="16px 0 64px"
                icon={require("assets/images/icon-lock.svg").default}
                className={`medium ${!tosAccepted ? "disabled" : ""}`}
                width="100%"
                onClick={() => tosAccepted && purchase()}
              >
                Pay $
                {(
                  (paywallSettings?.enablePaywall
                    ? paywallSettings?.price * 1
                    : 0) +
                  (paywallSettings?.askDonations ? donationAmount || 0 : 0) * 1
                ).toFixed(2)}
              </Button>
            )}
          </Flex>
        </S.StripeBox>
      </ModalProvider>
    </Flex>
  );
};
