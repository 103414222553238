import React, { useEffect, useState } from "react";
import { useAnalytics } from "use-analytics";
import { Helmet } from "react-helmet";
import {
  Foyer,
  FoyerFooter,
  FoyerTabs,
  PreviewWatermark,
  Theater,
} from "components";
import { useGetEvent } from "hooks";
import { useLocation } from "react-router-dom";

const useQuery = () => new URLSearchParams(useLocation().search);

export default ({ match }) => {
  const [tab, setTab] = useState("about");
  const [refresh, setRefresh] = useState(new Date());
  const [image, setImage] = useState(null);
  const [showPreview, setShowPreview] = useState(true);
  const { theaterData, foyerData, footerData } = useGetEvent({
    url: match.params.url,
    orderSlug: match.params.slug,
    subscriberSlug: useQuery().get("subscriberID"),
    refresh: refresh,
  });
  const { page } = useAnalytics();

  useEffect(() => {
    page();
  }, [page]);

  useEffect(() => {
    theaterData.backgroundImage && setImage(theaterData.backgroundImage);

    if (theaterData.active) {
      setShowPreview(false);
    } else if (theaterData.activated) {
      setShowPreview(false);
    } else if (new Date(theaterData.eventStart) < new Date("02/01/2023")) {
      setShowPreview(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theaterData.backgroundImage, theaterData.active, theaterData.activated]);

  const loaded = theaterData.active !== undefined;

  return (
    <>
      <Helmet key={new Date()} title={theaterData.title}>
        <meta property="og:type" content="article" />
        <meta property="og:updated_time" content={new Date().getTime()} />
        <meta
          property="og:image"
          content={`${image}?${new Date().getTime()}`}
        />
        <meta
          property="og:url"
          content={`https://showandtell.film/watch/${theaterData.featureUrl}`}
        />
      </Helmet>
      {loaded && showPreview && <PreviewWatermark />}

      <Theater
        {...theaterData}
        orderSlug={match.params.slug}
        subscriberSlug={useQuery().get("subscriberID")}
        setRefresh={setRefresh}
        loading={theaterData.eventId}
      >
        <FoyerTabs
          tab={tab}
          setTab={setTab}
          interactiveQa={foyerData.interactiveQa}
          {...(foyerData?.products?.length > 0 && {
            capability: foyerData?.capability,
          })}
          enableLive={foyerData.aboutSettings.enableLive}
        />
      </Theater>

      <Foyer
        {...foyerData}
        tab={tab}
        contactEmail={
          footerData.filmTeamEmail
            ? footerData.filmTeamEmail
            : "hello@showandtell.film"
        }
      />

      <FoyerFooter />
    </>
  );
};
