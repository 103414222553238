import { useEffect } from "react";

export default (ref, callback, excludedClasses = []) => {
  const handleClick = (e) => {
    // last condition removes event from the scrollbar
    if (
      ref.current &&
      !ref.current.contains(e.target) &&
      window.innerWidth - e.clientX > 16
    ) {
      let runCallback = true;
      if (excludedClasses[0]) {
        for (const excludedClass in excludedClasses) {
          for (let i = 0; i < e.target.classList.length; i++) {
            if (e.target.classList[i].includes(excludedClass)) {
              runCallback = false;
              break;
            }
          }
        }
      }
      if (runCallback) {
        callback();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};
