import styled from "styled-components";
import { colors } from "consts";
import { Flex } from "components";

export const Tag = styled(Flex)`
  background: ${colors.orange};
  font-size: 14px;
  color: ${colors.white};
  font-weight: bold;
  border-radius: 50px;
  line-height: 22px;
  padding: 4px 12px;
  margin: 4px;
  width: auto;
`;

export const TagWrapper = styled(Flex)`
  border: 1px solid ${colors.activeWhite3};
  background: ${colors.charcoal};
  border-radius: 8px;
  padding: 16px;
  margin: 0 0 16px;
  flex-wrap: wrap;
`;
