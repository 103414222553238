import styled from "styled-components";
import { colors } from "consts";
import { media, minMedia } from "utils";

export const Icon = styled.img`
  position: absolute;
  top: calc(50% - 8px);
  right: 15px;
  height: 14px;
`;

export const Button = styled.div`
  border: none;
  position: relative;
  background: ${({ background }) => (background ? background : colors.orange)};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  color: white;
  transition-duration: 0.3s;
  cursor: pointer;
  &:hover {
    background: ${colors.orange200};
  }
  &:focus {
    background: ${colors.orange300};
  }
  &:active {
    background: ${colors.orange400};
  }
  &.secondary {
    background: ${colors.gray600};
  }
  &.red {
    background: ${colors.orange500};
  }
  &.disabled {
    cursor: not-allowed !important;
    pointer-events: none;
    touch-action: none;
    background: ${colors.orange500};
    opacity: 0.75;
    cursor: default;
  }
  &.secondary.disabled {
    background: ${colors.gray600};
    opacity: 0.75;
    cursor: default;
  }
  &.outline {
    background: none;
    border: 1px solid ${colors.black};
    color: ${colors.black};
  }
  &.outline-white {
    background: none;
    border: 1px solid white;
    color: white;
  }
  &.outline-white-disabled {
    background: none;
    border: 1px solid white;
    color: white;
    opacity: 0.5;
    cursor: default;
  }
  &.pill {
    border-radius: 45px;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 48px;
  }
  border-radius: 2px;
  display: inline-block;
  padding: ${({ padding }) => (padding ? padding : "9px 24px")};
  height: ${({ height }) => (height ? height : "auto")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  width: ${({ width }) => (width ? width : "auto")};
  min-width: ${({ minwidth }) => minwidth};
  max-width: ${({ width }) => width};
  box-sizing: border-box;
  font-weight: ${({ bold }) => (bold ? "bold" : null)};
  &.large {
    font-size: 16px;
    padding: 12px 24px;
    ${minMedia.laptop`
      height: 63px;
      font-size: 18px;
      padding: 21px 32px;
    `}
  }

  &.theater-btn {
    font-size: 16px;
    padding: 12px 24px;
    ${minMedia.phonePlus`
      height: auto;
      font-size: 18px;
      padding: 12px 32px;
    `}
    ${minMedia.desktop`
      font-size: 18px;
      padding: 18px 32px;
    `}
    ${minMedia.monitor`
      font-size: 18px;
      padding: 24px 32px;
    `}
  }

  &.medium {
    height: 50px;
    font-size: 18px;
    padding: 14px 32px;
  }
  &.mobile-small {
    ${media.phonePlus`
      margin: 0 20px;
    `}
  }
  &.mobile-small-mb {
    ${media.phonePlus`
      margin: 0 20px 20px;
    `}
  }
  ${media.phonePlus`
    &.hide-mobile {
      display: none;
    }
  `};
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  justify-content: ${({ justify }) => (justify ? justify : "center")};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  white-space: nowrap;
`;
