import axiosRequest from "./axios.config";

// GENERAL
export const getTags = async (searchTerm = "", page = "", { global, type }) => {
  const params = `?search_term=${searchTerm}&page=${page}&${
    global && `global=${global}`
  }&${type && `type=${type}`}`;
  return await axiosRequest.get(`/v1/tags${params}`);
};

// PAGES
export const postPageTag = async (page_id, tag_name, global) =>
  await axiosRequest.post(
    `/v1/admin/page/tags/${page_id}?tag_name=${tag_name}&global=${global}`
  );

export const removePageTag = async (page_id, tag_id) =>
  await axiosRequest.delete(`/v1/unlink/page/${page_id}/${tag_id}`);

export const linkTagToPage = async (page_id, tag_id) =>
  await axiosRequest.post(`/v1/link/page/${page_id}/${tag_id}`);

// LEADOWNERS
export const postLeadownerTag = async (leadowner_id, tag_name, global) =>
  await axiosRequest.post(
    `/v1/admin/leadowner/tags/${leadowner_id}?tag_name=${tag_name}&global=${global}`
  );

export const removeLeadownerTag = async (leadowner_id, tag_id) =>
  await axiosRequest.delete(`/v1/unlink/leadowner/${leadowner_id}/${tag_id}`);

export const linkTagToLeadowner = async (leadowner_id, tag_id) =>
  await axiosRequest.post(`/v1/link/leadowner/${leadowner_id}/${tag_id}`);

// ACCOUNTS
export const postAccountTag = async (account_id, tag_name, global) =>
  await axiosRequest.post(
    `/v1/admin/account/tags/${account_id}?tag_name=${tag_name}&global=${global}`
  );

export const removeAccountTag = async (account_id, tag_id) =>
  await axiosRequest.delete(`/v1/unlink/account/${account_id}/${tag_id}`);

export const linkTagToAccount = async (account_id, tag_id) =>
  await axiosRequest.post(`/v1/link/account/${account_id}/${tag_id}`);

// ADMIN
export const createGlobalTag = async (tag, type) => {
  const params = `?tag_name=${tag}&type=${type}`;
  return await axiosRequest.post(`/v1/admin/global/tags${params}`);
};

export const patchTag = async (id, updatedName) =>
  await axiosRequest.patch(
    `/v1/admin/global/tags/${id}?tag_name=${updatedName}`
  );

export const deleteTag = async (id) =>
  await axiosRequest.delete(`/v1/admin/global/tags/${id}`);

export const bulkUpsertTags = async (ids, type, global, newTag) => {
  return await axiosRequest.patch(`/v1/admin/tags/bulk`, {
    ids,
    type,
    global,
    newTag,
  });
};

export const bulkLinkTags = async (ids, type, tag) => {
  return await axiosRequest.patch(`/v1/admin/tags/bulk/link`, {
    ids,
    type,
    tag,
  });
};

export const bulkUnlinkTag = async (ids, type, tag) => {
  return await axiosRequest.delete(`/v1/admin/tags/bulk`, {
    data: {
      ids,
      type,
      tag,
    },
  });
};
