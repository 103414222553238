import axiosRequest from "./axios.config";

export const getOne = async (pageId, orderId) =>
  await axiosRequest.get(`/v1/pages/${pageId}/orders/${orderId}`);

export const incrementVisits = async (pageId, orderId) =>
  await axiosRequest.patch(`/v1/pages/${pageId}/orders/${orderId}/visits`);

export const getAllAdminOrders = async (
  searchTerm = "",
  page = "",
  { after_watching = null }
) => {
  return await axiosRequest.get(
    `/v1/admin/orders?search_term=${searchTerm}&page=${page}${
      after_watching ? "&after_watching=true" : ""
    }`
  );
};

export const getAllPageOrders = async (pageId) =>
  await axiosRequest.get(`/v1/pages/${pageId}/orders`);

export const getCSV = async (id) =>
  await axiosRequest.get(`/v1/pages/${id}/orders.csv`);

export const getAllOrdersCSV = async () =>
  await axiosRequest.get(`/v1/admin/orders.csv`);
