import styled from "styled-components";
import { colors } from "consts";
import { Flex, Text, Image } from "components";

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 80px;
  width: 100%;
  height: 32px;
  padding: 4px 16px;
  align-items: center;
  background: transparent;
  border-radius: 2px;
  border: 1px solid ${colors.activeWhite3};
  position: relative;
  cursor: pointer;
`;

export const FilterDropdown = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  background: ${colors.gray800};
  width: auto;
  padding: ${({ active }) => (active ? "8px 16px" : "0px")};
  position: absolute;
  top: 40px;
  left: 0;
  height: ${({ active }) => (active ? "auto" : "0px")};
  opacity: ${({ active }) => (active ? "1" : "0")};
  transition: height 0.2 ease;
  border-radius: 8px;
  z-index: 2;
`;

export const DropdownOption = styled(Text)`
  font-size: 14px;
  background: transparent;
  margin: ${({ active }) => (active ? "6px 0" : "0px")};
  height: ${({ active }) => (active ? "auto" : "0px")};
  width: 100%;
  cursor: ${({ active }) => (active ? "pointer" : "default")};
  color: ${colors.activeWhite1};
  &:hover {
    color: ${colors.white};
  }
`;

export const CaretIcon = styled(Image)`
  transform: ${({ active }) => (active ? "rotate(0deg)" : "rotate(180deg)")};
  transition: transform 0.2 ease;
`;

export const FilterSectionDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.gray750};
  margin: 6px 0;
`;
