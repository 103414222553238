import styled from "styled-components";
import { Flex } from "components";
import { colors } from "consts";

export const Highlight = styled(Flex)`
  width: 100%;
  transition-duration: 0.3s;
  :hover {
    background: #302f2f;
    cursor: pointer;
  }
`;

export const Delete = styled(Flex)`
  cursor: pointer;
  height: 22px;
`;

export const Wrap = styled.div`
  background: rgba(35, 35, 35, 0.54);
  padding: 30px 34px;
  width: 100%;
  height: 100%;
`;

export const Spacer = styled.div`
  width: 100%;
  margin: 50px 0 90px;
  border-top: 1px solid #c4c4c4;
  opacity: 0.2;
`;

export const Thumbnail = styled.div`
  width: 48px;
  height: 48px;
  background: ${({ thumbnail }) =>
    thumbnail ? "url(" + thumbnail + ")" : colors.blue};
  margin: 0 30px 0 0;
  background-size: cover;
  background-position: center;
`;
