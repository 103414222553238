import { useQuery } from "react-query";
import axiosRequest from "../../api/axios.config";

const fetcher = async (leadownerId) => {
  const params = `?id=${leadownerId}`;
  let endpoint = `/v1/admin/leadowner${params}`;

  const raw = await axiosRequest.get(endpoint);
  return raw.data.data;
};

const useGetLeadowner = (leadownerId) => {
  return useQuery(
    ["leadowner", leadownerId],
    async () => {
      const data = await fetcher(leadownerId);

      return data;
    },
    {
      enabled: !!leadownerId,
    }
  );
};

export { useGetLeadowner };
