import { Button, Flex, Text } from "components";

export default ({ setStep }) => {
  return (
    <Flex column gap="24px">
      <Flex column gap="12px">
        <Text kind="h2" size="24px" weight="bold">
          Please choose the option that works best for you.
        </Text>
      </Flex>
      <Flex gap="12px" center>
        <a href="https://showandtell.typeform.com/to/wOSLPiTl">
          <Button>Show&Tell builds your event</Button>
        </a>
        <Button onClick={() => setStep("duplicate-event")}>
          Build your own event
        </Button>
      </Flex>
    </Flex>
  );
};
