import styled, { keyframes } from "styled-components";
import { Flex } from "components";
import { colors } from "consts";
import { minMedia } from "utils";

const blink = keyframes`
  0% { opacity: 0; }
  50% {opacity: 1; }
  100% { opacity: 0 ;}
`;

const animateIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1 ;}
`;

export const Wrapper = styled.div`
  position: relative;
  background-color: ${({ color }) => (color ? color : "#101010")};
  border-bottom: 2px solid #535353;
  overflow: hidden;
  box-sizing: border-box;
  max-height: auto;
  flex-direction: column;

  ${minMedia.laptopPlus`
    flex-direction: column;
    max-height: ${({ bannerheight }) =>
      bannerheight > 750 ? `90vh` : `750px`};
  `}

  ${minMedia.monitorPlus`
    width: auto;
    max-height: 90vh;
  `}

  .banner {
    margin-bottom: -4px;
    animation: ${animateIn} 3s;
    height: 90vh;
    ${minMedia.desktopPlus`
      width: 100%;
      height: auto;
    `}

    ${minMedia.monitor`
    width: auto;
    height: 42vw;
  `}

    ${({ bannerheight }) => (bannerheight > 600 ? `` : `height: 600px;`)};
  }

  &.video-active {
    min-height: 90vh;
    max-height: auto;
    ${minMedia.laptopPlus`
      min-height: calc(100vw - 100vw * 0.45);
      max-height: 90vh;
    `}
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center center;
  background-color: rgba(41, 41, 41, 0.5);
  ${minMedia.laptop`
    background-color: transparent;
    background-image: ${({ src }) =>
      "linear-gradient(90deg, #101010 24.17%, rgba(41, 41, 41, 0) 69.31%)" ||
      null};
    flex-direction: column;
  `}
`;

export const EventDetails = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 16px 16px 64px;
  ${minMedia.tablet`
    padding: 54px 32px 120px 105px;
  `}
  ${minMedia.laptop`
    padding: 0;
    width: 610px;
    margin:54px 0 120px 105px;
  `}
  ${minMedia.laptopPlus`
    padding: 0;
    width: 650px;
    margin:54px 0 120px 105px;
  `}
  &.skeleton {
    opacity: 0;
    animation: ${blink} 3s;
  }
`;

export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 55px;
  left: 0;
  right: 0;
  padding: 0 8px;
  ${minMedia.tablet`
    bottom: 105px;
    padding: 0 40px;
  `}
`;

export const Close = styled.img`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  z-index: 11;
  cursor: pointer;
`;

export const Back = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 11;
  padding: 4px 8px;
  color: white;
  font-weight: bold;
  background: ${colors.gray200};
  border-radius: 8px;
  font-size: 10px;
  cursor: pointer;
`;

export const CtaWrapper = styled(Flex)`
  flex-direction: column;
  .laptop-margin {
    margin-bottom: 16px;
  }
  ${minMedia.phonePlus`
    align-items: flex-start;
    .theater-btn {
      margin-right: 8px;
      width: 200px;
    }
  `}
  ${minMedia.laptop`
    max-width: 620px;
    align-items: flex-start;
    flex-direction: column;
    .theater-btn {
      width: 48%;
    }
    .laptop-margin {
      margin-bottom: 16px;
    }
  `}
`;

export const ReadMore = styled.span`
  font-size: ${({ size }) => (size ? size : "16px")};
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
`;

export const SkeletonTitle = styled.div`
  height: 64px;
  width: 500px;
  margin-bottom: 18px;
  background-color: #e4e1df;
  border-radius: 3px;
`;

export const SkeletonTextLine = styled.div`
  height: 24px;
  background-color: #e4e1df;
  border-radius: 3px;
  margin-bottom: 12px;
  &:nth-of-type(2) {
    width: 220px;
  }
  &:nth-of-type(3) {
    width: 620px;
  }
  &:nth-of-type(4) {
    width: 640px;
  }
  &:nth-of-type(5) {
    width: 630px;
  }
  &:nth-of-type(6) {
    width: 420px;
  }
  &:nth-of-type(7) {
    margin-top: 12px;
    width: 320px;
    display: inline-block;
    height: 63px;
  }
  &:nth-of-type(8) {
    height: 63px;
    width: 320px;
    display: inline-block;
  }
`;

export const MobileBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${({ background }) => `url(${background})`};
  background-size: cover;
  background-position: center center;
  background-color: rgba(41, 41, 41, 0.5);
`;
