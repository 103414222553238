import React from "react";
import { SimplePrompt, Text, Button, Flex } from "components";

export default ({
  showDuplicatePrompt,
  setShowDuplicatePrompt,
  credits,
  duplicate,
}) => {
  const cancel = () => {
    setShowDuplicatePrompt(false);
  };
  return (
    <SimplePrompt close={cancel}>
      {credits > 0 ? (
        <>
          <Text size="64px" weight="500" margin="0 0 24px" className="canela">
            Would you like to use 1 credit?
          </Text>
          <Text size="28px" margin="0 0 84px">
            Clicking continue will duplicate this event and use one of the
            filmmaker's credits.
          </Text>
          <Flex>
            <Button
              onClick={cancel}
              width="50%"
              padding="20px 0"
              margin="0 12px 0 0"
              className="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                duplicate(showDuplicatePrompt.id);
                setShowDuplicatePrompt({
                  show: false,
                  id: null,
                });
              }}
              width="50%"
              padding="20px 0"
              margin="0 0 0 12px"
            >
              Continue
            </Button>
          </Flex>
        </>
      ) : (
        <>
          <Text size="64px" weight="500" margin="0 0 24px" className="canela">
            No Credits Available
          </Text>
          <Text size="28px" margin="0 0 84px">
            This user has no credits available to duplicate this event.
          </Text>
          <Flex>
            <Button
              onClick={cancel}
              width="50%"
              padding="20px 0"
              margin="0 12px 0 0"
              className="secondary"
            >
              Cancel
            </Button>
          </Flex>
        </>
      )}
    </SimplePrompt>
  );
};
