import React, { useState, useEffect } from "react";
import { Flex, Text } from "components";
import AdminTable from "../AdminTable";
import * as S from "./AdminCustomerDetails.styled";

export default ({ ownerDetails: { orders } }) => {
  const [orderInfo, setOrderInfo] = useState(null);

  useEffect(() => {
    orders && setOrderInfo(orders);
  }, [orders]);

  // TODO: remove
  // const formatter = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  //   minimumFractionDigits: 2,
  // });

  return (
    <S.ListContainer margin="0 0 24px 0" maxHeight="450px">
      <Flex justify="space-between" align="center" margin="0 0 24px 0">
        <Text size="24px" weight="bold">
          Orders
        </Text>
      </Flex>

      {orderInfo?.length > 0 ? (
        <AdminTable type="customer_orders" data={orderInfo} />
      ) : (
        <Flex>
          <Text>No Customer Orders</Text>
        </Flex>
      )}
    </S.ListContainer>
  );
};
