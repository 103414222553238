import React, { useState } from "react";
import { AdminSidebar, Flex, AdminHeader } from "components";
import { AdminDetailsHeader } from "./AdminHeader";
import * as S from "./AdminLayout.styled";

export default ({ text, children, type }) => {
  // state
  const [searchTerm, setSearchTerm] = useState("");
  const [leadownerFilters, setLeadownerFilters] = useState({
    stage: "",
    source: "",
    tag: "",
  });
  const [eventFilters, setEventFilters] = useState({
    activated: false,
    archived: false,
    superarchived: false,
    startEventRange_start: null,
    startEventRange_end: null,
    tag: "",
  });
  const [customerFilters, setCustomerFilters] = useState({
    business_type: "",
    tag: "",
  });
  const [orderFilters, setOrderFilters] = useState({
    after_watching: null,
  });
  const [globalTagsFilter, setGlobalTagsFilter] = useState({
    type: "",
  });

  const determineFilters = (type) => {
    switch (type) {
      case "leads":
        return [leadownerFilters, setLeadownerFilters];
      case "events":
        return [eventFilters, setEventFilters];
      case "customers":
        return [customerFilters, setCustomerFilters];
      case "orders":
        return [orderFilters, setOrderFilters];
      case "globaltags":
        return [globalTagsFilter, setGlobalTagsFilter];
      default:
        return [null, () => null];
    }
  };

  return (
    <Flex width="100%" height="100%" justify="flex-start" align="flex-start">
      <AdminSidebar />
      <S.Wrapper
        direction="column"
        height="100%"
        width="calc(100% - 264px)"
        padding="0 40px 40px 40px"
        overflow="scroll"
      >
        {type === "details" ? (
          <AdminDetailsHeader text={text} />
        ) : (
          <AdminHeader
            text={text}
            {...{
              searchTerm,
              setSearchTerm,
              filterState: determineFilters(type),
              type,
            }}
          />
        )}
        {/*
            Clone each passed child and add the searchTerm prop
          */}
        {React.Children.map(children, (child) => {
          if (child) {
            return React.cloneElement(child, {
              searchTerm: searchTerm,
              filters: determineFilters(type)[0],
            });
          }
        })}
      </S.Wrapper>
    </Flex>
  );
};
