import React, { useState, useRef } from "react";
import { Text, Checkbox, Flex, Input, Label, Searchbar } from "components";
import { colors } from "consts";
import { useOutsideClick } from "hooks";
import * as S from "./FilterSelect.styled";
import { leadStages, leadSources } from "consts";

export default ({ filters, setFilters, type }) => {
  const node = useRef();
  const filtersRef = useRef(filters);
  filtersRef.current = filters;
  const [active, setActive] = useState(false);
  const [searchTerm] = useState("");

  useOutsideClick(
    node,
    () => {
      setActive(false);
    },
    ["react-datepicker"]
  );

  const handleFilterChange = ({ target }, filter) => {
    if (
      filter === "startEventRange_start" ||
      filter === "startEventRange_end"
    ) {
      setFilters({
        ...filtersRef.current,
        [filter]: target.value,
      });
    } else if (filter === "archived" || filter === "activated") {
      if (filtersRef.current[filter] === filter) {
        setFilters({
          ...filtersRef.current,
          [filter]: "",
        });
      } else {
        const mutually_exclusive_map = {
          // a page can only be active || archived
          // if needed update the values to be arrays and loop through those arrays if the exclusivity is > 1:1
          activated: "archived",
          archived: "activated",
        };

        const updatedFilters = {
          ...filtersRef.current,
          [filter]: !filtersRef.current[filter],
        };
        updatedFilters[mutually_exclusive_map[filter]] =
          filtersRef.current[filter];

        setFilters(updatedFilters);
      }
    } else if (filter === "tag") {
      setFilters({
        ...filtersRef.current,
        [filter]: target.value,
      });
    } else {
      setFilters({
        ...filtersRef.current,
        [filter]: !filtersRef.current[filter],
      });
    }
  };

  const handleStageTypeChange = (stage) => {
    if (filtersRef?.current?.stage && filtersRef?.current?.stage === stage) {
      setFilters({
        ...filtersRef.current,
        stage: "",
      });
    } else {
      setFilters({
        ...filtersRef.current,
        stage: stage,
      });
    }
  };

  const handleSourceChange = (source) => {
    if (filtersRef?.current?.source && filtersRef?.current?.source === source) {
      setFilters({
        ...filtersRef.current,
        source: "",
      });
    } else {
      setFilters({
        ...filtersRef.current,
        source: source,
      });
    }
  };

  const handleGlobalTagTypeChange = (type) => {
    if (filtersRef?.current?.type && filtersRef?.current?.type === type) {
      setFilters({
        ...filtersRef.current,
        type: "",
      });
    } else {
      setFilters({
        ...filtersRef.current,
        type: type,
      });
    }
  };

  const handleBusinessTypeChange = (businessType) => {
    if (
      filtersRef?.current?.business_type &&
      filtersRef?.current?.business_type === businessType
    ) {
      // The user clicked on the filter that is already checked
      setFilters({
        ...filtersRef.current,
        business_type: "",
      });
    } else {
      setFilters({
        ...filtersRef.current,
        business_type: businessType,
      });
    }
  };

  return (
    <S.DropdownContainer ref={node}>
      <Text
        size="14px"
        weight="400"
        margin="0 4px 0 0"
        color={colors.white}
        onClick={() => {
          setActive(!active);
        }}
      >
        Filter
      </Text>
      <S.CaretIcon
        src={require("assets/images/icon-caret-up.svg").default}
        active={active}
        onClick={() => {
          setActive(!active);
        }}
      />
      <S.FilterDropdown active={active}>
        {type === "events" ? (
          <>
            <Flex outline margin="12px 0">
              <Searchbar
                placeholder="Tag"
                value={searchTerm}
                onChange={(e) => handleFilterChange(e, "tag")}
              />
            </Flex>
            <S.FilterSectionDivider />
            <S.DropdownOption active={active}>
              <Flex margin="6px 0">
                <Checkbox
                  light
                  checked={filtersRef.current.activated}
                  onClick={(e) => handleFilterChange(e, "activated")}
                />
                <Text
                  margin="0 0 0 12px"
                  size="14px"
                  color={colors.activeWhite1}
                >
                  Activated
                </Text>
              </Flex>
            </S.DropdownOption>
            <S.DropdownOption active={active}>
              <Flex margin="6px 0">
                <Checkbox
                  light
                  checked={filtersRef.current.archived}
                  onClick={(e) => handleFilterChange(e, "archived")}
                />
                <Text
                  margin="0 0 0 12px"
                  size="14px"
                  color={colors.activeWhite1}
                >
                  Archived
                </Text>
              </Flex>
            </S.DropdownOption>
            <S.FilterSectionDivider />
            <S.DropdownOption active={active}>
              <Flex margin="6px 0">
                <Checkbox
                  light
                  checked={filtersRef.current.superarchived}
                  onClick={(e) => handleFilterChange(e, "superarchived")}
                />
                <Text
                  margin="0 0 0 12px"
                  size="14px"
                  color={colors.activeWhite1}
                >
                  Super Archived
                </Text>
              </Flex>
            </S.DropdownOption>
            <S.FilterSectionDivider />
            <S.DropdownOption active={active}>
              <Flex direction="column" align="flex-start">
                <Label>Date Range Start:</Label>
                <Input
                  type="date"
                  id="startEventRange_start"
                  value={filtersRef.current.startEventRange_start}
                  onChange={(e) =>
                    handleFilterChange(e, "startEventRange_start")
                  }
                  name="startEventRange_start"
                  placeholder="MM/DD/YYYY"
                  isClearable
                />
              </Flex>
            </S.DropdownOption>
            <S.DropdownOption active={active}>
              <Flex direction="column" align="flex-start">
                <Label>Date Range End:</Label>
                <Input
                  type="date"
                  id="startEventRange_end"
                  value={filtersRef.current.startEventRange_end}
                  onChange={(e) => handleFilterChange(e, "startEventRange_end")}
                  name="startEventRange_end"
                  placeholder="MM/DD/YYYY"
                  isClearable
                />
              </Flex>
            </S.DropdownOption>
          </>
        ) : type === "leads" ? (
          <>
            <Flex outline>
              <Searchbar
                placeholder="Tag"
                value={searchTerm}
                onChange={(e) => handleFilterChange(e, "tag")}
              />
            </Flex>
            <Flex width="100%" align="flex-start">
              <Flex direction="column" align="flex-start" width="100%">
                <Text
                  margin="12px 0 6px"
                  size="14px"
                  color={colors.activeWhite1}
                >
                  Stage:
                </Text>
                {leadStages.map((stage, i) => {
                  return (
                    <S.DropdownOption active={active} key={i}>
                      <Flex margin="6px 0">
                        <Checkbox
                          light
                          checked={filtersRef.current.stage === stage}
                          onClick={() => handleStageTypeChange(stage)}
                        />
                        <Text
                          margin="0 0 0 12px"
                          size="14px"
                          color={colors.activeWhite1}
                        >
                          {stage}
                        </Text>
                      </Flex>
                    </S.DropdownOption>
                  );
                })}
              </Flex>
              <Flex
                direction="column"
                align="flex-start"
                width="100%"
                padding="0 0 0 16px"
              >
                <Text
                  margin="12px 0 6px"
                  size="14px"
                  color={colors.activeWhite1}
                >
                  Source:
                </Text>
                {leadSources.map((source, i) => {
                  return (
                    <S.DropdownOption active={active} key={i}>
                      <Flex margin="6px 0" width="100%">
                        <Checkbox
                          light
                          checked={filtersRef.current.source === source}
                          onClick={() => handleSourceChange(source)}
                        />
                        <Text
                          margin="0 0 0 12px"
                          size="14px"
                          color={colors.activeWhite1}
                        >
                          {source}
                        </Text>
                      </Flex>
                    </S.DropdownOption>
                  );
                })}
              </Flex>
            </Flex>
          </>
        ) : type === "customers" ? (
          <>
            <Flex outline>
              <Searchbar
                placeholder="Tag"
                value={searchTerm}
                onChange={(e) => handleFilterChange(e, "tag")}
              />
            </Flex>
            <Text margin="12px 0 6px" size="14px" color={colors.activeWhite1}>
              Business type:
            </Text>
            {}
            <S.DropdownOption active={active}>
              <Flex margin="6px 0">
                <Checkbox
                  light
                  checked={filtersRef.current.business_type === "individual"}
                  onClick={() => handleBusinessTypeChange("individual")}
                />
                <Text
                  margin="0 0 0 12px"
                  size="14px"
                  color={colors.activeWhite1}
                >
                  Individual
                </Text>
              </Flex>
            </S.DropdownOption>
            <S.DropdownOption active={active}>
              <Flex margin="6px 0">
                <Checkbox
                  light
                  checked={filtersRef.current.business_type === "company"}
                  onClick={() => handleBusinessTypeChange("company")}
                />
                <Text
                  margin="0 0 0 12px"
                  size="14px"
                  color={colors.activeWhite1}
                >
                  Company
                </Text>
              </Flex>
            </S.DropdownOption>
          </>
        ) : type === "orders" ? (
          <>
            <S.DropdownOption active={active}>
              <Flex margin="6px 0">
                <Checkbox
                  light
                  checked={filtersRef.current.after_watching}
                  onClick={(e) => handleFilterChange(e, "after_watching")}
                />
                <Text
                  margin="0 0 0 12px"
                  size="14px"
                  color={colors.activeWhite1}
                >
                  Bought after watching
                </Text>
              </Flex>
            </S.DropdownOption>
          </>
        ) : type === "globaltags" ? (
          <>
            {["account", "lead", "page"].map((type, i) => {
              return (
                <S.DropdownOption active={active} key={i}>
                  <Flex margin="6px 0">
                    <Checkbox
                      light
                      checked={
                        filtersRef.current.type === type ||
                        (filtersRef.current.type === "leadowner" &&
                          type === "lead")
                      }
                      onClick={() => {
                        let arg = type;

                        if (type === "lead") arg = "leadowner";
                        handleGlobalTagTypeChange(arg);
                      }}
                    />
                    <Text
                      margin="0 0 0 12px"
                      size="14px"
                      color={colors.activeWhite1}
                    >
                      {type}
                    </Text>
                  </Flex>
                </S.DropdownOption>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </S.FilterDropdown>
    </S.DropdownContainer>
  );
};
