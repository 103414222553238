import styled, { css } from "styled-components";
import { Flex, Text } from "components";
import { colors } from "consts";

export const Content = styled(Flex)`
  pointer-events: none;
`;

export const UploadText = styled(Text)(
  ({ isDragActive }) => css`
    color: ${colors.activeWhite};
    transition: 0.25s;
  `
);

export const FileDropzone = styled(Flex)(
  ({ isDragActive, disabled }) => css`
    opacity: ${disabled ? ".5" : "1"};
    pointer-events: ${disabled && "none"};
    border: 2px dashed ${isDragActive ? colors.activeWhite : colors.gray600};
    border-radius: 4px;
    transition: 0.25s;

    &:active {
      border: 2px dashed ${colors.activeWhite};
    }

    &:active ${UploadText} {
      color: ${colors.activeWhite};
    }
  `
);

export const HiddenInput = styled.input`
  outline: none;
`;
