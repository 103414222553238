import axios from "axios";
import { api } from "utils";

const instance = axios.create({
  baseURL: api,
});

instance.interceptors.response.use(
  (config) => {
    const sessionId = config.headers["x-session-id"];

    sessionId && localStorage.setItem("x-session-id", sessionId);

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  (config) => {
    config.headers["x-session-id"] = localStorage.getItem("x-session-id");

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.defaults.withCredentials = false;

export default instance;
