import React, { useEffect, useState } from "react";
import { useAnalytics } from "use-analytics";
import {
  Button,
  Header,
  BackgroundProvider,
  Flex,
  Box,
  Input,
  Label,
  Text,
} from "components";
import { USERS } from "api";
import { colors } from "consts";
import { useAuth } from "hooks";
import { useHistory } from "react-router-dom";

export default () => {
  const [message, setMessage] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { setUser } = useAuth();
  const history = useHistory();
  const { page } = useAnalytics();

  useEffect(() => {
    page();
  }, [page]);

  const login = async () => {
    try {
      setLoading(true);
      const {
        data: {
          data: { type, name },
        },
      } = await USERS.login({ email, password });
      setUser({ type: type, name: name });

      setLoading(false);

      if (type === "admin") {
        history.push("/admin/customers");
      } else {
        history.push("/welcome");
      }
    } catch (err) {
      setLoading(false);
      setMessage("Error logging in");
    }
  };

  return (
    <BackgroundProvider
      height="100vh"
      color={colors.bgGray}
      url={require("assets/images/projector.jpg").default}
    >
      <Header showBackButton={false} />

      <Flex justify="center" height="95vh">
        <Box light width="465px" margin="48px 0">
          <Flex direction="column" align="flex-start">
            <Flex justify="center">
              <Text dark size="38px" lineheight="36px" margin="0 0 45px">
                Sign In
              </Text>
            </Flex>

            <Label color={colors.black}>Email</Label>
            <Input
              light
              margin="0 0 30px"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email"
            />

            <Label color={colors.black}>Password</Label>
            <Input
              light
              margin="0 0 53px"
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="password"
            />

            <Flex justify="center">
              {message && (
                <Text margin="-32px 0 12px" color={colors.red}>
                  {message}
                </Text>
              )}
            </Flex>

            <Flex justify="center">
              {loading ? (
                <Button bold className="outline">
                  Logging in...
                </Button>
              ) : (
                <Button bold onClick={() => login()}>
                  Login
                </Button>
              )}
            </Flex>
            <Flex justify="center">
              <a href="/forgot-password">
                <Text
                  margin="30px 0 0"
                  size="15px"
                  color={colors.orange}
                  onClick={() => login()}
                >
                  Forgot Password
                </Text>
              </a>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </BackgroundProvider>
  );
};
