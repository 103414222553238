export default [
  {
    country: "Afghanistan",
    "alpha-2-code": "AF",
    "alpha-3-code": "AFG",
    numeric: 4,
  },
  {
    country: "Albania",
    "alpha-2-code": "AL",
    "alpha-3-code": "ALB",
    numeric: 8,
  },
  {
    country: "Algeria",
    "alpha-2-code": "DZ",
    "alpha-3-code": "DZA",
    numeric: 12,
  },
  {
    country: "American Samoa",
    "alpha-2-code": "AS",
    "alpha-3-code": "ASM",
    numeric: 16,
  },
  {
    country: "Andorra",
    "alpha-2-code": "AD",
    "alpha-3-code": "AND",
    numeric: 20,
  },
  {
    country: "Angola",
    "alpha-2-code": "AO",
    "alpha-3-code": "AGO",
    numeric: 24,
  },
  {
    country: "Anguilla",
    "alpha-2-code": "AI",
    "alpha-3-code": "AIA",
    numeric: 660,
  },
  {
    country: "Antarctica",
    "alpha-2-code": "AQ",
    "alpha-3-code": "ATA",
    numeric: 10,
  },
  {
    country: "Antigua and Barbuda",
    "alpha-2-code": "AG",
    "alpha-3-code": "ATG",
    numeric: 28,
  },
  {
    country: "Argentina",
    "alpha-2-code": "AR",
    "alpha-3-code": "ARG",
    numeric: 32,
  },
  {
    country: "Armenia",
    "alpha-2-code": "AM",
    "alpha-3-code": "ARM",
    numeric: 51,
  },
  {
    country: "Aruba",
    "alpha-2-code": "AW",
    "alpha-3-code": "ABW",
    numeric: 533,
  },
  {
    country: "Australia",
    "alpha-2-code": "AU",
    "alpha-3-code": "AUS",
    numeric: 36,
  },
  {
    country: "Austria",
    "alpha-2-code": "AT",
    "alpha-3-code": "AUT",
    numeric: 40,
  },
  {
    country: "Azerbaijan",
    "alpha-2-code": "AZ",
    "alpha-3-code": "AZE",
    numeric: 31,
  },
  {
    country: "Bahamas (the)",
    "alpha-2-code": "BS",
    "alpha-3-code": "BHS",
    numeric: 44,
  },
  {
    country: "Bahrain",
    "alpha-2-code": "BH",
    "alpha-3-code": "BHR",
    numeric: 48,
  },
  {
    country: "Bangladesh",
    "alpha-2-code": "BD",
    "alpha-3-code": "BGD",
    numeric: 50,
  },
  {
    country: "Barbados",
    "alpha-2-code": "BB",
    "alpha-3-code": "BRB",
    numeric: 52,
  },
  {
    country: "Belarus",
    "alpha-2-code": "BY",
    "alpha-3-code": "BLR",
    numeric: 112,
  },
  {
    country: "Belgium",
    "alpha-2-code": "BE",
    "alpha-3-code": "BEL",
    numeric: 56,
  },
  {
    country: "Belize",
    "alpha-2-code": "BZ",
    "alpha-3-code": "BLZ",
    numeric: 84,
  },
  {
    country: "Benin",
    "alpha-2-code": "BJ",
    "alpha-3-code": "BEN",
    numeric: 204,
  },
  {
    country: "Bermuda",
    "alpha-2-code": "BM",
    "alpha-3-code": "BMU",
    numeric: 60,
  },
  {
    country: "Bhutan",
    "alpha-2-code": "BT",
    "alpha-3-code": "BTN",
    numeric: 64,
  },
  {
    country: "Bolivia (Plurinational State of)",
    "alpha-2-code": "BO",
    "alpha-3-code": "BOL",
    numeric: 68,
  },
  {
    country: "Bonaire, Sint Eustatius and Saba",
    "alpha-2-code": "BQ",
    "alpha-3-code": "BES",
    numeric: 535,
  },
  {
    country: "Bosnia and Herzegovina",
    "alpha-2-code": "BA",
    "alpha-3-code": "BIH",
    numeric: 70,
  },
  {
    country: "Botswana",
    "alpha-2-code": "BW",
    "alpha-3-code": "BWA",
    numeric: 72,
  },
  {
    country: "Bouvet Island",
    "alpha-2-code": "BV",
    "alpha-3-code": "BVT",
    numeric: 74,
  },
  {
    country: "Brazil",
    "alpha-2-code": "BR",
    "alpha-3-code": "BRA",
    numeric: 76,
  },
  {
    country: "British Indian Ocean Territory (the)",
    "alpha-2-code": "IO",
    "alpha-3-code": "IOT",
    numeric: 86,
  },
  {
    country: "Brunei Darussalam",
    "alpha-2-code": "BN",
    "alpha-3-code": "BRN",
    numeric: 96,
  },
  {
    country: "Bulgaria",
    "alpha-2-code": "BG",
    "alpha-3-code": "BGR",
    numeric: 100,
  },
  {
    country: "Burkina Faso",
    "alpha-2-code": "BF",
    "alpha-3-code": "BFA",
    numeric: 854,
  },
  {
    country: "Burundi",
    "alpha-2-code": "BI",
    "alpha-3-code": "BDI",
    numeric: 108,
  },
  {
    country: "Cabo Verde",
    "alpha-2-code": "CV",
    "alpha-3-code": "CPV",
    numeric: 132,
  },
  {
    country: "Cambodia",
    "alpha-2-code": "KH",
    "alpha-3-code": "KHM",
    numeric: 116,
  },
  {
    country: "Cameroon",
    "alpha-2-code": "CM",
    "alpha-3-code": "CMR",
    numeric: 120,
  },
  {
    country: "Canada",
    "alpha-2-code": "CA",
    "alpha-3-code": "CAN",
    numeric: 124,
  },
  {
    country: "Cayman Islands (the)",
    "alpha-2-code": "KY",
    "alpha-3-code": "CYM",
    numeric: 136,
  },
  {
    country: "Central African Republic (the)",
    "alpha-2-code": "CF",
    "alpha-3-code": "CAF",
    numeric: 140,
  },
  {
    country: "Chad",
    "alpha-2-code": "TD",
    "alpha-3-code": "TCD",
    numeric: 148,
  },
  {
    country: "Chile",
    "alpha-2-code": "CL",
    "alpha-3-code": "CHL",
    numeric: 152,
  },
  {
    country: "China",
    "alpha-2-code": "CN",
    "alpha-3-code": "CHN",
    numeric: 156,
  },
  {
    country: "Christmas Island",
    "alpha-2-code": "CX",
    "alpha-3-code": "CXR",
    numeric: 162,
  },
  {
    country: "Cocos (Keeling) Islands (the)",
    "alpha-2-code": "CC",
    "alpha-3-code": "CCK",
    numeric: 166,
  },
  {
    country: "Colombia",
    "alpha-2-code": "CO",
    "alpha-3-code": "COL",
    numeric: 170,
  },
  {
    country: "Comoros (the)",
    "alpha-2-code": "KM",
    "alpha-3-code": "COM",
    numeric: 174,
  },
  {
    country: "Congo (the Democratic Republic of the)",
    "alpha-2-code": "CD",
    "alpha-3-code": "COD",
    numeric: 180,
  },
  {
    country: "Congo (the)",
    "alpha-2-code": "CG",
    "alpha-3-code": "COG",
    numeric: 178,
  },
  {
    country: "Cook Islands (the)",
    "alpha-2-code": "CK",
    "alpha-3-code": "COK",
    numeric: 184,
  },
  {
    country: "Costa Rica",
    "alpha-2-code": "CR",
    "alpha-3-code": "CRI",
    numeric: 188,
  },
  {
    country: "Croatia",
    "alpha-2-code": "HR",
    "alpha-3-code": "HRV",
    numeric: 191,
  },
  {
    country: "Cuba",
    "alpha-2-code": "CU",
    "alpha-3-code": "CUB",
    numeric: 192,
  },
  {
    country: "Cura�ao",
    "alpha-2-code": "CW",
    "alpha-3-code": "CUW",
    numeric: 531,
  },
  {
    country: "Cyprus",
    "alpha-2-code": "CY",
    "alpha-3-code": "CYP",
    numeric: 196,
  },
  {
    country: "Czechia",
    "alpha-2-code": "CZ",
    "alpha-3-code": "CZE",
    numeric: 203,
  },
  {
    country: "C�te d'Ivoire",
    "alpha-2-code": "CI",
    "alpha-3-code": "CIV",
    numeric: 384,
  },
  {
    country: "Denmark",
    "alpha-2-code": "DK",
    "alpha-3-code": "DNK",
    numeric: 208,
  },
  {
    country: "Djibouti",
    "alpha-2-code": "DJ",
    "alpha-3-code": "DJI",
    numeric: 262,
  },
  {
    country: "Dominica",
    "alpha-2-code": "DM",
    "alpha-3-code": "DMA",
    numeric: 212,
  },
  {
    country: "Dominican Republic (the)",
    "alpha-2-code": "DO",
    "alpha-3-code": "DOM",
    numeric: 214,
  },
  {
    country: "Ecuador",
    "alpha-2-code": "EC",
    "alpha-3-code": "ECU",
    numeric: 218,
  },
  {
    country: "Egypt",
    "alpha-2-code": "EG",
    "alpha-3-code": "EGY",
    numeric: 818,
  },
  {
    country: "El Salvador",
    "alpha-2-code": "SV",
    "alpha-3-code": "SLV",
    numeric: 222,
  },
  {
    country: "Equatorial Guinea",
    "alpha-2-code": "GQ",
    "alpha-3-code": "GNQ",
    numeric: 226,
  },
  {
    country: "Eritrea",
    "alpha-2-code": "ER",
    "alpha-3-code": "ERI",
    numeric: 232,
  },
  {
    country: "Estonia",
    "alpha-2-code": "EE",
    "alpha-3-code": "EST",
    numeric: 233,
  },
  {
    country: "Eswatini",
    "alpha-2-code": "SZ",
    "alpha-3-code": "SWZ",
    numeric: 748,
  },
  {
    country: "Ethiopia",
    "alpha-2-code": "ET",
    "alpha-3-code": "ETH",
    numeric: 231,
  },
  {
    country: "Falkland Islands (the) [Malvinas]",
    "alpha-2-code": "FK",
    "alpha-3-code": "FLK",
    numeric: 238,
  },
  {
    country: "Faroe Islands (the)",
    "alpha-2-code": "FO",
    "alpha-3-code": "FRO",
    numeric: 234,
  },
  {
    country: "Fiji",
    "alpha-2-code": "FJ",
    "alpha-3-code": "FJI",
    numeric: 242,
  },
  {
    country: "Finland",
    "alpha-2-code": "FI",
    "alpha-3-code": "FIN",
    numeric: 246,
  },
  {
    country: "France",
    "alpha-2-code": "FR",
    "alpha-3-code": "FRA",
    numeric: 250,
  },
  {
    country: "French Guiana",
    "alpha-2-code": "GF",
    "alpha-3-code": "GUF",
    numeric: 254,
  },
  {
    country: "French Polynesia",
    "alpha-2-code": "PF",
    "alpha-3-code": "PYF",
    numeric: 258,
  },
  {
    country: "French Southern Territories (the)",
    "alpha-2-code": "TF",
    "alpha-3-code": "ATF",
    numeric: 260,
  },
  {
    country: "Gabon",
    "alpha-2-code": "GA",
    "alpha-3-code": "GAB",
    numeric: 266,
  },
  {
    country: "Gambia (the)",
    "alpha-2-code": "GM",
    "alpha-3-code": "GMB",
    numeric: 270,
  },
  {
    country: "Georgia",
    "alpha-2-code": "GE",
    "alpha-3-code": "GEO",
    numeric: 268,
  },
  {
    country: "Germany",
    "alpha-2-code": "DE",
    "alpha-3-code": "DEU",
    numeric: 276,
  },
  {
    country: "Ghana",
    "alpha-2-code": "GH",
    "alpha-3-code": "GHA",
    numeric: 288,
  },
  {
    country: "Gibraltar",
    "alpha-2-code": "GI",
    "alpha-3-code": "GIB",
    numeric: 292,
  },
  {
    country: "Greece",
    "alpha-2-code": "GR",
    "alpha-3-code": "GRC",
    numeric: 300,
  },
  {
    country: "Greenland",
    "alpha-2-code": "GL",
    "alpha-3-code": "GRL",
    numeric: 304,
  },
  {
    country: "Grenada",
    "alpha-2-code": "GD",
    "alpha-3-code": "GRD",
    numeric: 308,
  },
  {
    country: "Guadeloupe",
    "alpha-2-code": "GP",
    "alpha-3-code": "GLP",
    numeric: 312,
  },
  {
    country: "Guam",
    "alpha-2-code": "GU",
    "alpha-3-code": "GUM",
    numeric: 316,
  },
  {
    country: "Guatemala",
    "alpha-2-code": "GT",
    "alpha-3-code": "GTM",
    numeric: 320,
  },
  {
    country: "Guernsey",
    "alpha-2-code": "GG",
    "alpha-3-code": "GGY",
    numeric: 831,
  },
  {
    country: "Guinea",
    "alpha-2-code": "GN",
    "alpha-3-code": "GIN",
    numeric: 324,
  },
  {
    country: "Guinea-Bissau",
    "alpha-2-code": "GW",
    "alpha-3-code": "GNB",
    numeric: 624,
  },
  {
    country: "Guyana",
    "alpha-2-code": "GY",
    "alpha-3-code": "GUY",
    numeric: 328,
  },
  {
    country: "Haiti",
    "alpha-2-code": "HT",
    "alpha-3-code": "HTI",
    numeric: 332,
  },
  {
    country: "Heard Island and McDonald Islands",
    "alpha-2-code": "HM",
    "alpha-3-code": "HMD",
    numeric: 334,
  },
  {
    country: "Holy See (the)",
    "alpha-2-code": "VA",
    "alpha-3-code": "VAT",
    numeric: 336,
  },
  {
    country: "Honduras",
    "alpha-2-code": "HN",
    "alpha-3-code": "HND",
    numeric: 340,
  },
  {
    country: "Hong Kong",
    "alpha-2-code": "HK",
    "alpha-3-code": "HKG",
    numeric: 344,
  },
  {
    country: "Hungary",
    "alpha-2-code": "HU",
    "alpha-3-code": "HUN",
    numeric: 348,
  },
  {
    country: "Iceland",
    "alpha-2-code": "IS",
    "alpha-3-code": "ISL",
    numeric: 352,
  },
  {
    country: "India",
    "alpha-2-code": "IN",
    "alpha-3-code": "IND",
    numeric: 356,
  },
  {
    country: "Indonesia",
    "alpha-2-code": "ID",
    "alpha-3-code": "IDN",
    numeric: 360,
  },
  {
    country: "Iran (Islamic Republic of)",
    "alpha-2-code": "IR",
    "alpha-3-code": "IRN",
    numeric: 364,
  },
  {
    country: "Iraq",
    "alpha-2-code": "IQ",
    "alpha-3-code": "IRQ",
    numeric: 368,
  },
  {
    country: "Ireland",
    "alpha-2-code": "IE",
    "alpha-3-code": "IRL",
    numeric: 372,
  },
  {
    country: "Isle of Man",
    "alpha-2-code": "IM",
    "alpha-3-code": "IMN",
    numeric: 833,
  },
  {
    country: "Israel",
    "alpha-2-code": "IL",
    "alpha-3-code": "ISR",
    numeric: 376,
  },
  {
    country: "Italy",
    "alpha-2-code": "IT",
    "alpha-3-code": "ITA",
    numeric: 380,
  },
  {
    country: "Jamaica",
    "alpha-2-code": "JM",
    "alpha-3-code": "JAM",
    numeric: 388,
  },
  {
    country: "Japan",
    "alpha-2-code": "JP",
    "alpha-3-code": "JPN",
    numeric: 392,
  },
  {
    country: "Jersey",
    "alpha-2-code": "JE",
    "alpha-3-code": "JEY",
    numeric: 832,
  },
  {
    country: "Jordan",
    "alpha-2-code": "JO",
    "alpha-3-code": "JOR",
    numeric: 400,
  },
  {
    country: "Kazakhstan",
    "alpha-2-code": "KZ",
    "alpha-3-code": "KAZ",
    numeric: 398,
  },
  {
    country: "Kenya",
    "alpha-2-code": "KE",
    "alpha-3-code": "KEN",
    numeric: 404,
  },
  {
    country: "Kiribati",
    "alpha-2-code": "KI",
    "alpha-3-code": "KIR",
    numeric: 296,
  },
  {
    country: "Korea (the Democratic People's Republic of)",
    "alpha-2-code": "KP",
    "alpha-3-code": "PRK",
    numeric: 408,
  },
  {
    country: "Korea (the Republic of)",
    "alpha-2-code": "KR",
    "alpha-3-code": "KOR",
    numeric: 410,
  },
  {
    country: "Kuwait",
    "alpha-2-code": "KW",
    "alpha-3-code": "KWT",
    numeric: 414,
  },
  {
    country: "Kyrgyzstan",
    "alpha-2-code": "KG",
    "alpha-3-code": "KGZ",
    numeric: 417,
  },
  {
    country: "Lao People's Democratic Republic (the)",
    "alpha-2-code": "LA",
    "alpha-3-code": "LAO",
    numeric: 418,
  },
  {
    country: "Latvia",
    "alpha-2-code": "LV",
    "alpha-3-code": "LVA",
    numeric: 428,
  },
  {
    country: "Lebanon",
    "alpha-2-code": "LB",
    "alpha-3-code": "LBN",
    numeric: 422,
  },
  {
    country: "Lesotho",
    "alpha-2-code": "LS",
    "alpha-3-code": "LSO",
    numeric: 426,
  },
  {
    country: "Liberia",
    "alpha-2-code": "LR",
    "alpha-3-code": "LBR",
    numeric: 430,
  },
  {
    country: "Libya",
    "alpha-2-code": "LY",
    "alpha-3-code": "LBY",
    numeric: 434,
  },
  {
    country: "Liechtenstein",
    "alpha-2-code": "LI",
    "alpha-3-code": "LIE",
    numeric: 438,
  },
  {
    country: "Lithuania",
    "alpha-2-code": "LT",
    "alpha-3-code": "LTU",
    numeric: 440,
  },
  {
    country: "Luxembourg",
    "alpha-2-code": "LU",
    "alpha-3-code": "LUX",
    numeric: 442,
  },
  {
    country: "Macao",
    "alpha-2-code": "MO",
    "alpha-3-code": "MAC",
    numeric: 446,
  },
  {
    country: "Madagascar",
    "alpha-2-code": "MG",
    "alpha-3-code": "MDG",
    numeric: 450,
  },
  {
    country: "Malawi",
    "alpha-2-code": "MW",
    "alpha-3-code": "MWI",
    numeric: 454,
  },
  {
    country: "Malaysia",
    "alpha-2-code": "MY",
    "alpha-3-code": "MYS",
    numeric: 458,
  },
  {
    country: "Maldives",
    "alpha-2-code": "MV",
    "alpha-3-code": "MDV",
    numeric: 462,
  },
  {
    country: "Mali",
    "alpha-2-code": "ML",
    "alpha-3-code": "MLI",
    numeric: 466,
  },
  {
    country: "Malta",
    "alpha-2-code": "MT",
    "alpha-3-code": "MLT",
    numeric: 470,
  },
  {
    country: "Marshall Islands (the)",
    "alpha-2-code": "MH",
    "alpha-3-code": "MHL",
    numeric: 584,
  },
  {
    country: "Martinique",
    "alpha-2-code": "MQ",
    "alpha-3-code": "MTQ",
    numeric: 474,
  },
  {
    country: "Mauritania",
    "alpha-2-code": "MR",
    "alpha-3-code": "MRT",
    numeric: 478,
  },
  {
    country: "Mauritius",
    "alpha-2-code": "MU",
    "alpha-3-code": "MUS",
    numeric: 480,
  },
  {
    country: "Mayotte",
    "alpha-2-code": "YT",
    "alpha-3-code": "MYT",
    numeric: 175,
  },
  {
    country: "Mexico",
    "alpha-2-code": "MX",
    "alpha-3-code": "MEX",
    numeric: 484,
  },
  {
    country: "Micronesia (Federated States of)",
    "alpha-2-code": "FM",
    "alpha-3-code": "FSM",
    numeric: 583,
  },
  {
    country: "Moldova (the Republic of)",
    "alpha-2-code": "MD",
    "alpha-3-code": "MDA",
    numeric: 498,
  },
  {
    country: "Monaco",
    "alpha-2-code": "MC",
    "alpha-3-code": "MCO",
    numeric: 492,
  },
  {
    country: "Mongolia",
    "alpha-2-code": "MN",
    "alpha-3-code": "MNG",
    numeric: 496,
  },
  {
    country: "Montenegro",
    "alpha-2-code": "ME",
    "alpha-3-code": "MNE",
    numeric: 499,
  },
  {
    country: "Montserrat",
    "alpha-2-code": "MS",
    "alpha-3-code": "MSR",
    numeric: 500,
  },
  {
    country: "Morocco",
    "alpha-2-code": "MA",
    "alpha-3-code": "MAR",
    numeric: 504,
  },
  {
    country: "Mozambique",
    "alpha-2-code": "MZ",
    "alpha-3-code": "MOZ",
    numeric: 508,
  },
  {
    country: "Myanmar",
    "alpha-2-code": "MM",
    "alpha-3-code": "MMR",
    numeric: 104,
  },
  {
    country: "Namibia",
    "alpha-2-code": "NA",
    "alpha-3-code": "NAM",
    numeric: 516,
  },
  {
    country: "Nauru",
    "alpha-2-code": "NR",
    "alpha-3-code": "NRU",
    numeric: 520,
  },
  {
    country: "Nepal",
    "alpha-2-code": "NP",
    "alpha-3-code": "NPL",
    numeric: 524,
  },
  {
    country: "Netherlands (the)",
    "alpha-2-code": "NL",
    "alpha-3-code": "NLD",
    numeric: 528,
  },
  {
    country: "New Caledonia",
    "alpha-2-code": "NC",
    "alpha-3-code": "NCL",
    numeric: 540,
  },
  {
    country: "New Zealand",
    "alpha-2-code": "NZ",
    "alpha-3-code": "NZL",
    numeric: 554,
  },
  {
    country: "Nicaragua",
    "alpha-2-code": "NI",
    "alpha-3-code": "NIC",
    numeric: 558,
  },
  {
    country: "Niger (the)",
    "alpha-2-code": "NE",
    "alpha-3-code": "NER",
    numeric: 562,
  },
  {
    country: "Nigeria",
    "alpha-2-code": "NG",
    "alpha-3-code": "NGA",
    numeric: 566,
  },
  {
    country: "Niue",
    "alpha-2-code": "NU",
    "alpha-3-code": "NIU",
    numeric: 570,
  },
  {
    country: "Norfolk Island",
    "alpha-2-code": "NF",
    "alpha-3-code": "NFK",
    numeric: 574,
  },
  {
    country: "Northern Mariana Islands (the)",
    "alpha-2-code": "MP",
    "alpha-3-code": "MNP",
    numeric: 580,
  },
  {
    country: "Norway",
    "alpha-2-code": "NO",
    "alpha-3-code": "NOR",
    numeric: 578,
  },
  {
    country: "Oman",
    "alpha-2-code": "OM",
    "alpha-3-code": "OMN",
    numeric: 512,
  },
  {
    country: "Pakistan",
    "alpha-2-code": "PK",
    "alpha-3-code": "PAK",
    numeric: 586,
  },
  {
    country: "Palau",
    "alpha-2-code": "PW",
    "alpha-3-code": "PLW",
    numeric: 585,
  },
  {
    country: "Palestine, State of",
    "alpha-2-code": "PS",
    "alpha-3-code": "PSE",
    numeric: 275,
  },
  {
    country: "Panama",
    "alpha-2-code": "PA",
    "alpha-3-code": "PAN",
    numeric: 591,
  },
  {
    country: "Papua New Guinea",
    "alpha-2-code": "PG",
    "alpha-3-code": "PNG",
    numeric: 598,
  },
  {
    country: "Paraguay",
    "alpha-2-code": "PY",
    "alpha-3-code": "PRY",
    numeric: 600,
  },
  {
    country: "Peru",
    "alpha-2-code": "PE",
    "alpha-3-code": "PER",
    numeric: 604,
  },
  {
    country: "Philippines (the)",
    "alpha-2-code": "PH",
    "alpha-3-code": "PHL",
    numeric: 608,
  },
  {
    country: "Pitcairn",
    "alpha-2-code": "PN",
    "alpha-3-code": "PCN",
    numeric: 612,
  },
  {
    country: "Poland",
    "alpha-2-code": "PL",
    "alpha-3-code": "POL",
    numeric: 616,
  },
  {
    country: "Portugal",
    "alpha-2-code": "PT",
    "alpha-3-code": "PRT",
    numeric: 620,
  },
  {
    country: "Puerto Rico",
    "alpha-2-code": "PR",
    "alpha-3-code": "PRI",
    numeric: 630,
  },
  {
    country: "Qatar",
    "alpha-2-code": "QA",
    "alpha-3-code": "QAT",
    numeric: 634,
  },
  {
    country: "Republic of North Macedonia",
    "alpha-2-code": "MK",
    "alpha-3-code": "MKD",
    numeric: 807,
  },
  {
    country: "Romania",
    "alpha-2-code": "RO",
    "alpha-3-code": "ROU",
    numeric: 642,
  },
  {
    country: "Russian Federation (the)",
    "alpha-2-code": "RU",
    "alpha-3-code": "RUS",
    numeric: 643,
  },
  {
    country: "Rwanda",
    "alpha-2-code": "RW",
    "alpha-3-code": "RWA",
    numeric: 646,
  },
  {
    country: "R�union",
    "alpha-2-code": "RE",
    "alpha-3-code": "REU",
    numeric: 638,
  },
  {
    country: "Saint Barth�lemy",
    "alpha-2-code": "BL",
    "alpha-3-code": "BLM",
    numeric: 652,
  },
  {
    country: "Saint Helena, Ascension and Tristan da Cunha",
    "alpha-2-code": "SH",
    "alpha-3-code": "SHN",
    numeric: 654,
  },
  {
    country: "Saint Kitts and Nevis",
    "alpha-2-code": "KN",
    "alpha-3-code": "KNA",
    numeric: 659,
  },
  {
    country: "Saint Lucia",
    "alpha-2-code": "LC",
    "alpha-3-code": "LCA",
    numeric: 662,
  },
  {
    country: "Saint Martin (French part)",
    "alpha-2-code": "MF",
    "alpha-3-code": "MAF",
    numeric: 663,
  },
  {
    country: "Saint Pierre and Miquelon",
    "alpha-2-code": "PM",
    "alpha-3-code": "SPM",
    numeric: 666,
  },
  {
    country: "Saint Vincent and the Grenadines",
    "alpha-2-code": "VC",
    "alpha-3-code": "VCT",
    numeric: 670,
  },
  {
    country: "Samoa",
    "alpha-2-code": "WS",
    "alpha-3-code": "WSM",
    numeric: 882,
  },
  {
    country: "San Marino",
    "alpha-2-code": "SM",
    "alpha-3-code": "SMR",
    numeric: 674,
  },
  {
    country: "Sao Tome and Principe",
    "alpha-2-code": "ST",
    "alpha-3-code": "STP",
    numeric: 678,
  },
  {
    country: "Saudi Arabia",
    "alpha-2-code": "SA",
    "alpha-3-code": "SAU",
    numeric: 682,
  },
  {
    country: "Senegal",
    "alpha-2-code": "SN",
    "alpha-3-code": "SEN",
    numeric: 686,
  },
  {
    country: "Serbia",
    "alpha-2-code": "RS",
    "alpha-3-code": "SRB",
    numeric: 688,
  },
  {
    country: "Seychelles",
    "alpha-2-code": "SC",
    "alpha-3-code": "SYC",
    numeric: 690,
  },
  {
    country: "Sierra Leone",
    "alpha-2-code": "SL",
    "alpha-3-code": "SLE",
    numeric: 694,
  },
  {
    country: "Singapore",
    "alpha-2-code": "SG",
    "alpha-3-code": "SGP",
    numeric: 702,
  },
  {
    country: "Sint Maarten (Dutch part)",
    "alpha-2-code": "SX",
    "alpha-3-code": "SXM",
    numeric: 534,
  },
  {
    country: "Slovakia",
    "alpha-2-code": "SK",
    "alpha-3-code": "SVK",
    numeric: 703,
  },
  {
    country: "Slovenia",
    "alpha-2-code": "SI",
    "alpha-3-code": "SVN",
    numeric: 705,
  },
  {
    country: "Solomon Islands",
    "alpha-2-code": "SB",
    "alpha-3-code": "SLB",
    numeric: 90,
  },
  {
    country: "Somalia",
    "alpha-2-code": "SO",
    "alpha-3-code": "SOM",
    numeric: 706,
  },
  {
    country: "South Africa",
    "alpha-2-code": "ZA",
    "alpha-3-code": "ZAF",
    numeric: 710,
  },
  {
    country: "South Georgia and the South Sandwich Islands",
    "alpha-2-code": "GS",
    "alpha-3-code": "SGS",
    numeric: 239,
  },
  {
    country: "South Sudan",
    "alpha-2-code": "SS",
    "alpha-3-code": "SSD",
    numeric: 728,
  },
  {
    country: "Spain",
    "alpha-2-code": "ES",
    "alpha-3-code": "ESP",
    numeric: 724,
  },
  {
    country: "Sri Lanka",
    "alpha-2-code": "LK",
    "alpha-3-code": "LKA",
    numeric: 144,
  },
  {
    country: "Sudan (the)",
    "alpha-2-code": "SD",
    "alpha-3-code": "SDN",
    numeric: 729,
  },
  {
    country: "Suriname",
    "alpha-2-code": "SR",
    "alpha-3-code": "SUR",
    numeric: 740,
  },
  {
    country: "Svalbard and Jan Mayen",
    "alpha-2-code": "SJ",
    "alpha-3-code": "SJM",
    numeric: 744,
  },
  {
    country: "Sweden",
    "alpha-2-code": "SE",
    "alpha-3-code": "SWE",
    numeric: 752,
  },
  {
    country: "Switzerland",
    "alpha-2-code": "CH",
    "alpha-3-code": "CHE",
    numeric: 756,
  },
  {
    country: "Syrian Arab Republic",
    "alpha-2-code": "SY",
    "alpha-3-code": "SYR",
    numeric: 760,
  },
  {
    country: "Taiwan (Province of China)",
    "alpha-2-code": "TW",
    "alpha-3-code": "TWN",
    numeric: 158,
  },
  {
    country: "Tajikistan",
    "alpha-2-code": "TJ",
    "alpha-3-code": "TJK",
    numeric: 762,
  },
  {
    country: "Tanzania, United Republic of",
    "alpha-2-code": "TZ",
    "alpha-3-code": "TZA",
    numeric: 834,
  },
  {
    country: "Thailand",
    "alpha-2-code": "TH",
    "alpha-3-code": "THA",
    numeric: 764,
  },
  {
    country: "Timor-Leste",
    "alpha-2-code": "TL",
    "alpha-3-code": "TLS",
    numeric: 626,
  },
  {
    country: "Togo",
    "alpha-2-code": "TG",
    "alpha-3-code": "TGO",
    numeric: 768,
  },
  {
    country: "Tokelau",
    "alpha-2-code": "TK",
    "alpha-3-code": "TKL",
    numeric: 772,
  },
  {
    country: "Tonga",
    "alpha-2-code": "TO",
    "alpha-3-code": "TON",
    numeric: 776,
  },
  {
    country: "Trinidad and Tobago",
    "alpha-2-code": "TT",
    "alpha-3-code": "TTO",
    numeric: 780,
  },
  {
    country: "Tunisia",
    "alpha-2-code": "TN",
    "alpha-3-code": "TUN",
    numeric: 788,
  },
  {
    country: "Turkey",
    "alpha-2-code": "TR",
    "alpha-3-code": "TUR",
    numeric: 792,
  },
  {
    country: "Turkmenistan",
    "alpha-2-code": "TM",
    "alpha-3-code": "TKM",
    numeric: 795,
  },
  {
    country: "Turks and Caicos Islands (the)",
    "alpha-2-code": "TC",
    "alpha-3-code": "TCA",
    numeric: 796,
  },
  {
    country: "Tuvalu",
    "alpha-2-code": "TV",
    "alpha-3-code": "TUV",
    numeric: 798,
  },
  {
    country: "Uganda",
    "alpha-2-code": "UG",
    "alpha-3-code": "UGA",
    numeric: 800,
  },
  {
    country: "Ukraine",
    "alpha-2-code": "UA",
    "alpha-3-code": "UKR",
    numeric: 804,
  },
  {
    country: "United Arab Emirates (the)",
    "alpha-2-code": "AE",
    "alpha-3-code": "ARE",
    numeric: 784,
  },
  {
    country: "United Kingdom of Great Britain and Northern Ireland (the)",
    "alpha-2-code": "GB",
    "alpha-3-code": "GBR",
    numeric: 826,
  },
  {
    country: "United States Minor Outlying Islands (the)",
    "alpha-2-code": "UM",
    "alpha-3-code": "UMI",
    numeric: 581,
  },
  {
    country: "United States of America (the)",
    "alpha-2-code": "US",
    "alpha-3-code": "USA",
    numeric: 840,
  },
  {
    country: "Uruguay",
    "alpha-2-code": "UY",
    "alpha-3-code": "URY",
    numeric: 858,
  },
  {
    country: "Uzbekistan",
    "alpha-2-code": "UZ",
    "alpha-3-code": "UZB",
    numeric: 860,
  },
  {
    country: "Vanuatu",
    "alpha-2-code": "VU",
    "alpha-3-code": "VUT",
    numeric: 548,
  },
  {
    country: "Venezuela (Bolivarian Republic of)",
    "alpha-2-code": "VE",
    "alpha-3-code": "VEN",
    numeric: 862,
  },
  {
    country: "Viet Nam",
    "alpha-2-code": "VN",
    "alpha-3-code": "VNM",
    numeric: 704,
  },
  {
    country: "Virgin Islands (British)",
    "alpha-2-code": "VG",
    "alpha-3-code": "VGB",
    numeric: 92,
  },
  {
    country: "Virgin Islands (U.S.)",
    "alpha-2-code": "VI",
    "alpha-3-code": "VIR",
    numeric: 850,
  },
  {
    country: "Wallis and Futuna",
    "alpha-2-code": "WF",
    "alpha-3-code": "WLF",
    numeric: 876,
  },
  {
    country: "Western Sahara",
    "alpha-2-code": "EH",
    "alpha-3-code": "ESH",
    numeric: 732,
  },
  {
    country: "Yemen",
    "alpha-2-code": "YE",
    "alpha-3-code": "YEM",
    numeric: 887,
  },
  {
    country: "Zambia",
    "alpha-2-code": "ZM",
    "alpha-3-code": "ZMB",
    numeric: 894,
  },
  {
    country: "Zimbabwe",
    "alpha-2-code": "ZW",
    "alpha-3-code": "ZWE",
    numeric: 716,
  },
  {
    country: "�land Islands",
    "alpha-2-code": "AX",
    "alpha-3-code": "ALA",
    numeric: 248,
  },
];
