import styled from "styled-components";
import { colors } from "consts";
import { Flex, Text, Image } from "components";

export const UserMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${colors.gray200};
  height: 56px;
  max-width: 180px;
  width: 100%;
  padding: 12px 12px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
`;

export const UserMenuDropdown = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  background: ${colors.gray800};
  width: 180px;
  padding: ${({ active }) => (active ? "12px 16px" : "0px")};
  position: absolute;
  top: 64px;
  left: 0;
  height: ${({ active }) => (active ? "auto" : "0px")};
  opacity: ${({ active }) => (active ? "1" : "0")};
  transition: height 0.2 ease;
  border-radius: 8px;
  z-index: 2;
`;

export const UserImagePlaceholder = styled(Flex)`
  height: 32px;
  width: 32px;
  border-radius: 6px;
  background: ${colors.gray800};
`;

export const DropdownOption = styled(Text)`
  font-size: 14px;
  background: transparent;
  margin: ${({ active }) => (active ? "6px 0" : "0px")};
  height: ${({ active }) => (active ? "auto" : "0px")};
  width: 100%;
  cursor: ${({ active }) => (active ? "pointer" : "default")};
  color: ${colors.activeWhite1};
  &:hover {
    color: ${colors.white};
  }
`;

export const CaretIcon = styled(Image)`
  transform: ${({ active }) => (active ? "rotate(0deg)" : "rotate(180deg)")};
  transition: transform 0.2 ease;
`;
