import React, { useState, useEffect } from "react";
import { useAnalytics } from "use-analytics";
import { useHistory, useLocation } from "react-router-dom";
import {
  BackgroundProvider,
  AdminLayout,
  AdminCustomers,
  AdminEvents,
  AdminOrders,
  AdminLeadOwners,
  AdminGlobalTags,
} from "components";
import { ACCOUNT, CREDITS, PAGES, EVENTS, ORDERS } from "api";
import { routeTitles } from "consts";
import { toast } from "react-toastify";

// Retrieves data then triggers an automatic download in the browser
const downloadCSV = async (type) => {
  const data = {
    events: EVENTS.getAllCSV,
    customers: ACCOUNT.getAllCSV,
    orders: ORDERS.getAllOrdersCSV,
  };
  const res = await data[type]();
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `All-${type}__${new Date()}.csv`);
  document.body.appendChild(link);
  link.click();
};

export default ({ match }) => {
  // HOOKS
  const { page } = useAnalytics();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { adminRoute } = match.params;

  // STATE
  const [refresh, setRefresh] = useState();
  const [superArchiveId, setSuperArchiveId] = useState(null);
  const [restoreDetails, setRestoreDetails] = useState(null);

  // METHODS
  // Create props for the Accounts page
  const updateCredit = async (data) => {
    try {
      await CREDITS.createCredit(data);
    } catch (e) {
      alert(e);
    }
  };

  const edit = (e) => {
    const location = {
      pathname: `/event/${e}`,
      state: { superadmin: true },
    };

    push(location);
  };

  const superArchive = async () => {
    const res = await PAGES.update({ id: superArchiveId, superarchived: true });

    if (res.status === 200) {
      toast.success("Event successfully superarchived™️!");
      setRefresh(new Date());
      setSuperArchiveId(null);
    } else {
      setSuperArchiveId(null);
      toast.error(
        "There was an error archiving this event. Please contact support."
      );
    }
  };

  const restore = async () => {
    const res = await PAGES.update({
      id: restoreDetails.id,
      url: restoreDetails.url.split("-deprecated")[0],
      superarchived: false,
    });

    if (res.status === 200) {
      toast.success("Event successfully restored!");
      setRefresh(new Date());
      setRestoreDetails(null);
    } else {
      setRestoreDetails(null);
      toast.error(
        "There was an error restore this event. Please contact support."
      );
    }
  };

  const setPrompt = ({ id, url, type }) => {
    type === "archive" ? setSuperArchiveId(id) : setRestoreDetails({ id, url });
  };

  useEffect(() => {
    page();
  }, [page]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <BackgroundProvider minHeight="100vh">
      <AdminLayout text={routeTitles[adminRoute] || ""} type={adminRoute}>
        {adminRoute === "customers" && (
          <AdminCustomers
            updateCredit={updateCredit}
            downloadCSV={downloadCSV}
          />
        )}
        {adminRoute === "events" && (
          <AdminEvents
            edit={edit}
            restore={restore}
            refresh={refresh}
            setPrompt={setPrompt}
            superArchive={superArchive}
            restoreState={[restoreDetails, setRestoreDetails]}
            superArchiveState={[superArchiveId, setSuperArchiveId]}
            downloadCSV={downloadCSV}
          />
        )}
        {adminRoute === "leads" && (
          <AdminLeadOwners downloadCSV={downloadCSV} />
        )}
        {adminRoute === "globaltags" && (
          <AdminGlobalTags downloadCSV={downloadCSV} />
        )}
        {adminRoute === "orders" && <AdminOrders downloadCSV={downloadCSV} />}
      </AdminLayout>
    </BackgroundProvider>
  );
};
