import React, { useEffect } from "react";
import { useAnalytics } from "use-analytics";
import { Header, BackgroundProvider, Flex, Text } from "components";

export default () => {
  const { page } = useAnalytics();

  useEffect(() => {
    page();
  }, [page]);

  return (
    <BackgroundProvider align="flex-start" minHeight="100vh">
      <Header showBackButton={false} />

      <Flex margin="89px 0 0">
        <Flex
          width="1500px"
          margin="0 auto"
          padding="60px 40px"
          direction="column"
          align="flex-start"
          justify="flex-start"
        >
          <Flex margin="24px 0" direction="column" align="flex-start">
            <Text size="64px" weight="500" margin="0 0 17px" className="canela">
              Settings
            </Text>
            <Text>Complete or update your account settings</Text>
          </Flex>
        </Flex>
      </Flex>
    </BackgroundProvider>
  );
};
