import React, { useEffect } from "react";
import { useAnalytics } from "use-analytics";
import { Button, Header, BackgroundProvider, Flex, Text } from "components";
import { USERS } from "api";
import { colors } from "consts";
import styled from "styled-components";
import { minMedia } from "utils";

import ReactPlayer from "react-player";

export const VideoWrapper = styled(Flex)`
  height: 320px;
  width: 95%;

  ${minMedia.tablet`
    height: 320px;
    width: 720px;
  `}

  ${minMedia.laptop`
    height: 500px;
    width: 1000px;
  `}

  ${minMedia.monitor`
  height: 720px;
  width: 1400px
`}
`;

export default () => {
  const { page } = useAnalytics();

  useEffect(() => {
    page();
    window.scrollTo(0, 0);
  }, [page]);

  const startOnboarding = async () => {
    const _link = await USERS.getOnboardLink();
    window.location.href = _link.data.data.url;
  };

  return (
    <BackgroundProvider minHeight="100vh" color={colors.white}>
      <Header user showBackButton={false} />
      <Flex
        justify="flex-start"
        align="center"
        direction="column"
        margin="120px auto 0"
      >
        <Text
          size="54px"
          className="canela"
          center
          margin="0 0 28px 0"
          color={colors.black}
        >
          Thank you for creating your account
        </Text>
        <Text
          size="24px"
          weight="bold"
          center
          margin="0 0 64px 0"
          color={colors.black}
        >
          Please watch the video below for next steps
        </Text>
        <VideoWrapper justify="center" align="center">
          <ReactPlayer
            controls={true}
            playsinline={true}
            url={`https://player.vimeo.com/video/794668466`}
            width="100%"
            height="100%"
          />
        </VideoWrapper>
        <Button
          padding="16px 24px"
          margin="36px 0 120px"
          onClick={startOnboarding}
        >
          <Text size="20px" weight="bold" color={colors.white}>
            Continue to Stripe
          </Text>
        </Button>
      </Flex>
    </BackgroundProvider>
  );
};
