import React from "react";
import { Flex, FilterSelect, Text, Searchbar } from "components";
import { UserMenu } from ".";
import { isProduction } from "utils";
import { colors } from "consts";
import { USERS } from "api";
import { useParams } from "react-router-dom";

export default ({
  text,
  searchTerm,
  setSearchTerm,
  filterState: [filters, setFilters],
  type,
}) => {
  // HOOKS
  const { adminRoute } = useParams();

  // HELPERS
  const placeholders = {
    leads: "Name, Email",
    customers: "Name, Email",
    events: "Title, URL, Account Name, Account Email",
    orders: "Name, Email, Event URL, Event Title",
    globaltags: "Name",
  };

  // METHODS
  const logout = async () => {
    await USERS.logout();
    localStorage.clear();
    window.location.href = isProduction
      ? "https://showandtell.film/"
      : "/login";
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Flex
      justify="space-between"
      align="center"
      width="100%"
      height="88px"
      padding="60px 0"
    >
      <Flex>
        <Text
          size="24px"
          margin="0 12px 0 0"
          color={colors.white}
          weight="bold"
        >
          {text || "Customers"}
        </Text>
        <Searchbar
          placeholder={placeholders[adminRoute]}
          margin="0 16px"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <FilterSelect filters={filters} setFilters={setFilters} type={type} />
      </Flex>
      <UserMenu logout={logout} />
    </Flex>
  );
};
