import React, { useState, useEffect } from "react";
import { Text, Flex, ModalProvider } from "components";
import { Cropper } from "./";
import { FILES } from "api";
import { colors } from "consts";
import "react-image-crop/dist/ReactCrop.css";
import * as S from "./Cropper.styled";

export default ({
  open: [open, setOpen] = [false, () => {}],
  url,
  information,
  updateInformation,
  target,
}) => {
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(true);

  const imageToBlob = async () => {
    const imageURL = url.split(/\//g);
    const fileName = imageURL[imageURL.length - 1];
    const s3Blob = await FILES.getS3Image(fileName);

    setImage({
      url: URL.createObjectURL(s3Blob.data),
      name: fileName,
      type: s3Blob.headers["content-type"],
    });
  };

  useEffect(() => {
    url && imageToBlob();
    // eslint-disable-next-line
  }, [url]);

  return (
    <ModalProvider
      width="750px"
      margin="110px"
      padding="32px"
      show={open}
      close={() => setOpen(false)}
    >
      <S.CropLoading {...(loading && { loading: true })} />
      <Flex direction="column" align="flex-start" padding="40px 0">
        <Text className="canela" size="64px" margin="0 0 12px">
          Banner Image
        </Text>
        <Text size="14px" color={colors.gray8}>
          Using the cropping tool below please select the part of your image
          that you would like to be displayed on your event page. This tool
          gives you control over what parts of the image you want to be seen
          while maintaining the dimensions required by Show&Tell.
        </Text>
      </Flex>

      {image && (
        <Cropper
          image={image}
          {...(loading && { loading: true })}
          setLoading={setLoading}
          information={information}
          updateInformation={updateInformation}
          closeModal={() => setOpen(false)}
          target={target}
        />
      )}
    </ModalProvider>
  );
};
